import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import Checkbox from '../../../components/Forms/Checkbox'
import Loading from '../../../components/Loading'
import { makeCreatePicPayOrder } from '../../../main/factories/makeCreatePicPayOrder'
import { makeLoadMe } from '../../../main/factories/makeLoadMe'
import { MaskDocument, maskNumber } from '../../../utils/mask'
import InputTextMdlz from '../InputTextMdlz'
import character from './assets/character.png'
import character2 from './assets/character2.png'
import RegisterProps from './props'
import * as S from './styled'

const loadMe = makeLoadMe()
const createPicPayOrder = makeCreatePicPayOrder()

enum State {
  LOADING,
  READY,
  SAVING
}

export default function Register ({ onClick }: RegisterProps): ReactElement {
  const [me, setMe] = useState<any>()
  const [state, setState] = useState<State>(State.LOADING)
  const [visibleDetails, setVisibleDetails] = useState(false)
  const [claimValue, setClaimValue] = useState<any>({
    claimValue: 0,
    accept: false,
    inputDisabled: true
  })
  const [errors, setErrors] = useState<Record<string, string | null>>({})

  const coins = useMemo(() => {
    if (!me) return 0
    const value = me.purchasedCoins / 100
    return value.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }, [me])

  const convertedValue = useMemo(() => {
    const value = Math.ceil(claimValue.claimValue * 88) / 100
    return value.toLocaleString('pt-br', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
  }, [claimValue.claimValue])

  const disable = useMemo(() => {
    if (state === State.LOADING) return false
    return claimValue.inputDisabled
  }, [claimValue.inputDisabled, state])

  const handleClaim = useCallback(async (evt: any) => {
    evt.preventDefault()
    if (state === State.SAVING) return
    setState(State.SAVING)
    const response = await createPicPayOrder.handle({ claimValue: claimValue.claimValue })
    console.log(response.status)
    setState(State.READY)
    onClick()
  }, [claimValue, state])

  useEffect(() => {
    if (!me) return
    setErrors({})
    let hasError = false
    if (!claimValue.accept) {
      setErrors(errors => ({ ...errors, accept: 'O campo é obrigatório' }))
      hasError = true
    }
    if (claimValue.claimValue < 10) {
      setErrors(errors => ({ ...errors, claimValue: 'O valor minimo é 10' }))
      hasError = true
    }
    if (claimValue.claimValue > (me.purchasedCoins / 100)) {
      setErrors(errors => ({ ...errors, claimValue: 'Saldo insuficiente' }))
      hasError = true
    }

    if (!hasError) {
      setClaimValue((claim: any) => ({ ...claim, inputDisabled: false }))
      return
    }
    setClaimValue((claim: any) => ({ ...claim, inputDisabled: true }))
  }, [claimValue.claimValue, claimValue.accept, me])

  useEffect(() => {
    (async () => {
      const response = await loadMe.handle()
      switch (response.status) {
        case 200:
          setMe(response.data)
          setState(State.READY)
          break
        case 500:
          toast.error('Ocorreu um erro inesperado', { theme: 'colored' })
      }
    })()
  }, [])

  if (state === State.LOADING) return <Loading />

  return <S.Container>
    <S.Character>
      <img src={character} alt="" />
    </S.Character>

    <S.Form onSubmit={handleClaim}>
      <InputTextMdlz
        label="Nome completo:"
        name="name"
        onChange={() => { }}
        value={me.name}
        readonly={true}
        className='input'
      />

      <InputTextMdlz
        label="CPF:"
        mask={MaskDocument}
        name="document"
        onChange={() => { }}
        value={me.document}
        readonly={true}
        className='input'
      />

      <S.DigimoedasContainer>
        <p>Você tem:</p>

        <div>
          <p>{coins} <br /><span>pontos</span></p>

          <figure>
            <img src={character2} alt="" />
          </figure>
        </div>
      </S.DigimoedasContainer>

      <div>
        <InputTextMdlz
          label="Valor do resgate:"
          name="claimValue"
          onChange={setClaimValue}
          prefix="$"
          value={claimValue.claimValue}
          mask={maskNumber}
          className='input'
        />
        {errors.claimValue && <p className='error'>{errors.claimValue}</p>}
      </div>

      <InputTextMdlz
        label="Valor convertido:"
        name="convertedAmount"
        onChange={() => { }}
        prefix="R$"
        value={convertedValue}
        readonly={true}
        className='input'
      />

      <S.ViewDetails>
        <p onClick={() => setVisibleDetails(!visibleDetails)}><strong>Ver Mais Detalhes</strong></p>

        {visibleDetails && <div>
          <p><strong>Valor mínimo para resgate R$ 10,00</strong></p>

          <p>
            A taxa de conversão de pontos para crédito na carteira PICPAY é de 12%, nesta operação você será descontado de {claimValue.claimValue && claimValue.claimValue !== '' ? claimValue.claimValue : 0} pontos
          </p>
        </div>}
      </S.ViewDetails>

      <S.TermsContainer>
        <Checkbox
          checked={claimValue.accept}
          name="accept"
          onChange={(accept) => setClaimValue((state: any) => ({ ...state, accept }))}
          text="Li e aceito os termos referentes a conversão de pontos em crédito na minha carteira PICPAY."
        />
        {errors.accept && <p className='error'>{errors.accept}</p>}
      </S.TermsContainer>

      <button type="submit" disabled={disable}>Resgatar valor</button>
    </S.Form>
  </S.Container>
}
