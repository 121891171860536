import { FetchHeader } from '../../domain/use-case/FetchHeader'
import { FetchHeadAdapter } from '../protocols/FetchHeadAdapter'

export class ExcelFetchHeader implements FetchHeader {
  private readonly fetchHeadAdapter: FetchHeadAdapter

  constructor (fetchHeadAdapter: FetchHeadAdapter) {
    this.fetchHeadAdapter = fetchHeadAdapter
  }

  async handle (params: FetchHeader.Params): Promise<FetchHeader.Result> {
    return {
      headers: await this.fetchHeadAdapter.fetchHead(params.file)
    }
  }
}
