import styled from 'styled-components'

export const Container = styled.button`
  aspect-ratio: 98 / 154;
  align-items: center;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;

  figure {
    flex: 0;
    margin: 0;

    img {
      border-radius: 6px;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }

  div:first-of-type {
    padding: 8px;

    p {
      color: #4f2170;
      font-family: MdlzBiteType;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0;
      margin: 0;
    }
  }


  &:last-of-type {
    > div:last-of-type {
      display: none;
    }
  }

  :focus {
    outline: none;
  }

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    div:first-of-type {
      p {
        font-size: 24px;
      }
    }

    &, figure img {
      border-radius: 12px;
    }

  }
`

export const OrContainer = styled.div`
  align-items: center;
  background-color: #eec52c;
  border-radius: 50%;
  display: flex;
  height: 22px;
  justify-content: center;
  position: absolute;
  right: -12%;
  top: 36%;
  width: 22px;
  z-index: 1;

  p {
    color: #4f2170;
    font-size: 16px;
    margin: 0;
  }

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    right: -8%;
  }
`
