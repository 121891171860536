import { ReactElement } from 'react'
import { SurveyStartModalProps } from './props'
import * as S from './styled'

function SurveyStartModal ({ onStart }: SurveyStartModalProps): ReactElement {
  return <S.Container>
    <h1>Regras Gerais:</h1>
    <p>No game Seu Destino é Gigante você escolhe a nossa viagem de premiação:</p>
    <p>-1 pergunta por etapa;</p>
    <p>-é só clicar na resposta de que você mais gosta e fazer o nosso destino!</p>
    <p>Atenção: não vale digimoedas.</p>
    <p>Nossa viagem será para o destino + votado.</p>
    <p> <br /> Bora!</p>

    <S.Button onClick={onStart}>
      Jogar
    </S.Button>
  </S.Container>
}

export default SurveyStartModal
