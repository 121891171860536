import { ReactElement } from 'react'
import * as S from './style'

export default function ProgressCircle ({ progress = '0' }: { progress: string }): ReactElement {
  const radius = 50
  const circumference = 2 * Math.PI * radius
  const offset = circumference - (Math.min(+progress.replace(',', '.') ?? 0, 100) / 100) * circumference

  return (
    <S.Container className="progress-circle">
      <p className="progress-text">{isNaN(+progress.replace(',', '.')) ? 0 : progress}%</p>

      <svg className="progress-ring" width="120" height="120">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#7aebfc" />
            <stop offset="100%" stopColor="#6061f1" />
          </linearGradient>
        </defs>
        <circle
          className="progress-ring-circle"
          stroke="#dedee9"
          strokeWidth="8"
          fill="#fff"
          r={radius}
          cx="60"
          cy="60"
        />
        <circle
          className="progress-ring-circle"
          stroke="url(#gradient)"
          strokeWidth="8"
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset: offset }}
          fill="#fff"
          r={radius}
          cx="60"
          cy="60"
        />
      </svg>
    </S.Container>
  )
}
