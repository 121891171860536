import { ReactElement } from 'react'
import Card from '../../../../../../components/Card'
import * as S from './style'

interface StatisticsProps {
  pontos: string
  items: number
  totalItemsResgatados: string
  pontosGastos: string
  pontosAdiquiridos: string
}

export default function Statistics ({ pontos, items, totalItemsResgatados, pontosGastos, pontosAdiquiridos }: StatisticsProps): ReactElement {
  return <>
    <Card className="single-card">
      <S.Statistics className="main-stat">
        <S.BalanceContainer>
          <S.Icon>
            <svg className="icon-wallet">
              <use href="#svg-wallet"></use>
            </svg>
          </S.Icon>

          <S.StatContent>
            <S.StatTitle>
              {pontos}
              {' '}
              <span>PONTOS</span>
            </S.StatTitle>

            <S.StatDescription>
              SALDO DA CONTA
            </S.StatDescription>
          </S.StatContent>
        </S.BalanceContainer>

        <S.UserStatsContainer>
          <S.UserStat>
            <S.StatTitle>{items}</S.StatTitle>
            <S.StatDescription>ITENS</S.StatDescription>
          </S.UserStat>
        </S.UserStatsContainer>
      </S.Statistics>
    </Card>

    <S.GridContainer>
      <Card className="single-card">
        <S.Statistics>
          <S.Icon>
            <svg className="icon-item">
              <use href="#svg-item"></use>
            </svg>
          </S.Icon>

          <S.StatContent>
            <S.StatTitle>{totalItemsResgatados}</S.StatTitle>

            <S.StatDescription>
              TOTAL DE ITENS RESGATADOS
            </S.StatDescription>
          </S.StatContent>
        </S.Statistics>
      </Card>

      <Card className="single-card">
        <S.Statistics>
          <S.Icon>
            <svg className="icon-earnings">
              <use href="#svg-earnings"></use>
            </svg>
          </S.Icon>

          <S.StatContent>
            <S.StatTitle>
              {pontosGastos}
              {' '}
              <span>PONTOS</span>
            </S.StatTitle>

            <S.StatDescription>
              PONTOS GASTOS
            </S.StatDescription>
          </S.StatContent>
        </S.Statistics>
      </Card>

      <Card className="single-card">
        <S.Statistics>
          <S.Icon>
            <svg className="icon-revenue">
              <use href="#svg-revenue"></use>
            </svg>
          </S.Icon>

          <S.StatContent>
            <S.StatTitle>
              {pontosAdiquiridos}
              {' '}
              <span>PONTOS</span>
            </S.StatTitle>

            <S.StatDescription>
              PONTOS ADQUIRIDOS
            </S.StatDescription>
          </S.StatContent>
        </S.Statistics>
      </Card>
    </S.GridContainer>
  </>
}
