import { ReactElement, ReactNode, useState } from 'react'
import Accordion from '../../components/Accordion'
import Template from '../../template'
import * as S from './style'

interface FaqProps {
  question: string
  answer: ReactNode
}

const faqs: FaqProps[] = [
  {
    question: 'Quem participa da Campanha?',
    answer: <>
      <p>Vendas: Todos os Gerentes Regionais, Gerente de Área e Executivos dos canais TT, C&C, MT, Food, Dia e Farma.</p>
      <p>Merchandising: Todos os Gerentes, Coordenadores, Promotores e Aprendizes.</p>
      <p>Somente os colaboradores efetivos da Mondelēz são elegíveis à premiação. Contratações temporárias para projetos esporádicos não poderão participar da campanha.</p>
    </>
  },
  {
    question: 'Como faço para participar?',
    answer: <>
      <p>Para participar, o colaborador tem que realizar a sua adesão na campanha, através do site: <a href="https://www.gigantesdaexecucao.com">www.gigantesdaexecucao.com</a> e aceitar os termos do regulamento. O aceite é requisito obrigatório para a participação na campanha e deverá ser renovado, assim como os dados cadastrais, no início de cada onda da campanha.</p>
    </>
  },
  {
    question: 'Qual o período da campanha e o prazo de resgate de premiação?',
    answer: <>
      <p>A Campanha terá duração de 01 de fevereiro a 30 de novembro/2024.</p>
      <p>Resgates de prêmios até o dia 29/02/2025.</p>
    </>
  },
  {
    question: 'Como faço para saber a minha meta/KPI’s por onda?',
    answer: <>
      <p>Suas metas/KPI’s estarão disponíveis no site <a href="https://www.gigantesdaexecucao.com">www.gigantesdaexecucao.com</a> separada por canal dentro da sessão “Mecânicas”. O acesso pode ser feito através da navegação online de desktops ou também via celular durante todo o período da Campanha.</p>
    </>
  },
  {
    question: 'Quais os produtos de cada onda?',
    answer: <>
      <p>Verifique a mecânica completa e separada por canal dentro da sessão “Mecânicas” no site: <a href="https://www.gigantesdaexecucao.com">www.gigantesdaexecucao.com</a>.</p>
    </>
  },
  {
    question: 'Qual o período de duração de cada onda?',
    answer: <>
      <p>1ª onda: fevereiro/março</p>
      <p>2ª onda: abril/maio/junho</p>
      <p>3ª onda: julho/agosto/setembro</p>
      <p>4ª onda: outubro/novembro</p>
    </>
  },
  {
    question: 'Qual o fluxo da campanha?',
    answer: <>
      <p>Execução: Acompanhamento quinzenal do atingimento dos targets por colaborador;</p>
      <p>Apuração: Avaliação e cumprimento de metas;</p>
      <p>Divulgação: Realizada em 60 dias a contar do final da apuração;</p>
      <p>Contestações: Período aberto para ajuste de apuração caso necessário;</p>
      <p>Premiação: Realizada em até 60 dias após a divulgação do resultado.</p>
    </>
  },
  {
    question: 'Os pontos são acumulativos?',
    answer: <>
      <p>Sim. Você pode acumular seus pontos e resgatá-los no mês em que forem creditados, ou durante o período da Campanha de 2024.</p>
    </>
  },
  {
    question: 'Quais as novidades para ganhar mais pontos e prêmios?',
    answer: <>
      <p>Fique ligado no site da campanha e nos comunicados, teremos missões, jogos e campanhas aceleradoras para acúmulo de pontos para participar de leilão de prêmios e acúmulo para resgate de prêmios nas lojas parceiras.</p>
    </>
  },
  {
    question: 'Quantos serão os melhores premiados por onda do Time de Merchandising?',
    answer: <>
      <table>
        <tr>
          <td>Canal</td>
          <td>Promotores e Aprendizes</td>
          <td>Coordenadores</td>
          <td>Gerentes</td>
        </tr>
        <tr>
          <td>Merchan</td>
          <td>400</td>
          <td>35</td>
          <td>2</td>
        </tr>
      </table>
    </>
  },
  {
    question: 'Quantos serão os melhores premiados por onda do Time de Vendas?',
    answer: <>
      <p><strong>- Premiação por Onda:</strong></p>
      <table>
        <tbody>
          <tr>
            <td>
              <center><b>Canal</b></center>
            </td>
            <td>
              <center><b>Executivo</b></center>
            </td>
            <td>
              <center><b>GA</b></center>
            </td>
            <td>
              <center><b>GR</b></center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>Premiação</b></center>
            </td>
            <td>
              <center>R$ 800,00</center>
            </td>
            <td>
              <center>R$ 1.000,00</center>
            </td>
            <td>
              <center>R$ -</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>ATC</b></center>
            </td>
            <td>
              <center>4</center>
            </td>
            <td>
              <center>1</center>
            </td>
            <td rowSpan={10}>
              <center>0</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>TD</b></center>
            </td>
            <td>
              <center>4</center>
            </td>
            <td>
              <center>1</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>VJ</b></center>
            </td>
            <td>
              <center>4</center>
            </td>
            <td>
              <center>1</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>HIB</b></center>
            </td>
            <td>
              <center>4</center>
            </td>
            <td>
              <center>1</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>MT</b></center>
            </td>
            <td>
              <center>8</center>
            </td>
            <td>
              <center>2</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>FARMA + DIA</b></center>
            </td>
            <td>
              <center>1</center>
            </td>
            <td>
              <center>1</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>GKA + Americanas</b></center>
            </td>
            <td>
              <center>1</center>
            </td>
            <td>
              <center>1</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>C&C</b></center>
            </td>
            <td>
              <center>3</center>
            </td>
            <td>
              <center>1</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>FOOD</b></center>
            </td>
            <td>
              <center>1</center>
            </td>
            <td>
              <center>1</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>ECOMM</b></center>
            </td>
            <td>
              <center>1</center>
            </td>
            <td>
              <center>1</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>TOTAL PREMIADOS</b></center>
            </td>
            <td>
              <center><b>31</b></center>
            </td>
            <td>
              <center><b>11</b></center>
            </td>
            <td>
              <center><b>0</b></center>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p><b>Premiados FINAL - VIAGEM</b></p>
      <table>
        <tbody>
          <tr>
            <td>
              <center><b>Canal</b></center>
            </td>
            <td>
              <center><b>Executivo</b></center>
            </td>
            <td>
              <center><b>GA</b></center>
            </td>
            <td>
              <center><b>GR</b></center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>ATC</b></center>
            </td>
            <td>
              <center>1</center>
            </td>
            <td rowSpan={4}>
              <center>4</center>
            </td>
            <td rowSpan={4}>
              <center>2</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>TD</b></center>
            </td>
            <td>
              <center>1</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>VJ</b></center>
            </td>
            <td>
              <center>1</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>HIB</b></center>
            </td>
            <td>
              <center>1</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>MT</b></center>
            </td>
            <td>
              <center>1</center>
            </td>
            <td>
              <center>2</center>
            </td>
            <td>
              <center>1</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>FARMA + DIA</b></center>
            </td>
            <td>
              <center>1</center>
            </td>
            <td rowSpan={2}>
              <center>1</center>
            </td>
            <td rowSpan={2}>
              <center>1</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>GKA + Americanas</b></center>
            </td>
            <td>
              <center>1</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>C&C</b></center>
            </td>
            <td>
              <center>1</center>
            </td>
            <td rowSpan={2}>
              <center>1</center>
            </td>
            <td rowSpan={2}>
              <center>1</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>FOOD</b></center>
            </td>
            <td>
              <center>1</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>ECOMM</b></center>
            </td>
            <td>
              <center>1</center>
            </td>
            <td>
              <center>1</center>
            </td>
            <td>
              <center>1</center>
            </td>
          </tr>
          <tr>
            <td>
              <center><b>TOTAL PREMIADOS</b></center>
            </td>
            <td>
              <center><b>10</b></center>
            </td>
            <td>
              <center><b>9</b></center>
            </td>
            <td>
              <center><b>6</b></center>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  },
  {
    question: 'Como eu faço para acumular pontos mensalmente?',
    answer: <>
      <p>Para acumular pontos, basta bater suas metas e missões mensais. A quantidade de pontos de cada meta e missão será informada no site da Campanha: <a href="https://www.gigantesdaexecucao.com">www.gigantesdaexecucao.com</a> e através do e-mail corporativo de todos os colaboradores.</p>
    </>
  },
  {
    question: 'Caso eu esteja dentro do ranking de premiados, como faço para resgatar os prêmios?',
    answer: <>
      <p>Acesse o link de resgate de prêmios no site da Campanha, insira suas informações na loja parceira/escolhida, para receber o prêmio escolhido em seu endereço.</p>
    </>
  },
  {
    question: 'Caso o prêmio entregue esteja avariado, quem devemos acionar?',
    answer: <>
      <p>Entre em contato com o SAC, via e-mail: <a href="mailto:contato@gigantesdaexecucao.com">contato@gigantesdaexecucao.com</a> ou WhatsApp: (11) 98863-7184.</p>
    </>
  },
  {
    question: 'Quem acionar caso seja identificado erro no cálculo da meta ou apuração?',
    answer: <>
      <p>Entre em contato com o SAC, via e-mail: <a href="mailto:contato@gigantesdaexecucao.com">contato@gigantesdaexecucao.com</a> ou WhatsApp: (11) 98863-7184.</p>
    </>
  },
  {
    question: 'Como funciona a pontuação em caso de mudança de cargo ou posição do participante?',
    answer: <>
      <p>Para ser elegível, o participante tem que atender o cliente por mais de 50% do tempo do Programa. Em caso de mudança de cargo durante o período do Programa, ganhará a premiação quem tiver mais tempo na função.</p>
    </>
  },
  {
    question: 'Como fica a premiação caso o participante seja desligado?',
    answer: <>
      <p>Em caso de desligamento (sem justa causa, por justa causa e a pedido do colaborador), seja durante o período da campanha ou até no período de entrega da premiação, o participante não terá mais direito à premiação.</p>
    </>
  },
  {
    question: 'No caso de participantes afastados durante a campanha, como fica a pontuação?',
    answer: <>
      <p>a) O participante que permanecer afastado durante parte da duração da Campanha terá os pontos que obteve considerados proporcionalmente ao período efetivamente trabalhado, desde que tenha realizado o aceite na onda.</p>
      <p>b) O participante que permanecer afastado durante toda a duração da Campanha não será elegível ao Programa, pois não haverá prestação de serviços.</p>
    </>
  },
  {
    question: 'No caso de participantes em férias durante alguma onda, como fica a pontuação?',
    answer: <>
      <p>Se o colaborador estiver em período de férias durante o período de aceite da Campanha/Onda, este terá 15 (quinze) dias corridos, após o seu retorno, para solicitar a abertura de inscrição através do SAC: <a href="mailto:contato@gigantesdaexecucao.com">contato@gigantesdaexecucao.com</a>, para que se torne elegível à premiação. Caso a solicitação não ocorra dentro do prazo determinado, será necessário aguardar o início da próxima onda.</p>
    </>
  },
  {
    question: 'No caso de participantes que forem admitidos após o período de aceite de alguma onda, como fica a pontuação?',
    answer: <>
      <p>Em caso de colaboradores que forem admitidos após o período de aceite da Onda, será aberta a exceção para que ele entre em contato com o time responsável em até 15 (quinze) dias corridos, após sua efetivação, a fim de solicitar a abertura do aceite fora do período regulamentar. Caso a solicitação não ocorra dentro do prazo determinado, será necessário aguardar o início da próxima onda.</p>
    </>
  },
  {
    question: 'Ainda tenho dúvidas, com quem posso falar?',
    answer: <>
      <p>Entre em contato com o SAC: <a href="mailto:contato@gigantesdaexecucao.com">contato@gigantesdaexecucao.com</a> ou WhatsApp: (11) 98863-7184.</p>
    </>
  }
]

function Faq (): ReactElement {
  const [activeAccordion, setActiveAccordion] = useState(-1)

  const handleOnOpen = (newIndex: number): void => setActiveAccordion(newIndex)

  return <Template>
    <div style={{ marginTop: '8rem' }}></div>

    <div className="section-header">
      <div className="section-header-info">
        <p className="section-pretitle"></p>
        <h2 className="section-title">{''}</h2>
      </div>
    </div>

    <S.WidgetBox className="widget-box">

      <p className="widget-box-title">
        Confira abaixo as perguntas frequentes e caso permaneça com alguma dúvida, entre em contato através de um chamado no campo ao final dela.
      </p>

      <div className="widget-box-content">
        <div className="simple-accordion-list">
          {faqs.map((faq, index) => <Accordion index={index} question={faq.question} isOpen={activeAccordion === index} onOpen={handleOnOpen}>{faq.answer}</Accordion>)}
        </div>
      </div>
    </S.WidgetBox>
  </Template>
}

export default Faq
