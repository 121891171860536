import { ReactElement } from 'react'
import footprintIcon from '../../assets/footprint-icon.svg'
import * as S from './style'

export default function PresencaText (): ReactElement {
  return (
    <S.PresencaTextContainer>
      <div>
        <p className='pegadas'>
          A porcentagem da média de atingimento de Presença (Total Marcas) das lojas <br />
          =<br />
          número de Gigapassos <img src={footprintIcon} alt='ícone de pegada' />
        </p>
      </div>
      <S.PresencaTextContainerBottom>
        <p><strong>EXEMPLO:</strong><br />
          Se um promotor fizer uma média de 80% de Presença (Total Marcas) entre todas as suas lojas, ele receberá <span>80 Gigapassos.</span></p>
      </S.PresencaTextContainerBottom>
    </S.PresencaTextContainer>
  )
}
