import styled from 'styled-components'
import background from '../../../../assets/img/game-gira-giro/win-bg.png'

export const Container = styled.div`
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding: 100px 32px;
  width: 100%;

  h1, p, span {
    text-align: center;
  }

  h1 {
    color: #e9c300;
    font-size: 80px;
    letter-spacing: 1px;
    margin-bottom: 8px;
  }

  p {
    color: var(--white);
    filter: drop-shadow(0px 1px 3.5px rgba(21,21,22,0.15));
    font-size: 36px;

    span {
      color: #002874;
      background-color: #e9c300;
      border-radius: 16px;
      padding: 0 8px 4px;
    }

    img {
      margin-bottom: 4px;
    }
  }


  @media (max-width: 764px) {
    h1 {
      font-size: 52px;
    }

    p {
      font-size: 20px;

      img {
        width: 10%;
      }
    }
  }

  @media (max-width: 500px) {
    padding: 0 32px 50px 32px;
  }

  @media (max-width: 500px) {
    padding-top: 100px;
    padding-bottom: 55%;
  }
`
