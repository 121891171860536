import bat from '../../assets/bat.png'
import crow from '../../assets/crow.png'
import skeleton from '../../assets/skeleton.png'
import tombstone from '../../assets/tombstone.png'
import web from '../../assets/web.png'
import { adjustHorizontal, adjustVertical } from './helpers'

export default [
  {
    exists: true,
    img: bat,
    points: 55,
    x: adjustHorizontal(150),
    y: adjustVertical(50)
  },
  {
    exists: true,
    img: bat,
    points: 55,
    x: adjustHorizontal(230),
    y: adjustVertical(50)
  },
  {
    exists: true,
    img: skeleton,
    points: 35,
    x: adjustHorizontal(110),
    y: adjustVertical(120)
  },
  {
    exists: true,
    img: skeleton,
    points: 35,
    x: adjustHorizontal(185),
    y: adjustVertical(120)
  },
  {
    exists: true,
    img: skeleton,
    points: 35,
    x: adjustHorizontal(265),
    y: adjustVertical(120)
  },
  {
    exists: true,
    img: web,
    points: 25,
    x: adjustHorizontal(110),
    y: adjustVertical(190)
  },
  {
    exists: true,
    img: web,
    points: 25,
    x: adjustHorizontal(185),
    y: adjustVertical(190)
  },
  {
    exists: true,
    img: web,
    points: 25,
    x: adjustHorizontal(260),
    y: adjustVertical(190)
  },
  {
    exists: true,
    img: crow,
    points: 15,
    x: adjustHorizontal(40),
    y: adjustVertical(260)
  },
  {
    exists: true,
    img: tombstone,
    points: 20,
    x: adjustHorizontal(100),
    y: adjustVertical(260)
  },
  {
    exists: true,
    img: tombstone,
    points: 20,
    x: adjustHorizontal(160),
    y: adjustVertical(260)
  },
  {
    exists: true,
    img: tombstone,
    points: 20,
    x: adjustHorizontal(220),
    y: adjustVertical(260)
  },
  {
    exists: true,
    img: tombstone,
    points: 20,
    x: adjustHorizontal(280),
    y: adjustVertical(260)
  },
  {
    exists: true,
    img: crow,
    points: 15,
    x: adjustHorizontal(340),
    y: adjustVertical(260)
  }
]
