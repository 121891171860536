import { ReactElement } from 'react'
import girl from '../../../assets/img/incentivo-2024/leilao/MENINA.png'
import CountdownComponent from '../CountdownComponent'
import DigimoedasCountContainer from './DigimoedasCountContainer'
import { CountdownProps } from './props'
import * as S from './styled'

export default function Countdown ({ endTime }: CountdownProps): ReactElement {
  return <S.CountdownContainer>
    <figure>
      <img src={girl} />
    </figure>

    <CountdownComponent endTime={endTime} />

    <DigimoedasCountContainer step={3} />

  </S.CountdownContainer>
}
