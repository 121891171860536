import styled from 'styled-components'

export const SectionKPIFirstContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: #FFF;
`

export const SectionKPIsFirstContentEllipsis = styled.div`
    background-color: #FFC01A;
    border-radius: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 30px;
    max-height: 80px;
    max-width: 360px;

    @media screen and (max-width: 1365px) {
        max-height: 100px;
    }

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #4F1E70;
        font-weight: 900;
    }
    div p {
        color: #4F1E70;
        font-weight: 900;
        font-size: 30px;
        margin: 0px;
    }
    div span {
        font-size: 18px;
        text-align: center;
        line-height: 18px;
        margin-top: 4px;
    }
    img {
        height: 90px;
        margin-left: 10px;

        @media screen and (max-width: 520px) {
            height: 72px;
            margin-left: 2px;
        }
    }
`

export const SectionKPIFirstContentBottomText = styled.div`
    width: 100%;
    max-width: 400px;
    margin: 30px auto 0px auto;
    color: #FFF;
    font-size: 10px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media screen and (max-width: 1365px) {
        margin: 30px auto 30px auto;
    }
`

export const SectionKPIBottomPerson = styled.figure`
    left: -104px;
    max-width: fit-content;
    height: fit-content;
    width: 100%;

    img {
        height: 100%;
        max-height: 209px;
        max-width: 127px;
        width: 100%;
    }

    @media (min-width: 1366px) {
        display: none;
    }
`
