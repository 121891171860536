import { useCallback } from 'react';
import * as S from './style';

function Accordion ({ index, question, children, isOpen, onOpen }) {
  const handleOpen = useCallback(() => {
    if (!isOpen) {
      onOpen(index);
      return;
    }

    onOpen(undefined);
  }, [isOpen, onOpen, index]);
  return <S.SimpleAccordion className="simple-accordion">
    <S.SimpleAccordionHeader className="simple-accordion-header accordion-trigger">
      <S.SimpleAccordionTitle className="simple-accordion-title" onClick={handleOpen}>{index + 1}. {question}</S.SimpleAccordionTitle>
      <div className="simple-accordion-icon" onClick={handleOpen}>
        <svg className="icon-plus-small">
          <use href="#svg-plus-small"></use>
        </svg>

        <svg className="icon-minus-small">
          <use href="#svg-minus-small"></use>
        </svg>
      </div>

      {isOpen && <div className="simple-accordion-content accordion-content accordion-open">
        <S.SimpleAccordionText className="simple-accordion-text">
          {children}
        </S.SimpleAccordionText>
      </div>}
    </S.SimpleAccordionHeader>
  </S.SimpleAccordion>;
}

export default Accordion;
