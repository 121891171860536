import { ReactElement } from 'react'
import coin from '../../../assets/img/coin.png'
import * as S from './style'

export interface CurrentCoinsProps {
  coins: number
}

function CurrentCoins ({ coins }: CurrentCoinsProps): ReactElement {
  return <S.Container>
    <S.Coin src={coin} alt="Moeda" />
    <S.TextContainer>
      <S.Title>Você tem:</S.Title>
      <S.CoinsText>{coins}</S.CoinsText>
      <S.Text>Digimoedas</S.Text>
    </S.TextContainer>
  </S.Container>
}

export default CurrentCoins
