import { isAxiosError } from 'axios'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import InputText from '../../../../../components/Forms/Input'
import PageLoaderComponent from '../../../../../components/PageLoader'
import { useCart } from '../../../../../contexts/CartContext'
import { useModal } from '../../../../../contexts/ModalContext'
import api from '../../../../../services/api'
import { currency } from '../../../../../utils/format'
import * as S from './styled'

export default function Checkout (props) {
  const [address, setAddress] = useState({
    referencia: '',
    complemento: '',
    numero: '',
    logradouro: '',
    bairro: '',
    cidade: '',
    uf: '',
    cep: ''
  })
  const [checkout, setCheckout] = useState()
  const { openModal, closeModal } = useModal()
  const navigate = useNavigate()

  const { cart } = useCart()

  const total = useMemo(() => {
    if (!checkout) return 0
    return cart.subtotal + checkout.frete.valorFrete
  }, [cart, checkout])

  const handleFinishCheckout = useCallback(async (e) => {
    e.preventDefault()
    try {
      const response = await api.post('/cart/checkout')
      openModal({
        header: 'Obrigado',
        body: (<form className="form">
          <div className="form-row">
            <p>Seu pedido foi registrado com sucesso!</p>
          </div>

          <div className="popup-box-actions medium void">
            <Link to="/marketplace" className="popup-box-action full button secondary">Continuar resgatando pontos</Link>
          </div>
        </form>)
      })
    } catch (err) {
      if (isAxiosError(err)) {
        switch (err.response.data.message) {
          case 'Invalid param: coins':
            toast.error('Seu saldo é insuficiente', { theme: 'colored' })
            return
        }
      }
      toast.error('Ocorreu um erro ao tentar finalizar a compra', { theme: 'colored' })
      console.error(err)
    }
  }, [])

  const handleSaveAddress = useCallback(async (e) => {
    e.preventDefault()
    try {
      await api.post('/me/address', {
        ...address,
        numero: address.numero,
        cep: address.cep,
        referencia: address.referencia
      })
      toast.success('Os dados foram atualizado com sucesso', { theme: 'colored' })
    } catch (e) {
      toast.error('Ocorreu um erro ao tentar conectar com o servidor. Por favor, tente novamente.', { theme: 'colored' })
    }
  }, [address])

  const handleUpdateCEP = useCallback(async (newCep) => {
    setAddress(prevAddress => ({
      ...prevAddress,
      cep: newCep
    }))

    if (newCep.length === 8) {
      try {
        const response = await api.get(`cep?cep=${newCep}`)
        if (!response.data.cidade) {
          toast.error('CEP não encontrado. Por favor, verifique o CEP inserido.', { theme: 'colored' })
          return
        }
        setAddress(prevAddress => ({
          ...prevAddress,
          logradouro: response.data.logradouro,
          bairro: response.data.bairro,
          cidade: response.data.cidade,
          uf: response.data.uf,
        }))
      } catch (e) {
        toast.error('Ocorreu um erro interno. Por favor, tente novamente.', { theme: 'colored' })
      }
    }
  }, [])

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get('/checkout')
        setCheckout(response.data)
      } catch (error) {
        if (!isAxiosError(error)) {
          toast.error('Ocorreu um erro inesperado.', { theme: 'colored' })
          navigate('/perfil')
          return
        }

        if (!error.response) {
          toast.error('Ocorreu um erro inesperado.', { theme: 'colored' })
          navigate('/perfil')
          return
        }

        toast.error(error.response.data.message, { theme: 'colored' })
        navigate('/perfil')
      }
    })()
  }, [])

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get('/me')
        const userAddress = response.data.address
        setAddress({
          referencia: userAddress.referencia,
          complemento: userAddress.complemento,
          numero: userAddress.numero,
          logradouro: userAddress.logradouro,
          bairro: userAddress.bairro,
          cidade: userAddress.cidade,
          uf: userAddress.estado,
          cep: userAddress.cep
        })
      } catch (error) {
        console.error(error)
        toast.error('Ocorreu um erro interno ao carregar o CEP. Por favor, tente novamente.', { theme: 'colored' })
      }
    })()
  }, [])

  if (!cart) return <PageLoaderComponent />
  if (!checkout) return <PageLoaderComponent />

  return (
    <S.Template>
      <div className='sub-template'>
        <div className="grid grid-8-4 small-space">
          <div className="grid-column">
            <div className="widget-box">
              <p className="widget-box-title">Detalhes do pagamento</p>

              <div className="widget-box-content">
                <form className="form">
                  <div className="form-row split">
                    <div className="form-item">
                      <InputText label="Bairro" value={address && address.bairro} onChange={(event) => setAddress({ ...address, bairro: event.target.value })} />
                    </div>

                    <div className="form-item">
                      <InputText label="Cidade" value={address && address.cidade} onChange={(event) => setAddress({ ...address, cidade: event.target.value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText label="Estado" value={address && address.uf} onChange={(event) => setAddress({ ...address, uf: event.target.value })} />
                    </div>

                    <div className="form-item">
                      <InputText label="CEP" onChange={(event) => handleUpdateCEP(event.target.value)} maxLength={8} value={address && address.cep} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText label="Logradouro" value={address && address.logradouro} onChange={(event) => setAddress({ ...address, logradouro: event.target.value })} />
                    </div>

                    <div className="form-item">
                      <InputText label="Complemento" value={address && address.complemento} onChange={(event) => setAddress({ ...address, complemento: event.target.value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText label="Numero" value={address && address.numero} onChange={(numero) => setAddress({ ...address, numero: numero.target.value })} />
                    </div>

                    <div className="form-item">
                      <InputText label="Referencia" value={address && address.referencia} onChange={(referencia) => setAddress({ ...address, referencia: referencia.target.value })} />
                    </div>
                  </div>

                  <button className="button primary" style={{ marginTop: '16px' }} onClick={handleSaveAddress}>Savar endereço</button>
                </form>
              </div>
            </div>
          </div>

          <div className="grid-column">
            <div className="sidebar-box">
              <p className="sidebar-box-title">Resumo do pedido</p>

              <div className="sidebar-box-items">
                <div className="totals-line-list separator-bottom">
                  {cart.items.map((item, index) => (
                    <div className="totals-line" key={index}>
                      <div className="totals-line-info">
                        <p className="totals-line-title"><span className="bold">{item.sku.Product.name}</span></p>

                        <p className="totals-line-text">{currency(item.sku.price)} x {item.amount}</p>
                      </div>

                      <p className="price-title"><span className="currency"></span> {currency(item.total)}</p>
                    </div>
                  ))}

                </div>

                <div className="totals-line-list separator-bottom">
                  <div className="totals-line">
                    <p className="totals-line-title">Total do carrinho</p>

                    <p className="price-title"><span className="currency"></span> {currency(cart.subtotal)}</p>
                  </div>

                  <div className="totals-line">
                    <p className="totals-line-title">Frete</p>

                    <p className="price-title"><span className="currency"></span>{currency(checkout.frete.valorFrete)}</p>
                  </div>

                  <div className="totals-line">
                    <p className="totals-line-title">Total</p>

                    <p className="price-title"><span className="currency"></span> {currency(total)}</p>
                  </div>
                </div>

                <p className="price-title big separator-bottom"> {currency(total)} <span className="currency">Moedas</span></p>
              </div>

              <div className="sidebar-box-items">
                <button type="button" className="button primary" onClick={handleFinishCheckout}>Fazer pedido!</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </S.Template >
  )
}
