import { ReactElement } from 'react'
import PicPayModalProps from '../props'
import character from './assets/character.png'
import * as S from './style'

export default function NotFinishedModal ({ dismiss, confirm }: PicPayModalProps): ReactElement {
  return <S.Container>
    <figure>
      <img src={character} alt="" />
    </figure>

    <h1>
      Tem certeza que deseja sair?
      <br/>
      Ainda não concluímos!
    </h1>

    <p>Se você fechar esta tela, será necessário iniciar o processo novamente do zero.</p>

    <S.ConfirmButton onClick={confirm}>Sim, quero fechar</S.ConfirmButton>

    <S.CancelButton onClick={dismiss}>Agora não</S.CancelButton>
  </S.Container>
}
