import { useEffect, useState } from 'react';
import PageLoader from '../../components/PageLoader';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';
import Template from "../../template";
import RegulamentoContent from './RegulamentoContent';
import SectionKPIs from './SectionKPIs';

function Regulamento () {
  const { userCan } = useAuth();
  const [channel, setChannel] = useState(null)

  useEffect((() => {
    (async () => {
      const response = await api.get("/me")
      setChannel(response.data.channel)
    })()
  }), [])
  if (!channel) return <PageLoader />

  return <Template>
    <RegulamentoContent channelName={channel.name} />
    {userCan('read:mecanicas-2') && <SectionKPIs mecanica={channel.name} />}
  </Template>
}

export default Regulamento;
