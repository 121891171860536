import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
`

export const Coin = styled.img`
  width: 64px;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.p`
  color: var(--white);
  font-family: MdlzBiteType;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  margin-bottom: 0;
`

export const CoinsText = styled(Title)`
  color: #ffca00;
  font-size: 32px;
  line-height: 30px;
`

export const Text = styled(Title)`
  color: #ffca00;
`
