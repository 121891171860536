import styled from 'styled-components'

export const InstructionsModalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;

  h1, li {
    font-family: MdlzBiteType;
  }

  h1 {
    color: #2d0f47;
    font-size: 56px;
    line-height: 56px;
    text-align: center;
  }

  ul {
    list-style: disc;

    li {
      color: #382050;
      font-size: 22px;
      letter-spacing: 0px;
      line-height: 20px;
      margin-left: 24px;

      & + li {
        margin-top: 8px;
      }
    }
  }

  @media (max-width: 500px) {
    h1 {
      font-size: 40px;
      line-height: 40px;
    }

    ul li {
      font-size: 16px;
      line-height: normal;
    }
  }
`
