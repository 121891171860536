import { ReactElement, useEffect, useState } from 'react'
import { CountdownComponentProps } from './props'
import * as S from './styled'

interface TimeInfo {
  days: number[]
  hours: number[]
  minutes: number[]
}

export default function CountdownComponent ({ endTime }: CountdownComponentProps): ReactElement {
  const [time, setTime] = useState<TimeInfo>({
    days: [0, 0],
    hours: [0, 0],
    minutes: [0, 0]
  })
  useEffect(() => {
    const end = new Date(endTime).getTime()
    const intervalCallback = (): any => {
      const now = Date.now()
      const interval = end - now
      const daysD = Math.floor(interval / 1000 / 60 / 60 / 24 / 10)
      const days = Math.floor(interval / 1000 / 60 / 60 / 24 % 10)
      const hoursD = Math.floor(interval / 1000 / 60 / 60 % 24 / 10)
      const hours = Math.floor(interval / 1000 / 60 / 60 % 24 % 10)
      const minutesD = Math.floor(interval / 1000 / 60 % 60 / 10)
      const minutes = Math.ceil(interval / 1000 / 60 % 60 % 10)
      setTime({
        days: [daysD, days],
        hours: [hoursD, hours],
        minutes: [minutesD, minutes]
      })
    }
    const interval = setInterval(intervalCallback, 1000)
    intervalCallback()
    return () => { clearInterval(interval) }
  }, [])
  return <S.CountdownContent>
    <p>Lance terminará em:</p>

    <S.CountdownNumbersContainer>
      <div>
        <div>
          <S.CountdownNumberContainer>
            <p>{time.days[0]}</p>
          </S.CountdownNumberContainer>
          <S.CountdownNumberContainer>
            <p>{time.days[1]}</p>
          </S.CountdownNumberContainer>
        </div>

        <p>dias</p>
      </div>

      <div>
        <div>
          <S.CountdownNumberContainer>
            <p>{time.hours[0]}</p>
          </S.CountdownNumberContainer>
          <S.CountdownNumberContainer>
            <p>{time.hours[1]}</p>
          </S.CountdownNumberContainer>
        </div>

        <p>horas</p>
      </div>

      <S.SeparatorContainer>
        <p>:</p>
      </S.SeparatorContainer>

      <div>
        <div>
          <S.CountdownNumberContainer>
            <p>{time.minutes[0]}</p>
          </S.CountdownNumberContainer>
          <S.CountdownNumberContainer>
            <p>{time.minutes[1]}</p>
          </S.CountdownNumberContainer>
        </div>

        <p>minutos</p>
      </div>
    </S.CountdownNumbersContainer>
  </S.CountdownContent>
}
