import { ApproveOrder } from '../../../domain/use-case/ApproveOrder'
import { LoadReadyOrders } from '../../../domain/use-case/LoadReadyOrders'
import { ReproveOrder } from '../../../domain/use-case/ReproveOrder'

export interface OrderListProps {
  approveOrder: ApproveOrder
  reproveOrder: ReproveOrder
  loadReadyOrders: LoadReadyOrders
}

export enum PageState {
  LOADING,
  READY
}
