import { ReactElement } from 'react'
import AlternativeCardProps from './props'
import * as S from './styled'

export default function AlternativeCard ({ imgUrl, text, ...rest }: AlternativeCardProps): ReactElement {
  return <S.Container {...rest}>
    <figure>
      <img src={imgUrl} alt={text} />
    </figure>

    <div>
      <p>
        {text}
      </p>
    </div>

    <S.OrContainer>
      <p>Ou</p>
    </S.OrContainer>
  </S.Container>
}
