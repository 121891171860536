import styled from 'styled-components'
import DigimoedasCountContainerTarget from '../../Auction/Countdown/DigimoedasCountContainer'
import banner from './assets/onda_3.png'

export const Container = styled.div`
  aspect-ratio: 2560 / 902;
  background-image: url(${banner});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;

  @media (max-width: 520px) {
    position: relative;
  }
`

export const DigimoedasCountContainer = styled(DigimoedasCountContainerTarget)`
  position: absolute;
  right: 8%;
  margin: 0;
  bottom: 32px;

  @media (max-width: 1366px) {
    right: 4%;
    transform: scale(.9);
  }

  @media (max-width: 1120px) {
    right: 0;
    transform: scale(.8);
  }

  @media (max-width: 950px) {
    bottom: 0;
    transform: scale(.6);
  }

  @media (max-width: 724px) {
    right: -32px;
    transform: scale(.5);
  }

  @media (max-width: 520px) {
    bottom: -24px;
    right: -16px;
    transform: scale(.4) translate(50%, 50%);
  }
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 72px;
  justify-content: center;
  padding: 24px 24px 0;
  height: 100%;
  gap: 1rem;

  h1 {
    color: #fefefe;
    font-size: 80px;
    letter-spacing: -4px;
    margin-left: 16% !important;

    span {
      color: #e2c880;
    }


    @media (max-width: 1600px) {
      margin-left: 20% !important;
    }

    @media (max-width: 1366px) {
      font-size: 56px;
    }

    @media (max-width: 1120px) {
      font-size: 48px;
    }

    @media (max-width: 850px) {
      font-size: 36px;
      margin-left: 8% !important;

      /* br:last-of-type {
        display: none;
      } */
    }

    @media (max-width: 724px) {
      font-size: 28px;
      letter-spacing: -2px;
    }

    @media (max-width: 500px) {
      font-size: 24px;
      margin-left: 28% !important;
    }
  }

    p {
      color: #002679;
      font-size: 24px;
      margin-bottom: 24px !important;
      text-align: center;
      white-space: nowrap;

      span {
        color: #ffbc22;
        font-size: 16px;
      }

      :first-child {
        br {
          margin: -8px;

          &:last-child {
            margin: -4px;
          }
        }
      }
    }

    > div:has(button) {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 8px;

      span {
        font-size: 24px;
      }
    }

    > figure {
      max-width: 33%;
    }

  @media screen and (min-width: 1280px) {
    /* height: 28vh; */

    p {
      font-size: 72px;
      margin-bottom: 54px;


      span {
        font-size: 52px;
      }

      :first-child {
        br {
          margin: -20px;

          &:last-child {
            margin: -8px;
          }
        }
      }
    }

    > div:has(button) {
      span {
        font-size: 72px;
      }
    }

    > figure {
      max-width: 33%;
    }
  }

  @media screen and (min-width: 1280px) and (orientation: portrait) {

  }

  @media screen and (max-height: 763px) and (orientation: portrait) {
    /* padding-top: 132px; */
  }
`

export const MemoryGameButton = styled.button`
  background-color: #561a74;
  color: #ffbc22;
  font-size: 10px;
  padding-inline: 8px;
  width: fit-content;
  white-space: nowrap;

  @media screen and (min-width: 1280px) {
    font-size: inherit;
    padding-inline: 16px;
  }
`
