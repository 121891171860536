import { ReactElement, useEffect, useState } from 'react'
import digiMoedas from '../../../assets/img/incentivo-2024/digi-moeda.png'
import HexagonComponent from '../../../components/Hexagon'
import { useAuth } from '../../../contexts/AuthContext'
import api from '../../../services/api'
import * as S from './style'

export function RewardInfo (): ReactElement {
  const { avatar } = useAuth()
  const [user, setUser] = useState<any>()
  useEffect(() => {
    (async () => {
      const response = await api.get('/me')
      setUser(response.data)
    })()
  }, [])

  if (!user) return <></>

  return <S.BannerContainer className="section-banner profile-banner">
    <S.AvatarContainer>
      <div className="home-user-info">
        <div className="left">
          <div className="hexagon-container">
            <HexagonComponent src={avatar} className="" size="" />
          </div>
          <div className="greeting-user">
            <p>{user.name}</p>
            <p className="form-link">SEJA BEM VINDO!</p>
          </div>
        </div>
        <div className="right">

          <div className="group">
            <img className="stars" src={digiMoedas} alt="Estrelas" style={{ maxWidth: '60px' }} />
            <div className="line-deco" aria-hidden="true" />
            <div className="user-info-container">
              <p>{user.earnedCoins}</p>
              <p className="text-small">Digi Moedas</p>
            </div>
          </div>
        </div>
      </div>
    </S.AvatarContainer>

    <div style={{ clear: 'both' }}></div>
  </S.BannerContainer>
}
