import styled from 'styled-components'
import TemplateBase from '../../template'
import background from './assets/bg.png'

export const Template = styled(TemplateBase)`
  &.content-grid {
    width: initial;
    min-height: initial;
    max-width: initial;
    padding: initial;
    margin: initial;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const Container = styled.div`
  align-items: center;
  background-attachment: fixed;
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 100px;
  padding: 232px 28px 132px;
  justify-content: center;
  z-index: 0;
`

export const BannerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 1240px;
  min-height: 432px;
  position: relative;
  width: 100%;

  > figure {
    max-width: fit-content;
    position: absolute;

    img {
      width: 50%;
    }

    &:first-of-type {
      left: -5%;
      top: -5%;
    }

    &:last-of-type {
      bottom: -15%;
      right: -20%;

      @media (max-width: 692px) {
        bottom: -8%;
        right: -50%;
      }
    }
  }
`

export const BannerBg = styled.div`
  align-items: center;
  background-image: linear-gradient(93deg, #1d3f85 0%, #1d3f85 0%, #1d3f85 10%, #4f216f 84%, #4f216f 100%);
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 6px 4px rgba(0,0,0,0.25));
  opacity: 0.902;
  padding: 20px;
  width: 100%;

  h1 {
    color: var(--white);
    font-family: MdlzBiteType;
    font-size: 52px;
    text-align: center;
  }
`

export const WinnersContainer = styled.div`
  align-items: center;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fit, 296px);
  gap: 16px 0;
  justify-content: space-around;
  width: 100%;
`

export const BisWinners = styled.figure`
  max-width: 1360px;
  width: fit-content;

  img {
    width: 100%;
  }
`
