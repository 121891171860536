import { ReactElement, useEffect, useState } from 'react'
import sent from '../../assets/img/gallery/CERTO.png'
import error from '../../assets/img/gallery/ERRO.png'
import PageLoaderComponent from '../../components/PageLoader'
import api from '../../services/api'
import * as S from './styled'

enum GalleryState {
  LOADING,
  READY,
  ERROR
}

interface GalleryData {
  currentState: GalleryState
  videos: Array<{
    path: string
    status: 'NOVO' | 'APROVADO' | 'REPROVADO'
  }>
}

export default function Gallery (): ReactElement {
  const [state, setState] = useState<GalleryData>({
    currentState: GalleryState.LOADING,
    videos: []
  })

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get('/videos')
        setState({
          currentState: GalleryState.READY,
          videos: response.data
        })
      } catch (err) {
        setState({
          currentState: GalleryState.ERROR,
          videos: []
        })
      }
    })()
  }, [])

  if (state.currentState === GalleryState.LOADING) return <PageLoaderComponent />
  return <S.Template>
    <S.Container>
      <S.Title>
        Meus Vídeos
      </S.Title>

      <S.VideoListContainer>
        {state.videos.map((video) => <S.VideoContainer>
          <video controls>
            <source src={video.path} type="video/mp4" />
          </video>

          <S.VideoStatus>
            {video.status === 'NOVO' && <></>}
            {video.status === 'APROVADO' && <img src={sent} alt="Enviado" />}
            {video.status === 'REPROVADO' && <img src={error} alt="Erro" />}
          </S.VideoStatus>
        </S.VideoContainer>)}
      </S.VideoListContainer>
    </S.Container>
  </S.Template>
}
