import { ReactElement, useEffect, useState } from 'react'
import Loading from '../../../../../components/Loading'
import SectionHeaderComponent from '../../../../../components/SectionHeaderComponent'
import { makeLoadAccount } from '../../../../../main/factories/makeLoadAccount'
import Statistics from './Statistics'
import TopSellers from './TopSellers'

const loadAccount = makeLoadAccount()

enum PageStatus {
  LOADING,
  READY
}

export default function StoreAccount (): ReactElement {
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING)
  const [data, setData] = useState<any>()

  useEffect(() => {
    (async () => {
      setStatus(PageStatus.LOADING)
      const response = await loadAccount.handle()
      console.log(response)
      setData(response.data)
      setStatus(PageStatus.READY)
    })()
  }, [])

  if (status === PageStatus.LOADING) return <Loading />
  return <>
    <SectionHeaderComponent pretitle="Minha Loja" title='Visão Geral' />
    <Statistics {...data} />

    <TopSellers {...data} />

    {/* <SectionHeaderComponent pretitle="Minha Loja" title="Pagamentos" /> */}

    {/* <Payments /> */}
  </>
}
