import { Link as LinkComponent } from 'react-router-dom'
import styled from 'styled-components'
import topBg from './assets/top-bg.png'

export const TopContainer = styled.div`
  align-items: flex-end;
  background-image: url(${topBg}), linear-gradient(to bottom, #377845 10%, transparent 10%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  display: flex;
  flex: 1;
  min-height: 250px;
  max-height: 250px;
  padding-bottom: 40px;
  position: relative;
  overflow: hidden;
  width: 100%;
`

export const TopCircle = styled.div`
  background-image: radial-gradient(circle at top, rgba(216,222,225,0.9999999999999999) 0%, rgba(216,222,225,0) 70%);
  border-radius: 376px;
  height: 100%;
  max-width: 751px;
  max-height: 751px;
  position: absolute;
  top: -24px;
  opacity: 0.5;
  width: 100%;
`

export const Link = styled(LinkComponent)`
  outline: none;
  z-index: 0;

  &:hover {
    color: transparent;
  }
`

export const Logo = styled.figure`
  max-width: 210px;
  z-index: 0;

  img {
    object-fit: contain;
    width: 100%;
  }

  p {
    color: #f1cb0c;
    font-family: MdlzBiteType;
    font-size: 26px;
    letter-spacing: 1px;
    margin-bottom: -12px;
    text-align: center;
  }
`
