import { ReactElement } from 'react'
import Card from '../../../../../../components/Card'
import * as S from './style'

interface TopSellersProps {
  produtos: any[]
}

export default function TopSellers ({ produtos }: TopSellersProps): ReactElement {
  return <S.Container>
    <Card className="single-card">
      <S.TopSellersTitle>Top Sellers</S.TopSellersTitle>

      <S.TopSellersTable>
        <thead>
          <tr>
            <th>ITEM</th>
            <th>VENDAS</th>
            <th>VISUALIZAÇÕES</th>
          </tr>
        </thead>

        <tbody>
          {produtos.map(product => <tr>
            <td>
              <S.ItemLink to={`/product/${String(product.id)}`}>
                <figure>
                  <img src={product.image} alt={product.name} />
                </figure>
              </S.ItemLink>
              <S.LinkContent>
                <S.ItemLink to={`/product/${String(product.id)}`}>{product.name}</S.ItemLink>
              </S.LinkContent>
            </td>
            <td>$ {product.price}</td>
            <td>{product.amount}</td>
          </tr>)}
        </tbody>
      </S.TopSellersTable>
    </Card>
  </S.Container>
}
