import { ReactElement, useCallback, useMemo, useState } from 'react'
import Dropzone from '../../../../../components/Dropzone'
import ImageUpload from '../../../../../components/Forms/ImageUpload'
import InputText from '../../../../../components/Forms/InputText'
import { Progress } from '../../../../../components/Progress'
import SunEditor from '../../../../../components/SunEditor'
import { State, StepsProps } from '../props'
import * as S from '../style'

export default function StepOne ({ errors, uploadImage, setState, state }: StepsProps): ReactElement {
  const [progressValue, setProgressValue] = useState(0)

  const onDropImage = useCallback(async (acceptedFiles: File[]) => {
    const response = await uploadImage.handle({ files: acceptedFiles })

    setState((prev) => ({ ...prev, banner: response.data.image[0].path }))
    handleProgress()
  }, [])

  const onDropLogo = useCallback(async (data: any) => {
    setState(state => ({ ...state, logo: data.image.path }))
  }, [])

  const onDropBanner = useCallback(async (data: any) => {
    setState(state => ({ ...state, smallBanner: data.image.path }))
  }, [])

  const handleProgress = async (): Promise<void> => {
    setProgressValue(0)
    const totalSteps = 10
    const delay = 100

    for (let i = 1; i <= totalSteps; i++) {
      await new Promise((resolve) => setTimeout(resolve, delay))
      const newProgress = Math.round((i / totalSteps) * 100)
      setProgressValue(newProgress)
    }
  }

  const disableNextButton = useMemo(() => (
    errors.banner != null ||
    errors.description != null ||
    errors.name != null ||
    errors.regulation != null ||
    errors.smallBanner != null
  ), [errors])

  return <>
    <S.BannerContainer>
      <S.MainBannerLabel>Banner da Campanha (1920px x 676px)</S.MainBannerLabel>

      <S.ProgressContainer>
        {progressValue > 0 && <Progress color="#615dfa" progress={progressValue} width={1} />}
      </S.ProgressContainer>

      <Dropzone
        mimeTypes={{ 'image/*': [] }}
        handleOnDrop={onDropImage}
      >
        <S.ErrorMessage>{errors.banner}</S.ErrorMessage>
      </Dropzone>
    </S.BannerContainer>

    <S.InputsContainer>
      <InputText
        error={errors.name}
        label="Nome da campanha"
        onChange={(name) => setState({ ...state, name })}
        required
        value={state.name}
      />

      <InputText
        error={errors.description}
        label="Descrição da campanha"
        onChange={(description) => setState({ ...state, description })}
        required
        value={state.description}
      />
    </S.InputsContainer>

    <S.BannerAvatarContainer>
      <div>
        <ImageUpload title="Enviar Logo" text="110x110px tamanho mínimo" onSend={onDropLogo} />
        <S.ErrorMessage>{errors.logo}</S.ErrorMessage>
      </div>

      <div>
        <ImageUpload title="Enviar Banner Pequeno" text="324x76px tamanho mínimo" onSend={onDropBanner} />
        <S.ErrorMessage>{errors.smallBanner}</S.ErrorMessage>
      </div>
    </S.BannerAvatarContainer>

    <div className="post-open-content">
      <div
        className="post-open-content-body"
        style={{ width: '100%' }}
      >
        <S.EditorLabel>Regulamento</S.EditorLabel>
        <S.PostOpenParagraph className="post-open-paragraph">
          <SunEditor
            onChange={(regulation: string) => setState((prev) => ({ ...prev, regulation }))}
          />
          <S.ErrorMessage>{errors.regulation}</S.ErrorMessage>
        </S.PostOpenParagraph>
        <button
          className="primary button"
          disabled={disableNextButton}
          onClick={() => setState((prev) => ({ ...prev, currentState: State.STEP_TWO }))}
        >
          Avançar
        </button>
      </div>
    </div>
  </>
}
