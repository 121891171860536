import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  justify-content: center;

  figure {
    margin: 0;
  }

  @media (max-width: 500px) {
    figure {
      max-width: 20%;
    }
  }
`

export const Title = styled.div`
  color: var(--white);
  font-family: MdlzBiteType;
  font-size: 72px;
  line-height: 76px;
  margin: 0;
  text-align: center;

  @media (max-width: 500px) {
    font-size: 56px;
    line-height: 60px;

    br {
      margin: -12px;
    }
  }

  br {
    content: "";
    display: block;
    margin: -16px;
  }
`

export const Span = styled.span`
  color: #e9c300;
`
