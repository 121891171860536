import styled from 'styled-components'

export const Container = styled.div`
    margin-top: 64px;
    margin-bottom: 28px;
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
`

export const HeaderInfo = styled.div.attrs(() => {
  return {
    className: 'header-info'
  }
})``

export const PreTitle = styled.p`
    color: #FFFFFFC0;
    font-size: 0.75rem;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
`

export const Title = styled.h2`
    margin: 0;
    font-size: 1.625rem;
    font-weight: 700;
`

export const ActionsContainer = styled.div`
    align-items: flex-end;
    display: flex;
    padding-bottom: 0.25rem;

    > a {
        color: #adafca;
        cursor: pointer;
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 1rem;
        transition: color 0.2s ease-in-out;
        text-decoration: none;
    }
`
