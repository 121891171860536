import styled from 'styled-components'

export const Container = styled.div`
    background-color: #FFFFFF80;
    backdrop-filter: blur(8px);
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 2rem;
    padding: 80px;
    text-align: center;
`
