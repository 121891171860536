import styled from 'styled-components'

export const Table = styled.table`
  width: 100%;
`

export const ContinueButton = styled.button`
  max-width: 240px;
  justify-self: flex-end;
`
