import { ReactElement, useCallback, useEffect, useState } from 'react'
import PageLoaderComponent from '../../../components/PageLoader'
import { useModalMdlz } from '../../../contexts/ModalMdlzContext'
import { makeLoadNoSusto } from '../../../main/factories/makeLoadNoSusto'
import GameFinished from './GameFinished'
import NoGame from './NoGame'
import Puzzle from './Puzzle'
import ReadyModal from './Puzzle/Modals/ReadyModal'
import StartGame from './StartGame'
import * as S from './styled'

const loadNoSusto = makeLoadNoSusto()

export enum State {
  LOADING,
  READY,
  FINISHED,
  CANNOT_PLAY,
  LOSE,
  PLAY,
  PLAYING,
  NO_GAME
}

export interface WednesdayState {
  currentState: State
  data: any
  points: number
  time: number
  plays: number
  maxPoints: number
}

export default function WednesdayPuzzle (): ReactElement {
  const { closeModal, openModal } = useModalMdlz()
  const [state, setState] = useState<WednesdayState>({
    currentState: State.LOADING,
    data: null,
    points: 0,
    time: 90,
    plays: 0,
    maxPoints: 0
  })

  const handleOnPlay = (): void => {
    handleReadyModal()
    setState((prev) => ({ ...prev, currentState: State.PLAY }))
  }

  const handleStartPlaying = (): void => {
    closeModal()
    setState((prev) => ({ ...prev, currentState: State.PLAYING }))
  }

  const handleReadyModal = useCallback((): void => openModal({
    body: <ReadyModal handleStartPlaying={handleStartPlaying} />,
    customStyles: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      maxWidth: 669,
      padding: 0,
      width: '100%'
    },
    hasCloseButton: false,
    useClickOutside: false
  }), [])

  useEffect(() => {
    (async () => {
      const response = await loadNoSusto.handle()
      if (response.status === 204) {
        setState(state => ({
          ...state,
          plays: 2,
          points: 0,
          maxPoints: 0,
          currentState: State.NO_GAME
        }))
        return
      }
      if (response.data.length >= 2) {
        const max = response.data.reduce((a: number, b: any): number => {
          if (b.point > a) return b.point
          return a
        }, 0)
        setState(state => ({
          ...state,
          plays: 2,
          points: max,
          maxPoints: max,
          currentState: State.CANNOT_PLAY
        }))
        return
      }

      setState(state => ({
        ...state,
        plays: response.data.length,
        currentState: State.READY
      }))
    })()
  }, [])

  if (state.currentState === State.LOADING) return <PageLoaderComponent />

  return <S.Template>
    <S.Container>
      {state.currentState === State.READY && <StartGame OnStart={handleOnPlay} />}
      {state.currentState === State.PLAY && <S.ModalBg />}
      {state.currentState === State.PLAYING && <Puzzle setState={setState} state={state} />}
      {state.currentState === State.LOSE && <S.ModalBg />}
      {state.currentState === State.FINISHED && <GameFinished points={state.maxPoints} />}
      {state.currentState === State.CANNOT_PLAY && <GameFinished points={state.maxPoints} />}
      {state.currentState === State.NO_GAME && <NoGame />}
    </S.Container>
  </S.Template>
}
