import { Fragment, ReactElement, useCallback, useEffect, useState } from 'react'
import PageLoaderComponent from '../../../components/PageLoader'
import { LoadReadyOrders } from '../../../domain/use-case/LoadReadyOrders'
import { OrderListProps, PageState } from './props'
import * as S from './styled'

export default function OrderList ({ loadReadyOrders, approveOrder, reproveOrder }: OrderListProps): ReactElement {
  const [expandedRow, setExpandedRow] = useState<number | null>(null)
  const [orders, setOrders] = useState<LoadReadyOrders.Result>({} as unknown as LoadReadyOrders.Result)
  const [pageState, setPageState] = useState<PageState>(PageState.LOADING)
  const [reload, setReload] = useState<boolean>()

  const isOpen = useCallback((index: number) => expandedRow === index, [expandedRow])

  const handleOpen = useCallback((index: number) => {
    if (!isOpen(index)) {
      setExpandedRow(index)
      return
    }
    setExpandedRow(null)
  }, [expandedRow])

  const handleApprove = useCallback(async (index: number) => {
    const order = orders[index]
    await approveOrder.handle(order.pedidoParceiro)
    setReload(!reload)
  }, [orders])

  const handleReject = useCallback(async (index: number) => {
    const order = orders[index]
    await reproveOrder.handle(order.pedidoParceiro)
    setReload(!reload)
  }, [orders])

  useEffect(() => {
    (async () => {
      setPageState(PageState.LOADING)
      const response = await loadReadyOrders.handle()
      setOrders(response)
      setPageState(PageState.READY)
    })()
  }, [reload])

  if (pageState === PageState.LOADING) return <PageLoaderComponent />

  return <S.Template>
    <S.Container>
      <div className="widget-box">
        <S.Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome do usuário</th>
              <th>CPF</th>
              <th>Valor</th>
              <th>Valor do frete</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => <Fragment key={order.pedidoParceiro}>
              <tr>
                <td onClick={() => handleOpen(index)}>{order.pedidoParceiro}</td>
                <td onClick={() => handleOpen(index)}>{order.user.name}</td>
                <td onClick={() => handleOpen(index)}>{order.user.document}</td>
                <td onClick={() => handleOpen(index)}>R$ {order.value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                <td onClick={() => handleOpen(index)}>R$ {order.request.valorFrete.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                <S.ActionsCell>
                  <S.AcceptButton onClick={async () => await handleApprove(index)}>Aceitar</S.AcceptButton>
                  <S.RejectButton onClick={async () => await handleReject(index)}>Rejeitar</S.RejectButton>
                </S.ActionsCell>
              </tr>
              <S.Accordeon className={isOpen(index) ? 'open' : ''}>
                <S.ExpandedContainer colSpan={6}>
                  <table>
                    <thead>
                      <tr>
                        <th>Sku</th>
                        <th>Nome</th>
                        <th>Modelo</th>
                        <th>Preço</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.request.produtos.map(product => <tr>
                        <td>{product.skus[0].code}</td>
                        <td>{product.name}</td>
                        <td>{product.skus[0].model}</td>
                        <td>R$ {product.skus[0].price.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      </tr>)}
                    </tbody>
                  </table>
                </S.ExpandedContainer>
              </S.Accordeon>
            </Fragment>
            )}
          </tbody>
        </S.Table>
      </div>
    </S.Container>
  </S.Template>
}
