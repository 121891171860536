import { ReactElement, useCallback } from 'react'
import circleDown from '../../assets/img/circled-down.svg'
import * as S from './styled'

export function ArrowComponent (): ReactElement {
  const handleScrollToBottom = useCallback(() => {
    window.scrollTo({ top: 9999 })
  }, [])

  return <S.Container onClick={handleScrollToBottom}>
    <S.Icon src={circleDown} alt="" />
  </S.Container>
}
