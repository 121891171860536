import styled from 'styled-components'
import TemplateBase from '../../../template'

export const Template = styled(TemplateBase)`
  &.content-grid {
    width: initial;
    max-width: initial;
    padding: initial;

    display: flex;
    flex-direction: column;
    width: 100%;
  }
`

export const Table = styled.table`
  width: 100%;

  table {
    width: 100%;
  }

  thead tr th {
    border-bottom: 1px solid #ccc;
  }

  & > tbody > tr > td {
    cursor: pointer;
  }

  th {
    padding: 8px 20px;
    text-align: center;
  }
  
  td {
    padding: 8px 20px;
  }
`

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 200px 32px 0;

  div {
    height: fit-content;
    overflow: auto;
  }

`

export const ExpandedContainer = styled.td`
  td, th {
    cursor: default;
  }
`

export const ActionsCell = styled.td`
  display: flex;
  gap: 8px;
`

export const AcceptButton = styled.button`
  background-color: var(--primary);
  border-radius: 10px;
  box-shadow: 4px 7px 12px 0 rgba(35, 210, 226, 0.2);
  color: var(--white);
  font-size: 14px;
  padding: 0 20px;
  width: fit-content;
`

export const RejectButton = styled.button`
  background-color: var(--danger);
  border-radius: 10px;
  box-shadow: 4px 7px 12px 0 rgba(35, 210, 226, 0.2);
  color: var(--white);
  font-size: 14px;
  padding: 0 20px;
  width: fit-content;
`

export const Accordeon = styled.tr`
  display: none;
  & > td {
    border-bottom: 1px solid #ccc;
    padding: 0 0 8px 0;
  }

  &.open {
    display: table-row;
  }
`
