import { ReactElement, useMemo } from 'react'
import * as S from './style'

interface StatsProps {
  stats: {
    current: number
    total: number
    title: string
  }
}

export function StatsCard ({ stats }: StatsProps): ReactElement {
  const progress = useMemo(() => {
    return Math.floor(stats.current / stats.total * 100)
  }, [stats])

  return <S.Card>
    <S.Content>
      <div>
        <S.CardTitle>{stats.current}/{stats.total}</S.CardTitle>
        <S.CardText>{stats.title}</S.CardText>
      </div>

      <S.Divider />

      <S.Graph progress={progress} />
    </S.Content>
  </S.Card>
}
