import styled from "styled-components"
import TemplateBase from "../../../../template"

export const Template = styled(TemplateBase)`
  .sub-template {
    padding: 112px 0;
    width: 100vw;
    background-color: #fff;
  }

  &.content-grid {
    margin: 0;
    padding: 90px 0 0 0;
    .grid.grid-9-3,
    .section-header {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 30px;
    }
    .section-header {
      h2 {
        color: #8f91ac;
      }
      span {
        color: #f1cd3d;
      }
    }
    .product-preview-image {
      background: none !important;
      width: auto;
      margin-bottom: 0px;
    }
    .icon-delete {
      fill: red;
      opacity: 0.8 !important;
    }
    .form-counter {
      p {
        width: 100%;
        text-align: center;
        margin-bottom: 0;
      }
    }
  }

  .table-header-title {
    color: #8f91ac;
  }
  .table {
    padding: 10px 30px;
    max-width: 100%;
  }
  .table-row,
  .sidebar-box {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  }
  .simplebar-placeholder {
    display: none !important;
  }
  .sidebar-box {
    @media screen and (max-width: 760px) {
      margin-bottom: 40px;
    }
  }

  @media screen and (min-width: 1260px) {
    &.content-grid {
      &> .grid.grid-9-3 {
        grid-template-areas: "content sidebar";
        grid-template-columns: 74.6621621622% 23.9864864865% !important;
      }
      .section-header {
        padding: 0;
      }
      .table {
        padding: 10px 30px;
      }
    }
  }
`

export const TableHeaderTitle = styled.p`
  color: var(--white);
`

export const Image = styled.img`
  width: auto;
  max-height: 100%;
`
