import { ReactElement } from 'react'
import arrowDown from '../../assets/img/game-gira-giro/arrow-down.png'
import arrowUp from '../../assets/img/game-gira-giro/arrow-up.png'
import * as S from './styled'

function LogoGigaGiro (): ReactElement {
  return <S.Container>
    <figure>
      <img src={arrowUp} alt="" />
    </figure>
    <S.Title>
      <S.Span>Giga</S.Span>
      <br />
      Giro
    </S.Title>

    <figure>
      <img src={arrowDown} alt="" />
    </figure>
  </S.Container>
}

export default LogoGigaGiro
