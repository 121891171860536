import styled from 'styled-components';

export const SimpleAccordion = styled.div`
  padding: 1% 0;
`;

export const SimpleAccordionHeader = styled.div``;

export const SimpleAccordionTitle = styled.p`
  font-size: 20px !important;
`;

export const SimpleAccordionText = styled.p`
  font-size: 18px !important;

  table {
    width: 100%;
    td {
      border: 1px solid #000;
      border-collapse: collapse;
      padding: 8px 16px;
    }
  }
`;
