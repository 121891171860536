import styled from 'styled-components'
import banner from '../../../assets/img/hub/BANNER_onda2.png'
import DigimoedasCountContainerTarget from '../../Auction/Countdown/DigimoedasCountContainer'

export const Container = styled.div`
  aspect-ratio: 2560 / 902;
  background-image: url(${banner});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;

  @media (max-width: 520px) {
    position: relative;
  }
`

export const DigimoedasCountContainer = styled(DigimoedasCountContainerTarget)`
  position: absolute;
  right: 4%;
  margin: 0;
  top: 200px;

  @media (max-width: 950px) {
    right: 0;
    transform: scale(.6);
  }

  @media (max-width: 520px) {
    right: -16px;
    top: auto;
    bottom: -24px;
    transform: scale(.4) translate(50%, 50%);
  }
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 72px;
  justify-content: center;
  padding: 24px 24px 0;
  height: 100%;
  gap: 1rem;

    p {
      color: #002679;
      font-size: 24px;
      margin-bottom: 24px !important;
      text-align: center;
      white-space: nowrap;

      span {
        color: #ffbc22;
        font-size: 16px;
      }

      :first-child {
        br {
          margin: -8px;

          &:last-child {
            margin: -4px;
          }
        }
      }
    }

    > div:has(button) {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 8px;

      span {
        font-size: 24px;
      }
    }

    > figure {
      max-width: 33%;
    }

  @media screen and (min-width: 1280px) {
    /* height: 28vh; */

    p {
      font-size: 72px;
      margin-bottom: 54px;


      span {
        font-size: 52px;
      }

      :first-child {
        br {
          margin: -20px;

          &:last-child {
            margin: -8px;
          }
        }
      }
    }

    > div:has(button) {
      span {
        font-size: 72px;
      }
    }

    > figure {
      max-width: 33%;
    }
  }

  @media screen and (min-width: 1280px) and (orientation: portrait) {

  }

  @media screen and (max-height: 763px) and (orientation: portrait) {
    /* padding-top: 132px; */
  }
`

export const MemoryGameButton = styled.button`
  background-color: #561a74;
  color: #ffbc22;
  font-size: 10px;
  padding-inline: 8px;
  width: fit-content;
  white-space: nowrap;

  @media screen and (min-width: 1280px) {
    font-size: inherit;
    padding-inline: 16px;
  }
`
