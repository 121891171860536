import { Buffer } from 'buffer'
import exceljs from 'exceljs'
import { FetchHeadAdapter } from '../../data/protocols/FetchHeadAdapter'

export class ExcelAdapter implements FetchHeadAdapter {
  async fetchHead (file: File): Promise<string[]> {
    const wb = new exceljs.Workbook()
    const buffer = await file.arrayBuffer()
    const data = await wb.xlsx.load(Buffer.from(buffer))
    const rowValues = data.worksheets[0].getRow(1).values
    if (!rowValues) return []
    return Object.values(rowValues).map(value => value?.toString() ?? '')
  }
}
