import { ReactElement } from 'react'
import AuctionProductCard from './AuctionCard'
import AuctionProductsProps from './props'
import * as S from './styled'

export default function AuctionProducts ({ items, onClick }: AuctionProductsProps): ReactElement {
  return <S.AuctionProductsContainer>
    <S.Title>Outros lances</S.Title>

    <S.AuctionProductsGridContainer>
      {items.map((props: any) => <AuctionProductCard
        key={props.id}
        onClick={() => onClick(props.id)}
        {...props}
      />)}
    </S.AuctionProductsGridContainer>

    {items.length > 8 && <S.ArrowsContainer>
      <S.ArrowButton disabled>
        {'<'}
      </S.ArrowButton>

      <S.ArrowButton>
        {'>'}
      </S.ArrowButton>
    </S.ArrowsContainer>}
  </S.AuctionProductsContainer>
}
