import { ReactElement } from 'react'
import bgImg1 from '../../../assets/img/marketplace/category/01.png'
import bgImg2 from '../../../assets/img/marketplace/category/02.png'
import bgImg3 from '../../../assets/img/marketplace/category/03.png'
import bgImg4 from '../../../assets/img/marketplace/category/04.png'
import SectionHeaderTitle from '../SectionHeaderTitle'
import AuctionCard from './AuctionCard'
import * as S from './styled'

export default function Auction (): ReactElement {
  return <S.Container>
    <SectionHeaderTitle
      arrowBackAction={() => {}}
      arrowForwardAction={() => {}}
      title="Leilão"
    />

    <S.AuctionCardsContainer>
      <AuctionCard
        bgImgSrc={bgImg1}
        description="Navegue por todos os itens"
        itemCount="1360"
        time={0}
        title="Todos os itens"
      />

      <AuctionCard
        bgImgSrc={bgImg2}
        description="A melhor seleção"
        itemCount="254"
        time={0}
        title="Casa e Cozinha"
        to="/3359"
      />

      <AuctionCard
        bgImgSrc={bgImg3}
        description="Produtos Digitais"
        itemCount="1207"
        time={0}
        title="Gift cards"
        to="/5232"
      />

      <AuctionCard
        bgImgSrc={bgImg4}
        description="Notebooks, videogames..."
        itemCount="153"
        time={0}
        title="Eletrônicos"
        to="/3285"
      />
    </S.AuctionCardsContainer>
  </S.Container>
}
