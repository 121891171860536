import { AxiosError, AxiosInstance } from 'axios'
import { CreateHubCampaing } from '../../domain/use-case/CreateHubCampaing'

export class ApiCreateCampaing implements CreateHubCampaing {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (params: CreateHubCampaing.Params): Promise<CreateHubCampaing.Result> {
    try {
      const response = await this.api.post('/hub-campanha', params)
      return response
    } catch (error) {
      const axiosError = error as AxiosError
      return {
        data: axiosError.response?.data ?? axiosError.name,
        status: axiosError.response?.status ?? 0
      }
    }
  }
}
