import { ReactElement, ReactNode } from 'react'
import footprintIcon from '../../assets/footprint-icon.svg'
import * as S from './style'

export interface LojasPorEstrelaTextProps {
  disclaimer?: ReactNode
}

export default function LojasPorEstrelaText ({ disclaimer }: LojasPorEstrelaTextProps): ReactElement {
  return <S.LojasPorEstrelaTextContainer>
    <S.LojasPorEstrelaTextContainerTop>
      <div>
        <h3>Loja  <br/>4 Estrelas</h3>
        <p>Pontuação Máxima<br />20 Gigapassos por mês* <img src={footprintIcon} alt='ícone de pegadas' /></p>
      </div>
      <div>
        <h3>Loja  <br/>5 Estrelas</h3>
        <p>Pontuação Máxima<br />35 Gigapassos por mês* <img src={footprintIcon} alt='ícone de pegadas' /></p>
      </div>
    </S.LojasPorEstrelaTextContainerTop>
    <S.LojasPorEstrelaTextContainerMid>
      <p>
        <strong>EXEMPLO:</strong><br />
        Um Executivo atende 3 lojas, sendo elas 1 loja (5 estrelas), 1 loja (4 estrelas) e 1 loja não atingiu a quantidade de estrelas necessárias para ser contabilizada na campanha. Dessa maneira a pontuação final desse executivo será calculada na seguinte forma:
      </p>
    </S.LojasPorEstrelaTextContainerMid>
    <S.LojasPorEstrelaTextContainerBottom>
      <div>
        <div>
          <h3>LOJA  <br/>4 ESTRELAS</h3>
          <p>
            1 loja / 3 lojas =<br />
            33% de atingimento
          </p>
          <br />
          <p>
            33% x 20 estrelas = Pontuação de <br />
            <span>06 Gigapassos</span>
          </p>
        </div>
        <div>
          <h3>LOJA  <br/>5 ESTRELAS</h3>
          <p>
            1 loja / 3 lojas =<br />
            33% de atingimento
          </p>
          <br />
          <p>
            33% x 35 estrelas = Pontuação de <br />
            <span>11 Gigapassos</span>
          </p>
        </div>
      </div>
      <div className='with-disc'>
        <footer>Sendo assim, esse Executivo atinge a pontuação de <span>17 Gigapassos </span>no primeiro mês da campanha.</footer>
        {disclaimer}
      </div>
    </S.LojasPorEstrelaTextContainerBottom>
  </S.LojasPorEstrelaTextContainer>
}
