import { AxiosInstance } from 'axios'
import { LoadCargos } from '../../domain/use-case/LoadChannels'
import { CargoModel } from '../entities/CargoModel'

const mapToCargoModel = (cargo: any): CargoModel => {
  return {
    id: cargo.id,
    channelId: cargo.channelId,
    name: cargo.name
  }
}

export class ApiLoadCargos implements LoadCargos {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (params: LoadCargos.Params): Promise<CargoModel[]> {
    const response = await this.api.get('/hub-campanha/cargos', { params })
    return response.data.cargos.map(mapToCargoModel)
  }
}
