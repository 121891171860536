import styled from 'styled-components'
import TemplateBase from '../../../template'

export const Template = styled(TemplateBase)`
  &.content-grid {
    width: initial;
    min-height: initial;
    max-width: initial;
    padding: initial;
  }
`

export const Container = styled.div`
  align-items: center;
  background-color: #4f2170;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  width: 100vw;

  h1, p, button {
    font-family: MdlzBiteType;
    margin: 0;
  }

  @media (max-width: 520px) {
    min-height: 1px;
    padding: 0 32px 50px 32px;
  }

  @media screen and (max-width: 520px) and (orientation: portrait) {
    padding-top: 100px;
    padding-bottom: 55%;
  }
`
