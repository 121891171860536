import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  max-width: 100%;
  width: 840px;

  &.win {
    flex-direction: column;

    @media screen and (max-width: 520px) and (orientation: portrait) {
      flex-direction: column;
      justify-content: flex-start;
      height: calc(100vh - 500px);

      & > div {
        flex: initial;
      }
    }
  }

  h1, > p {
    color: var(--white);
    text-align: center;
  }

  h1 {
    white-space: nowrap;
  }

  & > div {
    display: flex;
    flex: 1 100%;
    gap: 20px;
    flex-direction: column;
  }

  @media screen and (max-width: 520px) and (orientation: portrait) {
      flex-direction: column;
      justify-content: flex-start;
      height: min-content;

      & > div {
        flex: initial;
      }
  }

  @media screen and (max-width: 520px) and (orientation: portrait) {
    flex-direction: column;
    height: calc(100vh - 500px);
  }

  @media screen and (max-width: 375px) and (orientation: portrait) {
    gap: 12px;

    h1 {
      font-size: 28px;
    }
  }
`

export const CardsContainer = styled.div`
  display: grid;
  gap: 4px;
  grid-template-columns: repeat(4, 1fr);
  margin: 0 auto 0 auto;
  width: 100%;

  @media screen and (max-width: 540px) and (orientation: portrait) {
    max-width: 300px;
  }

  @media screen and (max-width: 375px) and (orientation: portrait) {
    max-width: 232px;
  }
`

export const DigimoedasContainer = styled.div`
  align-items: center;
  background-color: #ffdc39;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  padding: 8px 24px;
  position: relative;
  max-width: 300px;
  margin: 0 auto;

  p {
    color: #4f2170;
    font-size: 24px;
    line-height: 20px;
  }

  figure {
    bottom: -50%;
    max-width: 40px;
    position: absolute;
    right: -20px;
  }

  @media screen and (max-width: 375px) and (orientation: portrait) {
    p {
      font-size: 16px;
      line-height: 12px;
    }

    figure {
      bottom: -64%;
      max-width: 32px;
    }
  }
`

export const ModalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  position: relative;
  overflow: auto;

  h1, p {
    font-family: MdlzBiteType;
    margin: 0;
    text-align: center;
  }

  h1 {
    border-bottom: 4px solid #ffdc39;
    color: #ffdc39;
    font-size: 72px;
    letter-spacing: -2px;
    margin-bottom: 16px;
    text-align: center;

    br {
      content: "";
      display: block;
      margin: -28px;
    }

    @media screen and (max-width: 280px) and (orientation: portrait) {
      font-size: 64px;
    }
  }

  p {
    color: var(--white);
    font-size: 36px;
  }

  > figure {
    z-index: 1;
  }

  button {
    background-color: #ffdc39;
    border-radius: 2px;
    color: #0e3e8a;
    font-family: MdlzBiteType;
    font-size: 28px;
    font-weight: 400;
    max-width: fit-content;
    padding: 4px 32px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;

    @media screen and (max-width: 375px) and (orientation: portrait) {
      font-size: 24px;
    }

    @media screen and (max-width: 280px) and (orientation: portrait) {
      font-size: 16px;
    }
  }
`

export const DigimoedasWonContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;

  p {
    font-size: 64px;
  }

  figure {
    margin: 0;
    max-width: 52px;
  }
`

export const TryAgainButton = styled.button`
  bottom: 0;
  position: absolute;

  @media screen and (max-width: 280px) and (orientation: portrait) {
    bottom: 2%;
  }
`

export const CongratulationsButton = styled.button`
  margin-top: 20px;
`

export const GiganteFigure = styled.figure`
  img {
    max-width: 200px;
  }
`

export const WinMessage = styled.p`
  color: #ffffff;
  font-size: 2.5rem;
  text-align: center;

  @media (max-width: 520px) {
    font-size: 24px;
  }
`
