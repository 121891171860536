import { ReactElement, useCallback, useEffect, useState } from 'react'
import PageLoaderComponent from '../../../components/PageLoader'
import { makeFindGiftCardOrder } from '../../../main/factories/makeFindGiftCardOrder'
import Template from '../../../template'
import { InputInline } from './InputInline'
import * as S from './style'

const findGiftCardOrders = makeFindGiftCardOrder()

enum PageState {
  LOADING,
  READY,
  NO_CONTENT
}

export default function AdminGiftCards (): ReactElement {
  const [state, setState] = useState<PageState>(PageState.LOADING)
  const [data, setData] = useState<any[]>([])

  const getStatusName = useCallback((status: string) => {
    const statusList: Record<string, string> = {
      DONE: 'Resgatado',
      WAITING_CODE: 'Aguardando código',
      WAITING_CLAIM: 'Aguardando resgate'
    }
    if (statusList[status]) return statusList[status]

    return status
  }, [])

  useEffect(() => {
    (async () => {
      setState(PageState.LOADING)
      const response = await findGiftCardOrders.find()
      setData(response.data)
      if (response.status === 204) {
        setState(PageState.NO_CONTENT)
      }
      if (response.status === 200) {
        setState(PageState.READY)
      }
    })()
  }, [])

  if (state === PageState.LOADING) return <PageLoaderComponent />

  return <Template>
    {state === PageState.NO_CONTENT && <>Ainda não temos nenhum gift card resgatado</>}
    {state === PageState.READY && <S.TableContainer>
      <S.SalesTable>
        <thead>
          <tr>
            <th>ITEM</th>
            <th>DATA</th>
            <th>STATUS</th>
            <th>PREÇO</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {data.map((el, i) => (
            <>
              <tr>
                <td>
                  <S.ItemLink to="">
                    <figure>
                      <img src={el.image} alt={el.name} />
                    </figure>
                  </S.ItemLink>

                  <S.ItemLinkContent>
                    <S.ItemLink to="">{el.name}</S.ItemLink>
                  </S.ItemLinkContent>
                </td>
                <td>{new Date(el.createdAt).toLocaleDateString('pt-br', { timeZone: 'America/Sao_Paulo' })}</td>
                <td>{getStatusName(el.status)}</td>
                <S.Price>
                  <span>R$</span>
                  {' '}
                  {el.price}
                </S.Price>
                <td>
                  {el.status === 'WAITING_CODE' && <InputInline id={el.id} />}
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </S.SalesTable>
    </S.TableContainer>}
  </Template>
}
