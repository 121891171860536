import { ReactElement, useCallback, useRef, useState } from 'react'
import SectionHeaderTitle from '../SectionHeaderTitle'
import MissionCard from './MissionCard'
import * as S from './styled'

const width = 218

export interface MissionsProps {
  missions: {
    quests: any[]
  }
}

export interface MissionsState {
  translateX: number
}

export default function Missions ({ missions }: MissionsProps): ReactElement {
  const [state, setState] = useState<MissionsState>({
    translateX: 0
  })
  const ref = useRef<HTMLDivElement>(null)

  const handleIncrease = useCallback(() => {
    if (!ref.current) return
    const newState = { ...state }
    newState.translateX -= width
    if (newState.translateX < -(missions.quests.length - 3) * width) {
      newState.translateX = 0
    }
    setState(newState)
  }, [state])

  const handleDecrease = useCallback(() => {
    if (!ref.current) return
    const newState = { ...state }
    newState.translateX += width
    if (newState.translateX >= 0) {
      newState.translateX = 0
    }
    setState(newState)
  }, [state])

  return <S.Container>
    <SectionHeaderTitle
      arrowBackAction={handleDecrease}
      arrowForwardAction={handleIncrease}
      title="Missões"
    />

    <S.CardsContainer translateX={state.translateX} ref={ref}>
      <div>
        {missions.quests?.map((q: any) => <MissionCard {...q} />)}
      </div>
    </S.CardsContainer>
  </S.Container>
}
