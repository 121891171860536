import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useModalMdlz } from '../../../contexts/ModalMdlzContext'
import { RemoteGetGiraGiroGame } from '../../../data/use-case/RemoteGetGiraGiroGame'
import { GetGiraGiroGame } from '../../../domain/use-case/GetGiraGiroGame'
import GameFinished from './GameFinished'
import GiroQuiz from './GiroQuiz'
import ModalDefeat from './GiroQuiz/ModalDefeat'
import Roulette from './Roulette'
import StartGame from './StartGame'
import * as S from './styled'

enum State {
  LOADING,
  READY,
  ROULETTE,
  QUIZ,
  FINISHED,
  CANNOT_PLAY
}

interface GiragiroState {
  currenteState: State
  currentQuestion: any
  data: GetGiraGiroGame.Result
  answerCount: number
}

const getGiraGiroGame = new RemoteGetGiraGiroGame()

export default function GiraGiro (): ReactElement {
  const { openModal, closeModal } = useModalMdlz()
  const [state, setState] = useState<GiragiroState>({
    currenteState: State.READY,
    currentQuestion: null,
    data: null as unknown as GetGiraGiroGame.Result,
    answerCount: 0
  })

  const handleChangeQuiz = useCallback((index: number) => {
    setState((state) => ({
      ...state,
      currenteState: State.QUIZ,
      currentQuestion: state.data.giragiro.Perguntas[index]
    }))
  }, [])

  const handleTryAgain = useCallback(() => {
    const answerCount = state.answerCount + 1
    if (answerCount === 2) {
      setState({ ...state, currenteState: State.FINISHED, answerCount })
      return
    }
    setState({ ...state, currenteState: State.ROULETTE, answerCount })
  }, [state])

  const playAgain = useCallback(() => {
    const answerCount = state.answerCount + 1
    closeModal()
    setState({ ...state, currenteState: State.ROULETTE, answerCount })
  }, [])

  const handleFinish = useCallback(() => {
    // const answerCount = state.answerCount + 1
    // if (answerCount === 1) {
    //   setState((state) => ({ ...state, currenteState: State.FINISHED, answerCount }))
    //   return
    // }

    openModal({
      body: <ModalDefeat TryAgain={playAgain} />,
      hasCloseButton: false
    })
  }, [])

  useEffect(() => {
    (async () => {
      try {
        setState(state => ({ ...state, currenteState: State.LOADING }))
        const response = await getGiraGiroGame.handle()
        const currenteState = response.answers.length < 2 ? State.READY : State.CANNOT_PLAY
        setState(state => ({ ...state, answerCount: response.answers.length, currenteState, data: response }))
      } catch (err) { }
    })()
  }, [state.answerCount])

  return <S.Template>
    <S.Container>
      {state.currenteState === State.READY && <StartGame OnStart={() => setState({ ...state, currenteState: State.ROULETTE })} />}
      {state.currenteState === State.ROULETTE && <Roulette OnStart={handleChangeQuiz} />}
      {state.currenteState === State.QUIZ && <GiroQuiz
        TryAgain={handleTryAgain}
        Finish={handleFinish}
        coins={state.data.giragiro.points}
        quiz={state.currentQuestion}
      />}
      {state.currenteState === State.FINISHED && <GameFinished points={state.data.giragiro.points + state.data.answers.reduce<number>((acc: number, p: any): number => acc + Number.parseInt(p.points), 0)} />}
      {state.currenteState === State.CANNOT_PLAY && <GameFinished points={state.data.answers.reduce<number>((acc: number, p: any): number => acc + Number.parseInt(p.points), 0)} />}
    </S.Container>
  </S.Template>
}
