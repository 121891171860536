import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import arrowBack from '../../../assets/img/incentivo-2024/marketplace/arrow-back.png'
import arrowForward from '../../../assets/img/incentivo-2024/marketplace/arrow-forward.png'
import { makeLoadBanners } from '../../../main/factories/makeLoadBanners'
import * as S from './styled'

const loadBanners = makeLoadBanners()

enum ComponentState {
  LOADING,
  READY
}

export default function Highlights (): ReactElement {
  const [state, setState] = useState<ComponentState>(ComponentState.LOADING)
  const [highlights, setHighlights] = useState<any[]>([])
  const [highlightIndex, setHighlightIndex] = useState(0)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  const startInterval = useCallback((): void => {
    if (highlights.length > 0) {
      intervalRef.current = setInterval(() => {
        setHighlightIndex((prev) => (prev + 1) % highlights.length)
      }, 5000)
    }
  }, [highlights.length])

  const resetInterval = useCallback((): void => {
    if (intervalRef.current) clearInterval(intervalRef.current)

    startInterval()
  }, [startInterval])

  const goBack = useCallback(() => {
    setHighlightIndex((prev) => (prev - 1 + highlights.length) % highlights.length)

    resetInterval()
  }, [highlights.length, resetInterval])

  const goForward = useCallback(() => {
    setHighlightIndex((prev) => (prev + 1) % highlights.length)

    resetInterval()
  }, [highlights.length, resetInterval])

  useEffect(() => {
    (async () => {
      setState(ComponentState.LOADING)
      const response = await loadBanners.handle()
      setHighlights(response.data.banners)
      setState(ComponentState.READY)
    })()
  }, [])

  useEffect(() => {
    startInterval()

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [highlights])

  if (state === ComponentState.LOADING) return <>carregando...</>

  return <S.HighlightsContainer>
    <S.HighlightsContentContainer>
      <S.TextContainer>
        <S.Title>
          {highlights[highlightIndex].title}
        </S.Title>

        <S.Price>
          <span>$</span>
          {highlights[highlightIndex].price}
        </S.Price>
      </S.TextContainer>

      <S.Button onClick={() => { window.location.href = highlights[highlightIndex].link }}>
        Resgatar
      </S.Button>

      <S.ArrowsContainer>
        <S.ArrowButton onClick={goBack}>
          <img src={arrowBack} alt="Go back" />
        </S.ArrowButton>

        <S.ArrowButton onClick={goForward}>
          <img src={arrowForward} alt="Next" />
        </S.ArrowButton>
      </S.ArrowsContainer>
    </S.HighlightsContentContainer>

    <S.HighlightImageContainer>
      <figure>
        <img src={highlights[highlightIndex].image} alt={highlights[highlightIndex].productName} />
      </figure>
    </S.HighlightImageContainer>
  </S.HighlightsContainer>
}
