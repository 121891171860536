import { ReactElement, useCallback, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import PageLoaderComponent from '../../components/PageLoader'
import ProfileNav from '../../components/ProfileNav'
import { useFetch } from '../../hooks/useFetch'
import Template from '../../template'

import { toast } from 'react-toastify'
import { useAuth } from '../../contexts/AuthContext'
import api from '../../services/api'
import * as S from './style'

// to be reviewed
export interface User {
  id: string
  createdAt?: Date
  createdId?: Date
  updatedAt?: Date
  updatedId?: Date
  deletedId?: Date
  deletedAt?: Date
  avatarId?: number
  channelId: number
  displayName: string
  name: string
  email: string
  document: string
  birthday: string
  cargo: string
  cellphone: string
  matricula?: unknown
  cdgHolding?: unknown
  passwordHash: string
  active: boolean
  activationToken: string
  approved: boolean
  approvedAt?: Date
  approvedBy?: unknown
  recoveryToken?: unknown
  acceptInfo?: unknown
  currentExp: number
  currentLevel: number
  purchasedCoins: number
  earnedCoins: number
  googleSub?: unknown
  channel: {
    id: number
    createdAt: Date
    createdId?: unknown
    updatedAt?: Date
    updatedId?: unknown
    deletedId?: unknown
    deletedAt?: Date
    orgId: number
    name: string
    regulationId: number
    teamId: number
    userId?: unknown
  }
  role: {
    id: number
    name: string
    created_at: Date
    updated_at?: Date
    deleted_at?: Date
    distributor: number
  }
  address?: {
    id?: string
    createdAt: string
    createdBy: string
    updatedAt: string
    deletedAt: string
    userId: string
    cep: string
    bairro: string
    cidade: string
    estado: string
    logradouro: string
    numero: string
    referencia: string
    complemento: string
  }
}

export interface ProfileContextType {
  user: User | null
  setUser: (user: User) => void
}

export default function Perfil (): ReactElement {
  const [originalUser, setOrignalUser] = useState<User | null>(null)
  const [user, setUser] = useState<User | null>(null)
  const { setUser: setStorageUser } = useAuth()

  const { data } = useFetch('/me')

  const updateUser = useCallback(async () => {
    if (user === null) return
    try {
      const response = await api.put(`/me/${user.id}`, user)
      setStorageUser(response.data)
      toast.success('Informações salvas com sucesso!', { theme: 'colored' })
    } catch (error) {
      console.error(error)
      toast.success('Ocorreu um erro ao tentar salvar as inforamções. Por favor, tente novamente.', { theme: 'colored' })
    }
  }, [user])

  const resetForm = useCallback(() => {
    setUser(originalUser)
  }, [originalUser])

  useEffect(() => {
    setOrignalUser(data)
    setUser(data)
  }, [data])

  if (originalUser == null) return <PageLoaderComponent />

  return (
    <Template>
      <div className='grid grid-3-9 medium-space'>
        <ProfileNav resetForm={resetForm} updateUser={updateUser} />

        {(user !== undefined || user !== null) && (
          <div className="account-hub-content">
            <S.Container>
              <Outlet context={{ user, setUser }} />
            </S.Container>
          </div>
        )}
      </div>
    </Template >
  )
}
