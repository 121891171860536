import { ReactElement } from 'react'
import badge01 from '../../../assets/img/hub/badges/01.png'
import badge02 from '../../../assets/img/hub/badges/02.png'
import badge03 from '../../../assets/img/hub/badges/03.png'
import badge04 from '../../../assets/img/hub/badges/04.png'
import badge05 from '../../../assets/img/hub/badges/05.png'
import badge06 from '../../../assets/img/hub/badges/06.png'
import badge07 from '../../../assets/img/hub/badges/07.png'
import badge08 from '../../../assets/img/hub/badges/08.png'
import * as S from './styled'

export default function Badges (): ReactElement {
  return <S.Card>
    <S.TitleContainer>
      <h1>Meus Emblemas</h1>
    </S.TitleContainer>

    <S.BadgesContainer>
      <figure>
        <img src={badge01} alt="" />
      </figure>

      <figure>
        <img src={badge02} alt="" />
      </figure>

      <figure>
        <img src={badge03} alt="" />
      </figure>

      <figure>
        <img src={badge04} alt="" />
      </figure>

      <figure>
        <img src={badge05} alt="" />
      </figure>

      <figure>
        <img src={badge06} alt="" />
      </figure>

      <figure>
        <img src={badge07} alt="" />
      </figure>

      <figure>
        <img src={badge08} alt="" />
      </figure>
    </S.BadgesContainer>
  </S.Card>
}
