import styled from 'styled-components'

export const TableContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    top: -1px;
    grid-column: span 2;
    max-width: 612px;
    place-self: center;

    @media (max-width: 1365px) {
      grid-column: initial;
    }

    table {
      color: #FFF;
      font-size: 15px;
      width: 80%;
      margin: 20px auto 32px auto;

      @media screen and (max-width: 1365px) {
        margin-bottom: 60px;
      }
      thead {
        tr {
          th {
            border: 0 none;
            border-bottom: 2px solid #E18719;
            padding-bottom: 12px;
            text-align: center;
            text-transform: uppercase;
          }
        }
      }
      tr {
        &:last-child {
          b {
            color: #FFC01A;
          }
          td {
            border-bottom: 0 none;
          }
        }
        td {
          margin: 0 auto;
          border: 1px solid #E18719;
          border-collapse: collapse;
          padding: 6px 12px;
          white-space: nowrap;
          @media screen and (max-width: 520px) {
            font-size: 9px;
            padding: 3px 5px;
            white-space: normal;
          }
          &:first-child {
            border-left: 0 none;
          }
          &:last-child {
            border-right: 0 none;
          }
        }
      }
    }
  `
