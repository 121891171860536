import styled, { keyframes } from 'styled-components'
import bubble from '../../../../assets/img/game-gira-giro/BOTÃO-GIRAR.png'

const spinAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

export const Container = styled.div`
  align-items: center;
  background-image: radial-gradient(circle at right, rgba(0,137,205,0.724) 0%, rgba(0,137,205,0) 100%);
  display: flex;
  justify-content: center;
  min-height: 100vh;
  position: relative;
  width: 100%;

  @media (max-width: 764px) {
    padding: 0 32px 50px 32px;
  }

  @media (max-width: 764px) {
    padding-top: 100px;
    padding-bottom: 55%;
  }
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  @media (max-width: 1200px) {
    margin-left: 24%;
  }

  @media (max-width: 632px) {
    justify-content: flex-end;
  }
`

export const ProductsBg = styled.figure`
  height: 100%;
  position: absolute;
  right: 4%;

  @media (max-width: 1200px) {
    display: none;
  }
`

export const Roulette = styled.figure<{ $paused: boolean }>`
  animation: ${spinAnimation} 1s linear infinite;
  animation-play-state: ${({ $paused }) => $paused && 'paused'};
  margin: 0 10% 0 0;
  position: relative;
  transition: transform 3s ease-in-out;

  img {
    max-width: 428px;
    width: 100%;
  }

  @media (max-width: 1200px) {
    margin: 0;
  }

  @media (max-width: 764px) {
    max-width: 80%;
  }
`

export const CharacterContainer = styled.div`
  left: -48%;
  position: absolute;
  top: 24%;

  @media (max-width: 764px) {
    left: -28%;
    top: 20%;
  }

  @media (max-width: 632px) {
    left: -16%;
    max-width: 50%;
  }
`

export const Character = styled.figure`
  img {
    max-width: 230px;
  }

  @media (max-width: 764px) {
    max-width: 80%;
  }
`

export const BubbleBgContainer = styled.div`
  aspect-ratio: 111 / 122;
  background-image: url(${bubble});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  left: -10%;
  top: -18%;
  position: absolute;
  max-width: 111px;
  width: 100%;

  @media (max-width: 764px) {
    left: -28%;
    top: -20%;
  }

  @media (max-width: 632px) {
    top: -32%;
    width: 60%;
  }
`

export const SpinButton = styled.button`
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  figure {
    margin: 0;
  }

  p {
    color: #4c2072;
    font-size: 32px;
    line-height: 28px;

    span {
      color: #e9c300;
    }

    br {
      content: "";
      display: block;
      margin: 0px;
    }
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 764px) {
    margin-top: 6% !important;

    p {
      font-size: 20px;
      line-height: 18px;
    }
  }

  @media (max-width: 632px) {
    margin-top: 2% !important;

    p {
      font-size: 20px;
      line-height: 20px;
    }
  }

  @media (max-width: 500px) {
    margin-top: 10% !important;

    figure {
      line-height: 10px;
      height: 80%
    }

    p {
      font-size: 16px;
      line-height: 16px;
    }
  }

  @media (max-width: 400px) {
    margin-top: -2% !important;
    min-height: 76px;
    min-width: 68px;

    p {
      font-size: 12px;
      line-height: 12px;
    }
  }
`

export const PointsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16%;
  width: 100%;

  p {
    color: #e9c300;
    font-size: 60px;
    letter-spacing: 2px;
    line-height: 28px;
    text-align: center;

    span {
      font-size: 28px;
      letter-spacing: 0;
    }
  }
`
