import Template from '../../template';
import * as S from './styled';

export default function Quests () {
    return (
        <Template>
            <S.Container>
                Em breve esta página estará disponível.
            </S.Container>
        </Template>
    );
}
