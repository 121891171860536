import styled from 'styled-components'

import background from '../../assets/img/incentivo-2024/login/LOGIN_BG.png'
import footer from '../../assets/img/incentivo-2024/login/LOGIN_FOOTER.png'
import header from '../../assets/img/incentivo-2024/login/LOGIN_HEADER.png'

export const LoginBg = styled.div`
  background-image: url(${background});
  background-position: center 80%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  padding: 0 32px;
`

export const LoginContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 0 40px;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
`

export const Header = styled.header`
  background-image: url(${header});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 50%;
  max-height: 19.75rem;
  padding-top: 10.25rem;
  position: absolute;
  overflow: hidden;
  top: -13%;
  transform: translateX(-50%);
  width: 100%;

  a, p, li {
    color: #fff;
    font-weight: 600;
    white-space: nowrap;
  }

  @media screen and (max-height: 1366px) and (max-width: 1024px) and (orientation: portrait) {
    padding-top: 13rem;
  }

  @media screen and (max-height: 1024px) and (max-width: 768px) and (orientation: portrait) {
    max-height: 12rem;
    padding-top: 7rem;
    top: -10%;
  }

  @media screen and (min-height: 1025px) and (max-width: 1023px) and (orientation: portrait) {
    padding-top: 12rem;
  }

  @media screen and (min-height: 600px) and (max-height: 1365px) and (min-width: 1024px) and (orientation: portrait) {
    padding-top: 13rem;
    top: -25%;
  }

  @media screen and (max-height: 800px) and (orientation: landscape) {
    max-height: 12.75rem;
    padding-top: 7rem;
  }
`

export const HeaderImg = styled.img`
  height: 100%;
  left: -8%;
  max-width: 304px;
  position: absolute;
  top: -5%;

  @media screen and (max-width: 1024px) and (orientation: portrait) {
    display: none;
  }
`

export const HeaderContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

export const MdlzLogoContainer = styled.figure`
  margin: 0;
  max-width: 120px;
`

export const Footer = styled.footer`
  background-image: url(${footer});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  max-height: 316px;
  position: absolute;
  bottom: -20%;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  width: 100%;

  @media screen and (max-height: 1366px) and (max-width: 1024px) and (orientation: portrait) {
    max-height: 200px;
    bottom: 0%;
  }

  @media screen and (max-height: 1024px) and (max-width: 768px) and (orientation: portrait) {
    max-height: 80px;
    bottom: -8%;
  }

  @media screen and (min-height: 1025px) and (max-width: 1023px) and (orientation: portrait) {
    bottom: -5%;
  }

  @media screen and (min-height: 600px) and (max-height: 1365px) and (min-width: 1024px) and (orientation: portrait) {
    bottom: -25%;
  }

  @media screen and (max-height: 800px) and (orientation: landscape) {
    max-height: 12.75rem;
    bottom: -5%;
  }
`

export const WhatsAppLink = styled.a`
  position: fixed;
  right: 5%;
  bottom: 2%;

  figure {
      max-height: 48px;
      max-width: 48px;
    }
`

export const FooterImg = styled.img`
  bottom: -28%;
  position: absolute;
  right: -6%;
  height: 100%;
  max-width: 304px;

  @media screen and (max-width: 1246px) {
    height: 98%;
  }

  @media screen and (max-width: 1024px) and (orientation: portrait) {
    display: none;
  }
`

export const GigantesLogo = styled.figure`
  margin: 0;
  max-width: 600px;

  @media screen and (max-width: 1326px) {
    position: absolute;
  }
`

export const AbsoluteGigantesLogo = styled.figure`
  margin: 0;
  max-width: 600px;
  position: absolute;
  top: -25%;
  left: 0;

  @media screen and (min-width: 1326px) {
    display: none;
  }
`

export const LoginFormContainer = styled.div`
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  filter: drop-shadow(0 0 30px rgba(94,92,154,0.12));
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  max-width: 484px;
  padding: 2rem clamp(2rem, 1.6471rem + 1.5059vw, 4rem);
  position: relative;
  width: 100%;

  @media screen and (max-width: 1326px) {
    padding-top: 136px;
  }

  @media screen and (max-width: 400px) {
    padding-top: 64px;
    margin-top: 150px;
  }

  @media screen and (max-height: 800px) and (orientation: portrait) {
    margin-top: 152px;
  }
`

export const LoginFormTitle = styled.h1`
  color: #4f2170;
  font-weight: 700;
  font-size: 30px;
  margin: 0;
  text-align: center;

  @media screen and (max-width: 1326px) {
    display: none;
  }
`

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > div {
    width: 100%;
  }

  input {
    color: #3e3f5e !important;
    font-size: 16px !important;
  }

  label {
    color: #afb0c0 !important;
  }
`

export const LinkText = styled.p`
  color: #561a74;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  margin: 4px 0 12px;
  text-align: left;
`

export const LoginButton = styled.button`
  align-items: center;
  background-color: #ffdc39;
  border-radius: 12px;
  color: #561a74;
  font-size: 2rem;
  font-weight: 700;
  height: 48px;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`

export const Text = styled.p`
  color: #3e3f5e;
  font-size: 14px;
  font-weight: 700;
  margin: 8px 0;
  text-align: center;
`

export const ClubeMdlzButton = styled.a`
  align-items: center;
  background-color: #561a74;
  border-radius: 12px;
  display: flex;
  height: 48px;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  width: 70%;
  > img {
    width: 50%;
  }
`

export const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;

  p, a {
    text-align: center;
  }
`

export const EmailLink = styled.a`
  color: #561a74;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  margin: 4px 0 12px;
`
