import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../../../contexts/ModalContext';
import { useNotify } from '../../../contexts/NotifyContext';
import * as S from '../style';

function NoticeGroup () {
  const { notices, markAllAsReady } = useNotify();

  const unread = useMemo(() => {
    return notices.filter(n => !n.isRead).length > 0;
  }, [notices]);

  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();
  useEffect(() => {
    if (!unread) return;
    openModal({
      body: <>
        <div className="form-row">Você tem uma nova notificação.<br />Deseja ve-la agora?</div>
        <S.ModalGrid>
          <button onClick={() => {
            markAllAsReady();
            navigate('/newsfeed');
            closeModal();
          }} className="button primary">Sim</button>
          <button onClick={closeModal} className="button primary">Não</button>
        </S.ModalGrid>
      </>,
      size: 'small'
    });
  }, [openModal, unread, navigate, markAllAsReady, closeModal]);

  return <></>;
}

export default NoticeGroup;
