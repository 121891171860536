import { ReactElement } from 'react'
import Template from '../../../template'
import * as S from './style'

export default function AdminRewards (): ReactElement {
  return <Template>
    <S.TableContainer>
      <S.SalesTable>
        <thead>
          <tr>
            <th>DATA</th>
            <th>ITEM</th>
            <th>TIPO</th>
            <th>CÓDIGO</th>
            <th>PREÇO</th>
            <th>DESCONTO</th>
            <th>FATURAMENTO</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Nov 15th, 2019</td>
            <td>
              <S.ItemLink to="">Pixel Diamond Gaming Magazine</S.ItemLink>
            </td>
            <td>Venda</td>
            <td>VK1287</td>
            <td>$26</td>
            <td>50%</td>
            <td>$13</td>
            <td>
              <S.IconContainer>
                <svg className="percentage-diff-icon icon-minus-small">
                  <use xlinkHref="#svg-minus-small"></use>
                </svg>
              </S.IconContainer>
            </td>
          </tr>
          {
            Array.from(new Array(11)).map((_, i) => (
              <tr key={i}>
                <td>Nov 15th, 2019</td>
                <td>
                  <S.ItemLink to="">Pixel Diamond Gaming Magazine</S.ItemLink>
                </td>
                <td>Venda</td>
                <td>VK1287</td>
                <td>$26</td>
                <td>50%</td>
                <td>$13</td>
                <td>
                  <S.IconContainer>
                    <svg className="percentage-diff-icon icon-plus-small">
                      <use xlinkHref="#svg-plus-small"></use>
                    </svg>
                  </S.IconContainer>
                </td>
              </tr>
            ))
          }
        </tbody>
      </S.SalesTable>
    </S.TableContainer>
  </Template>
}
