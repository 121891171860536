import { ReactElement } from 'react'
import picpay from './assets/PICPAY.png'
import character from './assets/character.png'
import * as S from './style'

export default function PicPaySteps (): ReactElement {
  return <S.Template>
    <S.Container>
      <S.Content>
        <S.StepsContainer>
          <S.AbsoluteContainer>
            <S.Bubble>
              <p>VEJA COMO É FÁCIL <br />ABRIR A SUA CONTA</p>
              <img src={picpay} alt="" />
            </S.Bubble>

            <figure>
              <img src={character} alt="" />
            </figure>
          </S.AbsoluteContainer>

          <S.Step className="step-one">
            <h3>Passo <span>1</span></h3>

            <p>Baixe o <br />aplicativo <br />do <span>PicPay</span></p>
          </S.Step>

          <S.Step>
            <h3>Passo <span>2</span></h3>

            <p>Clique em <br /><span>CADASTRAR</span></p>
          </S.Step>

          <S.Step>
            <h3>Passo <span>3</span></h3>

            <p>Preencha com as <br />suas informações e <br /><span>CONCLUA O SEU <br />CADASTRO</span></p>
          </S.Step>

          <S.Step>
            <h3>Passo <span>4</span></h3>

            <p><span>CONFIRME</span> <br />o seu <br />smartphone</p>
          </S.Step>

          <S.Step>
            <h3>Passo <span>5</span></h3>

            <p>Crie uma <br />senha de <br /><span>8 DIGITOS</span></p>
          </S.Step>

          <S.Step>
            <h3>Passo <span>6</span></h3>

            <p>Tire uma foto do <br /><span>SEU ROSTO</span></p>
          </S.Step>
        </S.StepsContainer>

        <S.Description>
        A foto será usada para confirmar a sua identidade.
        <br />
        Após a verificação do cadastro, em minutos, <br />você receberá um e-mail e já poderá usar a sua conta.
        </S.Description>

        <S.Reminder>
          <p>Lembre-se de que o cadastro precisa estar <br />no mesmo CPF do cadastro do PicPay</p>
        </S.Reminder>

      </S.Content>
    </S.Container>
  </S.Template>
}
