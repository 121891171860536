import { ReactElement } from 'react'
import imageApuracao1 from '../assets/apuracao1.png'
import imageApuracao2 from '../assets/apuracao2.png'
import TopMaskImageVendas from '../assets/top-image-vendas.png'
import RegulamentoMerchan from './RegulamentoMerchan'
import RegulamentoContentProps from './props'
import * as S from './style'

export default function RegulamentoContent ({ channelName }: RegulamentoContentProps): ReactElement {
  if (channelName === 'MERCHAN') return <RegulamentoMerchan />

  return <S.RegulamentoContainer className="widget-box">
    <S.RegulamentoContainerTopMask>
      <div>
        REGULAMENTO – GIGANTES DA EXECUÇÃO<br></br>
        <span>TIME DE VENDAS</span>
      </div>
      <S.RegulamentoContainerTopMaskImage >
        <img src={TopMaskImageVendas} alt="Imagem de um desenho humano comemorando" />
      </S.RegulamentoContainerTopMaskImage>
    </S.RegulamentoContainerTopMask>
    <h3><b>Preâmbulo:</b></h3>
    <hr />
    <ol type="A">
      <li>
        <p><b>A. Nome do Programa: </b>Gigantes da Execução 2024</p>
      </li>
      <li>
        <p><b>B. Oferecedor do Programa: </b>MONDELEZ BRASIL LTDA e MONDELEZ BRASIL NORTE E NORDESTE LTDA</p>
      </li>
      <li>
        <p><b>C. Método de auditoria: </b>Análises dos reports gerados pelas ferramentas Wise, EBI, Visor, BI,
          Scanntech e dados fornecidos pelos clientes</p>
      </li>
      <li>
        <p><b>D. Público-alvo: </b>Todos os Gerentes Regionais de vendas, Gerente de Área e Vendedores dos canais TT, C&C, MT, Food, Dia e Farma</p>
      </li>
      <li>
        <p><b>E. Região de desenvolvimento do Programa: </b>Total Brasil</p>
      </li>
      <li>
        <p><b>F. Duração do Programa: </b>01/02/2024 a 30/11/2024</p>
      </li>
      <li>
        <p><b>G. Produtos Participantes: </b>Todas as famílias comercializadas pela Mondelez. Não serão contabilizados
          produtos que não são produzidos pela Mondelez, como, por exemplo: sorvetes</p>
      </li>
      <li>
        <p><b>H. Mecânica do Programa: </b>Anexada após o regulamento</p>
      </li>
      <li>
        <p><b>I. Premiação: </b>Viagem para América Latina all inclusive na classe econômica e premiações dos melhores de cada Onda
            conforme a cláusula 7</p>
      </li>
      <li>
        <p><b>J. Prazo para apuração e divulgação dos resultados: </b>Até 60 (sessenta) dias corridos após o término
        </p>
      </li>
      <li>
        <p><b>K. Concessão da premiação: </b>Anexo I</p>
      </li>
      <li>
        <p><b>L. Prazo para concessão da premiação: </b>Primeiro Semestre de 2025, caso haja algum indicativo de
          pandemia ou guerra poderá ser adiada</p>
      </li>
    </ol>
    <hr />
    <ol>
      <li>
        <p><b>1. Programa</b></p>
        <ol>
          <li>
            <p><b>1.1. </b>O Programa <strong>“Campanha Gigantes da Execução”</strong> (“Programa”) é realizado pela pessoa jurídica
              elencada no item “B” do preâmbulo.</p>
          </li>
          <li>
            <p><b>1.2. </b>O Programa consiste na mecânica, descrita no item “H” do Preâmbulo (Anexo I), que visa
              premiar os empregados participantes do Programa que atingirem as metas previstas no Anexo I, durante
              o período pré-estabelecido no item “F” do preâmbulo e conforme critérios constantes no presente
              Regulamento.</p>
          </li>
          <li>
            <p><b>1.3. </b>Para serem elegíveis à participação no Programa, os empregados participantes devem fazer
              parte do público-alvo previsto no item “D” e fazer uso das plataformas previstas no item “C” do
              preâmbulo para fins de auditoria quanto ao atingimento das metas.</p>
          </li>
          <li>
            <p><b>1.4. </b>A Mondelez, independentemente da necessidade de qualquer aviso prévio ou aprovação pelos
              Empregados Participantes, poderá alterar as plataformas acima descritas, necessárias para realizar a
              apuração de resultados do Programa. Os empregados serão avisados da ocorrência através do e-mail.
            </p>
            <ol>
              <li>
                <p><b>1.4.1. </b>Caso haja suspensão do uso de uma ou todas as plataformas previstas no item
                  “C” do preâmbulo, a Mondelez indicará qual será a plataforma substituta, sendo que as
                  informações já computadas, serão consideradas para fins de apuração do atingimento das
                  metas descritas no Anexo I.</p>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <p><b>2. Empregados Participantes</b></p>
        <ol>
          <li>
            <p><b>2.1. </b>Poderão participar do Programa todos os empregados, conforme descrição do item “D” do
              preâmbulo, e que realizem sua adesão através do site http://gigantesdaexecucao.com/ e aceitarem os
              termos da campanha. Será necessário fazer o aceite e renovação dos dados cadastrais no início de no
              meio do período da campanha.</p>
          </li>
          <li>
            <p><b>2.2. </b>O aceite é requisito obrigatório para a participação na campanha e deverá ser renovado,
              assim como os dados cadastrais, no início de cada onda da campanha. Todos os participantes serão
              avisados através do e-mail corporativo e através do banner no site da campanha, acerca das datas de
              início e fim de cada período de aceite de regulamento, bem como também serão notificados do não
              aceite no final de cada período, através do e-mail corporativo. O não aceite em qualquer uma das
              ondas implicará a não utilização do resultado atingido naquela onda para a criação do ranking final,
              impactando na premiação do participante.</p>
          </li>
          <li>
            <p><b>2.3. </b>Caso o empregado esteja em período de férias durante o aceite da campanha, este terá 15
              (quinze) dias corridos, após o seu retorno, para solicitar a abertura de inscrição através do SAC
              para que se torne elegível à premiação. Caso a solicitação não ocorra dentro do prazo determinado,
              será necessário aguardar o início da próxima onda.</p>
          </li>
          <li>
            <p><b>2.4. </b> Em caso de colaboradores que forem admítidos no após o período de aceite da Onda, será aberta a exceção
                para que ele entre em contato com o time responsável em até 15 (quinze) dias corridos após sua efetivação, a fim de
                solicitar a abertura de aceite fora do período regulamentar. Caso a solicitação não ocorra dentro do prazo determinado,
                será necessário aguardar o início da próxima onda.
            </p>
          </li>
          <li>
            <p><b>2.5. </b>Em caso de desligamento (sem justa causa, por justa causa e a pedido do empregado), seja
              durante o período da campanha ou até no período de entrega da premiação, o Empregado Participante
              não terá direito ao recebimento do prêmio. Sendo assim, a premiação fica para o próximo colocado,
              desde que tenha batido os targets. Esse critério também se aplicará em caso de bônus sazonais.</p>
          </li>
          <li>
            <p><b>2.6. </b>Somente os colaboradores efetivos da Mondelez são elegíveis à premiação. Contratações
              temporárias para projetos esporádicos não poderão participar da campanha.</p>
          </li>
          <li>
            <p><b>2.7. </b>Todas as comunicações relativas a este Programa serão informadas aos empregados
              participantes através dos seus superiores imediatos, por e-mail e pelo telefone celular corporativo.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p><b>3. Período de Participação</b></p>
        <ol>
          <li>
            <p><b>3.1. </b>O Programa terá duração entre as datas indicadas no item “F” do preâmbulo.</p>
          </li>
          <li>
            <p><b>3.2. </b>O período de duração do Programa poderá ser interrompido pela Mondelez a seu exclusivo
              critério, mediante comunicação por e-mail aos Empregados Participantes.</p>
          </li>
        </ol>
      </li>
      <li>
        <p><b>4. Produtos Participantes</b></p>
        <ol>
          <li>
            <p><b>4.1. </b>Participam do Programa todos os produtos fabricados pela Mondelez nas categorias
              elencadas no item G do preâmbulo (“Produtos Participantes”).</p>
          </li>
        </ol>
      </li>
      <li>
        <p><b>5. Funcionamento do Programa</b></p>
        <ol>
          <li>
            <p><b>5.1. Para ser considerado elegível a participar da Programa, deverá o Público-Alvo: </b><br />
              a) Se encontrar na região de desenvolvimento do Programa, elencadas no item “E” do preâmbulo;<br />
              b) Se cadastrar no site http://gigantesdaexecucao.com/ e aceitar os termos da Campanha. <br />
              c) Se enquadrar, de acordo com os critérios da Mondelez, na(s) categoria(s) descrita(s) no item “D”
              do preâmbulo.</p>
          </li>
          <li>
            <p><b>5.2. Mudança do Participante. </b>Para ser elegível, o Empregado Participante tem que atender o
              cliente por mais de 50% do tempo do Programa. Em caso de mudança de cargo durante o período do
              Programa, ganhará a premiação quem tiver mais tempo na função.</p>
          </li>
          <li>
            <p><b>5.3. </b>A mecânica do Programa está indicada no após o aceite deste Regulamento.</p>
          </li>
        </ol>
      </li>
      <li>
        <p><b>6. Apuração, Contestações e Critérios de Desempate</b></p>
        <ol>
          <li>
            <p><b>6.1. Critérios de target: </b>Os targets de Loja 4/5 estrelas serão extraídas de relatórios do
              time Loja Perfeita. Já os targets de Sell Out e Sell In serão retirados dos relatórios enviados por
              Sales Planning.</p>
          </li>
          <li>
            <p><b>6.2. Apuração. </b>A apuração será feita através das plataformas previstas no item “C” do
              preâmbulo e a divulgação dos resultados se dará conforme prazo e formato determinado no item “J” do
              preâmbulo.</p>
            <figure><img src={imageApuracao1} alt="Imagem apuração 1" /></figure>
            <br /><br />
            <figure><img src={imageApuracao2} alt="Imagem apuração 2" /></figure>
            <br /><br />
          </li>
          <li>
            <p><b>6.3. </b>Para acompanhamento dos resultados, a Mondelez disponibilizará, quinzenalmente, aos
              Empregados Participantes, a evolução da performance individualizada, assim como a posição no
              ranking.</p>
            <p><b>6.4. Contestações: </b>Caso um ou mais Empregados Participantes não concordem com a apuração final
              realizada e/ou com a premiação atribuída a ele, o caso deverá ser comunicado através de e-mail para
              o contato@gigantesdaexecucao.com ou Whatsapp +55 (11) 98863-7184, em até 10 (dez) dias corridos
              contados a partir do dia da divulgação dos resultados. Insurgências quanto ao resultado ou qualquer
              divergência levantada após o prazo descrito serão desconsideradas.</p>
            <p><b>6.5. </b>Nos casos de contestação do resultado, o prazo para entrega do prêmio, previsto no item
              “L” do preâmbulo deste regulamento, ficará suspenso, e passará a contar integralmente a partir da
              data de divulgação do resultado da investigação do caso.</p>
            <p><b>6.6. Dos Critérios de desempate: </b><br />
             a) Para a Premiação por Onda serão adotados os seguintes critérios de desempate:<br />
              - Melhor performance KP1 (Loja ou categoria);<br />
              - Melhor performance KP2 (Marca Foco);<br />
              - Pontos Percentuais;<br />
              - Diferença na abertura das casas decimais;<br />
              - Em caso de empate na pontuação, os participantes ocuparão a mesma posição no ranking, mas os nomes estarão em ordem alfabética apenas por uma questão de organização.</p>
            <p> b) Para a Premiação da Viagem serão adotados os seguintes critérios de desempate:<br />
              - Melhor Performance nas Ondas;<br />
              - Pontos Percentuais;<br />
              - Diferença na abertura das casas decimais;
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p><b>7. Premiação</b></p>
        <ol>
          <li>
            <p><b>7.1. </b>É a indicada no item “I”, que será concedida na forma e no prazo previsto nos itens “K” e
              “L” do preâmbulo, respectivamente.</p>
          </li>
          <li>
            <p><b>7.2. </b>A premiação consiste em: <br />
              a) Para os melhores do Programa: Uma viagem de 04 noites na América latina. Os valores referentes à
              passagem com bagagem (sem excedente), hospedagem, transporte e alimentação gastos durante a viagem
              serão custeados pela Mondelez. Demais valores gastos serão custeados pelo Participante. A Mondelez
              encaminhará o itinerário da viagem em momento oportuno. Qualquer atividade que o Participante
              realize fora das indicadas no itinerário e/ou condições escolhidas pela Mondelez, estarão sob
              responsabilidade do empregado Participante. Acompanhantes não serão permitidos na viagem, mesmo que
              custeado integralmente pelo participante e/ou terceiro.<br />
              b) Pontuação em plataforma de premiação: Crédito de pontuação em plataforma de premiação. O valor
              correspondente ao cargo ganhador será entregue na residência do ganhador a cada onda, após a
              divulgação do ranking.
            </p>
            <ol>
              <li>
                <p><b>7.2.1. </b>O Participante será exclusivamente responsável pela emissão de passaporte e
                  demais documentos e/ou requisitos que possam ser exigidos pela legislação local e do país de
                  destino. A contratação do seguro de viagem, assim como seu custo será sob a responsabilidade
                  da Mondelez.</p>
              </li>
              <li>
                <p><b>7.2.2. </b>O voo partirá do Aeroporto Internacional de São Paulo. A Mondelez custeará o
                  valor de deslocamento do domicílio do empregado Participante até o Aeroporto.</p>
              </li>
              <li>
                <p><b>7.2.3. </b>Cada Participante terá direito a apenas 1 (um) lugar na viagem. Acompanhantes
                  não serão permitidos na viagem, mesmo que custeado integralmente pelo participante e/ou
                  terceiro.</p>
              </li>
            </ol>
          </li>
          <li>
            <p><b>7.3. </b>Serão premiados com a viagem:</p><br />
            <center><p><b>PREMIADOS FINAL - VIAGEM</b></p></center>
            <S.Table>
              <table>
                <thead>
                  <tr>
                    <th>
                      <b>Canal</b>
                    </th>
                    <th>
                      <center><b>Executivo</b></center>
                    </th>
                    <th>
                      <center><b>GA</b></center>
                    </th>
                    <th>
                      <center><b>GR</b></center>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      <b>ATC</b>
                    </td>
                    <td>
                      <center>1</center>
                    </td>
                    <td rowSpan={4}>
                      <center>4</center>
                    </td>
                    <td rowSpan={4}>
                      <center>2</center>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>TD</b>
                    </td>
                    <td>
                      <center>1</center>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>VJ</b>
                    </td>
                    <td>
                      <center>1</center>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>HIB</b>
                    </td>
                    <td>
                      <center>1</center>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>MT</b>
                    </td>
                    <td>
                      <center>1</center>
                    </td>
                    <td>
                      <center>2</center>
                    </td>
                    <td>
                      <center>1</center>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>FARMA + DIA</b>
                    </td>
                    <td>
                      <center>1</center>
                    </td>
                    <td rowSpan={2}>
                      <center>1</center>
                    </td>
                    <td rowSpan={2}>
                      <center>1</center>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>GKA + Americanas</b>
                    </td>
                    <td>
                      <center>1</center>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>C&C</b>
                    </td>
                    <td>
                      <center>1</center>
                    </td>
                    <td rowSpan={2}>
                      <center>1</center>
                    </td>
                    <td rowSpan={2}>
                      <center>1</center>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>FOOD</b>
                    </td>
                    <td>
                      <center>1</center>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>ECOMM</b>
                    </td>
                    <td>
                      <center>1</center>
                    </td>
                    <td>
                      <center>1</center>
                    </td>
                    <td>
                      <center>1</center>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>TOTAL PREMIADOS</b>
                    </td>
                    <td>
                      <center><b>10</b></center>
                    </td>
                    <td>
                      <center><b>9</b></center>
                    </td>
                    <td>
                      <center><b>6</b></center>
                    </td>
                  </tr>
                </tbody>
              </table>
            </S.Table>
          </li>
          <li>
            <p><b>7.4. </b>Serão premiados como melhores da Onda:</p><br />
            <center><p><b>PREMIAÇÃO ONDA</b></p></center>
            <S.SectionTable />
          </li>
          <li>
            <p><b>7.5. </b>Para cada canal será premiado o Head de Vendas.</p>
          </li>
          <li>
            <p><b>7.6. </b>Em nenhuma hipótese, a premiação paga poderá ser substituída por qualquer outro tipo de
              pagamento que não o descrito no Anexo I do Regulamento.</p>
          </li>
          <li>
            <p><b>7.7. </b>Exceto quando a lei exigir retenção na fonte, os valores devidos a título de tributos,
              bem como suas respectivas obrigações acessórias, serão de responsabilidade exclusiva do
              Participante.</p>
          </li>
          <li>
            <p><b>7.8. </b>Todos os pagamentos de premiação constarão na folha de pagamento subsequente à premiação
              para efeito de recolhimento de impostos caso necessário.</p>
          </li>
          <li>
            <p><b>7.9. </b>O resgate dos pontos creditados na plataforma parceira deverá ser feito até o dia
              29/02/2025. Caso haja atraso na postagem dos créditos na plataforma parceira ou tenha baixa adesão
              de resgate de premiação, a Mondelez poderá postergar a data de resgate da premiação.</p>
          </li>
          <li>
            <p><b>7.10. </b>O leilão será aberto na 1ª quinzena do mês subsequente ao final da Onda vigente</p>
          </li>
        </ol>
      </li>
      <li>
        <p><b>8. Cláusulas Gerais</b></p>
        <ol>
          <li>
            <p><b>8.1. </b>Este é um Programa contabilizado por meio de sistemas eletrônicos e auditoria. A Mondelez
              se reserva o direito de, caso aconteça alguma falha nos sistemas operacionais do Programa, alterar a
              forma de apuração das metas e performance dos participantes, sem qualquer prejuízo aos empregados,
              bem como cancelar o Programa. Qualquer eventual alteração feita pela Mondelez será comunicada aos
              participantes imediatamente através de e-mail.</p>
          </li>
          <li>
            <p><b>8.2. </b>Os empregados participantes permitem desde já a utilização pela Mondelez de sua imagem e
              nomes, sem qualquer ônus, em vídeos, internet e qualquer material de divulgação do Programa. A
              presente autorização é concedida a título gratuito, abrangendo o uso da imagem acima mencionada em
              todo território nacional e no exterior, em todas as suas modalidades e, em destaque, das seguintes
              formas: (I) home page do veículo de comunicação; (II) edição impressa dos veículos de comunicação
              (III) divulgação em geral.</p>
          </li>
          <li>
            <p><b>8.3. </b>Os empregados participantes declaram, desde já, que estão cientes que os contatos feitos
              para a operacionalização do Programa através de seus e-mails e telefones corporativos.</p>
          </li>
          <li>
            <p><b>8.4. </b>Casos omissos e/ou não previstos neste regulamento serão de boa-fé resolvidos pela
              comissão da Mondelez responsável pelo Programa.</p>
          </li>
          <li>
            <p><b>8.5. </b>A Mondelez reserva-se ao direito de alterar a premiação em decorrência da necessidade de
              mudanças na política econômica e financeira durante o período de vigência do Programa.</p>
          </li>
          <li>
            <p><b>8.6. </b>A Mondelez reserva-se o direito de cancelar o Programa e/ou a participação de empregados
              participantes a qualquer momento se constatar a existência de fraude, bem como poderá cancelar o
              Programa a seu exclusivo critério.</p>
          </li>
          <li>
            <p><b>8.7. </b>Nenhuma renúncia a qualquer termo ou condição deste Acordo, seja mediante conduta ou por
              qualquer outra forma, em uma ou mais ocasiões, deve ser interpretada como novação ou tácita
              aceitação de modificação das obrigações estabelecidas.</p>
          </li>
          <li>
            <p><b>8.8. </b>Não será permitida a flexibilização dos requisitos de participação, incluindo, mas não
              se limitando, o Aceite ao Programa. Caso o empregado não aceite as condições do programa na
              forma indicada no item 2.2, será considerado automaticamente inelegível. O aceite em apenas um dos
              períodos não torna o Empregado elegível.</p>
          </li>
          <li>
            <p><b>8.9. </b>Somente serão permitidos, antes do término do programa, pedidos de alteração deste que
              versem sobre alteração da data de término da promoção, do aumento do valor da premiação, diminuição
              da proporção entre número participantes e a quantidade de prêmios e aumento no número de
              participantes, desde que as alterações sejam devidamente comunicadas a todos os participantes.</p>
          </li>
          <li>
            <p><b>8.10. </b>Não será autorizado aditamento que envolva mudança de modalidade ou alteração da
              mecânica da promoção.</p>
          </li>
          <li>
            <p><b>8.11. </b>Este Programa busca incentivar o crescimento da cultura de execução da Mondelez. No
              entanto, não existe nenhuma garantia de sucesso e de consequente premiação do Participante.</p>
             <p><b>8.12. </b>Para garantir a coordenação da viagem, duas pessoas da Mondelez envolvidas na Campanha irão acompanhar a viagem.</p>
          </li>
        </ol>
      </li>
    </ol>
    <br /><br />
    <h3><b>ANEXO II – TERMO DE ADESÃO – CAMPANHA GIGANTES DA EXECUÇÃO</b></h3>
    <p>Por este termo, o empregado da Mondelez, declara para os devidos fins que concorda com o regulamento do Programa, que
      ocorrerá no período estipulado no item F do preâmbulo do Regulamento, promovida pela Mondelez nos termos do
      regulamento do Programa que foi submetido pela Mondelez ao Participante. [DE 01/02/2024 À 30/11/2024].</p>
    <p>
      Nesse sentido, ao assinar e aderir o presente Programa, declara o Participante que: <br />
      a) Conhece e está integralmente de acordo com o regulamento e premissas do Programa;<br />
      b) Concorda plenamente com as regras e critérios de avaliação destacados no Regulamento do Programa, ficando
      acordado que, caso o empregado não se enquadre ou deixem de cumprir as regras do Programa, deixará de participar do
      mesmo;<br />
      c) Reconhece que o Programa busca incentivar o aumento no volume de vendas, mas que não garante nenhuma obtenção de
      sucesso em seu negócio;<br />
      d) A Mondelez decidirá exclusivamente sobre as metas a serem atingidas, bem como fará a apuração dos resultados,
      podendo pelo ato de liberalidade alterar a mecânica de apuração comunicando previamente ao início de cada Etapa via
      e-mail.
    </p>
  </S.RegulamentoContainer>
}
