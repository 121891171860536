import styled from 'styled-components';

export const Principal = styled.div`
  background-color: var(--white);
  width: 100%;
  max-width: 350px;
  min-height: 400px;
  border-radius: 6px;
  padding-top: 130px;
  text-align: center;
  box-shadow: 2px 5px 5px 2px #00000021;
  margin: 0;
  padding-bottom: 32px;
  position: relative;

  @media (max-width: 932px) {
    margin: 0 auto;
  }

  .background {
    background: linear-gradient(90deg, var(--primary) 0%, var(--secondary) 100%);
    border-radius: 6px 6px 8px 8px;
    height: 86px;
    width: 100%;
    position: absolute;
    top: 0;
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  height: 30px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: ${({ color }) => color};
  font-weight: bold;
  font-family: Rajdhani,sans-serif;
  text-transform: uppercase;
  border-radius: 6px;
  margin-top: 7px;
  margin-bottom: 7px;
  width: fit-content;

  p {
    color: #fff;
    line-height: normal;
    margin-bottom: 6px;
    user-select: none;
  }
`;

export const Score = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 20px 0;

    .score{
        margin: 15px 35px;
        text-transform: uppercase;
        font-weight: bold;

        p{
            color: var(--text-light);
            margin-top: 10px;
        }
    }
`;

export const Link = styled.button`
    display: inline-block;
    width: 80%;
    min-height: 48px;
    border-radius: 10px;
    background-color: #4e246e;
    color: #fff;
    font-size: .875rem;
    font-weight: 700;
    text-align: center;
    line-height: 1rem;
    padding: 16px;
    cursor: pointer;
    transition: background-color .2s ease-in-out,color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
    box-shadow: 3px 5px 10px 0 rgb(62 63 94 / 20%);

    &:hover{
        background-color: #36194c;
        color: #fff;
    }
`;

export const ModalQuiz = styled.div`
  &.form-row {
    display: inline-block;
  }

  p {
    line-height: 200%;
    font-size: 22px;
    font-weight: bold;
  }
`;

export const Data = styled.p`
  font-size: 20px;
`;
