import styled from 'styled-components'
import TopContainerComponent from '../../components/TopContainer'
import TemplateBase from '../../template'

export const TopContainer = styled(TopContainerComponent)`
  min-height: 300px;
  max-height: 300px;
  margin-top: -100px;
`

export const Template = styled(TemplateBase)`
  &.content-grid {
    width: initial;
    min-height: initial;
    max-width: initial;
    padding: initial;
  }
`

export const Container = styled.div`
  background-color: #fff;
  width: 100vw;

  p {
    font-family: Montserrat, sans-serif;
    margin: 0;
  }
`

export const SectionsContainer = styled.div`
  position: relative;

  > figure {
    max-width: clamp(10rem, 5.3883rem + 19.6764vw, 29rem);
    position: absolute;
    top: 178px;

    &:first-child {
      left: -252px;
    }

    &:last-of-type {
      right: -252px;
    }
  }
  `

export const SectionsGridContainer = styled.div.attrs((props) => {
  return {
    ...props,
    className: 'content-grid'
  }
})`
  display: grid;

  .header-info {
    h2 {
      color: #40415d;
    }

    p {
      color: #9192a6;
    }
  }

  @media screen and (max-width: 680px) {
    padding-inline: 32px !important;
  }
`

export const CategoriesGridContainer = styled.div`
  overflow-x: scroll;
  width: 100%;

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`

export const CategoriesContainer = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, 272px);
  justify-content: center;

  @media screen and (max-width: 540px) {
    display: flex;
    width: min-content;
  }
`

export const FilterContainer = styled.div`
  margin-top: 64px;
`

export const ProductsGridContainer = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, 272px);
  justify-content: center;
`

export const ShowMoreButton = styled.button`
  justify-self: center;
  margin-top: 32px;
  padding-inline: 20px;
  width: fit-content;
`

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
