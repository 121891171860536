import styled from 'styled-components';

export const Container = styled.div`
  &.small label {
    color: var(--text-light);
  }

  input {
    backdrop-filter: unset !important;

    &:read-only {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
`;

export const SearchButton = styled.button`
  background-color: var(--secondary);

  &:hover {
    background-color: var(--secondary) !important;
  }
`;
