import { ReactElement } from 'react'
import PicPayModalProps from '../props'
import character from './assets/character.png'
import * as S from './style'

export default function SuccessModal ({ dismiss }: Omit<PicPayModalProps, 'confirm'>): ReactElement {
  return <S.Container>
    <figure>
      <img src={character} alt="" />
    </figure>

    <h1>
      Oba, sua Transferência PicPay foi um Sucesso! 🎉
    </h1>

    <p>
      Seu saldo será atualizado em até 7 dias úteis. Agora é só relaxar e aguardar o crédito.
      <br/>
      Caso tenha qualquer dúvida, nosso time de Atendimento de Gigantes da Execução está à sua disposição. Basta entrar em contato! <a href="mailto:contato@gigantesdaexecucao.com">contato@gigantesdaexecucao.com</a>
    </p>

    <S.GoBackButton onClick={dismiss}>Voltar</S.GoBackButton>
  </S.Container>
}
