import styled from 'styled-components'

export const Container = styled.div``

export const AuctionCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    gap: 16px;
  }
`

export const FilterContainer = styled.div`
  margin-top: 64px;
`
