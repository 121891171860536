import { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react'
import ErrorComponent from '../../../components/Forms/ErrorComponent'
import InputTextMdlzProps from './props'
import * as S from './style'

export default function InputTextMdlz ({
  as = 'input',
  name,
  id,
  label,
  value,
  onChange,
  placeholder,
  readonly,
  type = 'text',
  mask,
  error,
  maxLength,
  required,
  className,
  prefix
}: InputTextMdlzProps): ReactElement {
  const [internalValue, setInternalValue] = useState<string>('')

  const handleValueChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      if (mask !== null && mask !== undefined) value = mask(value)
      if (onChange !== null && onChange !== undefined) onChange((prevValue: any) => ({ ...prevValue, [name]: value }))
      setInternalValue(value)
    },
    [mask, name, onChange]
  )

  useEffect(() => {
    value && setInternalValue(value)
  }, [value])

  return <S.Container>
      <label htmlFor={id}>{label}</label>

      <S.InputContainer>
        {prefix != null && <p>{prefix}</p>}

        <S.Input
          as={as}
          className={className}
          type={type}
          id={id}
          name={name}
          value={internalValue !== null && internalValue !== undefined && internalValue !== '' ? internalValue : '' }
          onChange={handleValueChange}
          placeholder={placeholder}
          readOnly={readonly}
          required={required}
          maxLength={maxLength}
        />
      </S.InputContainer>
      <ErrorComponent>{error}</ErrorComponent>
    </S.Container>
}
