import { AxiosError, AxiosInstance } from 'axios'
import { LoadHubCampanhas } from '../../domain/use-case/LoadMyHubCampanhas'

export class ApiLoadMyHubCampanhas implements LoadHubCampanhas {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (): Promise<LoadHubCampanhas.Result> {
    try {
      const response = await this.api.get('/hub-campanha/minhas-campanhas')
      return response
    } catch (err) {
      const axiosError = err as AxiosError
      if (axiosError.response) {
        return axiosError.response
      }

      if (axiosError.request) {
        return {
          data: axiosError.request,
          status: 0
        }
      }

      return {
        data: axiosError.message,
        status: 0
      }
    }
  }
}
