import styled from 'styled-components'
import ErrorComponent from '../../../../components/Forms/ErrorComponent'
import TemplateBase from '../../../../template'

export const Template = styled(TemplateBase)`
  &.content-grid {
    min-height: initial;

    padding: 0 28px 100px;

    .content-grid.full {
        margin-top: 150px;
    }

    @media (max-width: 520px) {
        padding-bottom: 150px;

        max-width: 100%;

        .content-grid.full {
            margin-top: 50px !important;
        }
    }
  }
`

export const BannerContainer = styled.div`
    position: relative;
    overflow: hidden;
`

export const Banner = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
`

export const PostCard = styled.div`
    width: 100% !important;
    max-width: 1153px !important;
    margin: 0 auto !important;
    padding: 32px 28px !important;

    .post-open-content, .post-open-content-body {
        padding: 0 !important;
    }
`

export const ImagePreview = styled.img`
    height: 100%;
    object-fit: cover;
    width: 100%;
`

export const MainBannerLabel = styled.label`
    color: var(--text-light);
    font-weight: 700;
    margin-left: 36px;
`

export const ProgressContainer = styled.div`
    margin-inline: auto;
    max-width: 500px;
    width: 100%;
`

export const BannerAvatarContainer = styled.div`
    display: flex;
    gap: 16px 32px;
    justify-content: center;
    padding-inline: 40px;

    > div {
        max-width: 284px;
        width: 100%;
    }

    @media (max-width: 600px) {
        flex-direction: column;
    }
`

export const EditorLabel = styled.label`
    color: var(--text-light);
    font-weight: 700;
    margin: 0 0 0 36px;
`

export const PostOpenParagraph = styled.p`
    margin-top: 8px !important;
`

export const InputsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
`

export const FormProgressContainer = styled.div`
    display: flex;
    gap: 8px;
    margin: 16px auto 0;
    width: fit-content;
`

export const FormProgress = styled.div<{ $activeView: boolean }>`
    aspect-ratio: 1;
    background-color: ${({ $activeView }) => $activeView ? 'var(--primary)' : 'var(--white)'};
    border-radius: 50%;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, .06);
    width: 8px;
`

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 16px;
`

export const ErrorMessage = styled(ErrorComponent)`
    margin-bottom: 16px;
`

export const BannerPreview = styled.figure`
  margin: 0 auto;
  max-width: 852px;
  width: 100%;

  img {
      object-fit: cover;
      height: auto;
    }
`

export const LogoPreview = styled.figure`
  background-color: var(--white);
  display: flex;
  height: 100%;
  margin: 0 auto;
  max-height: 130px;
  max-width: 120px;
  width: 100%;

  img {
      clip-path: inherit;
      height: 110px;
      object-fit: cover;
      margin: auto;
      width: 100px;
    }
`

export const SmallBannerPreview = styled.figure`
    border-radius: 12px 12px 0 0;
    margin: 0 auto;
    max-width: 284px;
    overflow: hidden;
    width: 100%;

    img {
        height: auto;
        object-fit: cover;
    }
`

export const CheckboxGrid = styled.div`
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(5, 1fr);
`

export const CheckLabel = styled.label`
    margin-bottom: 16px;
    input {
        margin-right: 8px;
    }
`

export const LinkContainer = styled.div`
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
`
