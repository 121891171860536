import styled, { css } from 'styled-components'
import cardBack from '../../../assets/img/memory-game/card-back.png'
import { CardState } from '../Data/Entities/CardState'

const cardUp = css`
  transform: rotateY(-180deg);
`
const cardDown = css`
  transform: rotateY(0);
`
const cardUp2 = css`
  transform: rotateY(0deg);
`
const cardDown2 = css`
  transform: rotateY(180deg);
`

export const Container = styled.div`
  align-items: center;
  aspect-ratio: 67 / 92;
  perspective: 1000px;
  position: relative;
`

export const CardFace = styled.div<any>`
  align-items: center;
  background-image: url(${cardBack});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  backface-visibility: hidden;
  border-radius: 8%;
  display: flex;
  inset: 0;
  justify-content: center;
  transform-style: preserve-3d;
  position: absolute;
  transition: all 1s;
  ${(props) => props.cardState === CardState.FLIP_UP ? cardUp : cardDown}

  &:last-child {
    background-image: none;
    ${(props) => props.cardState === CardState.FLIP_UP ? cardUp2 : cardDown2}
  }

  img {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
  }
`
