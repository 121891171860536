import styled from 'styled-components'

export const FormSelect = styled.div`
  width: 100%;
  position: relative;

  label {
    padding: 0 6px;
    background-color: var(--white);
    color: #adafca;
    font-size: .75rem;
    font-weight: 600;
    position: absolute;
    top: -8px;
    left: 12px;
    pointer-events: none;
  }

  select {
    width: 100%;
    height: 48px;
    padding-inline: 20px 44px;
    font-size: 0.875rem;
    cursor: pointer;
  }
`

export const FormSelectIcon = styled.svg`
  fill: var(--dark);
  transform: rotate(90deg);
  position: absolute;
  top: 20px;
  right: 20px;
  pointer-events: none;
  width: 6px;
  height: 8px;
`
