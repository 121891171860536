/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useState } from 'react'
import characterOne from '../../../../assets/img/game-gira-giro/posicao1.png'
import characterTwo from '../../../../assets/img/game-gira-giro/posicao2.png'
import lateral from '../../../../assets/img/game-gira-giro/question-lateral.png'
import roulette from '../../../../assets/img/game-gira-giro/roulette.png'
import arrowLeft from '../../../../assets/img/game-gira-giro/spin-arrow-left.png'
import arrowRight from '../../../../assets/img/game-gira-giro/spin-arrow-right.png'
import RouletteProps from './props'
import * as S from './styled'

export default function Roulette ({ OnStart }: RouletteProps): ReactElement {
  const [paused, setPaused] = useState(true)
  const [selectedSlot, setSelectedSlot] = useState<number | null>(null)

  const spinRoulette = (): void => {
    setPaused(false)
    setSelectedSlot(null)
  }

  const timeOut = Math.floor(Math.random() * 7000) + 3000

  useEffect(() => {
    if (!paused) {
      setTimeout(() => {
        setPaused(true)

        const result = Math.floor(Math.random() * 6)

        OnStart(Math.min(6, Math.max(1, result)))
      }, timeOut)
    }
  }, [paused])

  return <S.Container>
    <S.ProductsBg>
      <img src={lateral} alt="" />
    </S.ProductsBg>

    <S.Content>
      <S.CharacterContainer>
        <S.BubbleBgContainer>
          {selectedSlot
            ? <S.PointsContainer>
              <p>
                {String(selectedSlot).padStart(2, '0')}
                <br />
                <span>pontos</span>
              </p>
            </S.PointsContainer>
            : <S.SpinButton disabled={!paused} onClick={spinRoulette}>
              <figure>
                <img src={arrowLeft} alt="" />
              </figure>

              <p>
                <span>Gira</span>
                <br />
                Roleta
              </p>

              <figure>
                <img src={arrowRight} alt="" />
              </figure>
            </S.SpinButton>}
        </S.BubbleBgContainer>

        <S.Character>
          <img src={paused ? characterOne : characterTwo} alt="" />
        </S.Character>
      </S.CharacterContainer>

      <S.Roulette $paused={paused}>
        <img src={roulette} alt="" />
      </S.Roulette>
    </S.Content>
  </S.Container>
}
