import styled from 'styled-components'

export const ModalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  position: relative;
  overflow: auto;

  h1, p {
    font-family: MdlzBiteType;
    margin: 0;
    text-align: center;
  }

  h1 {
    border-bottom: 4px solid #ffdc39;
    color: #ffdc39;
    font-size: 72px;
    letter-spacing: -2px;
    margin-bottom: 16px;
    text-align: center;

    br {
      content: "";
      display: block;
      margin: -28px;
    }

    @media screen and (max-width: 280px) and (orientation: portrait) {
      font-size: 64px;
    }
  }

  p {
    color: var(--white);
    font-size: 36px;
  }

  > figure {
    z-index: 1;
  }

  button {
    background-color: #ffdc39;
    border-radius: 2px;
    color: #0e3e8a;
    font-family: MdlzBiteType;
    font-size: 28px;
    font-weight: 400;
    max-width: fit-content;
    padding: 4px 32px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;

    @media screen and (max-width: 375px) and (orientation: portrait) {
      font-size: 24px;
    }

    @media screen and (max-width: 280px) and (orientation: portrait) {
      font-size: 16px;
    }
  }
`

export const GiganteFigure = styled.figure`
  img {
    max-width: 200px;
  }
`

export const TryAgainButton = styled.button`
  margin-top: 32px;
`
