import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  width: 100%;

  @media (max-width: 500px) {
    align-items: center;
    flex-direction: column;
  }
`

export const Character = styled.figure`
  height: fit-content;
  max-width: fit-content;
  margin-top: 36px;

  img {
    width: 100%;
  }

  @media (max-width: 500px) {
    align-self: flex-end;
    margin: 0 40px -60px 0;
    max-width: 100px;
  }
`

export const Form = styled.form`
  display: grid;
  grid-template-columns: 5fr 3fr;
  max-width: 464px;
  width: 100%;

  .input {
    height: 56px !important;
  }

  .error {
    color: #cc0000;
  }

  h1, p, button {
    color: #284087;
    font-family: MdlzBiteType;
    letter-spacing: 0px;
    margin: 0;
    text-align: center;
  }

  h1 {
    font-size: 36px;
  }

  p {
    font-size: 16px;
  }

  button {
    background-color: #f1cb0c;
    border-radius: 10px;
    font-size: 16px;
    height: 32px;
    letter-spacing: 0px;
    outline: none;
    padding-inline: 20px;
    text-align: center;

    &:hover {
      background-color: #f1cb0cCC;
      color: #284087CC;
    }
  }

  > :not(:nth-child(3)):not(:nth-child(4)) {
    height: 74px;
    grid-column: span 2;
    place-content: center;
  }

  > :nth-child(3), > :nth-child(4) {
    height: 112px;
    place-content: center;
  }

  > :nth-child(4) {
    div:has(input) {
      height: 56px;
    }
  }

  > :last-child {
    height: 32px !important;
  }
`

export const DigimoedasContainer = styled.div`
  position: relative;
  width: 100%;

  > p {
    color: #f1cb0c;
    margin-left: 20px;
    text-align: left;
  }

  div {
    align-items: center;
    border-radius: 4px;
    background-color: #284087;
    display: flex;
    filter: drop-shadow(0px 6px 4px rgba(3,4,2,0.15));
    height: 56px;
    position: relative;
    padding-inline: 16px;
    width: 66.66%;

    p {
      color: #fecc00;
      font-family: MdlzBiteType;
      font-size: 36px;
      letter-spacing: 0px;
      line-height: 12px;
      margin: 6px 0 0;

      span {
        font-size: 12px;
      }

      br {
        content: "";
        display: block;
        margin: 6px;
      }
    }

    figure {
      position: absolute;
      right: -52px;
      max-width: fit-content;

      width: 100%;
    }
  }
`

export const ViewDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 24px;
  height: 100% !important;
  gap: 12px;
  width: 100%;

  p {
    font-size: 14px;
    letter-spacing: 0px;
    color: #2f2f2f;
    font-weight: 400;
    font-family: Roboto;
    text-align: center;
  }

  > p {
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: right;
    text-decoration: underline;
  }

  div {
    align-items: center;
    background-color: #faf9fd;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    filter: drop-shadow(0px 2px 3.5px rgba(9,9,27,0.25));
    justify-content: center;
    height: 80px;
    padding-inline: 32px;
  }
`

export const TermsContainer = styled.div`
  margin-inline: auto;
  max-width: 254px;

  > div:has(input) {
    label {
      color: #2f2f2f;
      font-family: Roboto;
      font-size: 10px;
      font-weight: 700;
      letter-spacing: 0px;
      margin: 0;
    }
  }
`
