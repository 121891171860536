import styled from 'styled-components'

export const LojasPorEstrelaTextContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #FFF;
`

export const LojasPorEstrelaTextContainerTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 23px;
    }
    div h3 {
        color: #FFC01A;
        font-weight: 900;
        font-size: 22px;
        text-align: center;

        @media (min-width: 1366px) {
            br {
                display: none;
            }
        }
    }
    div p {
        color: #FFF;
        display: flex;
        font-size: 16px;
        margin: 0px;
        max-width: 220px;
        text-align: center;
        padding: 2px 6px;
        line-height: 1.1;
    }
    div img {
        width: 20px;
        margin-top: auto;
        margin-left: 4px;
    }
`

export const LojasPorEstrelaTextContainerMid = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 34px;
    font-weight: 300;
    p {
        max-width: 390px;
        margin: 0px auto;
        color: #FFF;
        font-size: 15px;
        text-align: justify;
        line-height: 1.2;
        & strong {
            font-weight: 900;
        }

        @media (max-width: 1365px) {
            max-width: 284px;
        }
    }
`

export const LojasPorEstrelaTextContainerBottom = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    line-height: 1.2;
    div {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    div div {
        flex-direction: column;
        max-width: 165px;
    }
    h3, p {
        margin: 0px auto;
        color: #FFF;
        font-size: 15px;
        text-align: center;
        padding: 0px 4px;
        line-height: 1.2;
    }
    h3 {
        font-weight: 900;

        @media (min-width: 1366px) {
            br {
                display: none;
            }
        }
    }
    p span, footer span {
        color: #FFC01A;
        font-weight: 900;
    }
    footer {
        margin: 25px auto 0px auto;
        max-width: 335px;
        text-align: center;
        font-size: 15px;
    }

    .with-disc {
        flex-direction: column;
        justify-content: flex-start;

        footer {
            margin-bottom: 10px;
        }

        small {
            margin: 0 auto;
            max-width: 335px;
            text-align: left;
            font-size: 10px;
        }
    }
`
