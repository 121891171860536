import styled from 'styled-components'
import TemplateBase from '../../../../../template'

export const Template = styled(TemplateBase)`
  .sub-template {
    padding: 112px 20px;
    width: 100vw;
    background-color: #fff;
  }

  &.content-grid {
    margin: 0;
    padding: 90px 0 0 0;
    .grid.grid-8-4 {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 30px 0 30px;
    }
    .form-input {
      label {
        z-index: 10;
      }
    }
  }
  .widget-box,
  .sidebar-box {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  }
  .totals-line-info {
    max-width: 80%;
  }

  @media screen and (min-width: 1260px) {
    &.content-grid {
      &> .grid.grid-8-4 {
        grid-template-areas: "content sidebar";
        grid-template-columns: 74.6621621622% 23.9864864865% !important;
      }
    }
  }
`
