import styled from 'styled-components'
import Card from '../../../../components/Card'

export const Container = styled(Card)`
  background-color: var(--white);
  border-radius: 8px;
  filter: drop-shadow(0px 3px 20px rgba(13,12,40,0.15));
  overflow: hidden;
`
export const VideoArea = styled.div`
  background-color: #615dfa;
  width: 212px;

  @media (max-width: 1280px) {
    width: 132px;
  }
`

export const EmbedVideo = styled.video`
  aspect-ratio: 1.78;
  display: block;
  margin: 0 auto;
  height: 100%;
  width: 100%;
`

export const PlaceHolder = styled.div`
  aspect-ratio: 16/9;
  background-color: #1d3f85;
  width: 100%;
`

export const ContentContainer = styled.div`
  padding: 16px;
  position: relative;

  h1, p {
    font-family: Rajdhani !important;
    font-size: 10px;
    line-height: 12px;
  }

  h1 {
    color: #0e3e8a;
    font-weight: 700;
  }

  p {
    color: #3e3f5e;
    font-weight: 500;
  }

  @media (max-width: 1280px) {
    padding: 12px;

    h1, p {
      font-size: 6px;
      line-height: 6px;
      margin-bottom: 2px !important;
    }
  }
`

export const AvatarContainer = styled.figure`
  background-color: #1d3f85;
  border: 4px solid #ffffff;
  border-radius: 50%;
  height: 24px;
  position: absolute;
  top: -20%;
  width: 24px;

  @media (max-width: 1280px) {
    height: 16px;
    top: -12%;
    width: 16px;
  }
`
