import styled from 'styled-components'

export const BidButton = styled.button`
  background-color: #f7cb25;
  border-radius: 28px;
  color: #1d3f85;
  font-family: MdlzBiteType;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 4px 16px;
  white-space: nowrap;
  width: 100%;
`
