import styled from 'styled-components'
import TemplateBase from '../../../template'
import background from './assets/BG.png'
import bubble from './assets/bubble.png'

export const Template = styled(TemplateBase)`
  &.content-grid {
    width: initial;
    min-height: initial;
    max-width: initial;
    padding: initial;

    display: flex;
    min-height: 100vh;
    width: 100%;
  }
`

export const Container = styled.div`
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex: 1;
  justify-content: center;
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px;
  max-width: 600px;
  padding: 152px 28px 132px;

  @media (max-width: 764px) {
    padding: 132px 28px;
  }
`

export const StepsContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 110px 110px 180px;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 64px 32px;
  position: relative;
  width: 100%;

  @media (max-width: 764px) {
    grid-template-columns: 1fr;
    max-width: 180px;
  }
`

export const AbsoluteContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  left: -58%;
  position: absolute;
  top: -36%;
  z-index: 1;

  @media (max-width: 1148px) {
    left: -54%;
    top: -26%;

    figure {
      width: 88%;
    }
  }

  @media (max-width: 1024px) {
    left: -52%;
    top: -20%;

    figure {
      width: 80%;
    }
  }

  @media (max-width: 948px) {
    left: -47%;
    top: -4%;

    figure {
      width: 64%;
    }
  }

  @media (max-width: 850px) {
    left: -43%;
    top: 16%;

    figure {
      width: 50%;
    }
  }

  @media (max-width: 764px) {
    position: initial;
    z-index: initial;

    figure {
      width: initial;
    }
  }
`

export const Bubble = styled.div`
  align-items: center;
  aspect-ratio: 178 / 137;
  background-image: url(${bubble});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 64px;
  max-width: 200px;
  width: 100%;

  p {
    color: #e9c300;
    font-family: MdlzBiteType;
    font-size: 23px;
    letter-spacing: -1px;
    line-height: 26px;
    margin-bottom: 8px;
    text-align: center;
    white-space: nowrap;
  }

  img {
    margin-bottom: 12px;
  }

  @media (max-width: 1148px) {
    max-width: 178px;
    margin-left: 52px;

    p {
      font-size: 20px;
      line-height: 22px;
    }

    img {
      max-width: 132px;
    }
  }

  @media (max-width: 1024px) {
    max-width: 170px;
    margin-left: 44px;

    p {
      font-size: 20px;
      line-height: 22px;
    }

    img {
      max-width: 128px;
    }
  }

  @media (max-width: 948px) {
    max-width: 152px;
    margin-left: 28px;

    p {
      font-size: 16px;
      line-height: 20px;
    }

    img {
      max-width: 116px;
    }
  }

  @media (max-width: 850px) {
    max-width: 128px;
    margin-left: 12px;

    p {
      font-size: 16px;
      line-height: 20px;
      margin: 0;
    }

    img {
      max-width: 100px;
    }
  }

  @media (max-width: 764px) {
    max-width: 200px;

    p {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    img {
      max-width: 132px;
    }
  }
`

export const Step = styled.div`
  align-items: center;
  border: 2px solid #e7e3d1;
  border-top-color: transparent;
  border-radius: 13px;
  display: flex;
  height: 100%;
  max-height: 100px;
  justify-content: center;
  padding: 24px 18px;
  position: relative;

  h3 {
    color: var(--white);
    filter: drop-shadow(0px 1px 3.5px rgba(21,21,22,0.15));
    font-family: MdlzBiteType;
    font-size: 22px;
    letter-spacing: 0;
    margin: 0;
    position: absolute;
    text-align: center;
    top: -80%;

    span {
      font-size: 81px;
      letter-spacing: 1px;
      color: #e9c300;
    }
  }

  p {
    color: var(--white);
    font-size: 16px;
    font-family: Roboto;
    line-height: 18px;
    margin: 0;
    text-align: center;
    white-space: nowrap;

    span {
      color: #e9c300;
      font-weight: 400;
      font-family: "Roboto";
      text-align: center;
    }
  }

  &.step-one {
    p span {
      font-weight: 700;
    }
  }

  @media (max-width: 500px) {
    h3 {
      font-size: 18px;
      top: -72%;

      span {
        font-size: 72px;
      }
    }

    p {
      font-size: 12px;
    }
  }
`

export const Description = styled.p`
  font-size: 16px;
  color: var(--white);
  font-family: Roboto;
  margin: 0;
  text-align: center;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`

export const Reminder = styled.div`
  align-items: center;
  border-radius: 13px;
  background-color: #e9c300;
  display: flex;
  justify-content: center;
  min-height: 58px;
  max-width: 435px;
  padding: 8px 28px;

  p {
    font-size: 21px;
    letter-spacing: 0px;
    color: #1b339c;
    font-family: MdlzBiteType;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
  }

  @media (max-width: 500px) {
    padding-inline: 8px;

    p {
      font-size: 16px;

      br {
        display: none;
      }
    }
  }
`
