import { ReactElement, useCallback, useState } from 'react'
import { makeUpdateGiftCardPinCode } from '../../../../main/factories/makeUpdateGiftCardPinCode'
import * as S from './styled'

const updateGiftCardPinCode = makeUpdateGiftCardPinCode()

interface InputInlineParams {
  id: number
}

enum ComponentState {
  READY,
  SAVING,
  SUCCESS,
  ERROR
}

export function InputInline ({ id }: InputInlineParams): ReactElement {
  const [state, setState] = useState<ComponentState>(ComponentState.READY)
  const [pinCode, setPinCode] = useState<string>()
  const handleAddPincode = useCallback(async (evt: any) => {
    evt.preventDefault()
    if (!pinCode) return
    setState(ComponentState.SAVING)
    const response = await updateGiftCardPinCode.update({
      id,
      pinCode
    })
    if (response.status === 200) {
      setState(ComponentState.SUCCESS)
      return
    }
    setState(ComponentState.ERROR)
    setTimeout(() => setState(ComponentState.READY), 5000)
  }, [pinCode])

  if (state === ComponentState.ERROR) return <>Algo deu errado, por favor tente novamente</>
  if (state === ComponentState.SUCCESS) return <>PinCode salvo com sucesso</>
  if (state === ComponentState.SAVING) return <>Salvando...</>

  return <S.Container onSubmit={async (evt) => await handleAddPincode(evt)}>
    <S.InputText label='PinCode' onChange={setPinCode} />
    <S.Button className="button primary" type="submit">Salvar</S.Button>
  </S.Container>
}
