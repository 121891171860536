import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: .25rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  &>div {
    flex: 1 100%;
  }
`

export const CheckContainerGroup = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
`

export const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 75%;
  color: #888;
`

export const Check = styled.div`
  aspect-ratio: 1;
  border-radius: 50%;
  border: 1px solid #ddd;
  display: inline-block;
  margin-right: 16px;
  width: 20px;

  &.active {
    border: 5px solid var(--primary);
  }
`
