import styled from 'styled-components'
import TemplateBase from '../../template'
import DigimoedasWaveCountContainer from '../Auction/Countdown/DigimoedasWaveCountContainer'
import background from './assets/BG.png'

export const Template = styled(TemplateBase)`
  &.content-grid {
    background-image: url(${background});
    display: flex;
    flex: 1;
    width: initial;
    min-height: initial;
    max-width: initial;
    padding: initial;
    margin: initial;
  }
`

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 32px;
  margin-inline: auto;
  max-width: 1024px;
  padding: 300px 32px 100px;

  @media (max-width: 980px) {
    padding: 300px 32px 140px;
  }
`

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  gap: 32px;
  padding-inline: 56px;
  width: 100%;

  button {
    background-color: #f7cb25;
    border: none;
    color: var(--primary);
    height: 46px;
    outline: none;
    padding-inline: 20px;
    white-space: nowrap;
    width: fit-content;
  }

  @media (max-width: 584px) {
    justify-content: center;
    padding: 0;
  }

  @media (max-width: 468px) {
    flex-direction: column;
    gap: 8px;

    button {
      max-width: 200px;
      width: 100%;
    }
  }
`

export const CardsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

export const Digimoedas = styled(DigimoedasWaveCountContainer)`
  border-radius: 40px;
  height: 134px;
  margin: 0;
  width: 241px;

  p:last-child {
    font-size: 16px;
  }

  figure {
    max-width: 50%;

    img {
      bottom: -46px;
    }
  }

  @media (max-width: 1024px) {
    width: 303px;

    figure {
      max-width: 30%;

      img {
        bottom: -46px;
      }
    }
  }
`

export const StatsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
