import styled from 'styled-components'

export const Container = styled.div`
  .sun-editor {
    border-radius: 1rem;
  }

  .se-toolbar {
    border-radius: 1rem 1rem 0 0;
    padding: 6px;
  }

  .se-wrapper-inner {
    padding: 1rem;
  }

  .se-resizing-bar {
    border: 1px solid #dadada;
    border-radius: 0 0 1rem 1rem;
    padding-left: 1rem;
  }
`
