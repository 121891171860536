import styled from 'styled-components'
import background from '../../assets/img/questionario/BG.png'
import TemplateBase from '../../template'

export const Template = styled(TemplateBase)`
  &.content-grid {
    width: initial;
    min-height: initial;
    max-width: initial;
    padding: initial;
    margin: initial;
  }

  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
`

export const BgContainer = styled.div`
  background-image: url(${background});
  background-position: bottom;
  background-size: cover;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
  padding: 172px 28px 0;

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    background-image: initial;
  }
`

export const White = styled.p`
  color: var(--white);
  font-size: 32px;
  margin-bottom: 8px;
`

export const Container = styled.div`
  position: relative;
  width: 100%;

  h1, p {
    font-family: MdlzBiteType;
    letter-spacing: 0;
    text-align: center;
  }

  > div {
    align-items: center;
    display: flex;
    flex-direction: column;

    &:first-of-type {
      > h1 {
        color: #eec52c;
        font-size: 36px;
      }

      > p {
        color: var(--white);
        font-size: 18px;
        margin-bottom: 8px;
      }
    }

    > figure {
      bottom: 13%;
      left: 0;
      margin: 0 auto;
      max-width: fit-content;
      position: absolute;
      right: 0;
    }
  }

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    align-self: flex-end;

    > div {
      gap: 16px;

      &:first-of-type {

        > h1 {
          font-size: 68px;
        }

        > p {
          font-size: 36px;
        }
      }

      &:last-of-type {
        align-items: flex-end;
        flex-direction: row;
        justify-content: center;
      }

      > figure {
        margin: 0;
        position: initial;
      }
    }
  }
`

export const NoContent = styled.div`
  &&& {
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
`

export const OptionsContainer = styled.div``
export const Disclaimer = styled.p`
  color: rgb(109 34 189);
  margin-top: 16px;
  margin-bottom: 48px;
`

export const AlternativesContainer = styled.div`
  align-items: stretch;
  display: grid;
  grid-template-columns: repeat(3, 98px);
  gap: 8px;
  justify-content: center;
  position: relative;

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    grid-template-columns: repeat(3, 184px);
    margin: 32px 0 0 0;
  }
`
