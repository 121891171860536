import { ReactElement } from 'react'
import ReadyModalProps from './props'
import * as S from './styled'

export default function ReadyModal ({ handleStartPlaying }: ReadyModalProps): ReactElement {
  return <S.ReadyModalContainer>
    <S.ReadyModalContent>
      <h1>Está pronto?</h1>

      <button onClick={handleStartPlaying}>Começar</button>
    </S.ReadyModalContent>
  </S.ReadyModalContainer>
}
