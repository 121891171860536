import styled from 'styled-components'

export const Container = styled.div`
  height: auto !important;
  width: 100%;

  label {
    font-size: 18px;
    letter-spacing: 0px;
    color: #284087;
    font-family: MdlzBiteType;
    margin: 12px;
    text-align: center;
  }
`

export const InputContainer = styled.div`
  align-items: center;
  background-color: #ebebeb;
  border-radius: 5px;
  display: flex;
  padding-inline: 12px;
  width: 100%;
`

export const Input = styled.input`
  border: none !important;
  color: #2f2f2f;
  font-weight: 700;
  font-size: 16px;
  height: 32px !important;
  letter-spacing: 0px;
`
