import styled from 'styled-components'

export const Container = styled.div`
  align-items: flex-end;
  background-color: #0e3e8a;
  border: 3px solid #ffca00;
  border-radius: 30px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  padding: 16px;
  position: relative;
  width: 100%;

  p {
    color: inherit;
  }
`

export const Label = styled.div`
  align-items: center;
  display: flex;
  font-size: 20px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: -17px;
  width: 100%;

  &>div {
    background-color: #0e3e8a;
    padding: 0 16px;
    text-transform: uppercase;
  }
`

export const Coin = styled.img`
  align-self: center;
  display: block;
  height: auto;
  width: 40px;
  max-width: 100%;
`

export const CoinCounter = styled.div`
  color: #ffca00;
  font-family: MdlzBiteType;
  font-size: 40px;
  line-height: 40px;
`

export const Text = styled(CoinCounter)`
  font-size: 1rem;
  line-height: 1rem;
`
