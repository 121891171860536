import { ReactElement } from 'react'
import amebaBottom from '../assets/ameba-bottom.png'
import amebaTop from '../assets/ameba-top.png'
import products from '../assets/finished-products.png'
import webBottom from '../assets/web-bottom.png'
import webTop from '../assets/web-top.png'
import * as S from './styled'

export default function NoGame (): ReactElement {
  return <S.Container>
    <S.ContentBg>
      <h1>Sem jogadas</h1>

      <p>
        No momento você não tem jogadas
      </p>

      <figure>
        <img src={products} alt="" />
      </figure>
    </S.ContentBg>

    <S.WebBottom>
      <img src={webBottom} alt="" />
    </S.WebBottom>

    <S.WebTop>
      <img src={webTop} alt="" />
    </S.WebTop>

    <S.AmebaBottom>
      <img src={amebaBottom} alt="" />
    </S.AmebaBottom>

    <S.AmebaTop>
      <img src={amebaTop} alt="" />
    </S.AmebaTop>
  </S.Container>
}
