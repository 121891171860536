import { ReactElement } from 'react'
import * as S from './styled'

export function Success (): ReactElement {
  return <S.ModalContainer>
    <S.ModalBody>
      <h1>SUCESSO</h1>
      <p>Seu vídeo foi enviado com sucesso, <br />por favor aguarde avaliação.</p>
    </S.ModalBody>
  </S.ModalContainer>
}
