import { ReactElement, useMemo } from 'react'
import CategoryCardProps from './props'
import * as S from './styled'

export default function AuctionCard ({ bgImgSrc, description, itemCount, time, title, to = '' }: CategoryCardProps): ReactElement {
  const path = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!to) {
      return '/leilao'
    }
    return `/leilao/${String(to)}`
  }, [to])

  const days = String(Math.floor(time / (3600 * 24))).padStart(2, '0')
  const hours = String(Math.floor((time % (3600 * 24)) / 3600)).padStart(2, '0')
  const minutes = String(Math.floor((time % 3600) / 60)).padStart(2, '0')

  return <S.Container>
    <S.CardContainer bgImgSrc={bgImgSrc} to={path}>
      <S.Content>
        <p>{title}</p>

        <p>{description}</p>
      </S.Content>

      <S.ItemCount>{itemCount} {itemCount === '1' ? 'item' : 'itens'}</S.ItemCount>
    </S.CardContainer>

    <S.CountdownContent>
      <S.CountdownNumbersContainer>
        <div>
          <div>
            <S.CountdownNumberContainer>
              <p>{days[0]}</p>
            </S.CountdownNumberContainer>
            <S.CountdownNumberContainer>
              <p>{days[1]}</p>
            </S.CountdownNumberContainer>
          </div>

          <p>Dias</p>
        </div>

        <S.SeparatorContainer>
          <p>:</p>
        </S.SeparatorContainer>

        <div>
          <div>
            <S.CountdownNumberContainer>
              <p>{hours[0]}</p>
            </S.CountdownNumberContainer>
            <S.CountdownNumberContainer>
              <p>{hours[1]}</p>
            </S.CountdownNumberContainer>
          </div>

          <p>Horas</p>
        </div>

        <S.SeparatorContainer>
          <p>:</p>
        </S.SeparatorContainer>

        <div>
          <div>
            <S.CountdownNumberContainer>
              <p>{minutes[0]}</p>
            </S.CountdownNumberContainer>
            <S.CountdownNumberContainer>
              <p>{minutes[1]}</p>
            </S.CountdownNumberContainer>
          </div>

          <p>Minutos</p>
        </div>
      </S.CountdownNumbersContainer>
    </S.CountdownContent>
  </S.Container>
}
