import { ReactElement, useState } from 'react'
import { Memoria } from '../../../games/Memoria'
import StartGame from '../../../games/Memoria/StartGame'
import * as S from './style'

function JogoDaMemoria (): ReactElement {
  const [readyToPlay, setReadyToPlay] = useState(false)

  return <S.Template>
    <S.Container>
    {
      readyToPlay
        ? <Memoria />
        : <StartGame handleStart={setReadyToPlay} />
    }
    </S.Container>
  </S.Template>
}

export default JogoDaMemoria
