import styled from 'styled-components'

export const TableContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    grid-column: span 2;
    top: -1px;

    @media (max-width: 1365px) {
        grid-column: initial;
    }

    table {
        color: #FFF;
        font-size: 15px;
        margin: 36px 0 0 42px;
        max-width: 564px;
        width: 100%;

        thead {
            tr {
                th {
                    border-collapse: collapse;
                    border-inline: 1px solid #E18719;
                    border-top: 2px solid #E18719;
                    padding: 12px 0 3px;
                    text-align: center;

                    &:first-child {
                        border-left: none;
                        text-align: left;
                    }

                    &:last-child {
                        border-right: none;
                    }

                    &:last-child,
                    &:nth-last-child(2),
                    &:nth-last-child(3) {
                        width: 24%;
                    }
                }
            }
        }
        tbody tr {
            &:last-child {
                td {
                    border-bottom: 0 none;
                }
            }
            td {
                margin: 0 auto;
                border: 1px solid #E18719;
                border-collapse: collapse;
                padding: 3px 0;

                @media screen and (max-width: 520px) {
                    font-size: 9px;
                    padding: 3px 5px;
                }
                &:first-child {
                    border-left: 0 none;
                }
                &:last-child {
                    border-right: 0 none;
                }
            }

            @media screen and (max-width: 1365px) {
                grid-column: initial;
            }
        }

        tbody {
            tr:first-child td:first-child,
            tr:last-child td {
                color: #FFC01A;
            }
        }

        @media screen and (max-width: 1365px) {
            margin: 36px auto 60px;
        }
    }
`
