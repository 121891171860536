import styled from 'styled-components';
import TemplateBase from '../../template';

export const Template = styled(TemplateBase)`
  .sub-template {
    padding: 112px 0;
    width: 100vw;
    background-color: #fff;
  }

  &.content-grid {
    margin: 0;
    padding: 90px 0 0 0;
    .grid.grid-3-9,
    .section-header {
      width: 100%;
      margin: 0 auto;
      padding: 0 50px;
    }
    .grid.grid-3-9 {
      padding-top: 20px;
    }
    .section-header {
      h2 {
        color: #8f91ac;
      }
      span {
        color: #f1cd3d;
      }
    }
    .section-filters-bar {
      padding: 0 50px;
    }
    .product-preview-image {
      background: none !important;
      width: auto;
      margin-bottom: 0px;
    }
  }
  .form-input,
  .form-select {
    label {
      z-index: 10;
    }
  }

  .table-header-title {
    color: #8f91ac;
  }
  .table {
    padding: 10px 30px;
  }
  .table-row,
  .sidebar-box,
  .product-preview {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  }

  @media screen and (min-width: 1260px) {
    &.content-grid {
      &> .grid.grid-3-9 {
        grid-template-areas: "content sidebar";
        grid-template-columns: 74.6621621622% 23.9864864865% !important;
      }
      .table {
        padding: 10px 30px;
      }
    }
  }
`;

export const Figure = styled.figure`
  align-items: center;
  display: flex;
  justify-content: center;
  img {
    height: auto;
    max-height: 100%;
    max-width: auto;
    width: auto;
  }
`;
