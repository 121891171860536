import { ReactElement } from 'react'
import safe from '../assets/safe.png'
import CatalogPointsProps from './props'
import * as S from './style'

export default function CatalogPoints ({ value }: CatalogPointsProps): ReactElement {
  return <S.Container>
    <S.Content>
      <p>Você tem:</p>

      <div>
        <p>{value}</p>
        <p>Pontos Shopping</p>
      </div>
    </S.Content>

    <figure>
      <img src={safe} />
    </figure>
  </S.Container>
}
