import axios from 'axios'
import { FormEvent, ReactElement, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CampaignErrors, CampaignInfo, NewCampaignProps, State } from './props'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import * as S from './style'

export default function NewCampaign ({ uploadImage, createCampaing }: NewCampaignProps): ReactElement {
  const navigate = useNavigate()
  const [errors, setErrors] = useState<CampaignErrors>({})
  const [state, setState] = useState<CampaignInfo>({
    currentState: State.STEP_ONE,
    description: '',
    report: [],
    results: [],
    name: '',
    target: [],
    roles: []
  })

  const handleSubmit = useCallback(
    async (evt: FormEvent<HTMLFormElement>) => {
      evt.preventDefault()
      try {
        await createCampaing.handle(state)
        toast.success('Campanha criada com sucesso!', { theme: 'colored' })
        navigate('/admin/campanha')
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status !== 500) {
            console.log(error.response?.data)
            const [, param] = error.response?.data.toString().match(/: (.*)$/)
            if (error.response?.data.match(/^Missing param/)) {
              toast.error(`O ${String(param)} precisa ser informado`, {
                theme: 'colored'
              })
            }
            return
          }
        }

        toast.error('Ocorreu um erro interno no servidor', { theme: 'colored' })
      }
    },
    [state, navigate]
  )

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [state.currentState])

  useEffect(() => {
    setErrors(() => ({}))

    if (state.banner == null) {
      setErrors((prev) => ({
        ...prev,
        hasError: true,
        banner: 'O campo Banner é obrigatório'
      }))
    }

    if (state.description === '') {
      setErrors((prev) => ({
        ...prev,
        hasError: true,
        description: 'O campo Descrição é obrigatório'
      }))
    }

    if (state.logo == null) {
      setErrors((prev) => ({
        ...prev,
        hasError: true,
        logo: 'O campo Logo é obrigatório'
      }))
    }

    if (state.mechanics == null) {
      setErrors((prev) => ({
        ...prev,
        hasError: true,
        mechanics: 'O campo Mecânica é obrigatório'
      }))
    }

    if (state.name === '') {
      setErrors((prev) => ({
        ...prev,
        hasError: true,
        name: 'O campo Nome é obrigatório'
      }))
    }

    if (state.regulation === '<p><br></p>' || state.regulation == null) {
      setErrors((prev) => ({
        ...prev,
        hasError: true,
        regulation: 'O campo Regulamento é obrigatório'
      }))
    }

    if (state.smallBanner == null) {
      setErrors((prev) => ({
        ...prev,
        hasError: true,
        smallBanner: 'O campo Banner Pequeno é obrigatório'
      }))
    }
  }, [state])

  return <S.Template>
    <div className="content-grid full">
      <article className="post-open">
        <form action="" method="post" onSubmit={handleSubmit}>
          <S.PostCard className="post-open-body">
            <div className="post-open-heading">
              <p className="post-open-timestamp">
                <span className="highlighted">
                  {new Date().toLocaleString('pt-BR')}
                </span>
              </p>
            </div>
            {state.currentState === State.STEP_ONE && <StepOne uploadImage={uploadImage} errors={errors} setState={setState} state={state} />}
            {state.currentState === State.STEP_TWO && <StepTwo uploadImage={uploadImage} errors={errors} setState={setState} state={state} />}
          </S.PostCard>
        </form>
      </article>

      <S.FormProgressContainer>
        <S.FormProgress $activeView={state.currentState >= State.STEP_ONE} />
        <S.FormProgress $activeView={state.currentState >= State.STEP_TWO} />
      </S.FormProgressContainer>
    </div>
  </S.Template>
}
