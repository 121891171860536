import {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import api from '../../services/api'
import { useAuth } from '../AuthContext'

interface Item {
  amount: number
  sku: {
    code: number
    Product: {
      id: number
      name: string
    }
    images: Array<{ path: string }>
    price: string
  }
}
interface CartProps {
  items: Item[]
  subtotal: number
  total: number
}

interface CartProviderProps {
  children: ReactNode
}

interface CartContextType {
  addToCart: (skuCode: number, amount: number) => Promise<void>
  updateAmount: (skuCode: number, amount: number) => Promise<void>
  removeFromCart: (skuCode: number, amount: number) => Promise<void>
  cart: CartProps
}

const CartContext = createContext<CartContextType | null>(null)

export default function CartProvider ({ children }: CartProviderProps): ReactElement {
  const { user } = useAuth()
  const [cart, setCart] = useState<CartProps>({ items: [], subtotal: 0, total: 0 })

  const addToCart = useCallback(async (skuCode: number, amount: number) => {
    await api.patch('/cart', { skuCode, amount })
    loadCart()
  }, [cart])

  const removeFromCart = useCallback(async (skuCode: number, amount: number) => {
    await api.patch('/cart', { skuCode, amount })
    loadCart()
  }, [cart])

  const updateAmount = useCallback(async (skuCode: number, amount: number) => {
    await api.patch('/cart', { skuCode, amount })
    loadCart()
  }, [cart])

  const loadCart = useCallback(async () => {
    const response = await api.get('/cart')
    setCart(response.data.cart)
  }, [])

  useEffect(() => {
    loadCart()
  }, [user])

  return (
    <CartContext.Provider
      value={{ addToCart, updateAmount, removeFromCart, cart }}
    >
      {children}
    </CartContext.Provider>
  )
}

export function useCart (): CartContextType {
  const context = useContext(CartContext)

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!context) throw new Error('useCart must be used within a CartProvider')

  return context
}
