import { ReactElement } from 'react'
import { useAuth } from '../../../../contexts/AuthContext'
import { VideoCardProps } from './props'
import * as S from './styled'

export default function VideoCard ({ video }: VideoCardProps): ReactElement {
  const { avatar, user } = useAuth()

  return <S.Container>
    <S.VideoArea>
      {video
        ? <S.EmbedVideo controls key={video}>
          <source src={video} type="video/mp4" />
        </S.EmbedVideo>
        : <S.PlaceHolder />
      }
    </S.VideoArea>

    <S.ContentContainer>
      <S.AvatarContainer>
        <img src={avatar} alt="Avatar" />
      </S.AvatarContainer>

      <h1>
        Exercícios 01
      </h1>

      <p>
        @{user.displayName}
      </p>
    </S.ContentContainer>
  </S.Container>
}
