import { ReactElement, useMemo } from 'react'
import { Link } from 'react-router-dom'
import banner from '../../../../assets/img/hub/mission-card-banner.png'
import close from '../../../../assets/img/quest/completedq-b.png'
import open from '../../../../assets/img/quest/openq-b.png'
import { Progress } from '../../../../components/Progress'
import MissionCardProps from './props'
import * as S from './styled'

export default function MissionCard ({ progress, quest, ...rest }: MissionCardProps): ReactElement {
  const realProgress = useMemo(() => {
    return (progress / quest.total_progress) * 100
  }, [])
  const isDone = useMemo(() => {
    return progress === quest.total_progress
  }, [progress, quest])
  return <S.Card className="js-card">
    <S.Container>
      <figure>
        <img src={banner} alt={'Bis'} />
      </figure>

      <S.ExpContainer>
        <p>
          <span>+</span>
          {' '}
          {quest.quest_reward[0].value} {quest.quest_reward[0].reward_type.name}
        </p>
      </S.ExpContainer>

      <S.ContentContainer>
        <figure>
          <img src={isDone ? close : open} alt="Icon" />
        </figure>

        <Link to={'/mission/'}>{quest.name}</Link>

        <S.Description>
          {quest.description}
        </S.Description>

        <Progress color="#615dfa" progress={realProgress} width={4} />

        <S.ProgressText>
          {progress}/{quest.total_progress} completed
        </S.ProgressText>
      </S.ContentContainer>
    </S.Container>
  </S.Card>
}
