import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import PageLoaderComponent from '../../../components/PageLoader'
import { makeAcceptHubRegulation } from '../../../main/factories/makeAcceptHubRegulation'
import { makeLoadMyHubCampanha } from '../../../main/factories/makeLoadMyHubCampanha'
import CampaignHubDataProps from '../../CampaignHub/props'
import * as S from './styled'

const loadMyHubCampaign = makeLoadMyHubCampanha()
const acceptHubRegulation = makeAcceptHubRegulation()

enum State {
  LOADING,
  READY
}

function CampaignHubRegulation (): ReactElement {
  const { slug } = useParams()
  const [campaign, setCampaign] = useState<CampaignHubDataProps>({} as unknown as CampaignHubDataProps)
  const [state, setState] = useState<State>(State.LOADING)

  const navigate = useNavigate()

  const regulation = useMemo(() => campaign.regulation, [campaign])

  const timestamp = useMemo(() => {
    if (!regulation) return
    return new Date(regulation.createdAt).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })
  }, [regulation])

  const loadHubCampaign = useCallback(async () => {
    try {
      const response = await loadMyHubCampaign.handle({ id: String(slug) })
      setCampaign(response.data)
      console.log(response.data.acceptAt)
      if (response.data.acceptedAt) {
        navigate(`/hub-de-campanhas/mecanica/${String(slug)}`)
        return
      }
      setState(State.READY)
    } catch (error) {
      console.error(error)
      toast.error('Não foi possível carregar os detalhes da campanha. Por favor, tente novamente.', { theme: 'colored' })
    }
  }, [slug])

  const handleAccept = useCallback(async (evt: any) => {
    evt.preventDefault()
    const response = await acceptHubRegulation.handle({ id: String(slug) })
    switch (response.status) {
      case 200:
        navigate(`/hub-de-campanhas/mecanica/${String(slug)}`)
        return
      case 400:
        toast.error('A data para aceite do regulamento terminou', { theme: 'colored' })
        return
      default:
        toast.error('Ocorreu um erro ao tentar aceitar a camapanha', { theme: 'colored' })
    }
  }, [slug])

  useEffect(() => {
    loadHubCampaign()
    document.body.scrollTop = 500
    document.documentElement.scrollTop = 500
  }, [loadHubCampaign])

  if (state !== State.READY) return <PageLoaderComponent />

  return <S.Template>
    <S.Container>
      <S.TopBanner banner={campaign.banner} />
      <S.RegulationOpenContainer className="content-grid full">
        <article className="regulation-open">
          <S.RegulationCard className="regulation-open-body">
            <div className="regulation-open-heading">
              <p className="regulation-open-timestamp"><span className="highlighted">{timestamp}</span></p>
              <h2 className="regulation-open-title">{regulation.title}</h2>
            </div>
            <div className="regulation-open-content">
              <div className="regulation-open-content-body">
                <p className="regulation-open-paragraph" dangerouslySetInnerHTML={{ __html: regulation.content }}></p>
              </div>
            </div>
          </S.RegulationCard>
        </article>
        <S.Form onSubmit={handleAccept}>
          <button onClick={() => navigate(-1)} type="button" className='primary button'>Rejeitar</button>
          <button className='primary button' type='submit'>Aceitar</button>
        </S.Form>
      </S.RegulationOpenContainer>
    </S.Container>
  </S.Template>
}

export default CampaignHubRegulation
