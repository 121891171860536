import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import AuctionCardProps from './props'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const CardContainer = styled(Link)<Pick<AuctionCardProps, 'bgImgSrc'>>`
  ${({ bgImgSrc }) => css`
    background: url(${bgImgSrc}) no-repeat right top, linear-gradient(45deg, #e7912d 0%, #f1cd3d 100%);
    background-size: cover;
    border-radius: 6px;
    box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
    filter: drop-shadow(0 0 20px rgba(94,93,151,0.06));
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
    justify-content: space-between;
    padding: 12px;
    width: 144px;

    p {
      color: var(--white);
      font-family: Rajdhani !important;
      font-weight: 700;
      margin: 0;
    }

    &:nth-child(1) {
      > p:last-child {
        color: #605df6;
      }
    }

    :nth-child(2) {
      > p:last-child {
        color: #658cfb;
      }
    }

    &:nth-child(3) {
      > p:last-child {
        color: #7accfd;
      }
    }

    &:nth-child(4) {
      > p:last-child {
        color: #79d1e1;
      }
    }

    @media screen and (min-width: 1280px) and (orientation: landscape) {
      border-radius: 10px;
      padding: 24px 28px;
      width: 240px;
    }
  `}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 50%;

  p {
    &:first-child {
      font-size: 8px;
    }

    &:last-child {
      font-size: 6px;
      font-weight: 500;
    }

    @media screen and (min-width: 1280px) and (orientation: landscape) {
      &:first-child {
        font-size: 16px;
      }

      &:last-child {
        font-size: 12px;
      }
    }
  }
`

export const ItemCount = styled.p`
  background-color: white;
  border-radius: 200px;
  font-size: 6px;
  letter-spacing: 0px;
  line-height: 14px;
  padding-inline: 8px;
  text-decoration: none;
  text-transform: uppercase;
  width: fit-content;

  &:nth-child(1) {
    color: #605df6;
  }

  &:nth-child(2) {
    color: #658cfb;
  }

  &:nth-child(3) {
    color: #7accfd;
  }

  &:nth-child(4) {
    color: #79d1e1;
  }

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    font-size: 10px;
    line-height: 24px;
  }
`

export const CountdownContent = styled.div.attrs((props) => {
  return {
    ...props,
    className: 'countdown'
  }
})`
  align-items: center;
  display: flex;
  gap: 16px;

  p {
    color: #f7cb25;
    font-family: MdlzBiteType;
    font-size: 6px;
    letter-spacing: 1px;
    margin: 0;
    text-transform: uppercase;

    @media screen and (min-width: 1280px) and (orientation: landscape) {
      font-size: 8px;
    }
  }
`

export const CountdownNumbersContainer = styled.div`
  justify-content: space-around;

  &, > div > div {
    display: flex;
    gap: 4px;
  }

  > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`

export const SeparatorContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  max-height: 40px;

  p {
    color: #fff;
    font-size: 10px;
    vertical-align: middle;

    @media screen and (min-width: 1280px) and (orientation: landscape) {
      font-size: 18px;
    }
  }
`

export const CountdownNumberContainer = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 2px 2px 2px 2px #4f216f;
  justify-content: center;
  display: flex;
  filter: drop-shadow(0px 6px 4px rgba(0,0,0,0.15));
  padding: 4px;
  width: 12px;

  p {
    color: #1d3f85;
    font-size: 10px;
    text-align: center;
  }

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    padding: 4px 8px;
    width: 20px;

    p {
      color: #0e3e8a;
      font-size: 16px;
      letter-spacing: -1px;
    }
  }
`
