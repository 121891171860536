import { ReactElement } from 'react'
import * as S from './styled'

export default function DashboardPowerBI (): ReactElement {
  return <S.Template>
    <S.Container
       title="1_dash_mondelez_gigantes"
       src="https://app.powerbi.com/view?r=eyJrIjoiOTZjNjFiNzYtY2IwMy00YWUwLWEzMzMtMzM3ZTdkYWY4YzY5IiwidCI6ImU3NzM4ZmNlLThmOGItNDc1Yy05MGFkLWU3YmFlMTRkNjMxYSJ9"
       frameBorder={0}
       allowFullScreen
    />
  </S.Template>
}
