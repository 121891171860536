import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const TableContainer = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
`

export const SalesTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 0.75rem;
  color: var(--white);
  font-family: Rajdhani, sans-serif;
  width: 100%;

  thead {
    font-size: 0.75rem;

    th {
      padding: 0.875rem 1.5rem 0;
    }
  }

  tbody {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1em;

    tr {
      box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
      border: 1.75rem solid transparent;

      td {
        background-color: rgba(0, 0, 0, .3);
        padding: 28px;

        &:first-child {
          align-items: center;
          border-radius: 0.75rem 0 0 0.75rem;
          display: flex;
          gap: 1rem;
          text-align: start;
        }

        &:last-child {
          border-radius: 0 0.75rem 0.75rem 0;
        }
      }
    }
  }


  th, td {
    &:nth-child(n+2) {
      text-align: center;
    }
  }
`

export const Price = styled.td`
  white-space: nowrap;

  span {
    color: var(--success);
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;

  button {
    color: var(--white);
    padding-inline: 20px;

    &:first-child {
      background-color: var(--primary);
      height: 48px;
      box-shadow: 4px 7px 12px 0 rgba(97, 93, 250, 0.2);

      &:hover {
        background-color: #5753e4;
      }
    }

    :last-child {
      background-color: #3e3f5e;
      box-shadow: 3px 5px 10px 0 rgba(62, 63, 94, 0.2);

      &:hover {
        background-color: #2e2e47;
      }
    }
  }
`

export const ItemLinkContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
`

export const ItemLink = styled(Link)`
  color: var(--white);
  text-decoration: none !important;

  > figure, img {
    border-radius: 0.75rem;
    height: 3.75rem;
    margin: 0;
    width: 3.75rem;
  }

  &:nth-child(2) {
    font-size: 0.75rem;
    padding-left: 0.875rem;
    position: relative;

    ::before {
      border: 2px solid #3ad2fe;
      border-radius: 50%;
      content: "";
      height: 8px;
      left: 0;
      position: absolute;
      top: 1px;
      width: 8px;
    }
  }

  &:last-child {
    color: #ADAFCA;
  }
`
