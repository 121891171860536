import styled from 'styled-components';
import TemplateBase from '../../template';

export const Template = styled(TemplateBase)`
  &.content-grid {
    @media (max-width: 520px) {
      max-width: 100%;
    }
  }
`

export const Container = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`;

export const Image = styled.img`
  display: block;
  max-width: 100%;
  width: auto;
`;
