import { AxiosError, AxiosInstance } from 'axios'
import { LoadCoins } from '../../domain/use-case/LoadCoins'

export class ApiLoadCoins implements LoadCoins {
  private readonly api: AxiosInstance
  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (params: LoadCoins.Params): Promise<LoadCoins.Result> {
    try {
      const response = await this.api.get('/coins', { params })
      return response
    } catch (err) {
      const axiosError = err as AxiosError
      if (axiosError.response) {
        return axiosError.response
      }

      if (axiosError.request) {
        return {
          data: axiosError.request,
          status: 0
        }
      }

      return {
        data: { message: axiosError.message },
        status: 0
      }
    }
  }
}
