import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 600px;
  width: 100%;

  h1 {
    color: #fffeff;
    font-family: MdlzBiteType;
    font-size: 48px;
    letter-spacing: -3px;
    text-align: center;
    text-shadow: 2px 3px #49236c;

    span {
      color: #49236c;
      text-shadow: 2px 3px #fffeff;
    }
  }
`

export const BannerBg = styled.div`
  border-radius: 44px;
  background-color: rgba(231, 175, 35, .702);
  filter: drop-shadow(0px 6px 4px rgba(0,0,0,0.25));
  padding: 20px 40px;
  position: relative;
  width: 100%;

  > figure {
    max-width: fit-content;
    position: absolute;

    img {
      width: 50%;
    }

    &:first-of-type {
      right: -28%;
      top: -12%;
    }

    &:last-of-type {
      bottom: -14%;
      left: -8%;
    }

    @media (max-width: 602px) {
      &:first-of-type {
        right: -32%;
        top: -8%;
      }

      &:last-of-type {
        bottom: -10%;
        left: -10%;
      }
    }

    @media (max-width: 450px) {
      &:first-of-type {
        right: -44%;
        top: -3%;
      }

      &:last-of-type {
        bottom: -3.8%;
        left: -14%;
      }
    }
  }
`

export const WinnersContainer = styled.div`
  align-items: center;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fit, 154px);
  justify-content: space-between;
  width: 100%;

  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`

export const Winner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  figure {
    margin: 0;
    max-width: 80%;
    width: fit-content;

    img {
      width: 100%;
    }
  }

  p {
    color: #4f2070;
    font-family: MdlzBiteType;
    font-size: 20px;
    letter-spacing: -1px;
    margin: 0;
    max-width: 80%;
    text-align: center;
  }
`
