import styled from 'styled-components';

export const BannerContainer = styled.div`
    aspect-ratio: 1184 / 186;
    background-image: url(${({ $bannerImg }) => $bannerImg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;

    &.section-banner {
        display: flex;
        flex-direction: column;
        height: initial;
        justify-content: center;
        padding: 0 60px;
        width: 100%;
    }

    &.profile-banner {
        padding: 0;
        background-position: top;
        background-color: var(--white);
        width: 100%;
        height: clamp(240px, 25vw, 280px);
    }
    .section-banner-title, .section-banner-text {
        position: relative;
        margin-bottom: 0;
    }

    .section-banner-title {
        font-size: 100px;
        font-family: MdlzBiteType;

        @media (max-width: 1366px) {
            font-size: 72px;
        }

        @media (max-width: 1024px) {
            font-size: 56px;
        }

        @media (max-width: 600px) {
            font-size: 32px;
        }

        @media (max-width: 500px) {
            font-size: 24px;
        }
    }

    .section-banner-text {
        margin-top: 6px;


        @media (max-width: 600px) {
            font-size: 8px;
            margin: 0;
        }

        @media (max-width: 500px) {

        }
    }
`;

export const AvatarContainer = styled.div`
    position: relative;
    border-radius: 5px;
    top: 35px;
    left: 0;
    z-index: 20;
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    p {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
        font-size: 1.5rem;
        font-weight: 700;
        padding-top: clamp(20px, 1vw, 100px);
    }
    .hexagon-container {
        width: clamp(140px, 12.7vw, 180px);
        aspect-ratio: 1;
    }
`;
