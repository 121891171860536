import { ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import CardCampaign from '../../components/Card/CardCampaign'
import { makeLoadMyHubCampanhas } from '../../main/factories/makeLoadMyHubCampanhas'
import TopBanner from './TopBanner'
import CampaignHubDataProps from './props'
import * as S from './styled'

const loadMyHubCampaigns = makeLoadMyHubCampanhas()

enum State {
  LOADING,
  READY,
  NODATA,
  FAILURE
}

function CampaignHub (): ReactElement {
  const [campaignData, setCampaignData] = useState<CampaignHubDataProps[]>([])
  const [state, setState] = useState<State>(State.LOADING)
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      const response = await loadMyHubCampaigns.handle()
      console.log(response)
      switch (response.status) {
        case 403:
          setState(State.FAILURE)
          toast.error('Você não está autorizado a acessar esta página.', { theme: 'colored' })
          navigate(-1)
          return
        case 204:
          toast.error('No momento, não existem campanhas ativas.', { theme: 'colored' })
          setState(State.NODATA)
          return
        case 200:
          setCampaignData(response.data)
          setState(State.READY)
      }
    })()
  }, [])

  if (state === State.LOADING) return <S.Template>...</S.Template>
  if (state === State.FAILURE) return <S.Template>...</S.Template>

  return <S.Template>
    <S.Container>
      <TopBanner campaings={campaignData} />
      <S.Content>
        <S.CampaignCardsContainer>
          {state === State.NODATA && <div>No momento, não existem campanhas ativas.</div>}
          {state === State.READY && campaignData.map((campaign) => <CardCampaign key={campaign.id} {...campaign} />)}
        </S.CampaignCardsContainer>
      </S.Content>
    </S.Container>
  </S.Template>
}

export default CampaignHub
