import { ReactElement, useState } from 'react'
import InputSelect from '../../../components/Forms/InputSelect'
import Template from '../../../template'
import * as S from './styled'

interface FieldsAssociation {
  idKpi: number
  idTime: number
  idCargo: number
  subCargo: number
  data: number
  chavePrimaria: number
  target: number
  realizado: number
}

interface AssociateComponentProps {
  headers: string[]
  onComplete: (value: any) => any
}

function AssociateComponent ({ headers, onComplete }: AssociateComponentProps): ReactElement {
  const [values, setValues] = useState<FieldsAssociation>({
    idKpi: 0,
    idTime: 1,
    idCargo: 2,
    subCargo: 3,
    data: 4,
    chavePrimaria: 5,
    target: 6,
    realizado: 7
  })

  return <Template>
    <div className="grid medium-space">
      <div className="account-hub-content">
        <div className="section-header">
          <div className="section-header-info">
            <p className="section-pretitle">Upload Resultados</p>

            <h2 className="section-title">
              Envie uma planilha para atualizar
            </h2>
          </div>
        </div>

        <div className="grid-column">
          <div className="widget-box">
            <p className="widget-box-title">Escolha os campos</p>
            <div className="widget-box-content">
              <S.Table>
                <tr>
                  <td>
                    idKpi
                  </td>
                  <td>
                    <InputSelect onChange={(idKpi: string) => setValues({ ...values, idKpi: Number.parseInt(idKpi, 10) })}>
                      {headers.map((header, index) => <option selected={values.idKpi === index} key={index} value={index}>{header}</option>)}
                    </InputSelect>
                  </td>
                </tr>
                <tr>
                  <td>
                    idTime
                  </td>
                  <td>
                    <InputSelect onChange={(idTime: string) => setValues({ ...values, idTime: Number.parseInt(idTime, 10) })}>
                      {headers.map((header, index) => <option selected={values.idTime === index} key={index} value={index}>{header}</option>)}
                    </InputSelect>
                  </td>
                </tr>
                <tr>
                  <td>
                    idCargo
                  </td>
                  <td>
                    <InputSelect onChange={(idCargo: string) => setValues({ ...values, idCargo: Number.parseInt(idCargo, 10) })}>
                      {headers.map((header, index) => <option selected={values.idCargo === index} key={index} value={index}>{header}</option>)}
                    </InputSelect>
                  </td>
                </tr>
                <tr>
                  <td>
                    SubCargo
                  </td>
                  <td>
                    <InputSelect onChange={(subCargo: string) => setValues({ ...values, subCargo: Number.parseInt(subCargo, 10) })}>
                      {headers.map((header, index) => <option selected={values.subCargo === index} key={index} value={index}>{header}</option>)}
                    </InputSelect>
                  </td>
                </tr>
                <tr>
                  <td>
                    DATA
                  </td>
                  <td>
                    <InputSelect onChange={(data: string) => setValues({ ...values, data: Number.parseInt(data, 10) })}>
                      {headers.map((header, index) => <option selected={values.data === index} key={index} value={index}>{header}</option>)}
                    </InputSelect>
                  </td>
                </tr>
                <tr>
                  <td>
                    Chave primária
                  </td>
                  <td>
                    <InputSelect onChange={(chavePrimaria: string) => setValues({ ...values, chavePrimaria: Number.parseInt(chavePrimaria, 10) })}>
                      {headers.map((header, index) => <option selected={values.chavePrimaria === index} key={index} value={index}>{header}</option>)}
                    </InputSelect>
                  </td>
                </tr>
                <tr>
                  <td>
                    TARGET
                  </td>
                  <td>
                    <InputSelect onChange={(target: string) => setValues({ ...values, target: Number.parseInt(target, 10) })}>
                      {headers.map((header, index) => <option selected={values.target === index} key={index} value={index}>{header}</option>)}
                    </InputSelect>
                  </td>
                </tr>
                <tr>
                  <td>
                    Realizado
                  </td>
                  <td>
                    <InputSelect defaultValue={values.idKpi} onChange={(realizado: string) => setValues({ ...values, realizado: Number.parseInt(realizado, 10) })}>
                      {headers.map((header, index) => <option selected={values.realizado === index} key={index} value={index}>{header}</option>)}
                    </InputSelect>
                  </td>
                </tr>
              </S.Table>
            </div>
          </div>
        </div>
        <div className="grid-column errors">

        </div>
      </div>
      <S.ContinueButton className='button medium secondary' onClick={() => onComplete(values)}>Continuar</S.ContinueButton>
    </div>
  </Template>
}

export default AssociateComponent
