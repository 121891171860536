import styled from 'styled-components'
import TemplateBase from '../../template'

export const Template = styled(TemplateBase)`
  &.content-grid {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const Container = styled.iframe`
  aspect-ratio: 980 / 612;
  max-width: 980px;
  width: 100%;

  @media (max-width: 500px) {
    aspect-ratio: 612 / 980;
  }
`
