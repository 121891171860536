import styled from 'styled-components'
import bulletButton from '../../../assets/img/bullet-button.png'

export const Container = styled.div`
  align-items: center;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 30px;

  input {
    width: 100%;
  }

  @media (max-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const ProductContainer = styled.div`
  display: flex;
  gap: 32px;

  @media (max-width: 520px) {
    flex-direction: column;
  }
`

export const BidContainer = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 520px) {
    flex-direction: column;
  }
`

export const SliderContainer = styled.div`
  align-items: center;
  background-color: #002b76;
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 30px 20px 20px 20px;
  width: 100%;
`

export const ImageContainer = styled.div`
  flex: 1 50%;
`
export const ProductImage = styled.img`
  display: block;
  height: auto;
  max-width: 100%;
`

export const InfoContainer = styled.div`
  display: flex;
  flex: 1 50%;
  flex-direction: column;
`

export const InfoTitle = styled.h1`
  color: var(--white);
  font-family: MdlzBiteType;
  font-size: 40px;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 36px;
  }

  @media (max-width: 900px) {
    font-size: 32px;
  }

  @media (max-width: 850px) {
    font-size: 28px;
  }
`

export const BulletButton = styled.button`
  align-items: center;
  aspect-ratio: 1;
  background-color: transparent;
  background-image: url(${bulletButton});
  background-repeat: no-repeat;
  color: var(--white);
  display: flex;
  font-family: MdlzBiteType;
  justify-content: center;
  width: 39px;

  :focus {
    outline: 0 none;
  }
`
export const Slider = styled.input``
