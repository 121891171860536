import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  height: 120px;
  width: 120px;

  svg {
    transform: rotate(-90deg);

    circle {
      fill: none;
      stroke-linecap: round;
    }
  }

  p {
    color: #3e405c;
    font-size: 24px;
    font-family: Rajdhani, sans-serif;
    font-weight: 700;
    line-height: 12px;
    left: 50%;
    position: absolute;
    text-align: center;
    transform: translate(-50%, -50%);
    top: 50%;
  }
`
