export const adjustHorizontal = (value: number): number => {
  const widthScale = 300 / 421
  const width = window.innerWidth

  return (width <= 480) ? Math.round(value * widthScale) : value
}

export const adjustVertical = (value: number): number => {
  const heightScale = 360 / 532
  const width = window.innerWidth

  return (width <= 480) ? Math.round(value * heightScale) : value
}

export const getRandomDirection = (): number => {
  let direction = 0

  while (direction === 0) {
    direction = Math.floor(Math.random() * 5) - 2
  }

  return direction
}
