import { ReactElement, useCallback } from 'react'
import CountdownComponent from './CountdownComponent'
import Points from './Points'
import PontosNoSusto from './PontosNoSusto'
import PuzzleProps from './props'
import * as S from './styled'

export default function Puzzle ({ setState, state }: PuzzleProps): ReactElement {
  const clearScore = useCallback(() => {
    setState((prev) => ({ ...prev, points: 0, time: 90 }))
  }, [state.points, state.time])

  const increaseScore = useCallback((points: number) => {
    setState((prev: any) => {
      const maxPoints = Math.max(prev.maxPoints, +prev.points + points)
      return { ...prev, points: +prev.points + points, maxPoints }
    })
  }, [state.points, state.time])

  return <S.Container>
    <Points points={state.points} />

    <S.MobileTimeScoreContainer>
      <Points points={state.points} />
      <CountdownComponent time={state.time} />
    </S.MobileTimeScoreContainer>

    <PontosNoSusto
      clearScore={clearScore}
      increaseScore={increaseScore}
      setState={setState}
      state={state}
    />

    <CountdownComponent time={state.time} />
  </S.Container>
}
