import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const ContentContainer = styled.div`
    overflow: auto;
    padding-bottom: 100px;

  > div.single-card {
    padding: 1.5rem 1.75rem;
  }

  button {
    border-radius: 0.625rem;
    color: var(--white);
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 3rem;
    padding-inline: 1rem;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
`

export const FilterContainer = styled.div`
  align-items: center;
  gap: 1rem;

  &, > div {
    display: flex;
  }

  button {
    background-color: #23d2e2;
    box-shadow: 4px 7px 12px 0 rgba(35, 210, 226, 0.2);
    white-space: nowrap;
    width: fit-content;

    &:hover {
      background-color: #1bc5d4;
    }
  }
`

export const TableContainer = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
`

export const SalesTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 0.75rem;
  width: 100%;

  thead {
    font-size: 0.75rem;

    th {
      padding: 0.875rem 1.5rem 0;
    }
  }

  tbody {
    background-color: #FFFFFF80;
    backdrop-filter: blur(8px);
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1em;

    tr {
      box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
      border: 1.75rem solid transparent;
      border-radius: 0.75rem;

      td {
        padding: 28px;

        &:first-child {
          align-items: center;
          display: flex;
          gap: 1rem;
          text-align: start;
        }
      }
    }
  }


  th, td {
    &:nth-child(n+2) {
      text-align: center;
    }
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;

  button {
    &.content {
      background-color: var(--primary);
      box-shadow: 4px 7px 12px 0 rgba(97, 93, 250, 0.2);

      &:hover {
        background-color: #5753e4;
      }
    }

    background-color: #3e3f5e;
    box-shadow: 3px 5px 10px 0 rgba(62, 63, 94, 0.2);

    &:hover {
      background-color: #2e2e47;
    }
  }
`

export const ItemLinkContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
`

export const ItemLink = styled(Link)`
  color: #3E3F5E;
  text-decoration: none !important;

  > figure, img {
    border-radius: 0.75rem;
    height: 3.75rem;
    margin: 0;
    width: 3.75rem;
  }

  &:nth-child(2) {
    font-size: 0.75rem;
    padding-left: 0.875rem;
    position: relative;

    ::before {
      border: 2px solid #3ad2fe;
      border-radius: 50%;
      content: "";
      height: 8px;
      left: 0;
      position: absolute;
      top: 1px;
      width: 8px;
    }
  }

  &:last-child {
    color: #3E3F5E;
  }
`
