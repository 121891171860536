import styled from 'styled-components'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  > div:first-child {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    max-width: 100%;
    padding: 1.75rem;

    button {
      border-radius: 0.625rem;
      color: var(--white);
      line-height: 3rem;
      padding-inline: 1rem;
      transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    }

    @media screen and (max-width: 1200px) {
      &, > div {
        flex-direction: column;
        justify-content: center;
        width: 100%;

        button {
          align-self: center;
          width: 180px;
          white-space: nowrap;
        }
      }
    }
  }
`

export const DateInputsContainer = styled.div`
  gap: 0.75rem;

  &, > div {
    display: flex;
  }
`

export const SearchButton = styled.button`
  background-color: #23d2e2;
  box-shadow: 4px 7px 12px 0 rgba(35,210,226,.2);
  width: 4.25rem;

  &:hover {
    background-color: #1bc5d4;
  }
`

export const DownloadButton = styled.button`
  background-color: var(--primary);
  box-shadow: 4px 7px 12px 0 rgba(97,93,250, 0.2);
  font-size: 0.875rem;
  font-weight: 700;
  width: fit-content;

  &:hover {
    background-color: #5753e4;
  }
`

export const TableContainer = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
`

export const SalesTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  thead {
    font-size: 0.75rem;

    th {
      padding: 0.875rem 1.5rem 0;
    }
  }

  tbody {
    background-color: #FFFFFF80;
    backdrop-filter: blur(8px);
    border-radius: 12px;
    box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1em;

    tr {
      td {
        /* padding: 0.5rem 1.5rem; */
        padding: 28px 8px;
        
        &:first-child {
          padding-left: 28px;
        }
        
        &:last-child {
          padding-right: 28px;
        }

        a {
          color: #6d22bd;
        }

        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(6) {
          color: #000;
          font-weight: 500;
          line-height: 1.1428571429em;
        }
      }
    }
  }


  th, td {
    &:nth-child(n+3) {
      text-align: center;
    }
  }
`

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  width: 1rem;
  height: 1rem;
  border: 2px solid transparent;
  border-radius: 50%;

  .icon-clock {
    fill: #333333 !important;
    transform: scale(1.4);
  }

  &:has(svg.icon-minus-small) {
    border-color: #ff5384;

    svg {
      fill: #ff5384;
    }
  }

  &:has(svg.icon-plus-small) {
    border-color: #23d2e2;

    svg {
      fill: #23d2e2;
    }
  }
`
