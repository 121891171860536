import styled from 'styled-components'

export const TryAgainModalContainer = styled.div`
  align-items: center;
  aspect-ratio: 468 / 421;
  display: flex;
  justify-content: center;
  justify-self: center;
  max-width: 468px;
  width: 100%;

  @media (max-width: 764px) {
    max-width: 348px;
  }

  @media (max-width: 632px) {
    max-width: 90%;
  }
`

export const TryAgainModalContent = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;

  h1 {
    color: #ffffff;
    font-family: MdlzBiteType;
    font-size: 69px;
    letter-spacing: -1px;
    line-height: 64px;
    margin: 0;
    text-align: center;
    white-space: nowrap;
  }

  @media (max-width: 900px) {
    h1 {
      font-size: 52px;
      line-height: 48px;
    }
  }

  @media (max-width: 632px) {
    h1 {
      font-size: 48px;
      line-height: 48px;
    }
  }

  @media (max-width: 450px) {
    h1 {
      font-size: 40px;
      line-height: 40px;
    }
  }
`

export const TryAgainButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
`

export const TryAgainButton = styled.button`
  background-color: #422069;
  color: var(--white);
  font-family: MdlzBiteType;
  font-size: 38px;
  font-weight: 500;
  line-height: 27px;
  height: 54px;
  padding-inline: 20px;
  text-align: center;
  white-space: nowrap;

  @media (max-width: 900px) {
    font-size: 32px;
    line-height: 20px;
    height: 46px;
  }

  @media (max-width: 632px) {
    font-size: 24px;
    line-height: 16px;
    height: 32px;
  }

  @media (max-width: 450px) {
    font-size: 18px;
    line-height: 12px;
    height: 32px;
    padding-inline: 16px;
  }
`

export const ViewPointsButton = styled.button`
  background-color: var(--white);
  border-radius: 12px;
  color: #422069;
  font-family: MdlzBiteType;
  font-size: 28px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  padding-inline: 16px;
  text-align: center;
  width: fit-content;

  @media (max-width: 900px) {
    font-size: 20px;
    line-height: 16px;
    height: 32px;
  }

  @media (max-width: 632px) {
    font-size: 16px;
    line-height: 16px;
    height: 32px;
  }

  @media (max-width: 450px) {
    font-size: 16px;
    line-height: 12px;
    height: 28px;
    padding-inline: 16px;
  }
`
