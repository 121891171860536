import { ReactElement } from 'react'
import { useOutletContext } from 'react-router-dom'
import { ProfileContextType } from '../../../..'
import ImageUpload from '../../../../../../components/Forms/ImageUpload'
import HexagonComponent from '../../../../../../components/Hexagon'
import { useAuth } from '../../../../../../contexts/AuthContext'
import api from '../../../../../../services/api'

import { toast } from 'react-toastify'
import * as S from './style'

export default function AvatarBanner (): ReactElement {
  const { avatar, setAvatar, userCan } = useAuth()
  const { user } = useOutletContext<ProfileContextType>()

  const handleAvatarUpload = async (img: any): Promise<void> => {
    try {
      await api.patch('/me/avatar', { avatarId: img.image.id })
      const newUser = { ...user, avatarId: img.image.id, avatar: img.image }
      localStorage.setItem('user', JSON.stringify(newUser))
      setAvatar(img.image.path)
    } catch (e) {
      toast.error('Ocorreu um erro ao enviar seu avatar. Por favor tente novamente mais tarde')
      console.log(e)
    }
  }

  return <S.AvatarBannerContainer>
    <S.AvatarPreview>
      <HexagonComponent size="medium" src={avatar} className="avatar" />
    </S.AvatarPreview>

    {userCan('avatar:update') === true && <ImageUpload title="Trocar Avatar" text="110x110px tamanho minimo" onSend={handleAvatarUpload} />}

    {userCan('banner:update') === true && <ImageUpload title="Trocar Banner" text="1184x300px tamanho minimo" onSend={handleAvatarUpload} />}
  </S.AvatarBannerContainer>
}
