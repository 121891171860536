import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PageLoaderComponent from '../../../../components/PageLoader'
import { useCart } from '../../../../contexts/CartContext'
import { makeLoadMe } from '../../../../main/factories/makeLoadMe'
import { currency } from '../../../../utils/format'
import * as S from './styles'

const loadMe = makeLoadMe()

export default function Cart () {
  const { cart, updateAmount } = useCart()
  const [me, setMe] = useState()

  useEffect(() => {
    (async () => {
      const result = await loadMe.handle()
      setMe(result.data)
    })()
  }, [])

  if (!cart) return <PageLoaderComponent />
  if (!me) return <PageLoaderComponent />

  return (
    <S.Template>
      <div className='sub-template'>
        <div className="section-header">
          <div className="section-header-info">
            <p className="section-pretitle">Procure seu</p>

            <h2 className="section-title">Carrinho de compras <span className="highlighted">{cart.items.length}</span></h2>
          </div>
        </div>

        <div className="grid grid-9-3 small-space">
          <div className="grid-column">
            <div className="table-wrap" data-simplebar="init"><div className="simplebar-wrapper" style={{ margin: '0px' }}>
              <div className="simplebar-height-auto-observer-wrapper">
                <div className="simplebar-height-auto-observer"></div>
              </div>
              <div className="simplebar-mask">
                <div className="simplebar-offset" style={{ right: '0px', bottom: '0px' }}>
                  <div className="simplebar-content-wrapper" style={{ height: 'auto', overflow: 'auto' }}>
                    <div className="simplebar-content" style={{ padding: '0px' }}>
                      <div className="table table-cart split-rows">
                        <div className="table-header">
                          <div className="table-header-column">
                            <S.TableHeaderTitle className="table-header-title">Item</S.TableHeaderTitle>
                          </div>

                          <div className="table-header-column padded-left">
                            <S.TableHeaderTitle className="table-header-title">Quantidade</S.TableHeaderTitle>
                          </div>

                          <div className="table-header-column centered padded-left">
                            <S.TableHeaderTitle className="table-header-title">Preço</S.TableHeaderTitle>
                          </div>

                          <div className="table-header-column padded-big-left"></div>
                        </div>

                        {cart.items.map((item, key) => (
                          <div className="table-body same-color-rows" key={key}>
                            <div className="table-row medium">
                              <div className="table-column">
                                <div className="product-preview tiny" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <Link to={`/product/${item.sku.Product.id}`}>
                                    <figure className="product-preview-image liquid" style={{ background: `url('${item.sku.images[0]?.path}') center center / cover no-repeat` }}>
                                      {item.sku.images.length > 0 && <S.Image src={item.sku.images[0].path} alt="item-01" />}
                                    </figure>
                                  </Link>

                                  <div className="product-preview-info">
                                    <p className="product-preview-title">
                                      <Link to={`/product/${item.sku.code}`}>{item.sku.Product.name} {item.sku.model}</Link>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="table-column padded-left">
                                <div className="form-counter">
                                  <p className="form-counter-value">{item.amount}</p>

                                  <div className="form-counter-controls">
                                    <div className="form-counter-control form-counter-control-increase"
                                      onClick={() => updateAmount(item.sku.code, item.amount + 1)}
                                    >
                                      <svg className="form-counter-icon icon-small-arrow"
                                      >
                                        <use href="#svg-small-arrow"></use>
                                      </svg>
                                    </div>

                                    <div className="form-counter-control form-counter-control-decrease"
                                      onClick={() => updateAmount(item.sku.code, item.amount - 1)}
                                    >
                                      <svg className="form-counter-icon icon-small-arrow">
                                        <use href="#svg-small-arrow"></use>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="table-column centered padded-left">
                                <p className="price-title"><span className="currency"></span> {currency(+item.sku.price)} Moedas</p>
                              </div>

                              <div className="table-column padded-big-left">
                                <div className="table-action">
                                  <svg className="icon-delete" onClick={() => updateAmount(item.sku.code, 0)}>
                                    <use href="#svg-delete"></use>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simplebar-placeholder" style={{ width: 'auto', height: '416px' }}></div>
            </div>
              <div className="simplebar-track simplebar-horizontal" style={{ visibility: 'visible' }}>
                <div className="simplebar-scrollbar" style={{ width: '840px', display: 'block', transform: 'translate3d(0px, 0px, 0px)' }}></div>
              </div>
              <div className="simplebar-track simplebar-vertical" style={{ visibility: 'hidden' }}>
                <div className="simplebar-scrollbar" style={{ height: '0px', display: 'none' }}></div>
              </div>
            </div>
          </div>

          <div className="grid-column">
            <div className="sidebar-box margin-top">
              <p className="sidebar-box-title">Total do pedido</p>

              <div className="sidebar-box-items">
                <p className="price-title big"><span className="currency"></span> {currency(cart.total)}</p>

                <div className="totals-line-list">
                  <div className="totals-line">
                    <p className="totals-line-title">Carrinho ({cart.items.length})</p>

                    <p className="price-title"><span className="currency"></span> {currency(cart.total)}</p>
                  </div>

                  <div className="totals-line">
                    <p className="totals-line-title">Créditos</p>

                    <p className="price-title"><span className="currency"></span>{currency(me.purchasedCoins / 100)}</p>
                  </div>

                  <div className="totals-line">
                    <p className="totals-line-title">Saldo Final</p>

                    <p className="price-title"><span className="currency"></span> {currency((me.purchasedCoins / 100) - cart.total)}</p>
                  </div>
                </div>

                <a href="checkout">
                  <button className="button primary" disabled={(me.purchasedCoins / 100) - cart.total <= 0}>Continuar para o checkout</button>
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </S.Template >
  )
}
