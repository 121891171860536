import { ReactElement } from 'react'
import StatsItemProps from './props'
import * as S from './style'

export default function StatsItem ({ count, label }: StatsItemProps): ReactElement {
  return <S.StatsItem>
    <S.Count>
      {count ?? 0}
    </S.Count>

    <S.Label>{label}</S.Label>
  </S.StatsItem>
}
