import styled from 'styled-components'
import headerMask from './assets/header-mask.svg'

export const ModalHeader = styled.div`
    align-items: center;
    background-image: url(${headerMask});
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: center;
    position: relative;
    width: 100%;

    &:before {
        background: var(--primary);
        border-radius: 24px 24px 0px 0px;
        content: '';
        height: 100%;
        left: 0;
        opacity: 80%;
        position: absolute;
        top: 0;
        width: 100%;
    }
    img {
        position: relative;
        top: -30px;
        max-width: 160px;
    }
`

export const ButtonClose = styled.button`
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    background-color: #7750f8;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    height: 35px;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -10px;
    transition: background-color .2s ease-in-out;
    width: 35px;
    z-index: 2;
    :hover {
        background-color: var(--primary);
        transition: background-color .2s ease-in-out;
    }
`

export const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    & > * {
        color: #22212C;
        text-align: center;
        width: 100%;
    }
    h2 {
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        margin-top: 32px;
    }
    p {
        font-size: 24px;
        font-weight: 400;
        margin-top: 15px;
    }
    span {
        font-size: 16px;
        font-weight: 700;
        margin-top: 16px;
    }
    h3 {
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        background-color: var(--secondary);
        background-repeat: repeat;
        background-size: 100%;
        font-size: 2rem;
        font-weight: 700;
        margin: 0 auto;
        margin-top: 11px;
        width: auto;
    }
    a {
        background-color: var(--primary);
        border-radius: 18px;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 700;
        margin: 0 auto;
        margin-bottom: 24px;
        margin-top: 20px;
        max-width: 340px;
        padding: 18px 4px;
        width: 85%;
    }
`
