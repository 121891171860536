import styled from 'styled-components'
import readyBg from '../../../assets/ready-bg.png'

export const ReadyModalContainer = styled.div`
  align-items: center;
  aspect-ratio: 669 / 437;
  background-image: url(${readyBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  justify-self: center;
  max-width: 669px;
  width: 100%;

  @media (max-width: 764px) {
    max-width: 600px;
  }

  @media (max-width: 632px) {
    max-width: 90%;
  }
`

export const ReadyModalContent = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;

  h1 {
    color: #ffffff;
    font-family: MdlzBiteType;
    font-size: 82px;
    line-height: 65px;
    margin: 72px 0 0;
    text-align: center;
  }

  button {
    background-color: #422069;
    color: var(--white);
    font-family: MdlzBiteType;
    font-size: 48px;
    line-height: 34px;
    font-weight: 500;
    height: 64px;
    max-width: 214px;
    text-align: center;
  }

  @media (max-width: 764px) {
    h1 {
      font-size: 64px;
      line-height: 44px;
    }

    button {
      font-size: 32px;
      line-height: 20px;
      height: 46px;
      max-width: 184px;
    }
  }

  @media (max-width: 632px) {
    h1 {
      font-size: 48px;
      line-height: 28px;
      margin-top: 48px;
    }

    button {
      font-size: 24px;
      line-height: 16px;
      height: 32px;
      max-width: 132px;
    }
  }

  @media (max-width: 450px) {
    h1 {
      font-size: 32px;
      line-height: 20px;
    }

    button {
      font-size: 18px;
      line-height: 12px;
      height: 32px;
      max-width: fit-content;
      padding-inline: 16px;
    }
  }
`
