export default [
  {
    pathTo: '',
    title: 'Campanhas',
    children: [
      {
        path: '/mecanicas',
        title: 'Mecânica do Incentivo',
        permission: 'read:mecanicas-2'
      }, {
        path: '/mecanicas/pascoa',
        title: 'Mecânica de Páscoa'
      }, {
        path: '/hub-de-campanhas',
        title: 'HUB de Campanhas',
        permission: 'read:hub-campanhas'
      }
    ]
  },
  {
    pathTo: '/hub',
    title: 'Ativações',
    children: []
  },
  {
    pathTo: '/ranking',
    title: 'Ranking',
    children: []
  },
  {
    pathTo: '/ganhadores',
    title: 'Ganhadores',
    permission: 'read:winners',
    children: []
  },
  {
    pathTo: '/newsfeed',
    title: 'Notícias',
    children: []
  },
  {
    pathTo: '/faq',
    title: 'Dúvidas',
    children: []
  },
  {
    pathTo: '/marketplace',
    title: 'Loja',
    permission: 'read:marketplace',
    children: []
  }
]
