import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 100%;

  h1.section-title {
    color: #1d3f85;
    font-size: 16px;
    text-align: left;
  }

  > div {
    display: flex;
    gap: 4px;
    justify-content: center;

    button {
      background-color: transparent;
      border-radius: initial;
      cursor: pointer;
      padding-inline: 8px;

      figure {
        width: 4px;
        margin: 0;
      }

      :focus {
        outline: none;
      }
    }
  }

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    h1.section-title {
      font-size: 24px;
    }

    > div button figure {
      width: 8px;
    }
  }
`

export const AuctionMiddleContainer = styled.div`
  align-items: center;

  figure {
    margin: 0;
  }

  > figure {
    max-width: 30%;
  }

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    > figure {
      max-width: initial;
      position: relative;
      height: 36px;
      width: 144px;

      img {
        height: fit-content;
        top: -8%;
        position: absolute;
        z-index: 1;
      }
    }
  }
`

export const CoinCountContainer = styled.div`
  align-items: center;
  background-color: rgba(71,119,217,0.6);
  border-radius: 10px;
  display: flex;
  filter: drop-shadow(0px 4px 3px rgba(0,0,0,0.15));
  gap: 4px;
  height: 18px;
  justify-content: space-between;
  padding-inline: 8px;
  min-width: 108px;

  > div {
    align-items: center;
    display: flex;
    justify-content: center;

    p {
      white-space: nowrap;
    }

    &:first-child {
      background-color: rgba(247, 203, 37, 0.9);
      border-radius: 8px;
      filter: drop-shadow(0px 4px 3px rgba(0,0,0,0.15));
      height: 14px;
      min-width: 46px;

      p {
        color: #1d3f85;
        font-size: 10px;
        font-weight: 700;
      }
    }

    &:last-child {
      gap: 2px;

      p {
        color: #eec52c;
      }

      > p {
        font-size: 16px;
      }


      > div {
        align-self: flex-end;
        position: relative;

        figure {
          bottom: 56%;
          left: 0;
          right: 0;
          margin: 0 auto;
          max-width: 80%;
          position: absolute;
        }

        p {
          font-size: 6px;
          letter-spacing: 0;
          margin-bottom: 2px;
        }
      }
    }
  }

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    gap: 8px;
    height: 36px;
    padding-inline: 8px 16px;
    min-width: 212px;

    > div {
      &:first-child {
        background-color: #f5c4c4;
        border-radius: 10px;
        height: 28px;
        min-width: 92px;

        p {
          color: #0e3e8a;
          font-size: 18px;
          font-weight: 500;
        }
      }

      &:last-child {
        > p {
          font-size: 28px;
        }

        > div {
          p {
            font-size: 10px;
          }
        }
      }
    }
  }
`
