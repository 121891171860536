import { ReactElement } from 'react'
import TryAgainModalProps from './props'
import * as S from './styled'

export default function TryAgainModal ({ handleTryAgain, handleViewPoints, time }: TryAgainModalProps): ReactElement {
  return <S.TryAgainModalContainer>
    <S.TryAgainModalContent>
      {time > 0 ? <h1>Game Over!</h1> : <h1>Oh não, <br/>parece que o <br/>tempo acabou!</h1>}

      <S.TryAgainButtonsContainer>
        <S.TryAgainButton onClick={handleTryAgain}>Jogar novamente</S.TryAgainButton>

        <S.ViewPointsButton onClick={handleViewPoints}>Ver meus pontos</S.ViewPointsButton>
      </S.TryAgainButtonsContainer>
    </S.TryAgainModalContent>
  </S.TryAgainModalContainer>
}
