import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 48%;
`

export const SectionHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  h1 {
    color: #1d3f85;
    font-size: 16px;
  }

  > div {
    display: flex;
    gap: 4px;
    justify-content: center;

    button {
      background-color: transparent;
      border-radius: initial;
      padding-inline: 8px;

      figure {
        width: 4px;
        margin: 0;
      }

      :focus {
        outline: none;
      }
    }
  }

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    h1 {
      font-size: 24px;
    }

    > div button figure {
      width: 8px;
    }
  }
`

export interface CardsContainerProps {
  children: JSX.Element
  translateX: number
}

export const CardsContainer = styled.div<CardsContainerProps>`
  flex: 1 100%;
  overflow: hidden;
  padding-bottom: 20px;
  width: 100%;

  & > div {
    display: flex;
    gap: 16px;
    transform: translateX(${({ translateX }) => `${translateX}px`});
    transition: transform 500ms ease-in-out;
    width: max-content;
    @media (max-width: 520px) {
      flex-direction: column;
    }
  }

  @media (max-width: 520px) {
    overflow: visible;
  }
`
