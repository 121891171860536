import styled from 'styled-components'
import Card from '../../../components/Card'

export const KPICard = styled(Card)`
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  height: 172px;
  justify-content: space-between;
  padding: 20px;
  max-width: 100%;
  width: 284px;

  @media (max-width: 1024px) {
    justify-content: space-around;
    width: 303px;
  }
`

export const StatsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  justify-content: center;

  h6 {
    color: #1d3f85;
    font-family: MdlzBiteType;
    font-size: 24px;
    line-height: 28px;
    margin: 0;
    text-align: center;
  }
`

export const IndicatorsTable = styled.table`
  caption {
    color: #3e405c;
    font-family: Rajdhani;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    padding: 0;
    text-align: center;
  }

  thead th, tfoot td {
    color: #aeaeae;
    font-family: Rajdhani;
    font-size: 10px;
    font-weight: 700;
    line-height: normal;
    padding-inline: 4px;
    text-align: center;
    text-transform: uppercase;
  }

  tbody {
    color: #1d3f85;
    font-family: MdlzBiteType;
    font-size: 20px;
    line-height: normal;
    text-align: center;
  }
`
