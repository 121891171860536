import styled from 'styled-components'

export const HighlightsContainer = styled.div`
  align-items: flex-start;
  background-image: linear-gradient(45deg, #e7912d 0%, #f1cd3d 100%);
  border-bottom-left-radius: 92px;
  border-bottom-right-radius: 92px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  filter: drop-shadow(0 0 20px rgba(94,93,151,0.06));
  gap: 4rem;
  justify-content: center;
  padding: 158px 24px 100px;
  position: relative;
  height: 80vh;
  z-index: 2;

  @media screen and (max-width: 1365px) and (orientation: portrait) {
    height: 70vh;
  }

  @media screen and (max-width: 764px) and (orientation: portrait) {
    gap: 1rem;
    padding-top: 192px;
  }

  @media screen and (max-height: 763px) and (orientation: portrait) {
    padding-top: 132px;
  }

  @media screen and (max-width: 520px) {
    padding-top: 102px;
    height: auto;
    grid-template-columns: 1fr;
  }
`

export const HighlightsContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;
  justify-content: space-between;
  justify-self: flex-end;
  max-height: 398px;
  max-width: 480px;
  width: 100%;

  @media (max-width: 520px) {
    max-height: none;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  gap: 36px;
  justify-content: space-around;
`

export const Price = styled.p`
  color: #343d4c;
  font-size: 40px;
  text-transform: uppercase;

  span {
    font-size: 12px;
    vertical-align: super;
  }

  @media screen and (max-width: 520px) {
    font-size: 1.75rem;
  }
`

export const Title = styled.p`
  align-items: center;
  color: #fff;
  display: flex;
  flex: 1;
  height: 100%;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.25rem;
  text-transform: uppercase;
  width: auto;

  @media (max-width: 1365px) {
    font-size: 2rem;
    line-height: 2rem;
  }

  @media (max-width: 763px) {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  @media (max-width: 520px) {
    font-size: 1.25rem;
  }
`

export const Button = styled.button`
  background-color: #343d4c;
  border-radius: 0;
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  min-height: 52px;
  padding-inline: 24px;
  text-transform: uppercase;
  max-width: 184px;
`

export const HighlightImageContainer = styled.div`
  margin: 0;
  flex: 1;

  figure {
    bottom: -8%;
    max-width: 428px;
    position: absolute;
  }

  @media screen and (max-width: 950px) and (orientation: portrait) {
    figure {
      width: 42%;
    }
  }

  @media (max-width: 520px) {
    figure {
      position: initial;
      width: 100%;
    }
  }
`

export const ArrowsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
`

export const ArrowButton = styled.button`
  background-color: transparent;
  outline: none;
  width: fit-content;
`
