import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PageLoaderComponent from '../../components/PageLoader';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';
import Template from '../../template';
import * as S from './style';

export default function UserTerms () {
  const [term, setTerm] = useState({});
  const { user } = useAuth();

  useEffect(() => {
    const Data = async () => {
      try {
        const { data } = await api.get('term');
        setTerm(data);
      } catch (err) {
        toast.error(err.response.data.message, {
          theme: 'colored'
        });
      }
    };
    Data();
  }, [user]);

  if (!term) <PageLoaderComponent />;

  return <Template>
    <div style={{ marginTop: '8rem' }}></div>
    <S.PerfectScrollbar>
      <S.Body>
        <div className="text-center mb-5">
          <h1>Termos e Condições</h1>
        </div>
        <p dangerouslySetInnerHTML={{ __html: term.content }}></p>
      </S.Body>
    </S.PerfectScrollbar>
  </Template>;
}
