import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import * as S from './styled'

interface TopBannerHubCampanhaProps {
  className?: string
  banner: string
}

export default function TopBannerHubCampanha ({ className, banner }: TopBannerHubCampanhaProps): ReactElement {
  return <Link to="" className={className}>
    <S.Container style={{ backgroundImage: `url(${banner})` }} aria-label="A Onda 2 vem grandona. E você, maior ainda.">
    </S.Container>
  </Link>
}
