import { ReactElement, useMemo } from 'react'
import quest from '../../../assets/img/hub/Quest-Completed-Icon.png'
import badge from '../../../assets/img/hub/Unlockd-Badge-Icon.png'
import boy from '../../../assets/img/hub/boy-waving.png'
import { useAuth } from '../../../contexts/AuthContext'
import * as S from './styled'

interface StatsProps {
  stats: any
}

export default function Stats ({ stats }: StatsProps): ReactElement {
  const { userCan } = useAuth()
  const progresso = useMemo(() => {
    const completed = Number.parseInt(stats.quest.completed) + Number.parseInt(stats.badge.completed)
    const total = Number.parseInt(stats.quest.total) + Number.parseInt(stats.badge.total)
    return Math.floor(completed / total * 100)
  }, [])
  return <S.Container>
    <S.Card>
      <S.Content>
        <div>
          <S.Count>
            {stats.quest.completed}/{stats.quest.total}
          </S.Count>

          <S.Title>
            Quests
          </S.Title>

          <S.Description>
            Completed
          </S.Description>

          <figure>
            <img src={quest} alt="Ícone de Quest" />
          </figure>
        </div>

        {userCan('read:badges') && <>
          <hr />

          <div>
            <S.Count>
              {stats.badge.completed}/{stats.badge.total}
            </S.Count>

            <S.Title>
              Badges
            </S.Title>

            <S.Description>
              Unlocked
            </S.Description>

            <figure>
              <img src={badge} alt="Ícone de Badge" />
            </figure>
          </div>
        </>}

        {!Number.isNaN(progresso) && <S.Graph progress={progresso} />}
      </S.Content>
    </S.Card>

    <figure>
      <img src={boy} alt="Garoto acenando" />
    </figure>
  </S.Container>
}
