import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PageLoaderComponent from '../../../../../components/PageLoader'
import { makeLoadOrder } from '../../../../../main/factories/makeLoadOrder'
import { currency } from '../../../../../utils/format'
import { OrderProps } from './props'
import * as S from './style'

const loadOrder = makeLoadOrder()

enum State {
  LOADING,
  READY,
  ERROR
}

function ProfileOrder (): ReactElement {
  const [order, setOrder] = useState<{
    state: State
    data: OrderProps
  }>({
    state: State.LOADING,
    data: {
      data: {
        pedido: {
          codigoPedido: 0,
          dataHora: '',
          valorFrete: 0,
          valorProduto: 0,
          valorTotalPedido: 0
        },
        endereco: {
          cep: '',
          estado: '',
          logradouro: '',
          cidade: '',
          numero: 0,
          bairro: '',
          telefone: ''
        },
        destinatario: {
          nome: '',
          cpfCnpj: ''
        },
        entregas: [{
          codigoEntrega: 0,
          dataPrevisao: '',
          trackingEntrega: [{
            data: '',
            descricao: ''
          }],
          produtoEntrega: [{
            nome: '',
            quantidade: 0
          }]
        }]
      },
      error: {
        code: '',
        message: '',
        fields: []
      }
    }
  })
  const { orderId } = useParams()

  useEffect(() => {
    (async () => {
      try {
        const response = await loadOrder.handle({ orderId: String(orderId) })
        switch (response.status) {
          case 200:
            setOrder({
              data: response.data,
              state: State.READY
            })
            return
          default:
            setOrder((data) => ({
              ...data,
              state: State.ERROR
            }))
        }
      } catch (error) {
        setOrder((data) => ({
          ...data,
          state: State.READY
        }))
      }
    })()
  }, [orderId])

  if (order.state === State.LOADING) return <PageLoaderComponent />

  if (order.state === State.ERROR) {
    return <S.Template>
      <S.Container>
        <div className="section-header">
          <div className="section-header-info">
            <p className="section-pretitle">Erro com pedido</p>
            <h2 className="section-title">&nbsp;</h2>
            <p className="section-pretitle">{''}</p>
          </div>
        </div>
        <S.OrderDetails>
          <section className='infos-section'>
            <div className='infos-block'>
              Ocorreu um erro com o pedido.<br />
              Por favor entre em contato com o sac e informe o código:<br /><br />
              <h2>#{String(orderId)}</h2>
            </div>
          </section>
        </S.OrderDetails>
      </S.Container>
    </S.Template >
  }

  return <S.Template>
    <S.Container>
      <div className="section-header">
        <div className="section-header-info">
          <p className="section-pretitle">Rastreie seu pedido</p>
          <h2 className="section-title">Pedido #<span className="highlighted">{order.data.data.pedido.codigoPedido}</span></h2>
          <p className="section-pretitle">{new Date(order.data.data.pedido.dataHora).toLocaleDateString('pt-br')}</p>
        </div>
      </div>
      <S.OrderDetails>
        <section className='infos-section'>
          <div className='infos-block'>
            <h2>Endereço</h2>
            <p>Logradouro: <span className='text-muted'>{order.data.data.endereco.logradouro}, {order.data.data.endereco.numero}</span></p>
            <p>Cidade - UF: <span className='text-muted'>{order.data.data.endereco.cidade} - {order.data.data.endereco.estado}</span></p>
            <p>Bairro: <span className='text-muted'>{order.data.data.endereco.bairro}</span></p>
            <p>Telefone: <span className='text-muted'>{order.data.data.endereco.telefone}</span></p>
          </div>
          <div className='infos-block'>
            <h2>Destinatário</h2>
            <p>Nome: <span className='text-muted'>{order.data.data.destinatario.nome}</span></p>
            <p>CPF/CNPJ: <span className='text-muted'>{order.data.data.destinatario.cpfCnpj}</span></p>
          </div>
          <div className='infos-block'>
            <h2>Resumo</h2>
            <p>Subtotal: <span className='text-muted'>${currency(order.data.data.pedido.valorProduto)}</span></p>
            <p>Valor de frete: <span className='text-muted'>${currency(order.data.data.pedido.valorTotalPedido)}</span></p>
            <p>Total: <span className='text-muted'>${currency(order.data.data.pedido.valorTotalPedido)}</span></p>
          </div>
        </section>
      </S.OrderDetails>
      <S.Tacking>
        <h2>Detalhe das entregas</h2>
        {order.data.data.entregas.map((entrega, index) => <S.DeliveryItem key={index}>
          <h3>Pacote {index + 1}</h3>
          <S.DeliveryItemDesc>{entrega.trackingEntrega[entrega.trackingEntrega.length - 1].descricao}</S.DeliveryItemDesc>
          <S.TackingData>
            {entrega.trackingEntrega.map((step, index) => <S.TrackingDataContainer key={index}>
              <S.TackingDataDate>{new Date(step.data).toLocaleString('pt-br', { timeZone: 'America/Sao_Paulo' })}</S.TackingDataDate>
              <S.TackingDataDesc>{step.descricao}</S.TackingDataDesc>
            </S.TrackingDataContainer>)}
          </S.TackingData>
          <p>Entrega até: <span className='text-muted'>{entrega.dataPrevisao}</span></p>
          <p>Produtos:</p>
          <S.TrankingDataProducts>
            <thead>
              <tr>
                <td>Qtd.</td>
                <td>Item</td>
              </tr>
            </thead>
            <tbody>
              {entrega.produtoEntrega.map((produto, i) => <tr key={i}>
                <td>{produto.quantidade}</td>
                <td>{produto.nome}</td>
              </tr>
              )}
            </tbody>
          </S.TrankingDataProducts>
        </S.DeliveryItem>)}
      </S.Tacking>
    </S.Container>
  </S.Template >
}

export default ProfileOrder
