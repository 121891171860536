import { Link } from 'react-router-dom'
import styled from 'styled-components'
import header from '../../assets/img/incentivo-2024/home/HOME_HEADER.png'

export const Header = styled.header<{ menuOpen: boolean }>`
  background-image: url(${header});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 41%;
  left: 50%;
  max-height: 316px;
  padding-top: 144px;
  position: absolute;
  /* overflow: hidden; */
  top: -125px;
  transform: translateX(-50%);
  width: 100%;
  z-index: 200;

  a, p, li {
    color: #fff;
    font-weight: 600;
    white-space: nowrap;
  }

  @media (max-width: 520px) {
    position: fixed;
    top: -120px;
  }
`

export const ToggleContainer = styled.div.attrs(() => {
  return {
    className: 'js-toogle-container'
  }
})`
  display: flex;
  flex: 1;

  @media (min-width: 950px) {
    display: none;
  }
`

export const MenuToggle = styled.button`
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 8px;
  outline: none;
  width: fit-content;

  > img {
    max-width: 32px;
  }

  @media screen and (min-width: 1366px) and (orientation: portrait) {
    display: none;
  }
`

export const CloseIcon = styled.button`
  align-self: flex-start;
  background-color: transparent;
  border: none;
  display: flex;
  outline: none;
  width: fit-content;

  img {
    max-width: 32px;
  }
`

export const Menu = styled.div`
  align-items: center;
  background-color: #4f7bdd;
  border-bottom-left-radius: 68px;
  border-bottom-right-radius: 68px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  /* margin: 0 20px; */
  padding: 32px;
  position: absolute;
  top: 124px;
  width: 92%;
  left: 50%;
  margin: auto;
  transform: translateX(-50%);
  z-index: 99;

  > div {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`

export const MenuItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  gap: 8px;

  img {
    height: 32px;
    width: 32px;
  }

  p, a {
    font-family: MdlzBiteType;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    text-decoration: none;
  }
`

export const MenuItemLink = styled(Link)`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  gap: 8px;

  img {
    height: 32px;
    width: 32px;
  }

  p {
    font-family: MdlzBiteType;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
  }
`

export const MLink = styled.button`
  background-color: transparent;
  border: 0 none;
  color: var(--white);
  text-align: left;
  font-family: MdlzBiteType;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
`

export const Children = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  opacity: 1;
  padding: 16px 0;
  position: absolute;
  transform: translateY(0);
  transition: all .25s ease-in-out;
  z-index: 9999;

  &.menu-close {
    pointer-events: none;
    opacity: 0;
    transform: translateY(-100%);
  }
`

export const ChildrenItem = styled(Link)`
  color: #FFFFFF;
  font-family: Roboto,sans-serif;
  font-size: 18px;
  text-shadow: 3px 3px 2px #000;
  text-transform: uppercase;
`

export const MobileButton = styled.button`
  align-items: center;
  border-radius: 60px;
  background-color: #ffdc39;
  justify-content: center;
  height: 48px;
  padding: 0 20px;
  display: flex;
  position: absolute;
  left: 50%;
  margin: auto;
  transform: translateX(-50%);
  bottom: 16%;
  color: #153aaa;
  font-family: MdlzBiteType;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  margin-top: 200px;
  max-width: fit-content;

  @media screen and (orientation: landscape) {
    display: none;
  }
`

export const HeaderImg = styled.img`
  height: 100%;
  left: -150px;
  max-width: 304px;
  position: absolute;
  top: -5%;

  @media screen and (max-width: 1365px) and (orientation: portrait) {
    display: none;
  }
`

export const HeaderContent = styled.div.attrs(() => {
  return {
    className: 'js-header-content'
  }
})`
  align-items: center;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1365px) and (orientation: portrait) {
    gap: 16px;
    justify-content: space-between;
    padding-inline: 16px;
  }

  @media (max-width: 950px) {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;

    &>figure {
      margin: 0 auto;
    }
  }
  `

export const MdlzLogoContainer = styled.figure`
  margin: 0 1.5rem 0 0;
  max-width: 120px;
  display: flex;
  flex: 1;
  z-index: 5;

  @media screen and (max-width: 1365px) and (orientation: portrait) {
    max-width: 80px;
    margin: 0;
  }

  @media (max-width: 911px) {
    img {
      display: none;
    }
  }
`

export const NavList = styled.ul`
  display: flex;
  gap: 20px;
  margin: 0;
  list-style: none;
  position: relative;

  @media screen and (max-width: 1365px) and (orientation: portrait) {
    display: none;
  }
`

export const NavListItem = styled.li`
  > a {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    text-transform: uppercase;
  }
`

export const DropdownContainer = styled.div`
  align-items: baseline;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;

  p {
    margin: 0;
  }

  @media screen and (max-width: 1365px) and (orientation: portrait) {
    align-items: center;
    flex-direction: row-reverse;
  }
`

export const Avatar = styled.figure`
  aspect-ratio: 1;
  border: 1px solid #02ed3b;
  border-radius: 50%;
  margin: 0 0 0 1rem;
  overflow: hidden;
  width: 40px;

  @media screen and (max-width: 1365px) and (orientation: portrait) {
    margin: 0;
    width: 28px;
  }
`

export const DropdownToggleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  > svg {
    fill: #02ed3b;
    width: 8px;
    height: 10px;
    transform: rotate(90deg);
  }

  p {
    @media screen and (max-width: 1365px) and (orientation: portrait) {
      white-space: normal;
      max-width: 64px;
    }

    @media (max-width: 950px) {
      font-size: 1rem;
    }

    &.display-name {
      font-family: Roboto, sans-serif;
    }
  }
`

export const DropdownList = styled.ul<{ visible: boolean }>`
  position: absolute;
  list-style: none;
  margin: 0;
  top: 28px;
  visibility: ${({ visible }) => !visible && 'hidden'};

  > li, & > a {
    font-family: Rajdhani, sans-serif;
    text-decoration: underline;
  }
`

export const GigantesLogoLink = styled(Link)`
  display: block;
  left: 50%;
  margin: auto;
  max-width: 250px;
  transform: scale(.8);
  margin-top: -20px;

  @media (max-width: 950px) {
    transform: scale(.6);
  }

  @media (max-width: 500px) {
    margin-top: -36px;
    transform: scale(.5);
  }
`

export const GigantesLogo = styled.figure`
  max-width: fit-content;

  img {
    width: 100%;
  }
`

export const ClubSocial = styled(Link).attrs(() => {
  return {
    className: 'mdlz-2024-button',
    to: ''
  }
})`
  grid-area: 1 / 1 / 4 / 3;

  @media screen and (min-height: 874px) {
    grid-area: 1 / 1 / 5 / 3;
  }
`

export const Quiz = styled(Link).attrs(() => {
  return {
    className: 'mdlz-2024-button',
    to: ''
  }
})`
  grid-area: 4 / 2 / 6 / 3;
  height: 100%;
  justify-self: flex-end;
  max-height: 80px;
  margin-top: 20px;
  width: 100%;

  @media screen and (min-height: 874px) {
    align-self: flex-end;
    grid-area: 5 / 2 / 7 / 3;
    justify-self: flex-start;
    max-height: 100px;
    max-width: 100px;
  }
`

export const ClubeMdlz = styled(Link).attrs(() => {
  return {
    className: 'mdlz-2024-button',
    to: ''
  }
})`
  align-self: flex-end;
  height: 100%;
  max-width: 250px;
  width: 100%;
  grid-area: 7 / 2 / 9 / 4;

  &>img {
    display: none;
  }

  &:hover>img {
    display: block;
    position: relative;
    max-width: 100%;
    top: -50px;
    left: -65px;
  }

  @media screen and (max-height: 874px) {
    justify-self: center;
    grid-area: 5 / 2 / 7 / 4;
    max-height: 115px;
  }
`

export const PrizeShopping = styled(Link).attrs(() => {
  return {
    className: 'mdlz-2024-button',
    to: ''
  }
})`
  grid-area: 2 / 3 / 5 / 5;
  width: 265px;

  @media screen and (min-height: 874px) {
    grid-area: 3 / 3 / 6 / 5;
  }
`

export const Highlights = styled(Link).attrs(() => {
  return {
    className: 'mdlz-2024-button',
    to: ''
  }
})`
  grid-area: 1 / 5 / 4 / 6;

  @media screen and (min-height: 874px) {
    grid-area: 1 / 5 / 5 / 6;
  }
`

export const Ranking = styled(Link).attrs(() => {
  return {
    className: 'mdlz-2024-button',
    to: ''
  }
})`
  grid-area: 5 / 5 / 8 / 7;

  @media screen and (max-height: 874px) {
    grid-area: 4 / 5 / 6 / 7;
    max-width: 200px;
  }
`

export const Newsfeed = styled(Link).attrs(() => {
  return {
    className: 'mdlz-2024-button',
    to: ''
  }
})`
  grid-area: 5 / 4 / 7 / 5;
  max-height: 200px;

  @media screen and (min-height: 874px) {
    align-self: flex-end;
    grid-area: 6 / 4 / 9 / 5;
  }
`

export const Dropbox = styled.div<{ active: boolean }>`
background-color: #ffffff80;
backdrop-filter: blur(8px);
position: absolute;
width: min(90vw, 212px);
top: 64px;
right: 6px;
opacity: ${props => props.active ? 1 : 0};
visibility: ${props => props.active ? 'visible' : 'hidden'} ;
transform: ${props => props.active ? 'translate(0px)' : 'translate(0px, -40px)'};
transition: transform 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s;
z-index: 9002;
&>a {
  color: var(--primary);
}
.dropdown-navigation-link:hover {
  color: var(--primary);
}
@media(max-width: 640px) {
  max-width: 15rem;
  &.notice-box {
    right: -60px;
    max-width: unset;
  }
}
`

export const LeaveButton = styled.button`
i {
  margin-right: 16px;
}
`

export const ModalText = styled.p`
  margin: 0;
  line-height: normal;
`
