import styled from 'styled-components'
import CardComponent from '../../../components/Card'

export const Container = styled.div`
  padding-left: 28px;
  position: relative;

  > figure {
    left: -12%;
    margin: 0;
    max-width: 60px;
    position: absolute;
    top: -20%;
  }

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    padding-left: 80px;
    margin-top: 48px;

    > figure {
      left: -4%;
      max-width: 100%;
    }
  }
  `

export const Card = styled(CardComponent)`
  background-color: var(--white);
  border-radius: 4px;
  filter: drop-shadow(0px 3px 5px rgba(27,27,29,0.16));
  justify-content: center;
  height: 80px;
  max-width: 100%;
  padding: 8px 8px 8px 12px;
  width: 136px;

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    border-radius: 8px;
    height: 100%;
    padding: 20px 20px 20px 24px;
    width: 286px;
  }
`

export const Content = styled.div`
  justify-content: space-around;

  &, > div {
    align-items: center;
    display: flex;
    gap: 4px;
  }

  > div {
    flex-direction: column;
  }

  p {
    font-family: Rajdhani;
    font-size: 6px;
    text-align: center;

  }

  figure {
    margin: 0;
    max-width: 16px;
  }

  hr {
    border-left: 1px solid #cfcfd2;
    height: 100%;
    margin: 0;
  }

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    p {
      font-size: 12px;
    }

    figure {
      max-width: 32px;
    }
  }
`

export const Count = styled.p`
  color: #1d3f85;
  font-family: MdlzBiteType !important;
  font-size: 12px !important;
  font-weight: 500;
  line-height: 6px;
  margin-bottom: 4px !important;

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    font-size: 24px !important;
    margin-bottom: 16px !important;
  }
`

export const Title = styled.p`
  font-weight: 700;
`

export const Description = styled.p`
  text-transform: uppercase;
`

export const Graph = styled.div<{ progress: number }>`
  --primary: #615dfa;
  --secondary: #23d2e2;
  aspect-ratio: 1;
  background: conic-gradient(var(--primary) 0%, var(--secondary) ${props => `${props.progress}%`}, #e8e8ef ${props => `${props.progress}.1%`}, #e8e8ef 100%);
  border-radius: 50%;
  height: 50px;
  position: relative;
  width: 50px;

  &:after {
    align-items: center;
    aspect-ratio: 1;
    background-color: #ffffff;
    border-radius: 50%;
    content: "${props => `${props.progress}%`}";
    display: flex;
    font-family: Rajdhani, sans-serif;
    font-size: 12px;
    font-weight: bold;
    justify-content: center;
    margin: 8px;
    position: absolute;
    width: calc(100% - 16px);
  }

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    height: 100px;
    width: 100px;

    &:after {
      font-size: 24px
    }
  }
`
