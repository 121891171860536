import { ReactElement } from 'react'
import Template from '../../../template'

function SendingComponent (): ReactElement {
  return <Template>
    <div className="grid medium-space">
      <div className="account-hub-content">
        <div className="section-header">
          <div className="section-header-info">
            <p className="section-pretitle">Enviando planilha</p>

            <h2 className="section-title">
              A planilha está sendo processada...
            </h2>
          </div>
        </div>

        <div className="grid-column">
          <div className="widget-box">
            <p className="widget-box-title">Os dados estão sendo enviados para nosso servidor.</p>
            <div className="widget-box-content">
            </div>
          </div>
        </div>

      </div>
    </div>
  </Template>
}

export default SendingComponent
