import { ReactElement } from 'react'
import * as S from './styles'

function Message (): ReactElement {
  return <S.Message>
    <div>
      <p>Por favor, vire o celular para jogar</p>
    </div>
  </S.Message>
}

export default Message
