import { AxiosError, AxiosInstance } from 'axios'
import { CreateBid } from '../../domain/use-case/CreateBid'

export class ApiCreateBid implements CreateBid {
  private readonly api: AxiosInstance
  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (params: CreateBid.Params): Promise<CreateBid.Result> {
    try {
      const response = await this.api.post(`/auction/${params.itemId}`, {
        value: params.value
      })

      return response
    } catch (err) {
      const axiosError = err as AxiosError
      if (axiosError.response) {
        return axiosError.response
      }

      if (axiosError.request) {
        return {
          data: axiosError.request,
          status: 0
        }
      }

      return {
        data: axiosError.message,
        status: 0
      }
    }
  }
}
