import styled from 'styled-components';

export const ProgressContainer = styled.div`
  position: relative;
  overflow: hidden;
  border: 1px solid #000;
  width: 90%;
  height: 20px;
  border-radius: 8px;
  margin-top: 16px;
`;

export const ProgressBar = styled.div`
  width: ${(props) => props.progressValue || 0}%;
  position:absolute;
  background-color:var(--primary);
  top: 0;
  bottom: 0;
`;

export const UploadBox = styled.div`
  position: relative;
`

export const Icon = styled.div`
  align-items: center;
  background-color: #23d2e2;
  border-radius: 50%;
  display: flex;
  min-height: 1.5rem;
  justify-content: center;
  min-width: 1.5rem;
  position: absolute;
  right: 16px;

  svg {
    fill: var(--white);
    height: 1rem;
    width: 1.25rem;
  }
`

export const Completed = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  p {
    font-size: 1rem;
  }
`