import { ReactElement } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import { shortenNumber } from '../../../utils/shortenNumber'
import StatsItem from '../StatsItem'
import CardCampaignProps from './props'
import * as S from './style'

export default function CardCampaign ({
  banner,
  edit = false,
  description,
  id,
  logo,
  name,
  registred,
  regulation,
  ratio,
  total,
  users
}: CardCampaignProps): ReactElement {
  const navigate = useNavigate()
  const { userCan } = useAuth()

  return <S.Container>
    <S.Banner>
      <img src={banner} alt={name} />
    </S.Banner>

    <S.Content>
      <S.AvatarHexagon>
        <img src={logo} alt={name} />
      </S.AvatarHexagon>

      <S.Info>
        <h4>{name}</h4>

        <p>{description}</p>
      </S.Info>

      {userCan('read:hub-campanha-status') && <S.StatsContainer>
        <StatsItem label='Usuários' count={shortenNumber(total)} />
        <StatsItem label='Ativos' count={shortenNumber(registred)} />
        <StatsItem label='Engajamento' count={shortenNumber(ratio)} />
      </S.StatsContainer>}

      <S.UsersContainer>
        {users.slice(-5).reverse().map((user) => <S.UserContainer key={user}>
          <img src={user} alt="" />
        </S.UserContainer>)}

        {users.length > 5 && <S.UserContainer>
          <Link to="">
            +{shortenNumber(users.length - 5)}
          </Link>
        </S.UserContainer>}
      </S.UsersContainer>

      {edit
        ? <S.JoinButton onClick={() => navigate(`/admin/campanha/${id}`)}>
          <svg className="button-icon icon-join-group">
            <use href="#svg-join-group"></use>
          </svg>

          Editar campanha
        </S.JoinButton>
        : <>
          {!regulation?.accept && <S.JoinButton onClick={() => navigate(`/hub-de-campanhas/${id}`)}>
            <svg className="button-icon icon-join-group">
              <use href="#svg-join-group"></use>
            </svg>

            Entrar na campanha
          </S.JoinButton>}

          {regulation?.accept && <S.JoinButton onClick={() => navigate(`/hub-de-campanhas/mecanica/${id}`)}>
            <svg className="button-icon icon-join-group">
              <use href="#svg-join-group"></use>
            </svg>

            Entrar na campanha
          </S.JoinButton>}
        </>}

    </S.Content>
  </S.Container>
}
