import { ReactElement } from 'react'
import girlShopping from './assets/person-girl-shopping.svg'
import manVideogame from './assets/person-man-videogame.svg'
import manWalking from './assets/person-man-walking.svg'
import * as S from './style'

interface SectionKPIsProps {
  leftIconPerson: string
  mecanicaName: string
}

export default function SectionKPIsHeader ({ mecanicaName, leftIconPerson }: SectionKPIsProps): ReactElement {
  const personIconOptions = {
    manVideogame,
    girlShopping,
    manWalking
  }

  return (
    <S.SectionKPIsHeader>
      <S.SectionKPIHeaderTop>
        {leftIconPerson !== 'false' ? <S.SectionKPIHeaderTopPerson src={personIconOptions[leftIconPerson as keyof typeof personIconOptions]} alt="Imagem de um personagem humano desenhado" /> : <S.SectionKPIHeaderTopSpace></S.SectionKPIHeaderTopSpace>}
        <S.SectionKPIHeaderTopMask>
          <div>
            MECÂNICA<span>{mecanicaName}</span>
          </div>
        </S.SectionKPIHeaderTopMask>
        <S.SectionKPIHeaderTopText>
          Acumulado <span>Julho, Agosto <br/>e Setembro’ 24</span>
        </S.SectionKPIHeaderTopText>
      </S.SectionKPIHeaderTop>
    </S.SectionKPIsHeader>
  )
}
