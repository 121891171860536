import { ReactElement, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { ProfileContextType, User } from '../../..'
import Card from '../../../../../components/Card'
import PageLoaderComponent from '../../../../../components/PageLoader'
import SectionHeaderComponent from '../../../../../components/SectionHeaderComponent'
import AvatarBanner from './AvatarBanner'
import Badges from './Badges'
import InterestsInfo from './InterestsInfo'
import PersonalInfo from './PersonalInfo'
import ProfessionalInfo from './ProfessionalInfo'

import { useAuth } from '../../../../../contexts/AuthContext'
import api from '../../../../../services/api'
import * as S from './style'

function ProfileInfo (): ReactElement {
  const { user, setUser } = useOutletContext<ProfileContextType>()
  const [userData, setUserData] = useState<User>()
  const [accepts, setAccepts] = useState<string[]>([])
  const { userCan } = useAuth()

  useEffect(() => {
    if (user === null) return
    setUserData(user)
  }, [user])

  useEffect(() => {
    (async () => {
      const response = await api.get('/accepts')
      setAccepts(response.data)
    })()
  }, [])

  if ((userData === undefined || userData === null) || !('active' in userData)) {
    return <PageLoaderComponent />
  }

  return <>
    <SectionHeaderComponent pretitle="MEU PERFIL" title="Informações de Perfil" />

    <AvatarBanner />

    <Card className="single-card">
      <S.ProfileInfoTitle>Informações Pessoais</S.ProfileInfoTitle>

      <PersonalInfo user={userData} setUser={setUser} />
      {accepts.map((acceptedAt, index) => {
        if (!acceptedAt) return <></>
        return <S.AcceptInfo>
          <span className="label">Data do aceite da onda {index + 1}:</span>
          <span>
            {new Date(acceptedAt).toLocaleString('pt-BR', {
              day: 'numeric',
              month: 'long',
              weekday: 'long',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            })}
          </span>
        </S.AcceptInfo>
      })}
    </Card>

    {userCan('badges:manage') === true && <Card className="single-card">
      <S.ProfileInfoTitle>Missões e Conquistas</S.ProfileInfoTitle>

      <Badges />
    </Card>}

    {userCan('interesses:update') === true && <Card className="single-card">
      <S.ProfileInfoTitle>Interesses</S.ProfileInfoTitle>

      <InterestsInfo />
    </Card>}

    {userCan('educacao:update') === true && <Card className="single-card">
      <S.ProfileInfoTitle>Educação e Trabalho</S.ProfileInfoTitle>

      <ProfessionalInfo />
    </Card>}
  </>
}

export default ProfileInfo
