import styled from 'styled-components'
import containerBG from './assets/container-background.png'

export const SectionKPIs = styled.div`
    background: url(${containerBG});
    background-position: bottom;
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 0px 0px 6px 6px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;

    @media screen and (max-width: 1365px) {
        background-image: none;
    }

    p {
        font-family: 'Roboto', sans-serif;
    }
`

export const SectionKPIsHeader = styled.div`
    width: 100%;
`

export const SectionKPIsContent = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    position: relative;
    background: linear-gradient(180deg, #4F1E70 -4.46%, rgba(79, 30, 112, 0.45) 78.54%);
    top: -1px;
    padding: 0 28px 64px;

    @media screen and (max-width: 1365px) {
        grid-template-columns: 1fr;
    }
`

export const SectionKPIFirstContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -64px;

    @media (max-width: 1365px) {
        margin: 0;
    }
`

export const SectionKPISecondContent = styled.div`
    display: flex;
    margin-top: -64px;

    @media (max-width: 1365px) {
        margin: 0;
    }
`

export const SectionKPIsSecondContentBottomImage = styled.figure`
    margin: 12px 120px 0 auto;
    max-width: fit-content;
    grid-column: span 2;
    max-height: 167px;

    img {
        width: 100%;
    }

    @media screen and (max-width: 1365px) {
        margin-inline: auto 0;
        grid-column: initial;

        img {
            max-width: 200px;
        }
    }
`

export const SectionKPIsSecondContentBottomMobileImage = styled(SectionKPIsSecondContentBottomImage)`
    @media (min-width: 1366px) {
        display: none;
    }
`
