import { ReactElement, useCallback, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import character from '../../assets/img/questionario/character.png'
import PageLoaderComponent from '../../components/PageLoader'
import { useModalMdlz } from '../../contexts/ModalMdlzContext'
import api from '../../services/api'
import Template from '../../template'
import AlternativeCard from './AlternativeCard'
import SurveyStartModal from './SurveyStartModal'
import * as S from './styled'

enum SurveyState {
  LOADING,
  SEM_QUESTIONARIO,
  RESPONDIDO,
  INICIO,
  SUCESSO,
  ERROR
}

interface SurveyInfo {
  currentState: SurveyState
  surveyData: {
    alternatives: Array<{
      id: number
      name: string
      path: string
    }>
  }
}

export default function Questionario (): ReactElement {
  const [state, setState] = useState<SurveyInfo>({
    currentState: SurveyState.LOADING,
    surveyData: {
      alternatives: []
    }
  })
  const { openModal, closeModal } = useModalMdlz()

  const handleSubmitAnswer = useCallback(async (answerId: number) => {
    try {
      await api.post('/survey/answer', {
        alternativeId: answerId
      })
      setState((state) => ({ ...state, currentState: SurveyState.SUCESSO }))
    } catch (err) {
      toast.error('Ocorreu um erro, por favor tente novamente mais tarde.', { theme: 'colored' })
      setState((state) => ({ ...state, currentState: SurveyState.ERROR }))
    }
  }, [])

  useEffect(() => {
    (async () => {
      try {
        setState((state) => ({ ...state, currentState: SurveyState.LOADING }))
        const response = await api.get('/survey')
        if (response.status === 204) {
          setState((state) => ({ ...state, currentState: SurveyState.SEM_QUESTIONARIO }))
          return
        }
        if (response.data.hasAnswer) {
          setState((state) => ({ ...state, currentState: SurveyState.RESPONDIDO }))
          return
        }
        setState({ surveyData: response.data, currentState: SurveyState.INICIO })
        openModal({
          body: <SurveyStartModal onStart={closeModal} />
        })
      } catch (err) {
        toast.error('Ocorreu um erro, por favor tente novamente mais tarde.', { theme: 'colored' })
        setState((state) => ({ ...state, currentState: SurveyState.ERROR }))
      }
    })()
  }, [])

  if (state.currentState === SurveyState.LOADING) return <PageLoaderComponent />
  if (state.currentState === SurveyState.ERROR) return <Navigate to="/home" />

  if (state.currentState === SurveyState.SEM_QUESTIONARIO) {
    return <Template>
      <S.Container>
        <S.NoContent>
          <p>Não temos um questionário ainda</p>
        </S.NoContent>
      </S.Container>
    </Template>
  }

  if (state.currentState === SurveyState.RESPONDIDO) {
    return <Template>
      <S.Container>
        <S.NoContent>
          <p>Você já respondeu este questionário.</p>
          <p>Teremos outro em breve.</p>
        </S.NoContent>
      </S.Container>
    </Template>
  }

  if (state.currentState === SurveyState.SUCESSO) {
    return <Template>
      <S.Container>
        <div>
          <h1>Seu destino é gigante</h1>
        </div>

        <div>
          <S.White>Obrigado por ajudar a escolher o destino da viagem.</S.White>
        </div>

      </S.Container>
    </Template>
  }

  return <S.Template>
    <S.BgContainer>
      <S.Container>
        <div>
          <h1>Seu destino é gigante</h1>

          <p>Considerando a vibe escolhida, qual ideia de destino você curte mais?</p>
        </div>

        <div>
          <S.OptionsContainer>
            <S.AlternativesContainer>
              {state.surveyData.alternatives.map(alternative => <AlternativeCard
                imgUrl={alternative.path}
                text={alternative.name}
                onClick={async () => await handleSubmitAnswer(alternative.id)}
              />)}
            </S.AlternativesContainer>

            <S.Disclaimer>Imagens meramente ilustrativas.</S.Disclaimer>
          </S.OptionsContainer>

          <figure>
            <img src={character} alt="" />
          </figure>
        </div>

      </S.Container>
    </S.BgContainer>
  </S.Template>
}
