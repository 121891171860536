import { AxiosError, AxiosInstance } from 'axios'
import { LoadHubCampanha } from '../../domain/use-case/LoadMyHubCampanha'

export class ApiLoadMyHubCampanha implements LoadHubCampanha {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (params: LoadHubCampanha.Params): Promise<LoadHubCampanha.Result> {
    try {
      const response = await this.api.get(`/hub-campanha/minhas-campanhas/${params.id}`)
      return response
    } catch (err) {
      const axiosError = err as AxiosError
      if (axiosError.response) {
        return axiosError.response
      }

      if (axiosError.request) {
        return {
          data: axiosError.request,
          status: 0
        }
      }

      return {
        data: axiosError.message,
        status: 0
      }
    }
  }
}
