import styled from "styled-components"

export const Grid = styled.div`
    .quest-item {
        height: 100%;
    }

    @media (max-width: 520px) {
        &.grid.grid-3-3-3-3 {
            grid-template-columns: repeat(auto-fit, 284px) !important;
        }
    }
`

export const QuestList = styled.div`
    .table-header {
        display: none;
    }
    @media (max-width: 520px) {        
        &.table.table-quests {
            .table-body .table-column:last-child {
                width: 20px !important;
            }
            .progress-stat-wrap {
                width: 1px !important;
            }
        }
    }
`