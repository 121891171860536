export const shortenNumber = (num: number): string => {
  const thresholds = [
    { suffix: 'M', value: 1_000_000 },
    { suffix: 'K', value: 1_000 }
  ]

  const threshold = thresholds.find(({ value }) => num >= value)

  if (threshold) {
    const { value, suffix } = threshold
    const formatted = (Math.floor(num / (value / 10)) / 10).toFixed(1)

    return formatted.endsWith('.0') ? `${formatted.slice(0, -2)}${suffix}` : `${formatted}${suffix}`
  }

  return num.toString()
}
