import styled from 'styled-components'
import CardComponent from '../../../../components/Card'

export const Card = styled(CardComponent)`
  border-radius: 16px;
`

export const CardContainer = styled.div`
  background-color: var(--white);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  filter: drop-shadow(0 0 20px rgba(94,93,151,0.06));
  flex: 1;
  padding: 24px;

  figure {
    height: 132px;
  }

  p, a {
    color: #3E3F5E;
    margin: 0;
    text-decoration: none;
  }
`

export const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 4rem;
  position: relative;
`

export const PriceContainer = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 0 40px 0 rgba(94,92,154,.06);
  display: flex;
  flex-direction: column;
  font-weight: 700;
  height: 3.25rem;
  padding-inline: 0.5rem;
  position: absolute;
  right: -16px;
  top: -72px;

  p {
    color: #f7cb25;
    font-family: MdlzBiteType;
    font-size: 0.75rem;
    font-weight: 500;
    text-align: center;

    :first-child {
      font-size: 2rem;
      letter-spacing: -1px;
      line-height: 1.75rem;
    }
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  p, a {
    font-weight: 700;
  }
`

export const CategoryLinkContainer = styled.p`
  line-height: 1rem;
  padding-left: 0.875rem;
  position: relative;

  a {
    font-size: 0.875rem;
    line-height: 0.875em;
  }

  &:before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #5e8ef7;
    position: absolute;
    left: 0;
    top: 5px;
    bottom: 0;
    margin: auto 0;
  }
`

export const DescriptionContainer = styled.p`
  &, > * {
    color: #8f91ac !important;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.4285714286em;
  }
`

export const CardFooter = styled.div`
  align-items: center;
  background-color: #fcfcfd;
  border-top: 1px solid #e9e9f4;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  filter: drop-shadow(0 0 20px rgba(94,93,151,0.06));
  height: 56px;
  justify-content: center;
  position: relative;
`
