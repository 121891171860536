import { ChangeEvent, ReactElement, useCallback } from 'react'
import ErrorComponent from '../ErrorComponent'
import InputSelectProps from './InputSelect.props'
import * as S from './style'

export default function InputSelect ({ name, id, label, value, onChange, required, children, large, error, disabled }: InputSelectProps): ReactElement {
  const handleChange = useCallback((evt: ChangeEvent<HTMLSelectElement>) => {
    if (onChange != null) onChange(evt.target.value)
  }, [onChange])

  return <S.FormSelect>
    <label htmlFor={id}>{label}</label>
    <select id={id} name={name} onChange={handleChange} required={required} disabled={disabled}>
      {children}
    </select>
    <S.FormSelectIcon>
      <use href="#svg-small-arrow"></use>
    </S.FormSelectIcon>
    <ErrorComponent>{error}</ErrorComponent>
  </S.FormSelect>
}
