import { ReactElement, useEffect, useMemo, useState } from 'react'
import { TopBannerProps } from './props'
import * as S from './styled'

export default function TopBanner ({ campaings }: TopBannerProps): ReactElement {
  const [slider, setSlider] = useState<number>(0)

  const link = useMemo(() => {
    if (campaings[slider].regulation.accept) {
      return `/hub-de-campanhas/mecanica/${String(campaings[slider].id)}`
    }
    return `/hub-de-campanhas/${String(campaings[slider].id)}`
  }, [campaings, slider])

  useEffect(() => {
    const interval = setInterval(() => {
      const next = (slider + 1) % campaings.length
      setSlider(next)
    }, 5000)
    return () => clearInterval(interval)
  }, [campaings, slider])

  return <S.Container>
    <S.Slider currentSlider={slider}>
      {campaings.map(campaing => <S.Link to={link}>
        <S.BannerContainer aria-label="A Onda 2 vem grandona. E você, maior ainda." style={{ backgroundImage: `url(${String(campaing.banner)})` }}>
        </S.BannerContainer>
      </S.Link>)}
    </S.Slider>
  </S.Container>
}
