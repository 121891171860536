import axios from 'axios'
import { ReactElement, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ChannelComponent from '../../../components/ChannelComponent'
import InputSelect from '../../../components/Forms/InputSelect'
import PageLoader from '../../../components/PageLoader'
import { useAuth } from '../../../contexts/AuthContext'
import api from '../../../services/api'
import Template from '../../../template'
import * as S from '../style'
import RankingGeral from './RankingGeral'

export default function RankingAnual (): ReactElement {
  const [ranking, setRanking] = useState<any>()
  const [channelId, setChannelId] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [cargo, setCargo] = useState('Executivo de Vendas')
  const { doLogout, userCan } = useAuth()
  const [channelName, setChannelName] = useState()
  const [roles, setRoles] = useState<string[]>()

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      try {
        const results = await api.get('/ranking-anual', { params: { channelId, cargo } })
        setRanking(results.data.ranking)
        setChannelName(results.data.channel.name)
        setRoles(results.data.roles)
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response?.data.message === 'Token inválido') {
            doLogout()
            return
          }
        }
        toast.error('Ocorreu um erro inesperado, por favor tente novamente mais tarde!', { theme: 'colored' })
      }
      setIsLoading(false)
    })()
  }, [channelId, cargo])

  if (!ranking) return <PageLoader />
  return <Template>

    <div className="grid medium-space">
      <div className="account-hub-content">

        <div className="grid-column">
          <div className="widget-box">
            <>
              <S.FiltrosCargo className={userCan('ranking-all:read') && 'large'}>
                {userCan('ranking-all:read') && <ChannelComponent onChange={setChannelId} />}
                <InputSelect label="" onChange={setCargo}>
                  <option value="">Selecione um cargo</option>
                  {roles?.map((role: string) => <option key={role} value={role}>{role}</option>)}
                </InputSelect>
              </S.FiltrosCargo>
              {isLoading && <S.Loading>Carregando...</S.Loading>}
              {!isLoading && <RankingGeral channel={channelName} ranking={ranking} myPosition={undefined} />}
            </>
          </div>

        </div>
      </div>
    </div>
  </Template>
}
