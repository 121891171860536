import { Link as LinkComponent } from 'react-router-dom'
import styled from 'styled-components'
import { SliderProps } from './props'

export const Container = styled.div`
  aspect-ratio: 2560 / 902;
  overflow: hidden;
`

export const Slider = styled.div<SliderProps>`
  display: flex;
  height: 100%;
  transition: all .5s ease-in-out;
  transform: translateX(${props => `${props.currentSlider * -100}%`});
`

export const Link = styled(LinkComponent)`
  height: 100%;
  width: 100%;
`

export const BannerContainer = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  aspect-ratio: 2560 / 902;
  flex: 0;
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 72px;
  justify-content: center;
  padding: 24px 24px 0;
  height: 100%;
  gap: 1rem;

  > figure {
    max-width: 33%;
  }
`
