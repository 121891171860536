import styled from 'styled-components'
import TemplateBase from '../../../../../template'

export const Template = styled(TemplateBase)`
  &.content-grid {
    width: initial;
    min-height: initial;
    max-width: initial;
    padding: initial;
  }
`

export const Container = styled.div`
  background-color: #fff;
  width: 100vw;

  .section-header {
    padding: 70px 60px;
    padding-bottom: 20px;

        
    @media (max-width: 575px) {
      padding: 30px 60px;
    }

    .section-title {
      color: #3e3f5e;
    }
  }

  p {
    font-family: Montserrat, sans-serif;
    margin: 0;
  }
`

export const OrderDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 60px 20px 60px;
  min-height: calc(100vh - 350px);
  
  @media (max-width: 575px) {
    padding: 0 20px;
  }

  h2 {
    color: #3e3f5e;
    font-weight: 600;
    font-size: 1.5rem;
  }

  .infos-section {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
      
    @media (max-width: 575px) {
      flex-direction: column;
    }
  }

  .infos-block {
    margin-bottom: 20px;
    background-color: rgba(66, 122, 229, 0.1);
    border-radius: 12px;
    padding: 18px 24px;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
    
    p {
      font-size: 1.05rem;
      line-height: 1.4rem;
      font-weight: 600;
      margin-bottom: 8px;

      span {
        font-weight: 400;
      }
    }
  
  }
`

export const Tacking = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 60px 100px 60px;
    
  @media (max-width: 575px) {
    padding: 0 20px 130px 20px;
  }

  h2 {
    color: #3e3f5e;
    font-weight: 600;
    font-size: 1.5rem;
  }

`
export const DeliveryItem = styled.div`
  width: 100%;
  background-color: rgba(66, 122, 229, 0.1);
  border-radius: 12px;
  padding: 18px 24px;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 36px;

  p {
    font-size: 1.05rem;
    line-height: 1.4rem;
    font-weight: 600;
    margin-bottom: 8px;

    span {
        font-weight: 400;
      }
  }
`

export const DeliveryItemDesc = styled.div`
    font-size: 1.05rem;
    line-height: 1.4rem;
    font-weight: 600;
    margin-bottom: 16px;
    font-weight: bold;
`

export const TackingData = styled.div`
  border-left: 4px solid #ff7219;
  border-radius: 4px;
  background-color: #d1dce982;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
  padding: 8px 16px;
  width: fit-content;
`

export const TrackingDataContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;

  @media (max-width: 575px) {
    grid-template-columns: 1fr;
    gap: 0px;
  }
`

export const TackingDataDate = styled.div`
  font-weight: bold;
`

export const TackingDataDesc = styled.div`

`

export const TrankingDataProducts = styled.table`
  td {
    padding: 4px 8px;
  }

  thead {
    td {
      text-align: center;
      font-weight: bold;
      padding-top: 0;
    }
  }
`
