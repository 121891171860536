import { isAxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ChannelComponent from '../../components/ChannelComponent';
import InputSelect from '../../components/Forms/InputSelect';
import PageLoader from '../../components/PageLoader';
import { useAuth } from '../../contexts/AuthContext';
import { useWorksheetDate } from '../../hooks/useWorksheetDate';
import api from '../../services/api';
import Template from '../../template';
import RankingGeral from './RankingGeral';
import * as S from './style';

function Ranking () {
  const [ranking, setRanking] = useState();
  const [stepId, setOnda] = useState();
  const [channelId, setChannelId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [, setPeriod] = useState('');
  const [cargo, setCargo] = useState('gr');
  const { user, doLogout, userCan } = useAuth();
  const [channelName, setChannelName] = useState();
  const [roles, setRoles] = useState();
  const load = useWorksheetDate();

  const loadPageData = useCallback(async () => {
    setIsLoading(true);
    try {
      const results = await api.get('/ranking', { params: { stepId: stepId || 1, channelId } });
      setRanking(results.data);
      setPeriod(await load(stepId));
      setChannelName(results.data.channel.name);
      setRoles(results.data.roles);
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response.data.message === 'Token inválido') {
          doLogout();
          return;
        }
      }
      toast.error('Ocorreu um erro inesperado, por favor tente novamente mais tarde!', { theme: 'colored' });
    }
    setIsLoading(false);
  }, [doLogout, load, stepId, channelId]);

  const updateOnda = useCallback(async (onda) => {
    setOnda(onda);
  }, []);

  useEffect(() => {
    loadPageData();
  }, [loadPageData]);

  if (!ranking) return <PageLoader />;
  return <Template>

    <div className="grid medium-space">
      <div className="account-hub-content">

        <div className="grid-column">
          <div className="widget-box">
            <>
              <S.FiltrosCargo className={userCan('ranking-all:read') && 'large'}>
                <InputSelect label="Selecione uma onda" onChange={(evt) => updateOnda(evt)} large={true}>
                  <option value="1">Onda 1</option>
                  <option value="2">Onda 2</option>
                  <option value="3">Onda 3</option>
                </InputSelect>
                {userCan('ranking-all:read') && <ChannelComponent onChange={(channelId) => setChannelId(channelId)} />}
                <InputSelect label="" onChange={(cargo) => setCargo(cargo)} large={true}>
                  <option value="">Selecione um cargo</option>
                  {roles && Object.keys(roles).map(key => <option key={key} value={key}>{roles[key]}</option>)}
                </InputSelect>
              </S.FiltrosCargo>
              {!isLoading && <RankingGeral channel={channelName} onda={stepId} ranking={ranking[cargo].ranking} myPosition={ranking[cargo].myPosition} canal={user.canal} />}
              {isLoading && <S.Loading>Carregando...</S.Loading>}
            </>
          </div>

        </div>
      </div>
    </div>
  </Template>;
}

export default Ranking;
