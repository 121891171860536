import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;

  ul {
    list-style: disc inside;
  }

  h1, p {
    text-align: center;
  }

  h1 {
    border-bottom: 6px solid #ffdc39;
    font-family: MdlzBiteType;
    font-size: 48px;
    letter-spacing: -2px;
    color: #ffdc39;
    text-align: center;
    white-space: nowrap;

    @media (max-width: 520px) {
      font-size: 40px;
    }
  }

  p, li {
    color: var(--white);
    font-family: Roboto;
    font-size: 1rem;
    line-height: 1.25rem;
    margin-bottom: 0;
    text-align: left;
  }
`

export const Button = styled.button`
  background-color: #ffdc39;
  border-radius: 2px;
  color: #0e3e8a;
  font-family: MdlzBiteType;
  font-size: 28px;
  font-weight: 400;
  max-width: fit-content;
  padding: 4px 32px;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 520px) {
    font-size: 24px;
  }
`
