import styled from 'styled-components'
import background from '../../assets/img/gallery/bg.png'
import TemplateBase from '../../template'

export const Template = styled(TemplateBase)`
  &.content-grid {
    width: initial;
    min-height: initial;
    max-width: initial;
    padding: initial;
    margin: initial;
  }
`

export const Container = styled.div`
  background-color: #502272;
  background-position: bottom;
  background-size: cover;
  display: flex;
  gap: 32px;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  padding: 212px 32px 0;

  @media (max-width: 1280px) {
    background-image: url(${background});
    gap: 16px;
    padding: 212px 20px 0;
  }
`

export const Title = styled.h1`
  color: var(--white);
  font-family: MdlzBiteType;
  font-size: 32px;

  @media (max-width: 1280px) {
    font-size: 14px;
  }
`

export const VideoListContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-bottom: 32px;

  @media (max-width: 1280px) {
    gap: 8px;
  }

  @media (max-width: 520px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const VideoContainer = styled.div`
  aspect-ratio: 16 / 9;
  position: relative;

  & > video {
    height: 100%;
    width: 100%;
  }
`

export const VideoStatus = styled.figure`
  position: absolute;
  left: -2%;
  top: -16%;
  width: fit-content;
`
