import styled from 'styled-components'
import TemplateBase from '../../template'

export const Template = styled(TemplateBase)`
  &.content-grid {
    width: initial;
    max-width: initial;
    padding: initial;

    display: flex;
    min-height: 100vh;
    width: 100%;
    position: relative;

    @media (max-width: 520px) {
      margin-top: 50px;
    }
  }
`

export const Ameba = styled.figure`
  bottom: -6.8%;
  position: absolute;
  max-width: 40%;
  right: -8.8%;

  img {
    width: 100%;
  }

  @media (max-width: 1366px) {
    max-width: 40%;
  }
  `

export const Container = styled.div`
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 28px 136px;
  z-index: 0;
`
