import { AxiosError, AxiosInstance } from 'axios'
import { AdminUploadImage } from '../../domain/use-case/AdminUploadImage'

export class ApiAdminUploadImage implements AdminUploadImage {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (params: AdminUploadImage.Params): Promise<AdminUploadImage.Result> {
    try {
      const formData = new FormData()
      params.files.map(image => formData.append('image', image))
      const response = await this.api.post('/admin-upload/many', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (e) => { }
      })
      return response
    } catch (error) {
      const axiosError = error as AxiosError
      return {
        data: axiosError.response?.data ?? axiosError.name,
        status: axiosError.response?.status ?? 0
      }
    }
  }
}
