import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  max-width: 450px;
  padding-inline: 28px;

  h1 {
    color: #284087;
    font-family: MdlzBiteType;
    font-size: 28px;
    letter-spacing: 1px;
    line-height: 35px;
    text-align: center;
  }

  p {
    color: #2f2f2f;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    max-width: 324px;
    text-align: center;
  }

  figure {
    max-width: fit-content;

    img {
      width: 100%;
    }
  }
`

export const GoBackButton = styled.button`
  background-color: #fecc00;
  border-radius: 6px;
  color: #284087;
  font-family: MdlzBiteType;
  font-size: 18px;
  font-weight: 500;
  height: 36px;
  letter-spacing: 0px;
  padding-inline: 20px;
  max-width: 240px;
  text-align: center;

  &:focus {
    outline: none;
  }
`
