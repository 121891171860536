import { ReactElement, useCallback, useEffect, useState } from 'react'
import Dropzone from '../../../../../components/Dropzone'
import { Progress } from '../../../../../components/Progress'
import { ComponentState } from '../../../../../data/enums/ComponentState'
import { makeLoadRoles } from '../../../../../main/factories/makeLoadRoles'
import { State, StepsProps } from '../props'
import * as S from '../style'

const loadRoles = makeLoadRoles()

interface ComponentData {
  roles: Array<{ id: number, name: string }>
}

export default function StepTwo ({ errors, uploadImage, setState, state }: StepsProps): ReactElement {
  const [progressValue, setProgressValue] = useState(0)
  const [localState, setLocalState] = useState<ComponentState>(ComponentState.LOADING)
  const [data, setData] = useState<ComponentData>({} as unknown as ComponentData)

  const onDropMechanics = useCallback(async (files: File[]) => {
    const response = await uploadImage.handle({ files })

    setState((prev) => ({ ...prev, mechanics: response.data.image.map((image: any) => image.path) }))
    handleProgress()
  }, [])

  // const onDropTarget = useCallback(async (acceptedFiles: File[]) => {
  //   const response = await uploadImage.handle({ files: acceptedFiles })

  //   setState((prev) => ({ ...prev, target: response.data.target }))
  //   handleProgress()
  // }, [])

  const handleProgress = async (): Promise<void> => {
    setProgressValue(0)
    const totalSteps = 10
    const delay = 100

    for (let i = 1; i <= totalSteps; i++) {
      await new Promise((resolve) => setTimeout(resolve, delay))
      const newProgress = Math.round((i / totalSteps) * 100)
      setProgressValue(newProgress)
    }
  }

  const handleChangeRole = useCallback((evt: any) => {
    const value = Number.parseInt(evt.target.value)
    if (evt.target.checked) {
      const roles = [...state.roles, value]
      setState((state) => ({ ...state, roles }))
      return
    }
    const roles = [...state.roles.filter(role => role !== value)]
    setState((state) => ({ ...state, roles }))
  }, [state])

  useEffect(() => {
    (async () => {
      setLocalState(ComponentState.LOADING)
      const response = await loadRoles.handle()
      setData({ roles: response.roles })
      setLocalState(ComponentState.READY)
    })()
  }, [])

  if (localState === ComponentState.LOADING) return <>Carregando...</>

  return <>
    <S.BannerContainer>
      <S.MainBannerLabel>Mecânica</S.MainBannerLabel>

      <S.ProgressContainer>
        {progressValue > 0 && <Progress color="#615dfa" progress={progressValue} width={1} />}
      </S.ProgressContainer>

      <Dropzone
        mimeTypes={{ 'image/*': [] }}
        handleOnDrop={onDropMechanics}
        maxFiles={100}
      >
        <S.ErrorMessage>{errors.mechanics}</S.ErrorMessage>
      </Dropzone>
    </S.BannerContainer>

    <S.BannerContainer>
      <S.MainBannerLabel>Usuários Público-alvo</S.MainBannerLabel>

      <S.CheckboxGrid>
        {data.roles.map(role => <S.CheckLabel>
          <input type="checkbox" value={role.id} onChange={handleChangeRole} />
          {role.name}
        </S.CheckLabel>
        )}
      </S.CheckboxGrid>

    </S.BannerContainer>

    {/* <S.BannerContainer>
      <S.MainBannerLabel>Resultados</S.MainBannerLabel>

      <S.ProgressContainer>
        {progressValue > 0 && <Progress color="#615dfa" progress={progressValue} width={1} />}
      </S.ProgressContainer>

      <Dropzone
        mimeTypes={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] }}
        handleOnDrop={onDropResults}
      />
    </S.BannerContainer> */}

    <S.ButtonsContainer>
      <button className="primary button" onClick={() => setState((prev) => ({ ...prev, currentState: State.STEP_ONE }))}>Voltar</button>
      <button type="submit" className="primary button" disabled={errors.hasError}>Salvar</button>
    </S.ButtonsContainer>
  </>
}
