import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 132px 28px 0;
  width: 100%;

  @media (max-width: 500px) {
    padding: 0 28px 50px 28px;
  }

  @media (max-width: 500px) {
    padding: 100px 28px 55%;
  }
`

export const GiroContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 4px;

  h1, p {
    font-family: MdlzBiteType;
    margin: 0;
    text-align: center;
  }

  button {
    background-color: #e9c300;
    color: #182b8c;
    font-size: 24px;
    line-height: 20px;
    height: 28px;
    width: 60%;
  }

  @media (max-width: 500px) {
    button {
      font-size: 16px;
    }
  }
`

export const GiroTitleContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  justify-content: center;

  figure {
    margin: 0;
  }

  h1 {
    color: var(--white);
    font-size: 72px;
    line-height: 76px;

    span {
      color: #e9c300;
    }

    br {
      content: "";
      display: block;
      margin: -16px;
    }
  }

  @media (max-width: 500px) {
    figure {
      max-width: 20%;
    }

    h1 {
      font-size: 56px;
      line-height: 60px;

      br {
        margin: -12px;
      }
    }
  }
`

export const GiroContentContainer = styled.div`
  background-color: var(--white);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  filter: drop-shadow(0px 3px 5px rgba(27,27,29,0.16));
  position: relative;

  > figure {
    margin: 0;

    &:last-of-type {
      &, img {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
  }

  p, a {
    color: #3E3F5E;
    font-size: 8px;
    font-weight: 700;
    margin: 0;
    text-decoration: none;
  }

  @media (max-width: 1280px) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    > figure {
      &:last-of-type {
        &, img {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }
    }
  }
`

export const GiroBoy = styled.figure`
  left: 4%;
  position: absolute;
  top: -8%;
`

export const GiroCoins = styled.figure`
  position: absolute;
  right: 8%;
  top: -6%;
`

export const GiroRoulette = styled.figure`
  bottom: 0;
  position: absolute;
  right: 0;
`

export const Logo = styled.figure`
  bottom: 10%;
  position: absolute;
  right: 19%;
`

export const ModalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;

  ul {
    list-style: disc inside;
  }

  h1, p {
    text-align: center;
  }

  h1 {
    border-bottom: 6px solid #ffdc39;
    font-family: MdlzBiteType;
    font-size: 48px;
    letter-spacing: -2px;
    color: #ffdc39;
    text-align: center;
    white-space: nowrap;

    @media (max-width: 520px) {
      font-size: 40px;
    }
  }

  p, li {
    color: var(--white);
    font-family: Roboto;
    font-size: 16px;
  }
`

export const ModalButton = styled.button`
  background-color: #ffdc39;
  border-radius: 2px;
  color: #0e3e8a;
  font-family: MdlzBiteType;
  font-size: 28px;
  font-weight: 400;
  max-width: fit-content;
  padding: 4px 32px;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 520px) {
    font-size: 24px;
  }
`
