import styled, { css } from 'styled-components'

export const Container = styled.div`
  align-items: center;
  background-image: radial-gradient(circle at center, rgba(0,137,205,0.9999999999999999) 0%, rgba(0,137,205,0) 100%);
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 100vh;
  min-width: 100vw;
  padding: 100px 32px;
  position: relative;

  h1 {
    color: #e9c300;
    font-size: 40px;
    letter-spacing: 0px;
    margin-bottom: 16px;
    text-align: center;
  }
`

export const LateralImage = styled.figure`
  position: absolute;
  right: 4.5%;
  max-width: fit-content;
  top: -4%;

  img {
    width: 100%;
  }
`

export const QuestionContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 0;
`

export const QuestionBubble = styled.div`
  align-items: center;
  aspect-ratio: 755 / 120;
  background-color: var(--white);
  background-size: contain;
  border-radius: 20px;
  margin-bottom: 32px;
  display: flex;
  width: 100%;

  @media (max-width: 500px) {
    margin: 16px;
    padding: 16px;
  }
`

export const QuestionContent = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  height: 84%;
  max-width: 1024px;
  padding-inline: 56px;
  width: 100%;

  p {
    color: #002874;
    font-size: 28px;
    letter-spacing: 1px;
    text-align: center;
  }

  @media (max-width: 764px) {
    p {
      font-size: 20px;
    }
  }

  @media (max-width: 630px) {
    margin-bottom: 6%;

    p {
      font-size: 16px;
    }
  }

  @media (max-width: 580px) {
    padding-inline: 0px;

    p {
      font-size: 12px;
      margin: 4% 0 0;
    }
  }

  @media (max-width: 400px) {
    p {
      font-size: 10px;
      margin-top: 8%;
    }
  }
`

export const BottomContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  position: relative;
  max-width: 755px;
  width: 100%;

  > div {
    align-items: center;
    display: flex;
    gap: 16px;
    flex: 1;

    @media (max-width: 764px) {
      flex-direction: column;
    }

    @media (max-width: 630px) {
      margin-left: 30%;
    }
  }
`

export const Character = styled.figure`
  max-width: 152px;

  img {
    max-width: 250px;
    height: fit-content;
    left: 0;
    position: absolute;
    top: -10%;
    width: 100%;
  }

  @media (max-width: 764px) {
    max-width: 24%;

    img {
      width: 40%;
    }
  }
`

export const AlternativesContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const AlternativeContainer = styled.div<AnswerCheck>`
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  height: 100%;
  min-height: 65px;
  max-width: 381px;
  position: relative;
  width: 100%;

  ${({ $correct, $selected }) => !$selected ? alternativeIndexContainerWait : ($correct ? alternativeIndexContainerCorrect : alternativeIndexContainerWrong)}

  &:hover {
    figure.hover-egg {
      display: initial;
    }
  }
`

export const HoverImage = styled.figure.attrs((props) => {
  return {
    ...props,
    className: 'hover-egg'
  }
})`
  bottom: 4%;
  display: none;
  position: absolute;
  right: -4%;
`

interface AnswerCheck {
  $correct: boolean
  $selected?: boolean
}

const alternativeIndexContainerWait = css`
  background-color: #266bd5;
  p {
    color: var(--white);
  }
`

const alternativeIndexContainerCorrect = css`
  background-color: var(--white);
  p {
    color: #4c2072;
  }
`

const alternativeIndexContainerWrong = css`
  background-color: var(--white);
  p {
    color: #266bd5;
  }
`

const alternativeContentWait = css`
  background-color: var(--white);

  p {
    color: #002874;
  }
`

const alternativeContentWrong = css`
  background-color: #a31313;

  p {
    color: var(--white);
  }
`

const alternativeContentCorrect = css`
  background-color: #4c2072;

  p {
    color: var(--white);
  }
`

export const AlternativeIndexContainer = styled.div<AnswerCheck>`
  align-items: center;
  border-radius: 20px 0 0 20px;
  display: flex;
  height: 100%;
  min-height: 65px;
  justify-content: center;
  width: 20%;

  p {
    font-size: 32px;
    letter-spacing: 0;
    text-align: center;
  }

  ${({ $correct, $selected }) => !$selected ? alternativeIndexContainerWait : ($correct ? alternativeIndexContainerCorrect : alternativeIndexContainerWrong)}

  @media (max-width: 764px) {
    padding-inline: 20px;

    p {
      font-size: 24px;
    }
  }
`

export const AlternativeContent = styled.div<AnswerCheck>`
  align-items: center;
  border-radius: 0 20px 20px 0;
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 65px;
  padding: 8px 32px 8px 48px;
  width: 100%;

  p {
    font-size: 18px;
    letter-spacing: 1px;
    text-align: center;
  }

  ${({ $correct, $selected }) => !$selected ? alternativeContentWait : ($correct ? alternativeContentCorrect : alternativeContentWrong)}

  @media (max-width: 764px) {
    padding-inline: 20px;

    p {
      font-size: 16px;
    }
  }

  @media (max-width: 630px) {
    p {
      font-size: 14px;
    }
  }

  @media (max-width: 580px) {
    p {
      font-size: 12px;
    }
  }

  @media (max-width: 400px) {
    p {
      font-size: 10px;
    }
  }
`

export const CorrectAnswerImage = styled.figure<AnswerCheck>`
  display: ${({ $correct }) => $correct ? 'initial' : 'none'};
  bottom: -60%;
  position: absolute;
  right: -6%;
  z-index: -1;
`

export const CountdownContainer = styled.div`
  background-color: #e9c300;
  border-radius: 12px 12px 2px 2px;
  max-width: 146px;
  padding: 20px 0 0;
  width: 100%;

  div.countdown {
    flex-direction: column;

    p.title {
      color: #47266e;
      font-size: 30px;
      line-height: 25px;
      text-align: center;
    }

    > div {
      gap: 4px;
    }

    div.number-container {
      border-radius: 4px;
      height: 30px;
      width: 24px;

      p {
        font-size: 28px;
        letter-spacing: 0;
        color: #47266e;
      }
    }
  }
`

export const DigimoedasContainer = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  padding: 8px 0;
  position: relative;
  max-width: 300px;
  margin: 16px 0 0;
  white-space: nowrap;
  width: 100%;

  p {
    color: #4f2170;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 12px;
  }

  figure {
    bottom: -10%;
    max-width: 40px;
    margin: 0;
    position: absolute;
    right: -16%;
  }

  @media (max-width: 375px) {
    p {
      font-size: 16px;
      line-height: 12px;
    }

    figure {
      max-width: 32px;
    }
  }
`
