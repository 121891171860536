import api from '../../../../services/api'
import { GameState } from '../../Data/Entities/GameState'
import { GameStatus } from '../../Data/Entities/GameStatus'

export class StartNewGame {
  async handle (): Promise<GameStatus> {
    const request = await api.get('/games/memory')
    const newGameState: GameStatus = {
      ...request.data,
      gameState: request.data.MemoryGameWin.length > 0 ? GameState.WIN : GameState.PLAY
    }
    return newGameState
  }
}
