import { ReactElement, useEffect, useState } from 'react'
import { adjustHorizontal } from '../helpers'
import PaddleProps from './props'
import * as S from './styled'

export default function Paddle ({ containerRef, onDrag, x }: PaddleProps): ReactElement {
  const [isDragging, setIsDragging] = useState(false)
  const paddleWidth = adjustHorizontal(167)

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent): void => {
      if (isDragging && containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect()
        let newX = e.clientX - containerRect.left - (paddleWidth / 2)
        newX = Math.max(0, Math.min(newX, containerRect.width - paddleWidth))
        onDrag(newX)
      }
    }

    const handleMouseUp = (): void => {
      if (isDragging) {
        setIsDragging(false)
      }
    }

    const handleTouchMove = (e: TouchEvent): void => {
      if (isDragging && containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect()
        let newX = e.touches[0].clientX - containerRect.left - (paddleWidth / 2)
        newX = Math.max(0, Math.min(newX, containerRect.width - paddleWidth))
        onDrag(newX)
      }
    }

    const handleTouchEnd = (): void => {
      if (isDragging) {
        setIsDragging(false)
      }
    }

    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)
    window.addEventListener('touchmove', handleTouchMove)
    window.addEventListener('touchend', handleTouchEnd)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
      window.removeEventListener('touchmove', handleTouchMove)
      window.removeEventListener('touchend', handleTouchEnd)
    }
  }, [isDragging, onDrag])

  return (
    <S.Paddle
      $x={x}
      $width={paddleWidth}
      onMouseDown={() => setIsDragging(true)}
      onTouchStart={() => setIsDragging(true)}
    />
  )
}
