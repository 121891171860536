import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState
} from 'react'
import Modal from 'react-modal'
import close from '../../assets/img/incentivo-2024/mechanics/pascoa/close.png'
import * as S from './styled'

interface IModalMdlzContextValue {
  openModal: (config: IModalMdlzConfig) => void
  closeModal: () => void
}
interface IModalMdlzConfig {
  header?: string
  body?: string | JSX.Element | null
  size?: string | any
  goTo?: string
  hasCloseButton?: boolean
  customStyles?: Modal.Styles['content']
  useClickOutside?: boolean
}
interface ContextProps {
  children?: ReactNode
}
const ModalSize: any = {
  small: '25%',
  medium: '50%',
  large: '70%',
  fit: 'fit-content'
}

const ModalMdlzContext = createContext<IModalMdlzContextValue | null>(null)

export default function ModalMdlzProvider ({ children }: ContextProps): JSX.Element {
  const [modalIsOpen, setIsOpen] = useState(false)
  // const [header, setHeader] = useState('')
  const [body, setBody] = useState<string | null | JSX.Element>('')
  const [size, setSize] = useState<'small' | 'medium' | 'large' | 'fit'>('small')
  const [goTo, setGoTo] = useState<string | undefined>()
  const [hasCloseButton, setHasCloseButton] = useState<boolean>()
  const [styles, setStyles] = useState<Modal.Styles['content']>({})
  const modalRef = useRef<HTMLDivElement | null>(null)
  const modalContentRef = useRef<HTMLDivElement | null>(null)

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      modalContentRef.current !== null && modalContentRef.current !== undefined &&
      !modalContentRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const closeModal = useCallback((callback?: () => void) => {
    setIsOpen(false)
    document.removeEventListener('mousedown', handleClickOutside)

    if (callback !== null && callback !== undefined && modalRef.current !== null && modalRef.current !== undefined) {
      modalRef.current.addEventListener('animationend', callback, {
        once: true
      })
    }

    if (goTo) window.location.href = goTo
  }, [handleClickOutside, goTo])

  const openModal = useCallback(({ header, body, size, goTo, hasCloseButton = true, customStyles, useClickOutside = true }: IModalMdlzConfig) => {
    // Modal.setAppElement('#root')
    setIsOpen(true)
    // setHeader(header ?? '')
    setBody(body ?? null)
    setSize(size === null || size === undefined ? 'small' : size)
    setGoTo(goTo)
    setHasCloseButton(hasCloseButton)
    setStyles(customStyles)
    useClickOutside && document.addEventListener('mousedown', handleClickOutside)
  }, [handleClickOutside])

  const modalContextValue: IModalMdlzContextValue = {
    openModal,
    closeModal
  }
  return (
    <>
      <ModalMdlzContext.Provider value={modalContextValue}>
        {children}
        <S.CustomModal
          isOpen={modalIsOpen}
          onRequestClose={() => closeModal}
          size={ModalSize[size]}
          style={{
            content: styles,
            overlay:
            {
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
              zIndex: '1000'
            }
          }}
        >
          {/* <S.Header>{header}</S.Header>
          <hr /> */}
          <S.Body>{body}</S.Body>
          {hasCloseButton && <S.ButtonClose onClick={() => closeModal()}>
            <img src={close} width={16} />
          </S.ButtonClose>}
        </S.CustomModal>
      </ModalMdlzContext.Provider>
    </>
  )
}

export function useModalMdlz (): IModalMdlzContextValue {
  const context = useContext(ModalMdlzContext)

  if (context == null) throw new Error('useModalMdlz must be used within a ModalMdlzProvider')

  return context
}
