import { ReactElement } from 'react'
import * as S from './styled'

export function Failure (): ReactElement {
  return <S.ModalContainer>
    <S.ModalBody>
      <h1>ERRO</h1>
      <p>Ocorreu um erro inesperado<br />Por favor tente novamente mais tarde</p>
    </S.ModalBody>
  </S.ModalContainer>
}
