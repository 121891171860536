import { ReactElement } from 'react'
import safe from '../../assets/img/incentivo-2024/leilao/COFRE.png'
import * as S from './styled'

export interface DigimoedasCountContainerProps {
  className?: string
  title: string
  coins: number
}

export default function CoinsContainer ({ className, title, coins }: DigimoedasCountContainerProps): ReactElement {
  return <S.DigimoedasCountContainer className={className}>
    <S.DigimoedasCountContent>
      <p>{title}</p>

      <div>
        <p>{coins.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
        <p>digimoedas</p>
      </div>
    </S.DigimoedasCountContent>

    <S.SafeContainer>
      <img src={safe} />
    </S.SafeContainer>
  </S.DigimoedasCountContainer>
}
