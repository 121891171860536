import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import * as S from './styled'

export default function TopBanner (): ReactElement {
  return <Link to="">
    <S.Container aria-label="A Onda 2 vem grandona. E você, maior ainda.">
      <S.Content>
        <h1>Maior que essa <span>Onda 3</span> <br/>só o seu talento.<br/> Bora!</h1>
      </S.Content>
    </S.Container>
  </Link>
}
