import { AxiosInstance } from 'axios'
import { ApproveOrder } from '../../domain/use-case/ApproveOrder'

export class ApiApproveOrder implements ApproveOrder {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (orderId: number): Promise<void> {
    await this.api.patch(`/order/${orderId}/approve`)
  }
}
