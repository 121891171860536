import { ReactElement } from 'react'
import logo from './assets/logo.png'
import * as S from './styled'

interface TopContainerProps {
  className?: string
}

function TopContainer ({ className }: TopContainerProps): ReactElement {
  return <S.TopContainer className={className}>
    <S.TopCircle />

    <S.Link to="/picpay">
      <S.Logo>
        <p>Transferência</p>
        <img src={logo} alt="" />
      </S.Logo>
    </S.Link>
  </S.TopContainer>
}

export default TopContainer
