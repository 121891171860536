import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from "../../../contexts/AuthContext"
import api from '../../../services/api'
import Template from "../../../template"
import * as S from './style'

function RankingSelectorComponent () {
  const navigate = useNavigate()
  const [step, setStep] = useState(0)
  const [steps, setSteps] = useState()
  const [worksheets, setWorksheets] = useState([])
  const { userCan } = useAuth()

  const loadWorksheets = useCallback(async () => {
    const response = await api.get(`/worksheets`)
    setSteps(response.data.steps)
    setWorksheets(response.data.steps[step].worksheets)
  }, [step])

  const changeStep = useCallback((step) => {
    setWorksheets([])
    setStep(step)
    setWorksheets(steps[step].worksheets)
  }, [steps])

  useEffect(() => {
    loadWorksheets()
  }, [loadWorksheets])

  if (!userCan('ranking-all:read')) {
    navigate('/ranking')
    return <>Você não pode acessar essa área</>
  }

  if (!steps) return <>...</>

  return <Template>
    <S.Container>
      <S.Tabs step={step}>
        {steps && steps.map((step, index) => <li onClick={() => changeStep(index)}>Onda {step.name}</li>)}
      </S.Tabs>
      <S.Content>
        {worksheets.map(c => <S.Card className={c.active ? 'ativo' : ''}>
          <p><strong>Planilha:</strong> {c.name}</p>
          <p><strong>Canal: {c.channel ? c.channel.name : 'NA'}</strong></p>
          <p>Periodo: {c.period}</p>
          <p>Tipo: {c.type}</p>
          <S.Link to={`/ranking/${c.id}`}>Ver ranking</S.Link>
        </S.Card>)}
      </S.Content>
    </S.Container>
  </Template >
}

export default RankingSelectorComponent
