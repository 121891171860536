import { ReactElement } from 'react'
import PicPayModalProps from '../props'
import character from './assets/character.png'
import * as S from './style'

export default function ErrorModal ({ dismiss }: Omit<PicPayModalProps, 'confirm'>): ReactElement {
  return <S.Container>
    <figure>
      <img src={character} alt="" />
    </figure>

    <h1>
      Ops,
      <br/>
      algo deu errado!
    </h1>

    <p>
      Falha ao enviar. <br/>Por favor, confira os seus dados e tente novamente!
    </p>

    <S.GoBackButton onClick={dismiss}>Voltar</S.GoBackButton>
  </S.Container>
}
