import styled from 'styled-components'
import descriptionBg from '../../../assets/img/hub/mecanicas-extras/bubble.png'
import CardComponent from '../../../components/Card'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const Card = styled(CardComponent)`
  background-color: #fff;
  border-radius: 16px;
  filter: drop-shadow(0px 3px 5px rgba(27,27,29,0.16));
  padding: 0;
  position: relative;
  min-width: 500px;

  > figure {
    margin: 0;

    &:last-of-type {
      bottom: -14%;
      position: absolute;
      right: -2%;
      max-width: 100%;
    }

    &:first-of-type {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      height: 120px;
      display: flex;
      position: relative;

      h1 {
        bottom: 0;
        color: #561a74;
        font-size: 70px;
        height: fit-content;
        left: 6%;
        margin: auto 0;
        position: absolute;
        top: 0;
      }
    }
  }

  @media (max-width: 1280px) {
    border-radius: 10px;
    min-width: 308px;

    > figure {
      &:last-of-type {
        right: -1%;
        max-width: 54%;
      }

      &:first-of-type {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 74px;

        h1 {
          font-size: 40px;
        }
      }
    }
  }
`

export const ContentContainer = styled.div`
  display: flex;
  height: 284px;
  width: 64%;

  @media (max-width: 1280px) {
    height: initial;
  }
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  width: 100%;

  h1, h2, p {
    color: #561a74;
    font-family: MdlzBiteType;
    margin: 0;
    text-align: center;
  }

  > div:first-of-type p {
    font-size: 16px;
  }

  > p {
    font-size: 12px;
    letter-spacing: 0;
    width: 80%;
  }

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    > div:first-of-type p {
      font-size: 26px;
      letter-spacing: -1px;
    }

    > p {
      font-size: 18px;
      letter-spacing: 0;
      line-height: 16px;
    }
  }
`

export const DescriptionContainer = styled.div`
  align-items: center;
  aspect-ratio: 1.816;
  background-image: url(${descriptionBg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  padding: 16px;
  width: 100%;
`

export const DescriptionTitle = styled.h2`
  font-size: 24px !important;
  letter-spacing: 0;
  color: #4f2170;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    font-size: 40px !important;
  }
`

export const TextContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 2;
  gap: 8px;
  margin-inline: 16px;

  > div {
    &:first-child {
      flex: 60%;
    }

    &:last-child {
      flex: 40%;
    }
  }
`

export const Subtitle = styled.p`
  color: #4f2170;
  font-size: 20px;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;

  span {
    font-size: 16px;
  }

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    font-size: 32px;

    span {
      font-size: 24px !important;
    }
  }
`
export const SubtitleMerchan = styled.div`
  margin-bottom: 8px;
  & > p {
    color: #4f2170;
    font-size: 12px;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;

    span {
      font-size: 16px;
    }

    @media (min-width: 1280px) {
      font-size: 12px;

      span {
        font-size: 16px !important;
      }
    }
  }
`

export const Text = styled.p`
  font-size: 4px !important;
  letter-spacing: 0px;
  color: #4f2170;
  font-weight: 400;
  font-family: Roboto;
  margin: 0;
  text-align: center;

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    font-size: 8px !important;
  }
`

export const Divider = styled.hr`
  border-left: 2px solid #fff;
  height: 100%;
`

export const RulesButton = styled.button`
  background-color: #ffdc39;
  border-radius: 16px;
  color: #4f2170;
  margin-top: 20px !important;
  padding: 8px 32px;
  width: fit-content;
  z-index: 0;

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    padding: 4px 24px;
  }
`

export const ModalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;

  h1 {
    font-family: MdlzBiteType;
  }
  `

export const ModalTitle = styled.h1`
  border-bottom: 6px solid #ffdc39;
  font-size: clamp(3rem, 2.8388rem + 0.7286vw, 4rem);
  letter-spacing: -2px;
  color: #ffdc39;
  text-align: center;
  white-space: nowrap;

  @media (max-width: 520px) {
    font-size: 2.5rem;
  }
`

export const ModalList = styled.ol`
  color: #fff;
  font-family: Roboto;
  font-size: clamp(1rem, 0.7116rem + 0.3378vw, 1.25rem);
  letter-spacing: -1px;
  list-style: decimal;
  list-style-position: inside;
  padding-inline: 24px;
  text-align: center;

  li {
    margin-inline: 0;

    & + li {
      margin-top: 16px;
    }
  }
`
