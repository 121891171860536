import { ReactElement } from 'react'
import * as S from './styled'

export function Done (): ReactElement {
  return <S.ModalContainer>
    <S.ModalBody>
      <h1>COMPLETO</h1>
      <p>Você já enviou<br /> os vídeos desta<br /> semana.</p>
    </S.ModalBody>
  </S.ModalContainer>
}
