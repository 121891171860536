import { ReactElement, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import bgAtivacoesHover from '../../assets/img/incentivo-2024/home/ativacoes-hover.png'
import bgAtivacoes from '../../assets/img/incentivo-2024/home/ativacoes.png'
import bgRanking from '../../assets/img/incentivo-2024/home/btn-ranking.png'
import bgDestaque from '../../assets/img/incentivo-2024/home/destaque.png'
import bgDestaqueHover from '../../assets/img/incentivo-2024/home/destque-hover.png'
import bgMecanicasHover from '../../assets/img/incentivo-2024/home/mecanica-hover.png'
import bgMecanicas from '../../assets/img/incentivo-2024/home/mecanica.png'
import bgNoticiasHover from '../../assets/img/incentivo-2024/home/noticias-hover.png'
import bgNoticias from '../../assets/img/incentivo-2024/home/noticias2.png'
import bgPremioHover from '../../assets/img/incentivo-2024/home/premio-hover.png'
import bgPremio from '../../assets/img/incentivo-2024/home/premio.png'
import bgRankingHover from '../../assets/img/incentivo-2024/home/ranking-hover.png'
import ativacoes from '../../assets/img/mobile-2024/mobile-ativacao.png'
import ganhadores from '../../assets/img/mobile-2024/mobile-ganhadores.png'
import home from '../../assets/img/mobile-2024/mobile-home.png'
import mecanicas from '../../assets/img/mobile-2024/mobile-mecanicas.png'
import noticias from '../../assets/img/mobile-2024/mobile-notícias.png'
import premios from '../../assets/img/mobile-2024/mobile-premios.png'
import ranking from '../../assets/img/mobile-2024/mobile-ranking.png'
import { ArrowComponent } from '../../components/ArrowComponent'
import FooterComponent from '../../components/FooterComponent'
import HeaderComponent from '../../components/HeaderComponent'
import PageLoaderComponent from '../../components/PageLoader'
import { useAuth } from '../../contexts/AuthContext'
import * as S from './style'

export default function Home (): ReactElement {
  const { user, userCan } = useAuth()
  const { hash } = useLocation()

  useEffect(() => {
    if (!hash) return
    const element = document.querySelector(hash) as HTMLDivElement
    if (!element) return
    window.scrollTo({ top: element.offsetTop - 60 })
  }, [hash])

  return user == null
    ? <PageLoaderComponent />
    : <S.HomeContainer>
      <HeaderComponent />

      <S.Hidden>
        <S.LinksContainer left={745} top={902}>
          <S.Button left={1460} top={814} width={550} height={400} normal={bgRanking} hover={bgRankingHover} to='/ranking' />
          {userCan('read:winners') && <S.Button left={1440} top={259} width={492} height={578} normal={bgDestaque} hover={bgDestaqueHover} to='/ganhadores' />}
          {!userCan('read:winners') && <S.Button left={1440} top={259} width={492} height={578} normal={bgDestaque} hover={bgDestaqueHover} to='/em-breve' />}
          {userCan('read:marketplace') && <S.Button left={882} top={616} width={466} height={466} normal={bgPremio} hover={bgPremioHover} to='/marketplace' />}
          {!userCan('read:marketplace') && <S.Button left={882} top={616} width={466} height={466} normal={bgPremio} hover={bgPremioHover} to='/em-breve' />}
          <S.Button left={613} top={775} width={356} height={394} normal={bgMecanicas} hover={bgMecanicasHover} to='/mecanicas' />
          <S.Button left={701} top={1030} width={496} height={372} normal={bgAtivacoes} hover={bgAtivacoesHover} to='/hub' />
          <S.Button left={1239} top={1021} width={340} height={370} normal={bgNoticias} hover={bgNoticiasHover} to='/newsfeed' />
        </S.LinksContainer>
      </S.Hidden>

      <S.HiddenMobile>
        <Link id="home" to="/home"><img src={home} alt="Home" /></Link>
        <Link id="noticias" to="/newsfeed"><img src={noticias} alt="Noticias" /></Link>
        <Link id="mecanicas" to="/mecanicas"><img src={mecanicas} alt="Mecanicas" /></Link>
        <Link id="ativacoes" to="/hub"><img src={ativacoes} alt="Ativacoes" /></Link>
        <Link id="ranking" to="/ranking"><img src={ranking} alt="Ranking" /></Link>
        {userCan('read:winners') && <Link id="ganhadores" to="/ganhadores"><img src={ganhadores} alt="Ganhadores" /></Link>}
        {!userCan('read:winners') && <Link id="ganhadores" to="/em-breve"><img src={ganhadores} alt="Ganhadores" /></Link>}
        {userCan('read:marketplace') && <Link id="premios" to="/marketplace"><img src={premios} alt="Premios" /></Link>}
        {!userCan('read:marketplace') && <Link id="premios" to="/em-breve"><img src={premios} alt="Premios" /></Link>}
      </S.HiddenMobile>

      <FooterComponent />

      <ArrowComponent />
    </S.HomeContainer>
}
