import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import Dropzone from '../../../components/Dropzone'
import InputText from '../../../components/Forms/InputText'
import PageLoader from '../../../components/PageLoader'
import { Progress } from '../../../components/Progress'
import { useModal } from '../../../contexts/ModalContext'
import api from '../../../services/api'
import Template from '../../../template'
import * as S from './style'

function AdminMarketplaceBanner (): ReactElement {
  const [banners, setBanners] = useState<any>()
  const [params, setParams] = useState<any>({})
  const [errors] = useState<any>({})

  const { openModal, closeModal } = useModal()
  const dragItem = useRef<any>()
  const dragEnterItem = useRef<any>()
  const [progressValue, setProgressValue] = useState(0)
  const dragStart = (evt: any, position: any): any => {
    dragItem.current = position
  }

  const dragEnter = (evt: any, position: any): any => {
    dragEnterItem.current = position
  }
  const PATH = 'banner'
  const drop = useCallback(
    async (e: any) => {
      const newList = [...banners]
      const dragItemContent = newList[dragItem.current]
      newList.splice(dragItem.current, 1)
      newList.splice(dragEnterItem.current, 0, dragItemContent)
      const reordered = newList.map((list, index) => {
        list.order = index
        return list
      })
      await api.put(
        '/marketplace/banner/reorder',
        reordered.map((list) => {
          return {
            bannerId: list.id,
            order: list.order
          }
        })
      )
      setBanners(reordered)
    },
    [banners]
  )

  const handleSubmit = useCallback(
    async (evt: any) => {
      evt.preventDefault()
      try {
        if (!params.id) {
          const response = await api.post('/marketplace/banners', params)
          setBanners((banners: any) => [response.data, ...banners])
        } else {
          const response = await api.put('/marketplace/banners', params)
          const currentBanners = banners.filter(
            (banner: any) => banner.id !== params.id
          )
          setBanners([response.data, ...currentBanners])
        }
      } catch (error) {
        console.log(error)
      }
    },
    [banners, params]
  )

  const loadBanners = useCallback(async () => {
    const response = await api.get('/marketplace/banners')
    setBanners(response.data.banners || [])
  }, [])

  const handleEdit = useCallback((banner: any) => {
    setParams(banner)
  }, [])

  const handleConfirmDelete = useCallback(
    async (bannerId: string) => {
      try {
        await api.delete(`/marketplace/banners/${bannerId}`)
        const newBanners = banners.filter((banner: any) => banner.id !== bannerId)
        setBanners([...newBanners])
        closeModal()
      } catch (error) { }
    },
    [banners, closeModal]
  )

  const handleDeleteConfirm = useCallback(
    async (bannerId: string) => {
      openModal({
        header: 'Atenção',
        body: (
          <>
            Essa ação é irreversivel, deseja continuar?
            <S.Buttons>
              <button className="button primary" onClick={closeModal}>
                Não
              </button>
              <button
                className="button tertiary"
                onClick={async () => await handleConfirmDelete(bannerId)}
              >
                Sim
              </button>
            </S.Buttons>
          </>
        )
      })
    },
    [closeModal, handleConfirmDelete, openModal]
  )

  useEffect(() => {
    loadBanners()
  }, [loadBanners])

  const onDrop = (image: any): void => {
    setParams({ ...params, image: image.path })
  }
  const onDropImage = useCallback(
    async (acceptedFiles: any) => {
      const formData = new FormData()
      formData.append('image', acceptedFiles[0])
      const response = await api.post(`/admin-upload/${PATH}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (e) => {
          // let progress = Math.round((e.loaded * 100) / e.total);
        }
      })
      onDrop(response.data.image)
      handleProgress()
    },
    [onDrop, PATH]
  )

  if (!banners) return <PageLoader />
  const handleProgress = async (): Promise<void> => {
    setProgressValue(0)
    const totalSteps = 10
    const delay = 100

    for (let i = 1; i <= totalSteps; i++) {
      await new Promise((resolve) => setTimeout(resolve, delay))
      const newProgress = Math.round((i / totalSteps) * 100)
      setProgressValue(newProgress)
    }
  }
  return (
    <Template>
      <div style={{ marginTop: '8rem' }}></div>
      <div className="account-hub-content">
        <S.Container className="widget-box">
          <form
            onSubmit={handleSubmit}
            className="widget-box-content"
            style={{ position: 'relative' }}
          >
            <S.ImageContainer>
              <Dropzone
                mimeTypes={{ 'image/*': [] }}
                typeName="Banner"
                handleOnDrop={onDropImage}
                image={params.path}
              >{progressValue === 0 ? '' : <Progress color="#615dfa" progress={progressValue} width={1} />}</Dropzone>
            </S.ImageContainer>

            <S.FlexGrid>
              <InputText
                name="price"
                value={params.price}
                onChange={(price) => setParams({ ...params, price })}
                placeholder="Preço"
                type="text"
                error={errors.price}
              />
              <InputText
                name="title"
                value={params.title}
                onChange={(title) => setParams({ ...params, title })}
                placeholder="Título"
                type="text"
                error={errors.title}
              />
            </S.FlexGrid>

            <S.Grid>
              <InputText
                name="name"
                value={params.link}
                onChange={(link) => setParams({ ...params, link })}
                placeholder="Link"
                type="text"
                error={errors.link}
              />

              <button className="primary button">Salvar</button>
            </S.Grid>
          </form>

          <div className="table table-downloads split-rows">
            <div className="table-header">
              <div className="table-header-column">
                <p className="table-header-title">Item</p>
              </div>

              <div className="table-header-column padded">
                <p className="table-header-title">Preço</p>
              </div>

              <div className="table-header-column padded-left"></div>
            </div>

            <div className="table-body same-color-rows">
              {banners.map((banner: any, index: number): any => (
                <div
                  className="table-row medium"
                  draggable={true}
                  onDragStart={(evt) => dragStart(evt, index)}
                  onDragEnter={(evt) => dragEnter(evt, index)}
                  onDrop={async (evt) => await drop(evt)}
                  key={index}
                >
                  <div className="table-column">
                    <div className="product-preview tiny">
                      <figure className="product-preview-image short liquid">
                        <img src={banner.image} alt="item-11" />
                      </figure>

                      <div className="product-preview-info">
                        <p className="product-preview-title">{banner.link}</p>

                        <p className="product-preview-creator">
                          {' '}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="table-column padded">
                    <p className="table-title">
                      R$ {banner.price}
                    </p>
                  </div>

                  <div className="table-column padded-left">
                    <div className="table-actions">
                      <button
                        className="button tertiary"
                        onClick={async () => await handleDeleteConfirm(banner.id)}
                      >
                        Deletar
                      </button>
                      <button
                        className="button primary"
                        onClick={() => handleEdit(banner)}
                      >
                        Editar
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </S.Container>
      </div>
    </Template>
  )
}

export default AdminMarketplaceBanner
