import FooterComponent from '../components/FooterComponent';
import HeaderComponent from '../components/HeaderComponent';
import PageLoaderComponent from '../components/PageLoader';
import { useAuth } from '../contexts/AuthContext';
import * as S from './styled';

export default function Template ({ children, className = '' }) {
    const { user } = useAuth();
    return user ? (
        <>
            <HeaderComponent />
            <S.Container className={`${className} content-grid`}>
                {children}
            </S.Container>
            <FooterComponent />
        </>
    ) : <PageLoaderComponent />;
}
