import { ReactElement, useCallback, useEffect, useState } from 'react'
import bannerBg from '../../../assets/img/hub/mecanicas-extras/banner-bg.png'
import manEggs from '../../../assets/img/hub/mecanicas-extras/man-ovos.png'
import PageLoaderComponent from '../../../components/PageLoader'
import { useModalMdlz } from '../../../contexts/ModalMdlzContext'
import api from '../../../services/api'
import SectionHeaderTitle from '../SectionHeaderTitle'
import * as S from './styled'

enum State {
  LOADING,
  READY
}

interface MecanicaExtraAttributes {
  currentState: State
  data?: any
}

export default function MecanicaExtra (): ReactElement {
  const [state, setState] = useState<MecanicaExtraAttributes>({
    currentState: State.LOADING
  })
  const { openModal, closeModal } = useModalMdlz()

  const handleModdalToggle = useCallback(async () => {
    openModal({
      body: <S.ModalContainer>
        <S.ModalTitle>Regras Gerais:</S.ModalTitle>

        <S.ModalList>
          <li>
            Somente colaboradores que trabalharam no período de páscoa poderão usufruir da premiação.
          </li>
          <li>
            Esse BOOST, assim como toda campanha, é exclusivo para os colaboradores efetivos da Mondelēz. Dessa maneira, contratações temporárias para projetos esporádicos não poderão participar da campanha
          </li>
          <li>
            Em caso de desligamento (sem justa causa, por justa causa e a pedido do colaborador), seja durante o período da campanha / BOOST ou até no período de entrega da premiação, o participante não terá mais direito à premiação.
          </li>
        </S.ModalList>
      </S.ModalContainer>
    })
  }, [closeModal, openModal])

  useEffect(() => {
    (async () => {
      const response = await api.get('/me')
      setState({
        currentState: State.READY,
        data: response.data
      })
    })()
  }, [])

  if (state.currentState === State.LOADING) return <PageLoaderComponent />

  return <S.Container>
    <SectionHeaderTitle title="Mecânica Extra" />

    <S.Card>
      <figure>
        <h1>Participe!</h1>
        <img src={bannerBg} />
      </figure>

      <figure>
        <img src={manEggs} />
      </figure>
      <S.ContentContainer>
        <S.Content>
          <div>
            <p>Mecânica Páscoa</p>

            <p>Vendas</p>
          </div>

          <S.DescriptionContainer>
            <S.DescriptionTitle>
              Bateu levou!
            </S.DescriptionTitle>

            <S.TextContainer>
              {state.data.channel.name !== 'MERCHAN' && <><div>
                <S.Subtitle>
                  1,4%
                </S.Subtitle>
                <S.Text>
                  Devolução (R$)
                  <br />
                  Total Brasil
                </S.Text>
              </div>

                <S.Divider />

                <div>
                  <S.Subtitle>
                    500
                    <span> pontos</span>
                  </S.Subtitle>
                  <S.Text>
                    aceleradores para a viagem
                    <br />
                    mais aguardada do ano!
                  </S.Text>
                </div></>}
              {state.data.channel.name === 'MERCHAN' && <><div>
                <S.Subtitle>
                  1,4%
                </S.Subtitle>
                <S.Text>
                  Devolução (R$)
                  <br />
                  Total Brasil
                </S.Text>
              </div>

                <S.Divider />

                <div>
                  <S.SubtitleMerchan>
                    <p><span>Promotor </span> 300 gigapassos</p>
                    <p><span>Coordenador </span> 500 gigapassos</p>
                    <p><span>Gerente </span> 500 gigapassos</p>
                  </S.SubtitleMerchan>
                  <S.Text>
                    aceleradores para a viagem
                    <br />
                    mais aguardada do ano!
                  </S.Text>
                </div></>}
            </S.TextContainer>
          </S.DescriptionContainer>

          <p>
            Aceleradores para a viagem mais aguardada do ano!
          </p>
        </S.Content>
      </S.ContentContainer>
    </S.Card>

    <S.RulesButton onClick={handleModdalToggle}>Confira as regras</S.RulesButton>
  </S.Container>
}
