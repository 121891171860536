import { ReactElement } from 'react'
import { BidButtonProps } from './props'
import * as S from './style'

function BidButton ({ onClick, children, disabled = false }: BidButtonProps): ReactElement {
  return <S.BidButton onClick={onClick} disabled={disabled}>
    {children}
  </S.BidButton>
}

export default BidButton
