import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TopContainer from '../../components/TopContainer'
import { useModalMdlz } from '../../contexts/ModalMdlzContext'
import Intro from './Intro'
import ErrorModal from './Modals/Error'
import NotFinishedModal from './Modals/NotFinished'
import SuccessModal from './Modals/Success'
import Register from './Register'
import amebaBg from './assets/ameba-bg.png'
import * as S from './style'

enum State {
  LOADING,
  READY,
  REGISTER,
  SUCCESS,
  ERROR
}

export default function PicPay (): ReactElement {
  const { closeModal, openModal } = useModalMdlz()
  const [confirmLeave, setConfirmLeave] = useState(false)
  const [state, setState] = useState<State>(State.READY)
  const navigate = useNavigate()

  const handleGoToRegister = (): void => setState(State.REGISTER)

  const handleRedemption = useCallback((): void => {
    setState(State.LOADING)

    try {
      handleOpenModal(<SuccessModal dismiss={handleDismiss} />, State.SUCCESS)
    } catch (error) {
      console.log(error)
      handleOpenModal(<ErrorModal dismiss={handleDismiss} />, State.ERROR)
    }
  }, [])

  const handleOpenModal = (body: ReactElement, currentState: State): void => {
    setState(currentState)

    openModal({
      body,
      customStyles: { backgroundColor: 'var(--white)' },
      hasCloseButton: false,
      size: 'fit'
    })
  }

  const handleConfirmLeave = (): void => {
    setConfirmLeave(true)
    closeModal()
    navigate('/marketplace')
  }

  const handleDismiss = (): void => {
    closeModal()
  }

  useEffect(() => {
    if (state === State.REGISTER) {
      const handleMouseMove = (e: MouseEvent): void => {
        if (e.clientY <= 10 && !confirmLeave) {
          handleOpenModal(<NotFinishedModal dismiss={handleDismiss} confirm={handleConfirmLeave} />, State.REGISTER)
        }
      }

      window.addEventListener('mousemove', handleMouseMove)

      return () => {
        window.removeEventListener('mousemove', handleMouseMove)
      }
    }
  }, [confirmLeave, state])

  return <S.Template>
    <S.Container>
      <TopContainer />

      <S.Ameba>
        <img src={amebaBg} alt="" />
      </S.Ameba>

      <S.Content>
        {state === State.READY && <Intro onClick={handleGoToRegister} />}
        {state === State.REGISTER && <Register
          onClick={handleRedemption}
        />}
      </S.Content>
    </S.Container>
  </S.Template>
}
