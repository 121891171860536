import regulamentoVendas from '../../assets/documents/2022.02.17 - Gigantes da Execução Vendas.pdf'
import regulamentoMerchan from '../../assets/documents/2022.02.17 Gigantes da Execução Merchan.pdf'
import DefaultAvatar from '../../assets/img/avatar.png'
import bannerHome1 from '../../assets/img/banner/banner-lacta.png'
import campaignImgBackground from '../../assets/img/bg-onda.png'
import campaignLogo from '../../assets/img/incentivo-2024/login/LOGO_GIGANTES.png'

const CAMPAIGN_TITLE = 'Gigantes da Execução - 2024'
const CAMPAIGN_COMPANY_NAME = 'Mondelez'
const CAMPAIGN_VIDEO_URL = 'https://player.vimeo.com/video/691544711?h=1ed27da7b2&ampbadge=0&ampautopause=0&ampplayer_id=0&ampapp_id=58479'
const CAMPAIGN_FAQ_EMAIL = 'contato@gigantesdaexecucao.com'
const CAMPAIGN_COMPANY_PRIVACY_LINK = 'https://img.casadebraganca.com/gigantes-2023/documentos/Aviso%20de%20privacidade%20-%20Mondelez.pdf'

export const staticCampaignData = {
  // titulo da campanha
  title: CAMPAIGN_TITLE,
  // url de vídeo em home
  logo: campaignLogo,
  // url de background da campanha
  imgBackground: campaignImgBackground,
  // url de vídeo em home
  campaignVideo: CAMPAIGN_VIDEO_URL,
  // email de contato fale conosco
  email: CAMPAIGN_FAQ_EMAIL,
  // nome da companhia para tela de ativação
  companyName: CAMPAIGN_COMPANY_NAME,
  dataPrivacyLink: '',
  // url da politica de privacidade
  privacyLink: CAMPAIGN_COMPANY_PRIVACY_LINK,
  // url
  defaultAvatar: DefaultAvatar,
  // array de urls
  homeBanners: [bannerHome1],
  // array de objetos de regulamento
  regulamentos: [{
    equipe: 'MERCHAN',
    // url
    url: regulamentoMerchan,
    date: '2022-02-17'
  }, {
    // equipe GERAL é default para outras equipes
    equipe: 'GERAL',
    url: regulamentoVendas,
    date: '2022-02-17'
  }]
}
