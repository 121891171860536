import styled from 'styled-components'
import bubble from '../../../assets/img/bublle.png'

export const Container = styled.div`
  background: linear-gradient(90deg, rgba(48,62,72,1) 0%, rgba(36,45,51,1) 100%);
  border-radius: 16px;
  display: flex;
  height: 21px;
  justify-content: center;
  padding: 5px;
  position: relative;
  width: 100%;
  
  &::before {
    background: linear-gradient(90deg, rgba(84,63,89,1) 0%, rgba(39,34,43,1) 100%);
    border-radius: 16px;
    content: "";
    display: block;
    inset: 5px;
    position: absolute;
  }
`

export const Slider = styled.input`
  appearance: none;
  background: transparent;
  height: 5px;
  position: relative;
  top: 3px;
  width: 100%;
  z-index: 2;

  &::-webkit-slider-thumb {
    appearance: none;
    aspect-ratio: 1;
    background-color: #fff;
    border-radius: 15px;
    width: 15px;
  }

  &::-webkit-range-thumb {
    appearance: none;
    aspect-ratio: 1;
    background-color: #fff;
    border-radius: 15px;
    width: 15px;
  }
`

export const Progress = styled.div<{ progress: number }>`
  background: linear-gradient(90deg, rgba(238,220,40,1) 0%, rgba(207,76,24,1) 100%);
  border-radius: 16px;
  height: 5px;
  left: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: ${({ progress }) => `${progress * 0.95}%`};
`

export const ToolTip = styled.div<{ progress: number }>`
  aspect-ratio: 50 / 37;
  background-image: url(${bubble});
  background-position: -50% 0;
  background-size: contain;
  color: #0e3e8a;
  font-family: MdlzBiteType;
  font-size: 20px;
  left: ${({ progress }) => `calc(${progress * 0.90}% - 14px)`};
  position: absolute;
  top: -45px;
  width: 50px;

  &>div {
    text-align: center;
    width: 100%;
  }
`
