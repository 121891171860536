import { ReactElement } from 'react'
import digimoedas from '../../../../assets/img/game-gira-giro/digimoeda.png'
import { BidContainerProps } from './props'
import * as S from './style'

function BidContainer ({ children }: BidContainerProps): ReactElement {
  return <S.Container>
    <S.Label>
      <div>lance atual</div>
    </S.Label>
    <S.Coin src={digimoedas} alt="Moeda" />
    <S.CoinCounter>{children}</S.CoinCounter>
    <S.Text>digimoedas</S.Text>
  </S.Container>
}

export default BidContainer
