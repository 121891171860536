import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import banner from '../../../assets/img/hub/excercises/banner.png'
import girl from '../../../assets/img/hub/excercises/girl.png'
import { useAuth } from '../../../contexts/AuthContext'
import api from '../../../services/api'
import { Done } from './Done'
import { Failure } from './Failure'
import { Success } from './Success'
import VideoCard from './VideoCard'
import * as S from './styled'

enum UploadState {
  WAITING,
  FILE_SELECTED,
  SENDING,
  FAILURE,
  SUCCESS,
  DONE
}

interface State {
  currentState: UploadState
  progress: number
  file?: File
  videoFile?: string
}

export default function Excercises (): ReactElement {
  const [state, setState] = useState<State>({
    currentState: UploadState.WAITING,
    progress: 0
  })
  const { user } = useAuth()
  const handleOndrop = useCallback((newAcceptedFiles: File[]) => {
    const [file] = newAcceptedFiles
    setState((state) => ({ ...state, file, progress: 0, currentState: UploadState.FILE_SELECTED }))
  }, [])

  const onDropRejected = useCallback(() => { }, [])
  const onDragLeave = useCallback(() => { }, [])

  const onDragEnter = useCallback((isAccepted: boolean, isReject: boolean) => { }, [])

  const send = useCallback(async () => {
    if (!state.file) return
    const formData = new FormData()
    formData.append('video', state.file)
    setState((state) => ({ ...state, currentState: UploadState.SENDING }))
    try {
      const response = await api.post('/video', formData)
      setState((state) => ({
        ...state,
        currentState: UploadState.SUCCESS,
        file: undefined,
        videoFile: response.data.path
      }))
    } catch (err) {
      setState((state) => ({ ...state, currentState: UploadState.FAILURE, file: undefined }))
    }
  }, [state])

  useEffect(() => {
    (async () => {
      const response = await api.get('/video')
      if (!response.data) return
      if (response.data.videos.length >= 2) {
        setState((state) => ({
          ...state,
          currentState: UploadState.DONE,
          videoFile: response.data.videos[0].path
        }))
        return
      }
      const [lastVideo] = response.data.videos.slice(-1)
      console.log(lastVideo.path)
      setState((state) => ({
        ...state,
        currentState: UploadState.WAITING,
        videoFile: lastVideo.path
      }))
    })()
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop: handleOndrop,
    onDropRejected,
    onDragLeave,
    onDragEnter: () => onDragEnter(isDragAccept, isDragReject),
    maxFiles: 1,
    accept: {
      'video/mp4': ['.mp4']
    }
  })

  return <S.Container>
    <S.SectionHeaderTitle title="Exercícios da Semana" />

    <S.Card>
      <S.Banner>
        <h1>
          POSTURADOS<br />E PREMIADOS
        </h1>

        <img src={banner} />
      </S.Banner>

      <S.Character>
        <img src={girl} />
      </S.Character>

      <S.ContentContainer>
        {![UploadState.SUCCESS, UploadState.FAILURE, UploadState.DONE].includes(state.currentState) && <>
          <S.Content>
            <S.UploadContainer>
              <h1>Exercícios 01</h1>

              <p>{user.displayName}</p>
              <S.UploadButton {...getRootProps({
                isDragActive,
                isDragAccept,
                isDragReject,
                maxFiles: 1
              })}>
                <input role='button' {...getInputProps()} />
                <div>
                  <p>+</p>
                </div>

                <S.UploadText>
                  {state.currentState !== UploadState.FILE_SELECTED && <p>Carregue o seu vídeo</p>}
                  {state.currentState === UploadState.FILE_SELECTED && <p>Vídeo carregado</p>}
                </S.UploadText>
              </S.UploadButton>

              <S.SendButton onClick={send} disabled={state.currentState !== UploadState.FILE_SELECTED}>
                Enviar
              </S.SendButton>

            </S.UploadContainer>
          </S.Content>
        </>}
        {state.currentState === UploadState.SUCCESS && <Success />}
        {state.currentState === UploadState.FAILURE && <Failure />}
        {state.currentState === UploadState.DONE && <Done />}

        <S.VideoContainer>
          <VideoCard video={state.videoFile} />
        </S.VideoContainer>
      </S.ContentContainer>
    </S.Card>

    <S.RulesButton to='/galeria'>Veja os seus vídeos</S.RulesButton>
  </S.Container>
}
