import { useCallback, useState } from 'react'
import * as S from './style'
function CardWidget ({ status, message, content }) {
  const [contentData, setContentData] = useState(content)
  const handleUpdateComments = useCallback((comment) => {
    if (!content.comments) {
      content.comments = []
    }
    content.comments.push(comment)
    setContentData(content)
  })
  return <div className="widget-box no-padding">
    <div className="widget-box-status">
      <div className="widget-box-status-content">
        {status}
        <S.MessageContainer className="widget-box-status-text ql-editor">{message}</S.MessageContainer>
      </div>
      {/* <div className="widget-box-status-content">
        <CardContentActionComponent />
        <CardPostActionComponent content={contentData} onPostComment={handleUpdateComments} />
      </div> */}
    </div>
  </div>
}

export default CardWidget
