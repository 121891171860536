
import { Dispatch, SetStateAction } from 'react'
import { AdminUploadImage } from '../../../../domain/use-case/AdminUploadImage'
import { CreateHubCampaing } from '../../../../domain/use-case/CreateHubCampaing'

export interface NewCampaignProps {
  uploadImage: AdminUploadImage
  createCampaing: CreateHubCampaing
}

export enum State {
  STEP_ONE,
  STEP_TWO,
}

export interface CampaignInfo {
  currentState: State
  description: string
  regulation?: string
  banner?: string
  logo?: string
  mechanics?: string[]
  name: string
  report: string[]
  results: string[]
  smallBanner?: string
  target: string[]
  roles: number[]
}

export interface CampaignErrors {
  hasError?: boolean
  banner?: string
  description?: string
  logo?: string
  mechanics?: string
  name?: string
  regulation?: string
  smallBanner?: string
}

export interface StepsProps {
  errors: CampaignErrors
  setState: Dispatch<SetStateAction<CampaignInfo>>
  state: CampaignInfo
  uploadImage: AdminUploadImage
}
