import { ReactElement } from 'react'
import footPrintIcon from '../assets/footprint-icon.svg'
import * as S from './style'

export default function ListGigaPassosText (): ReactElement {
  return (
        <S.ListGigaPassosTextContainer>
          <div>
            <p>&lt;&lt;&lt; 99% = 0 Gigapassos </p>
            <img src={footPrintIcon} alt='Ícone de pegada' />
          </div>
          <div>
            <p>100 a 101% = 100 Gigapassos </p><img src={footPrintIcon} alt='Ícone de pegada' />
          </div>
          <div>
            <p>102 a 105% = 105 Gigapassos </p>
            <img src={footPrintIcon} alt='Ícone de pegada' />
          </div>
          <div>
            <p>106 a 108% = 108 Gigapassos </p>
            <img src={footPrintIcon} alt='Ícone de pegada' />
          </div>
          <div>
            <p>109 a 111% = 111 Gigapassos </p>
            <img src={footPrintIcon} alt='Ícone de pegada' />
          </div>
          <div>
            <p>112 a 116% = 116 Gigapassos </p>
            <img src={footPrintIcon} alt='Ícone de pegada' />
          </div>
          <div>
            <p>117 a 120% = 120 Gigapassos </p>
            <img src={footPrintIcon} alt='Ícone de pegada' />
          </div>
          <div>
            <p>121% &gt;&gt;&gt; = % de atingimento</p>
          </div>
          <div>
            <p>É o mesmo que o de Gigapassos. </p>
            <img src={footPrintIcon} alt='Ícone de pegada' />
          </div>
        </S.ListGigaPassosTextContainer>
  )
}
