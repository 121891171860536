import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  box-shadow: rgba(94, 92, 154, 0.06) 0px 0px 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
  padding: 32px 28px;
  position: relative;
`

export const Title = styled.h1`
  font-size: 1rem;
  font-weight: 700;
  width: 100%;
`

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`

export const Empty = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
`

export const TableContainer = styled.div`
  flex: 1;
  width: 100%;
`

export const Table = styled.table`
  flex: 1;
  width: 100%;

  td {
    font-family: Rajdhani;
    font-size: 0.875rem;
    font-weight: bold;
    padding: 8px;
  }
`
