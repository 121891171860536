import styled from 'styled-components'
import CardComponent from '../../../../components/Card'

export const Card = styled(CardComponent)`
  align-items: center;
  aspect-ratio: 1;
  background-color: var(--white);
  border-radius: 12px;
  filter: drop-shadow(0px 3px 5px rgba(27,27,29,0.16));
  flex: 1;
  flex-direction: column;
  justify-content: center;
  max-width: none;
  padding: 8px 8px 8px 12px;
  width: auto;
  z-index: 0;
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  justify-content: space-around;
  width: 100%;

  & > div {
    align-items: center;
    display: flex;
    gap: 4px;
  }

  > div {
    flex-direction: column;
  }
`

export const CardTitle = styled.h1`
  color: #1d3f85;
  font-family: Rajdhani;
  font-size: 32px;
  font-weight: bold;
`

export const CardText = styled.p`
  font-weight: bold;
  font-size: 16px;
`

export const Divider = styled.hr`
  border-left: 1px solid #cfcfd2;
  height: 100%;
  margin: 0;
`

export const Graph = styled.div<{ progress: number }>`
  --primary: #615dfa;
  --secondary: #23d2e2;
  aspect-ratio: 1;
  background: conic-gradient(var(--primary) 0%, var(--secondary) ${props => `${props.progress}%`}, #e8e8ef ${props => `${props.progress}.1%`}, #e8e8ef 100%);
  border-radius: 50%;
  height: 50px;
  position: relative;
  width: 50px;

  &:after {
    align-items: center;
    aspect-ratio: 1;
    background-color: #ffffff;
    border-radius: 50%;
    content: "${props => `${props.progress}%`}";
    display: flex;
    font-family: Rajdhani, sans-serif;
    font-size: 12px;
    font-weight: bold;
    justify-content: center;
    margin: 8px;
    position: absolute;
    width: calc(100% - 16px);
  }

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    height: 100px;
    width: 100px;

    &:after {
      font-size: 24px
    }
  }
`
