import { ReactElement } from 'react'
import coin from '../assets/coin.png'
import WinnerCardProps from './props'
import * as S from './style'

export default function WinnerCard ({ avatarImg, digimoedas, prizeImg, prizeName, reverse = false, winnerName }: WinnerCardProps): ReactElement {
  return <S.Container $reverse={reverse}>
  <div>
    <S.AvatarBg>
      <S.Avatar src={avatarImg} alt="" />

      <S.PrizeImg src={prizeImg} alt="" $reverse={reverse} className={prizeImg.includes('bag2') ? 'reserva' : ''} />
    </S.AvatarBg>
  </div>

  <S.Content>
    <S.WinnerName>{winnerName}</S.WinnerName>

    <S.PrizeContainer>
      <p>{prizeName}</p>

      <S.Digimoedas>
        <p>
          {digimoedas}
          <br />
          <span>DIGIMOEDAS</span>
        </p>

        <figure>
          <img src={coin} alt="" />
        </figure>
      </S.Digimoedas>
    </S.PrizeContainer>
  </S.Content>
</S.Container>
}
