import { ReactElement } from 'react'
import { ApiLoadCargos } from '../../data/use-case/ApiLoadCargos'
import { ApiLoadRankingByCampaing } from '../../data/use-case/ApiLoadRankingByCampaing'
import RankingTrident from '../../pages/Ranking/RankingTrident'
import api from '../../services/api'

export const makeRankingTridentPage = (): ReactElement => {
  const loadCargos = new ApiLoadCargos(api)
  const loadRankingByCampaing = new ApiLoadRankingByCampaing(api)
  return <RankingTrident loadCargos={loadCargos} loadRankingByCampaing={loadRankingByCampaing} />
}
