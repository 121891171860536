import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import character from './assets/character.png'
import logo from './assets/logo.png'
import * as S from './styled'

export default function QuizBisCard (): ReactElement {
  return <S.Container>
    <Link to="/quiz">
      <S.Card>
        <S.BannerContainer>
          <img src={character} alt="" />
        </S.BannerContainer>

        <S.Content>
          <p>Quiz</p>

          <figure>
            <img src={logo} alt="Logo Bis" />
          </figure>
        </S.Content>
      </S.Card>
    </Link>
  </S.Container>
}
