import { ReactElement, useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { io } from 'socket.io-client'
import PageLoaderComponent from '../../components/PageLoader'
import { useAuth } from '../../contexts/AuthContext'
import { useModalMdlz } from '../../contexts/ModalMdlzContext'
import api from '../../services/api'
import AuctionProducts from './AuctionProducts'
import Countdown from './Countdown'
import HighlightCarousel from './HighlightCarousel'
import ModalBid from './ModalBid'
import * as S from './styled'

enum PageState {
  LOADING,
  NO_CONTENT,
  RUNNING
}

interface PageStatus {
  pageState: PageState
  content: {
    endAt: string
    items: any[]
  }
}

export default function Auction (): ReactElement {
  const [status, setStatus] = useState<PageStatus>({ pageState: PageState.NO_CONTENT } as unknown as PageStatus)
  const { openModal } = useModalMdlz()
  const { token, updateCoins } = useAuth()

  const handleClickProd = useCallback((id: number) => {
    openModal({
      body: <ModalBid id={id} endTime={status.content.endAt} />,
      size: 'fit',
      goTo: 'https://gigantesdaexecucao.com/leilao'
    })
  }, [status])

  useEffect((): any => {
    const ioServer = io(String(process.env.REACT_APP_BASE_URL), {
      query: { token },
      transports: ['websocket']
    })

    ioServer.on('auction-item-update', (item: any) => {
      console.log(item)
      const items = [...status.content.items]
      const itemIndex = items.findIndex(c => c.id === item.id)
      items[itemIndex].currentBid = item.currentBid
      setStatus(s => ({
        ...s,
        content: {
          ...status.content,
          items
        }
      }))
    })

    ioServer.on('coins-update', (chunk) => {
      console.log(chunk.coins)
      updateCoins(chunk.coins)
    })

    return () => ioServer.close()
  }, [status.content, token, updateCoins])

  useEffect(() => {
    (async () => {
      try {
        setStatus((status) => ({ ...status, pageState: PageState.LOADING }))
        const response = await api.get('/auction')
        switch (response.status) {
          case 200:
            setStatus((status) => ({ ...status, pageState: PageState.RUNNING, content: response.data }))
            break
          case 204:
            setStatus((status) => ({ ...status, pageState: PageState.NO_CONTENT }))
            break
        }
      } catch (err) {
        toast.error('Ocorreu um erro. Por favor tente novamente mais tarde!', { theme: 'colored' })
      }
    })()
  }, [])

  switch (status.pageState) {
    case PageState.LOADING:
      return <PageLoaderComponent />
    case PageState.NO_CONTENT:
      return <S.Template>
        <S.BgContainer>
          <S.ContentContainer>
            <S.NoContentText>Leilão encerrado</S.NoContentText>
          </S.ContentContainer>
        </S.BgContainer>
      </S.Template>
    case PageState.RUNNING:
      return <S.Template>
        <S.BgContainer>
          <S.ContentContainer>
            <Countdown endTime={status.content.endAt} />

            <HighlightCarousel items={status.content.items} onClick={handleClickProd} />

            <AuctionProducts items={status.content.items} onClick={handleClickProd} />
          </S.ContentContainer>
        </S.BgContainer>
      </S.Template>
  }
}
