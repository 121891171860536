import { ReactElement } from 'react'
import ProgressCircle from '../../../components/ProgressCircle'
import quest from '../assets/Quest.png'
import character from '../assets/character.png'
import QuestResultsCardProps from './props'
import * as S from './style'

export default function QuestResultsCard ({ completed, progress }: QuestResultsCardProps): ReactElement {
  return <S.StatsCard>
  <figure>
    <img src={character} alt="" />
  </figure>

  <S.StatsContent>
    <h6>{completed[0]}/{completed[1]}</h6>

    <div>
      <p><strong>Missões</strong> <br/>completas</p>

    </div>

    <figure>
      <img src={quest} alt="" />
    </figure>
  </S.StatsContent>

  <ProgressCircle progress={progress} />
</S.StatsCard>
}
