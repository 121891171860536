import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import InputSelect from '../Forms/InputSelect';

function ChannelComponentMerchan ({ onChange, ...rest }) {
  const [channels, setChannels] = useState();

  const loadChannels = useCallback(async () => {
    const response = await api.get('/channels');
    setChannels(response.data.filter(channel => channel.teamId !== 1).filter(channel => [2].includes(channel.id)))
  }, []);

  useEffect(() => {
    loadChannels();
  }, [loadChannels]);

  return <InputSelect {...rest} onChange={(value) => onChange(value !== "" ? JSON.parse(value) : {})} name="canal" label="Canal">
    <option value="">Selecione o canal</option>
    {channels && channels.map(c => <option selected={rest.value?.id === c.id} value={JSON.stringify(c)}>{c.name}</option>)}
  </InputSelect>;
}

export default ChannelComponentMerchan;
