import { ReactElement, useMemo } from 'react'
import ListGigaPassosText from './ListGigaPassosText'
import ListGigaPassosTextCCMT from './ListGigaPassosTextCCMT'
import SectionKPIFirstContent from './SectionKPIFirstContent'
import LojasPorEstrelaText from './SectionKPIFirstContent/LojasPorEstrelaText'
import LojasPorEstrelaTextMerchan from './SectionKPIFirstContent/LojasPorEstrelaText/merchan'
import SectionKPISecondContent from './SectionKPISecondContent'
import PresencaText from './SectionKPISecondContent/PresencaText'
import SectionKPITable from './SectionKPITable'
import SectionKPITableMerchan from './SectionKPITable/SectionKPITableMerchan'
import SectionKPIsHeader from './SectionKPIsHeader'
import girlShopping from './SectionKPIsHeader/assets/person-girl-shopping.svg'
import manVideogame from './SectionKPIsHeader/assets/person-man-videogame.svg'
import manWalking from './SectionKPIsHeader/assets/person-man-walking.svg'
import bottomImage from './assets/bottom-person.png'
import * as S from './style'

interface SectionKPIsProps {
  mecanica: string
}

export default function SectionKPIs ({ mecanica }: SectionKPIsProps): ReactElement {
  const mecanicaOption = {
    MERCHAN:
      [{
        name: 'MERCHAN',
        header: { left_icon_person: 'manVideogame' },
        KPI_Ellipsis_1: { name: 'Loja 4/5 Estrelas', has_image: false },
        KPI_Ellipsis_2: { name: 'Presença', image: 'mondelezLogo' },
        first_content_component: <LojasPorEstrelaTextMerchan />,
        second_content_component: <PresencaText />,
        has_dona_gigante_warning: false,
        has_bottom_image: false,
        fonte_apuracao: <>
          Fonte de Apuração: <br />
          Loja 4/5 Estrelas - MC1 <br />
          Sell Out Marca Foco - Trilha de Sell Out <br />
          O target do Gerente e Coordenador será a média do target do seu time,  <br />
          assim como o resultado. <br />
          **Considerar toda família de BIS: Flowpack, BIS Xtra e Bisão.
        </>
      }],
    'C&C':
      [{
        name: 'C&C',
        header: { left_icon_person: 'false' },
        KPI_Ellipsis_1: { name: 'Loja 4/5 Estrelas', has_image: false },
        KPI_Ellipsis_2: { name: <>Sell Out<br/> Família Oreo + Família BIS</>, image: 'bisOreoLogo' },
        first_content_component: <LojasPorEstrelaText />,
        second_content_component: <ListGigaPassosTextCCMT />,
        has_dona_gigante_warning: false,
        has_bottom_image: true,
        fonte_apuracao: <>
          Fonte de Apuração: <br />
          Loja 4/5 Estrelas - MC1 <br />
          Ponto Extra - Base Ponto Extra <br />
          O target do Gerente de área será a média do target do seu time, assim como o resultado. <br />
          **Considerar toda família de BIS: Flowpack, BIS Xtra e Bisão.
        </>
      },
      {
        name: 'FOOD',
        header: { left_icon_person: 'girlShopping' },
        KPI_Ellipsis_1: { name: 'Sell In', has_image: true },
        KPI_Ellipsis_2: { name: <>Sell In Família Oreo + Família BIS <br/>(Milka, Cream Cheese)</>, image: 'bisOreoLogo' },
        first_content_component: <ListGigaPassosText />,
        second_content_component: <ListGigaPassosText />,
        has_dona_gigante_warning: false,
        has_bottom_image: false,
        fonte_apuracao: <>
          Fonte de Apuração: <br />
          Sell In Total Marcas - Relatório Sales Intelligence <br />
          Sell In Marca Foco - Carteira de pedidos<br />
          O target do Gerente de área será a média do target do seu time, assim como o resultado. <br />
          **Considerar toda família de BIS: Flowpack, BIS Xtra e Bisão.
        </>
      }],
    MT:
      [{
        name: 'MT',
        header: { left_icon_person: 'false' },
        KPI_Ellipsis_1: { name: 'Loja 4/5 Estrelas', has_image: false },
        KPI_Ellipsis_2: { name: <>Ponto Extra Família Oreo <br /> + Família BIS</>, image: 'bisOreoLogo' },
        first_content_component: <LojasPorEstrelaText />,
        second_content_component: <ListGigaPassosTextCCMT />,
        has_dona_gigante_warning: false,
        has_bottom_image: true,
        fonte_apuracao: <>
          Fonte de Apuração: <br />
          Loja 4/5 Estrelas - MC1 <br />
          Ponto Extra - Base Ponto Extra <br />
          O target do Gerente de área será a média do target do seu time, assim como o resultado. <br />
          **Considerar toda família de BIS: Flowpack, BIS Xtra e Bisão.
        </>
      }, {
        name: 'AMERICANAS',
        header: { left_icon_person: 'manWalking' },
        KPI_Ellipsis_1: { name: 'Loja 4/5 Estrelas', has_image: false },
        KPI_Ellipsis_2: { name: 'Sell Out Marca de Atendimento', image: 'false' },
        first_content_component: <LojasPorEstrelaText
          disclaimer={<small>*Para Americanas, será feito a média de todas as lojas dos executivos e todos terão a mesma quantidade de pontuação nesse KPI.</small>}
        />,
        second_content_component: <ListGigaPassosText />,
        has_dona_gigante_warning: true,
        has_bottom_image: false,
        fonte_apuracao: <>
          Fonte de Apuração: <br />
          Loja 4/5 Estrelas - MC1 <br />
          Sell In Marca Foco - Carteira de pedidos<br />
          O target do Gerente de área será a média do target do seu time, assim como o resultado. <br />
          **Considerar toda família de BIS: Flowpack, BIS Xtra e Bisão.
        </>
      }, {
        name: 'GKA',
        header: { left_icon_person: 'girlShopping' },
        KPI_Ellipsis_1: { name: 'Loja 4/5 Estrelas', has_image: false },
        KPI_Ellipsis_2: { name: 'Sell Out Marca de Atendimento', image: 'false' },
        first_content_component: <LojasPorEstrelaText
          disclaimer={<small>*Para GKA, será feito a média de todas as lojas dos executivos e todos terão a mesma quantidade de pontuação nesse KPI.</small>}
        />,
        second_content_component: <ListGigaPassosText />,
        has_dona_gigante_warning: true,
        has_bottom_image: false,
        fonte_apuracao: <>
          Fonte de Apuração: <br />
          Loja 4/5 Estrelas - MC1 <br />
          Sell In Marca Foco - Carteira de pedidos<br />
          O target do Gerente de área será a média do target do seu time, assim como o resultado. <br />
          **Considerar toda família de BIS: Flowpack, BIS Xtra e Bisão.
        </>
      }, {
        name: 'FARMA + DIA',
        header: { left_icon_person: 'girlShopping' },
        KPI_Ellipsis_1: { name: 'Sell In', has_image: true },
        KPI_Ellipsis_2: { name: <>Sell In  Família Oreo<br /> + Familía BIS</>, image: 'bisOreoLogo' },
        first_content_component: <ListGigaPassosText />,
        second_content_component: <ListGigaPassosText />,
        has_dona_gigante_warning: false,
        has_bottom_image: false,
        fonte_apuracao: <>
          Fonte de Apuração: <br />
          Sell In Total Marcas - Relatório Sales Intelligence <br />
          Sell In Marca Foco - Carteira de pedidos <br />
          O target do Gerente de área será a média do target do seu time, assim como o resultado. <br />
          **Considerar toda família de BIS: Flowpack, BIS Xtra e Bisão.
        </>
      }],
    LMT:
      [{
        name: 'LMT',
        header: { left_icon_person: 'false' },
        KPI_Ellipsis_1: { name: 'Loja 4/5 Estrelas', has_image: false },
        KPI_Ellipsis_2: { name: <>Sell Out Família Oreo <br /> + Família BIS</>, image: 'bisOreoLogo' },
        first_content_component: <LojasPorEstrelaText />,
        second_content_component: <ListGigaPassosTextCCMT />,
        has_dona_gigante_warning: false,
        has_bottom_image: true,
        fonte_apuracao: <>
          Fonte de Apuração: <br />
          Loja 4/5 Estrelas - MC1 <br />
          Ponto Extra - Base Ponto Extra <br />
          O target do Gerente de área será a média do target do seu time, assim como o resultado. <br />
          **Considerar toda família de BIS: Flowpack, BIS Xtra e Bisão.
        </>
      }, {
        name: 'AMERICANAS',
        header: { left_icon_person: 'manWalking' },
        KPI_Ellipsis_1: { name: 'Loja 4/5 Estrelas', has_image: false },
        KPI_Ellipsis_2: { name: 'Sell Out Marca de Atendimento', image: 'false' },
        first_content_component: <LojasPorEstrelaText />,
        second_content_component: <ListGigaPassosText />,
        has_dona_gigante_warning: true,
        has_bottom_image: false,
        disclaimer: <small>*Para Americanas, será feito a média de todas as lojas dos executivos e todos terão a mesma quantidade de pontuação nesse KPI.</small>,
        fonte_apuracao: <>
          Fonte de Apuração: <br />
          Loja 4/5 Estrelas - MC1 <br />
          Sell In Marca Foco - Carteira de pedidos<br />
          O target do Gerente de área será a média do target do seu time, assim como o resultado. <br />
          **Considerar toda família de BIS: Flowpack, BIS Xtra e Bisão.
        </>
      }, {
        name: 'GKA',
        header: { left_icon_person: 'girlShopping' },
        KPI_Ellipsis_1: { name: 'Loja 4/5 Estrelas', has_image: false },
        KPI_Ellipsis_2: { name: 'Sell Out Marca de Atendimento', image: 'false' },
        first_content_component: <LojasPorEstrelaText />,
        second_content_component: <ListGigaPassosText />,
        has_dona_gigante_warning: true,
        has_bottom_image: false,
        disclaimer: <small>*Para GKA, será feito a média de todas as lojas dos executivos e todos terão a mesma quantidade de pontuação nesse KPI.</small>,
        fonte_apuracao: <>
          Fonte de Apuração: <br />
          Loja 4/5 Estrelas - MC1 <br />
          Sell In Marca Foco - Carteira de pedidos<br />
          O target do Gerente de área será a média do target do seu time, assim como o resultado. <br />
          **Considerar toda família de BIS: Flowpack, BIS Xtra e Bisão.
        </>
      }, {
        name: 'FARMA + DIA',
        header: { left_icon_person: 'girlShopping' },
        KPI_Ellipsis_1: { name: 'Sell In', has_image: true },
        KPI_Ellipsis_2: { name: <>Sell In  Família Oreo<br /> + Familía BIS</>, image: 'bisOreoLogo' },
        first_content_component: <ListGigaPassosText />,
        second_content_component: <ListGigaPassosText />,
        has_dona_gigante_warning: false,
        has_bottom_image: false,
        fonte_apuracao: <>
          Fonte de Apuração: <br />
          Sell In Total Marcas - Relatório Sales Intelligence <br />
          Sell In Marca Foco - Carteira de pedidos <br />
          O target do Gerente de área será a média do target do seu time, assim como o resultado. <br />
          **Considerar toda família de BIS: Flowpack, BIS Xtra e Bisão.
        </>
      }],
    TT:
      [{
        name: 'ATC',
        header: { left_icon_person: 'manVideogame' },
        KPI_Ellipsis_1: { name: 'Positivação', has_image: true },
        KPI_Ellipsis_2: { name: <>Positivação Família Oreo <br/>+ Positivação Família BIS</>, image: 'bisOreoLogo' },
        first_content_component: <ListGigaPassosText />,
        second_content_component: <ListGigaPassosText />,
        has_dona_gigante_warning: false,
        has_bottom_image: false,
        fonte_apuracao: <>
          Fonte de Apuração: <br />
          Positivação: Relatório de RTM <br />
          Sell In Marca Foco - Carteira de pedidos<br />
          O target do Gerente de área será a média do target do seu time, assim como o resultado. <br />
          **Considerar toda família de BIS: Flowpack, BIS Xtra e Bisão.
        </>
      }, {
        name: 'VJ / HIB / TD',
        header: { left_icon_person: 'manVideogame' },
        KPI_Ellipsis_1: { name: 'SKU por PDV', has_image: true },
        KPI_Ellipsis_2: { name: <>SKU por PDV Família Oreo + <br/>SKU por PDV Família BIS</>, image: 'bisOreoLogo' },
        first_content_component: <ListGigaPassosText />,
        second_content_component: <ListGigaPassosText />,
        has_dona_gigante_warning: false,
        has_bottom_image: false,
        fonte_apuracao: <>
          Fonte de Apuração: <br />
          SKU por PDV - Relatório RTM <br />
          Sell In Marca Foco - Carteira de pedidos <br />
          O target do Gerente de área será a média do target do seu time, assim como o resultado. <br />
          **Considerar toda família de BIS: Flowpack, BIS Xtra e Bisão.
        </>
      }],
    ATC:
      [{
        name: 'ATC',
        header: { left_icon_person: 'manVideogame' },
        KPI_Ellipsis_1: { name: 'Positivação', has_image: true },
        KPI_Ellipsis_2: { name: <>Positivação Família Oreo <br/>+ Positivação Família BIS</>, image: 'bisOreoLogo' },
        first_content_component: <ListGigaPassosText />,
        second_content_component: <ListGigaPassosText />,
        has_dona_gigante_warning: false,
        has_bottom_image: false,
        fonte_apuracao: <>
          Fonte de Apuração: <br />
          Positivação: Relatório de RTM <br />
          Sell In Marca Foco - Carteira de pedidos<br />
          O target do Gerente de área será a média do target do seu time, assim como o resultado. <br />
          **Considerar toda família de BIS: Flowpack, BIS Xtra e Bisão.
        </>
      }, {
        name: 'VJ / HIB / TD',
        header: { left_icon_person: 'manVideogame' },
        KPI_Ellipsis_1: { name: 'SKU por PDV', has_image: true },
        KPI_Ellipsis_2: { name: <>SKU por PDV Família Oreo + <br/>SKU por PDV Família BIS</>, image: 'bisOreoLogo' },
        first_content_component: <ListGigaPassosText />,
        second_content_component: <ListGigaPassosText />,
        has_dona_gigante_warning: false,
        has_bottom_image: false,
        fonte_apuracao: <>
          Fonte de Apuração: <br />
          SKU por PDV - Relatório RTM <br />
          Sell In Marca Foco - Carteira de pedidos <br />
          O target do Gerente de área será a média do target do seu time, assim como o resultado. <br />
          **Considerar toda família de BIS: Flowpack, BIS Xtra e Bisão.
        </>
      }],
    'E-commerce':
      [{
        name: 'E-COMMERCE',
        header: { left_icon_person: 'manWalking' },
        KPI_Ellipsis_1: { name: 'Sell Out', has_image: true },
        KPI_Ellipsis_2: { name: <>Sell Out Família Oreo <br/>+ Familía BIS</>, image: 'bisOreoLogo' },
        first_content_component: <ListGigaPassosText />,
        second_content_component: <ListGigaPassosText />,
        has_dona_gigante_warning: false,
        has_bottom_image: false,
        fonte_apuracao: <>
          Fonte de Apuração: <br />
          Sell Out Total Marcas - Trilha de Sell Out  <br />
          Sell Out Marca Foco - Trilha de Sell Out <br />
          O target do Gerente de área será a média do target do seu time, <br />
          assim como o resultado. **Considerar toda família de BIS: Flowpack, BIS Xtra e Bisão.
        </>
      }]
  }

  const meca = useMemo(() => {
    console.log(mecanica)
    const value = Object.values(mecanicaOption).find(mec => mec.findIndex(m => m.name.includes(mecanica.toUpperCase())) >= 0)
    if (value) return value
    return mecanicaOption.TT
  }, [])

  const personIconOptions = {
    manVideogame,
    girlShopping,
    manWalking
  }

  return <>
    {meca.map((mec: any) => <S.SectionKPIs>
      <SectionKPIsHeader
        mecanicaName={mec?.name}
        leftIconPerson={mec.header.left_icon_person}
      />
      <S.SectionKPIsContent>
        <S.SectionKPIFirstContent>
          <SectionKPIFirstContent
            content={mec.first_content_component}
            ellipsisName={mec.KPI_Ellipsis_1.name}
            ellipsisImage={mec.KPI_Ellipsis_1.has_image}
          >
            {mec.fonte_apuracao}
          </SectionKPIFirstContent>

        </S.SectionKPIFirstContent>
        <S.SectionKPISecondContent>
          <SectionKPISecondContent
            content={mec.second_content_component}
            ellipsisName={mec.KPI_Ellipsis_2.name}
            ellipsisImage={mec.KPI_Ellipsis_2.image}
            hasDonaGiganteWarning={mec.has_dona_gigante_warning}
            hasBottomImage={mec.has_bottom_image}
            />
        </S.SectionKPISecondContent>

        {mec?.name !== 'MERCHAN' && <SectionKPITable />}
        {mec?.name === 'MERCHAN' && <SectionKPITableMerchan />}

        {mec.has_bottom_image && <S.SectionKPIsSecondContentBottomImage>
          <img src={bottomImage} alt="" />
        </S.SectionKPIsSecondContentBottomImage>}

        {mec.header.left_icon_person !== 'false' && <S.SectionKPIsSecondContentBottomMobileImage>
          <img
            src={personIconOptions[mec.header.left_icon_person as keyof typeof personIconOptions]}
            alt="Imagem de um personagem humano desenhado"
          />
        </S.SectionKPIsSecondContentBottomMobileImage>}
      </S.SectionKPIsContent>
    </S.SectionKPIs>)}
  </>
}
