import { HTMLAttributes, ReactElement } from 'react'
import * as S from './style'

export default function SectionKPITable (props: HTMLAttributes<HTMLDivElement>): ReactElement {
  return <S.TableContainer {...props}>
    <table>
      <thead>
        <tr>
          <th>
            <b>Canal</b>
          </th>
          <th>
            <center><b>Executivo</b></center>
          </th>
          <th>
            <center><b>GA</b></center>
          </th>
          <th>
            <center><b>GR</b></center>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <b>Premiação</b>
          </td>
          <td>
            <center>R$ 800,00</center>
          </td>
          <td>
            <center>R$ 1.000,00</center>
          </td>
          <td>
            <center>R$ -</center>
          </td>
        </tr>
        <tr>
          <td>
            <b>ATC</b>
          </td>
          <td>
            <center>4</center>
          </td>
          <td>
            <center>1</center>
          </td>
          <td rowSpan={10}>
            <center>0</center>
          </td>
        </tr>
        <tr>
          <td>
            <b>TD</b>
          </td>
          <td>
            <center>4</center>
          </td>
          <td>
            <center>1</center>
          </td>
        </tr>
        <tr>
          <td>
            <b>VJ</b>
          </td>
          <td>
            <center>4</center>
          </td>
          <td>
            <center>1</center>
          </td>
        </tr>
        <tr>
          <td>
            <b>HIB</b>
          </td>
          <td>
            <center>4</center>
          </td>
          <td>
            <center>1</center>
          </td>
        </tr>
        <tr>
          <td>
            <b>MT</b>
          </td>
          <td>
            <center>8</center>
          </td>
          <td>
            <center>2</center>
          </td>
        </tr>
        <tr>
          <td>
            <b>FARMA + DIA</b>
          </td>
          <td>
            <center>1</center>
          </td>
          <td>
            <center>1</center>
          </td>
        </tr>
        <tr>
          <td>
            <b>GKA + Americanas</b>
          </td>
          <td>
            <center>1</center>
          </td>
          <td>
            <center>1</center>
          </td>
        </tr>
        <tr>
          <td>
            <b>C&C</b>
          </td>
          <td>
            <center>3</center>
          </td>
          <td>
            <center>1</center>
          </td>
        </tr>
        <tr>
          <td>
            <b>FOOD</b>
          </td>
          <td>
            <center>1</center>
          </td>
          <td>
            <center>1</center>
          </td>
        </tr>
        <tr>
          <td>
            <b>ECOMM</b>
          </td>
          <td>
            <center>1</center>
          </td>
          <td>
            <center>1</center>
          </td>
        </tr>
        <tr>
          <td>
            <b>TOTAL <br/>PREMIADOS</b>
          </td>
          <td>
            <center><b>31</b></center>
          </td>
          <td>
            <center><b>11</b></center>
          </td>
          <td>
            <center><b>0</b></center>
          </td>
        </tr>
      </tbody>
    </table>
  </S.TableContainer>
}
