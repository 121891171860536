import styled from 'styled-components'
import paddleImg from '../../../assets/oreo_bar.png'
import { adjustVertical } from '../helpers'

const paddleHeight = adjustVertical(49)

interface PaddleProps {
  $x: number
  $width: number
}

export const Paddle = styled.div.attrs<PaddleProps>((props) => {
  return {
    ...props,
    style: {
      left: props.$x
    }
  }
})<PaddleProps>`
  background: url(${paddleImg}) no-repeat center center;
  background-size: contain;
  bottom: 20px;
  cursor: grab;
  height: ${paddleHeight}px;
  left: ${({ $x }) => $x}px;
  position: absolute;
  width: ${({ $width }) => $width}px;
`
