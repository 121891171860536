import { ReactElement } from 'react'
import PointsProps from './props'
import * as S from './styled'

export default function Points ({ points }: PointsProps): ReactElement {
  const formattedPoints = points.toString().padStart(3, '0')

  return <S.Container>
    <p className="title">Pontos</p>

    <S.CountdownNumbersContainer>
      <div>
        <div>
          <S.CountdownNumberContainer>
            <p>{formattedPoints[0]}</p>
          </S.CountdownNumberContainer>
          <S.CountdownNumberContainer>
            <p>{formattedPoints[1]}</p>
          </S.CountdownNumberContainer>
          <S.CountdownNumberContainer>
            <p>{formattedPoints[2]}</p>
          </S.CountdownNumberContainer>
        </div>
      </div>
    </S.CountdownNumbersContainer>
  </S.Container>
}
