import {
  ComponentType,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState
} from 'react'
import Modal from 'react-modal'
import * as S from './styled'
interface IModalContextValue {
  openModal: (config: IModalConfig) => void
  closeModal: () => void
}

interface IModalConfig {
  header?: string
  body?: string | JSX.Element | null
  size?: string | any
}
interface ContextProps {
  children?: ReactNode
}
const ModalSize: any = {
  small: '280px',
  medium: '50%',
  large: '70%'
}

const ModalContext = createContext<IModalContextValue | null>(null)
const CustomModal = Modal as ComponentType<ReactModal['props']>
export default function ModalProvider ({ children }: ContextProps): JSX.Element {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [header, setHeader] = useState('')
  const [body, setBody] = useState<string | null | JSX.Element>('')
  const [size, setSize] = useState<string>('small')
  const modalRef = useRef<HTMLDivElement | null>(null)
  const modalContentRef = useRef<HTMLDivElement | null>(null)

  const customStyles: Modal.Styles = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: ' translate(-50%, -50%)',
      backgroundColor: ' #fff',
      borderRadius: ' 8px',
      boxShadow: ' 0 2px 4px rgba(0, 0, 0, 0.2)',
      padding: '20px',
      overflow: 'auto',
      width: size === '' ? 'small' : ModalSize[size]
    },
    overlay: {
      zIndex: 9999
    }
  }

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      modalContentRef.current !== null && modalContentRef.current !== undefined &&
      !modalContentRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const closeModal = useCallback(
    (callback?: () => void) => {
      setIsOpen(false)
      document.removeEventListener('mousedown', handleClickOutside)

      if (callback !== null && callback !== undefined && modalRef.current !== null && modalRef.current !== undefined) {
        modalRef.current.addEventListener('animationend', callback, {
          once: true
        })
      }
    },
    [handleClickOutside]
  )
  const openModal = useCallback(
    ({ header, body, size }: IModalConfig) => {
      setIsOpen(true)
      setHeader(header ?? '')
      setBody(body ?? null)
      setSize(size === null || size === undefined ? 'small' : size)
      document.addEventListener('mousedown', handleClickOutside)
    },
    [handleClickOutside]
  )

  const modalContextValue: IModalContextValue = {
    openModal,
    closeModal
  }
  return (
    <>
      <ModalContext.Provider value={modalContextValue}>
        {children}
        <CustomModal
          isOpen={modalIsOpen}
          onRequestClose={() => closeModal}
          style={customStyles}
        >
          <S.Header>{header}</S.Header>
          <hr />
          <S.Body>{body}</S.Body>
          <S.ButtonClose onClick={() => closeModal()}>X</S.ButtonClose>
        </CustomModal>
      </ModalContext.Provider>
    </>
  )
}

export function useModal (): IModalContextValue {
  const context = useContext(ModalContext)

  if (context == null) throw new Error('useModal must be used within a ModalProvider')

  return context
}
