import { ReactElement, ReactNode } from 'react'
import bisLogo from '../assets/bis-logo.png'
import bisOreoLogo from '../assets/bis-oreo-logo.png'
import mondelezLogo from '../assets/mondelez-logo.png'
import * as S from './style'

interface SectionKPISecondContentProps {
  content: ReactNode
  ellipsisName: string
  ellipsisImage: string
  hasDonaGiganteWarning: boolean
  hasBottomImage: boolean
}

export default function SectionKPISecondContent ({ content, ellipsisName, ellipsisImage, hasDonaGiganteWarning, hasBottomImage }: SectionKPISecondContentProps): ReactElement {
  const ellipsisImageOptions = {
    bisLogo,
    mondelezLogo,
    bisOreoLogo
  }

  return (
    <S.SectionKPISecondContentContainer>
      <S.SectionKPIsSecondContentEllipsis>
        <div>
          <p>KPI 2</p>
          <span>{ellipsisName}</span>
        </div>
        {ellipsisImage !== 'false' ? <img src={ellipsisImageOptions[ellipsisImage as keyof typeof ellipsisImageOptions]} alt="Logo da empresa" className='scaled' /> : ''}
      </S.SectionKPIsSecondContentEllipsis>
      <div>
        {content}
      </div>
      {hasDonaGiganteWarning
        ? <S.SectionKPISecondContentWarning>
          *A Dona Gigante atende um cliente com as categorias de Bebidas e Biscoitos, sendo assim, será considerado o Sell Out dessas duas famílias com peso 1. Já o Gigantônio atende um cliente apenas com a categoria Chocolates, portanto será considerado o Sell Out de Chocolates com peso 2 para que ele não seja prejudicado em detrimento dos colegas que atendem mais categorias.
        </S.SectionKPISecondContentWarning>
        : ''}
      <div>
        <S.SectionKPIsSecondContentBottomText>
          Critério desempate: <br />
          - Melhor performance KP1 (Loja ou categoria);<br />
          - Melhor performance KP2 (Marca Foco);<br />
          - Pontos Percentuais;<br />
          - Diferença na abertura das casas decimais.
          <br /><br />
          Em caso de empate na pontuação, os participantes ocuparão a mesma posição no ranking, mas os nomes estarão em ordem alfabética apenas por uma questão de organização
        </S.SectionKPIsSecondContentBottomText>
      </div>
    </S.SectionKPISecondContentContainer>
  )
}
