import { AxiosInstance } from 'axios'
import { LoadReadyOrders } from '../../domain/use-case/LoadReadyOrders'

export class ApiLoadReadyOrders implements LoadReadyOrders {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (): Promise<LoadReadyOrders.Result> {
    const response = await this.api.get('/ready-orders')
    return response.data
  }
}
