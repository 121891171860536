import { AxiosError, AxiosInstance } from 'axios'
import { UpdateHubCampaing } from '../../domain/use-case/UpdateHubCampaing'

export class ApiUpdateCampaing implements UpdateHubCampaing {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (params: UpdateHubCampaing.Params): Promise<UpdateHubCampaing.Result> {
    try {
      const response = await this.api.put(`/hub-campanha/${params.id}`, params)
      return response
    } catch (error) {
      const axiosError = error as AxiosError
      return {
        data: axiosError.response?.data ?? axiosError.name,
        status: axiosError.response?.status ?? 0
      }
    }
  }
}
