import { ReactElement } from 'react'
import safe from '../../../assets/img/incentivo-2024/leilao/COFRE.png'
import * as S from './styled'

export interface DigimoedasWaveCountContainerProps {
  className?: string
  step: number
  value: number
}

export default function DigimoedasWaveCountContainer ({ className, step, value }: DigimoedasWaveCountContainerProps): ReactElement {
  return <S.DigimoedasCountContainer className={className}>
    <S.DigimoedasCountContent>
      <p>Você tem:</p>

      <div>
        <p>{(value).toLocaleString('pt-br', { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</p>
        <p>digimoedas onda {step}</p>
      </div>
    </S.DigimoedasCountContent>

    <figure>
      <img src={safe} />
    </figure>
  </S.DigimoedasCountContainer>
}
