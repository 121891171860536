import { ReactElement } from 'react'
import { ApiApproveOrder } from '../../data/use-case/ApiApproveOrder'
import { ApiLoadReadyOrders } from '../../data/use-case/ApiLoadReadyOrders'
import { ApiReproveOrder } from '../../data/use-case/ApiReproveOrder'
import OrderList from '../../pages/Admin/OrderList'
import { api } from '../../services/api'

export const makeAdminOrderList = (): ReactElement => {
  const approveOrder = new ApiApproveOrder(api)
  const reproveOrder = new ApiReproveOrder(api)
  const loadReadyOrders = new ApiLoadReadyOrders(api)
  return <OrderList approveOrder={approveOrder} reproveOrder={reproveOrder} loadReadyOrders={loadReadyOrders} />
}
