import styled from 'styled-components';

export const PageLoaderContainer = styled.div`
  .page-loader{
    background: var(--primary);
    background: linear-gradient(135deg, var(--primary) 0%, var(--secondary) 100%);
    z-index: 100000;
  }
  img {
    max-width: 15rem;
  }
  .loader-bar {
    background-color: #fff !important;
  }
  .page-loader-decoration {
    margin-bottom: 2rem;
    background-color: transparent;
  }
  .page-loader-info {
    display: none;
  }
`;
