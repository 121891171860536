import { ReactElement, useCallback, useEffect, useState } from 'react'
import { HistoricoAcessos } from '../../../components/HistoricoAcessos'
import PageLoader from '../../../components/PageLoader'
import api from '../../../services/api'
import Template from '../../../template'
import Stats from './Stats'
import { StatsCard } from './StatsCard'
import * as S from './styled'

enum State {
  LOADING,
  READY
}

export interface ParamsProps {
  stepId: string
}

interface PageContent {
  adesao: {
    current: number
    total: number
  }
  quests: {
    total: number
    completedRatio: number
    playersRatio: number
    plays: number
  }
  acessos: any
  engajamento: any
  totalCoins: number
  games: any[]
}

function Dashboard (): ReactElement {
  const [state, setState] = useState<State>(State.LOADING)
  const [data, setData] = useState<PageContent>({} as unknown as PageContent)

  const loadDashboardData = useCallback(async (stepId: string) => {
    const response = await api.get('/admin/dashboard', { params: { stepId } })
    setData(response.data)
    setState(State.READY)
  }, [])

  useEffect(() => {
    loadDashboardData('1')
  }, [loadDashboardData])

  if (state === State.LOADING) return <PageLoader />

  return <Template>
    <S.Grid>
      <S.Column>
        <S.InputSelect onChange={async (value: string) => await loadDashboardData(value)}>
          <option value={1}>Onda 1</option>
          <option value={2}>Onda 2</option>
          <option value={3}>Onda 3</option>
        </S.InputSelect>
        <S.Coins title='Total acumulado' coins={data.totalCoins} />
        <Stats stats={data.adesao} />
      </S.Column>

      <S.DashboardChart chartData={data.acessos} className={''} />

      <S.GridCards>
        <S.Card>
          <S.CardTitle>Missões distribuídas</S.CardTitle>
          <S.CardValue>{data.quests.total}</S.CardValue>
        </S.Card>
        <S.Card>
          <S.CardTitle>% Missões completas</S.CardTitle>
          <S.CardValue>{data.quests.completedRatio}</S.CardValue>
        </S.Card>
        <S.Card>
          <S.CardTitle>Jogadas realizadas</S.CardTitle>
          <S.CardValue>{data.quests.plays}</S.CardValue>
        </S.Card>
        <S.Card>
          <S.CardTitle>% Adesão games</S.CardTitle>
          <S.CardValue>{data.quests.playersRatio}</S.CardValue>
        </S.Card>
      </S.GridCards>
      <S.DashboardTable {...data.engajamento} />
      <HistoricoAcessos />
      {data.games.map((game: any) => <StatsCard stats={game} />)}
    </S.Grid>
  </Template>
}

export default Dashboard
