import { ReactElement, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import CardCampaign from '../../../components/Card/CardCampaign'
import PageLoaderComponent from '../../../components/PageLoader'
import { useAuth } from '../../../contexts/AuthContext'
import { useCampaign } from '../../../contexts/CampaignContext'
import { makeLoadAllCampanhas } from '../../../main/factories/makeLoadAllCampanhas'
import Template from '../../../template'
import CampaignHubDataProps from '../../CampaignHub/props'
import * as S from './style'

const loadAllCampaigns = makeLoadAllCampanhas()

enum State {
  LOADING,
  READY,
  NODATA,
  FAILURE
}

export default function AdminCampaign (): ReactElement {
  const { user, userCan } = useAuth()
  const { campaignData } = useCampaign()
  const navigate = useNavigate()
  const [campaigns, setCampaigns] = useState<CampaignHubDataProps[]>([])
  const [state, setState] = useState<State>(State.LOADING)

  useEffect(() => {
    (async () => {
      const response = await loadAllCampaigns.handle()

      switch (response.status) {
        case 403:
          setState(State.FAILURE)
          toast.error('Você não está autorizado a acessar esta página.', { theme: 'colored' })
          navigate(-1)
          return
        case 204:
          toast.error('No momento, não existem campanhas ativas.', { theme: 'colored' })
          setState(State.NODATA)
          return
        case 200:
          setCampaigns(response.data)
          setState(State.READY)
      }
    })()
  }, [])

  if (!user || !campaigns) return <PageLoaderComponent />

  return (
    <Template>
      <S.Grid>
        <S.HubContent className="account-hub-content">
          <div className="section-header">
            <div className="section-header-info">
              <h2 className="section-title">Campanhas {campaignData.title}</h2>
            </div>
          </div>

          <S.GridPosts>
            {userCan('post:create') && <S.NewPostContainer className="create-entity-box post-preview">
              <div className="create-entity-box-cover"></div>
              <div className="create-entity-box-avatar">
                <svg className="create-entity-box-avatar-icon icon-group">
                  <use href="#svg-group"></use>
                </svg>
              </div>
              <div className="create-entity-box-info">
                <p className="create-entity-box-title">Criar nova campanha</p>
                <p className="create-entity-box-text">Clique no botão para adicionar nova campanha</p>
                <Link to="/admin/campanha/new" className="button primary full popup-manage-group-trigger">Criar nova campanha</Link>
              </div>
            </S.NewPostContainer>}

            {state === State.NODATA && <div>No momento, não existem campanhas ativas.</div>}
            {state === State.READY && campaigns.map((campaign) => <CardCampaign edit key={campaign.id} {...campaign} />)}
          </S.GridPosts>
        </S.HubContent>
      </S.Grid>

    </Template>
  )
}
