import styled from 'styled-components'

export const CountdownContent = styled.div.attrs((props) => {
  return {
    ...props,
    className: 'countdown'
  }
})`
  align-items: center;
  display: flex;
  gap: 16px;

  p {
    color: #f7cb25;
    font-family: MdlzBiteType;
    font-size: 24px;
    letter-spacing: 1px;
    margin: 0;
    text-transform: uppercase;

    @media screen and (max-width: 375px) and (orientation: portrait) {
      font-size: 16px;
    }
  }
`

export const CountdownNumbersContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-around;

  > div > div {
    display: flex;
    gap: 8px;
  }

  > div {
    align-items: center;
    flex-direction: column;
    gap: 8px;
    display: flex;

    > p {
      &:not(:first-child) {
        font-size: 16px;
      }
    }
  }
`

export const SeparatorContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  max-height: 40px;

  p {
    color: #fff;
    vertical-align: middle;
  }
`

export const CountdownNumberContainer = styled.div.attrs((props) => {
  return {
    ...props,
    className: 'number-container'
  }
})`
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 2px 2px 2px 2px #4f216f;
  justify-content: center;
  display: flex;
  filter: drop-shadow(0px 6px 4px rgba(0,0,0,0.15));
  padding: 8px;
  width: 32px;

  p {
    color: #4f2170;
    font-size: 28px;
    text-align: center;
  }

  @media screen and (max-width: 375px) and (orientation: portrait) {
    padding: 4px;
    width: 24px;

    p {
      font-size: 20px;
    }
  }
`
