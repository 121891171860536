import styled from 'styled-components'

export const Message = styled.div.attrs(() => {
  return {
    className: 'js-message'
  }
})`
  background-color: #00000080;
  backdrop-filter: blur(8px);
  display: none;
  inset: 0;
  padding: 16px;
  position: fixed;
  z-index: 9999;

  & > div {
    align-items: center;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  @media (max-width: 911px) and (orientation: landscape) {
    display: flex;
  }
`
