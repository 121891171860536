import styled from 'styled-components'
import descriptionBg from '../../../assets/img/incentivo-2024/mechanics/pascoa/AMEBA_BASE.png'
import background from '../../../assets/img/incentivo-2024/mechanics/pascoa/background.png'
import TemplateBase from '../../../template'

export const Template = styled(TemplateBase)`
  &.content-grid {
    width: initial;
    min-height: initial;
    max-width: initial;
    padding: initial;
  }
`

export const Container = styled.div`
  background-attachment: fixed;
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex: 2;
  min-height: 100vh;
  overflow: hidden;
  width: 100vw;

  h1, h2, p, button {
    font-family: MdlzBiteType;
  }

  @media screen and (max-width: 1365px) and (orientation: portrait) {
    background: none;
    background-color: #4f2170;
    flex: 1;
  }
`

export const MechanicContainer = styled.div`
  align-items: flex-end;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 44.5%;

  @media screen and (max-width: 1365px) and (orientation: portrait) {
    align-items: center;
    flex: 1;
    padding-inline: 32px;
  }
`

export const MechanicContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: -32px;
  gap: 16px;
  width: 68%;

  @media screen and (max-width: 764px) and (orientation: portrait) {
    width: 100%;
  }
`

export const ImageContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 55.5%;
  margin: 0;

  figure {
    max-width: 32vw;
  }

  @media screen and (max-width: 1365px) and (orientation: portrait) {
    display: none;
  }
`

export const MechanicTitle = styled.h1`
  color: #fff;
  font-size: clamp(2.25rem, 0.5194rem + 2.0270vw, 3.75rem);
  letter-spacing: -2px;
  line-height: clamp(2rem, -0.3074rem + 2.7027vw, 4rem);
  text-align: center;
`

export const DescriptionContainer = styled.div`
  align-items: center;
  aspect-ratio: 1.816;
  background-image: url(${descriptionBg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: clamp(1rem, 0.5722rem + 0.8959vw, 2rem);
  width: 100%;
`

export const DescriptionTitle = styled.h2`
  font-size: clamp(3rem, 2.6776rem + 1.4572vw, 5rem);
  letter-spacing: -2px;
  line-height: clamp(3.25rem, 2.9276rem + 1.4572vw, 5.25rem);
  color: #4f2170;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
`

export const TextContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 2;
  gap: 8px;
  margin-inline: 16px;

  > div {
    &:first-child {
      flex: 40%;
    }

    &:last-child {
      flex: 60%;
    }
  }
`

export const Subtitle = styled.p`
  color: #4f2170;
  font-size: clamp(2.75rem, 2.6694rem + 0.3643vw, 3.25rem);
  letter-spacing: -2px;
  line-height: clamp(2rem, 1.7582rem + 1.0929vw, 3.5rem);
  text-align: center;
  white-space: nowrap;

  span {
    font-size: clamp(1.75rem, 1.6291rem + 0.5464vw, 2.5rem);
  }
`

export const SubtitleMerchan = styled.div`
  margin-bottom: 8px;
  & > p {
    color: #4f2170;
    font-size: 12px;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;

    span {
      font-size: 16px;
    }

    @media (min-width: 1280px) {
      font-size: 12px;

      span {
        font-size: 16px !important;
      }
    }
  }
`

export const Text = styled.p`
  font-size: clamp(0.5rem, 0.4194rem + 0.3643vw, 1rem);
  letter-spacing: 0px;
  color: #4f2170;
  font-weight: 400;
  font-family: Roboto;
  margin: 0;
  text-align: center;
`

export const Divider = styled.hr`
  border-left: 2px solid #fff;
  height: 100%;
`

export const MiddleText = styled.p`
  font-size: clamp(24px, 0.6347rem + 1.0135vw, 2.25rem);
  letter-spacing: -1px;
  line-height: clamp(1.75rem, 0.8847rem + 1.0135vw, 2.5rem);
  color: #fff;
  text-align: center;
`

export const ModalButton = styled.button`
  background-color: #ffdc39;
  border-radius: 52px;
  color: #4f2170;
  font-size: clamp(1.25rem, 1.2097rem + 0.1821vw, 1.5rem);
  font-weight: 400;
  line-height: clamp(1.5rem, 1.4597rem + 0.1821vw, 1.75rem);
  padding: 4px 32px;
  text-align: center;
  max-width: fit-content;
`

export const ModalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;

  h1 {
    font-family: MdlzBiteType;
  }
  `

export const ModalTitle = styled.h1`
  border-bottom: 6px solid #ffdc39;
  font-size: clamp(3rem, 2.8388rem + 0.7286vw, 4rem);
  letter-spacing: -2px;
  color: #ffdc39;
  text-align: center;
  white-space: nowrap;

  @media (max-width: 520px) {
    font-size: 2.5rem;
  }
`

export const ModalList = styled.ol`
  color: #fff;
  font-family: Roboto;
  font-size: clamp(1rem, 0.7116rem + 0.3378vw, 1.25rem);
  letter-spacing: -1px;
  list-style: decimal;
  list-style-position: inside;
  padding-inline: 24px;
  text-align: center;

  li {
    margin-inline: 0;

    & + li {
      margin-top: 16px;
    }
  }
`
