import styled from 'styled-components'

export const Character = styled.figure`
  margin-right: -20px;
  max-width: fit-content;

  img {
    width: 100%;
  }

  @media (max-width: 420px) {
    margin-right: -10px;
  }
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 340px;

  h1, p, button {
    color: #284087;
    font-family: MdlzBiteType;
    letter-spacing: 0px;
    margin: 0;
    text-align: center;
  }

  h1 {
    font-size: 36px;
  }

  p {
    font-size: 16px;
  }

  button {
    background-color: #f1cb0c;
    border-radius: 10px;
    font-size: 24px;
    height: 46px;
    letter-spacing: 1px;
    max-width: fit-content;
    outline: none;
    padding-inline: 20px;

    &:hover {
      background-color: #f1cb0cCC;
      color: #284087CC;
    }
  }

  @media (max-width: 600px) {
    max-width: 240px;

    h1 {
      font-size: 28px;
    }

    p {
      font-size: 14px;
    }

    button {
      font-size: 18px;
    }
  }

  @media (max-width: 500px) {
    max-width: 180px;

    h1 {
      font-size: 24px;
    }

    p {
      font-size: 12px;
    }

    button {
      font-size: 14px;
    }
  }

  @media (max-width: 420px) {
    max-width: 150px;

    h1 {
      font-size: 18px;
    }

    p {
      font-size: 10px;
    }

    button {
      font-size: 12px;
    }
  }
`
