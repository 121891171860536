import { ReactElement } from 'react'
import LogoGigaGiro from '../../../../../components/LogoGigaGiro'
import * as S from './styled'

interface ModalDefeatProps {
  TryAgain: () => void
}

function ModalDefeat ({ TryAgain }: ModalDefeatProps): ReactElement {
  return <S.ModalContainer>
    <LogoGigaGiro />

    <S.TryAgainButton onClick={TryAgain}>
      Jogar novamente
    </S.TryAgainButton>
  </S.ModalContainer>
}

export default ModalDefeat
