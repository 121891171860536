import { ReactElement } from 'react'
import { useModalMdlz } from '../../../../contexts/ModalMdlzContext'
import InstructionsModal from '../Puzzle/Modals/InstructionsModal'
import amebaBottom from '../assets/ameba-bottom.png'
import amebaTop from '../assets/ameba-top.png'
import products from '../assets/finished-products.png'
import logo from '../assets/logo-oreo.png'
import webBottom from '../assets/web-bottom.png'
import webTop from '../assets/web-top.png'
import StartGameProps from './props'
import * as S from './styled'

export default function StartGame ({ OnStart }: StartGameProps): ReactElement {
  const { openModal } = useModalMdlz()

  const handleOpenInstructionsModal = (): void => openModal({
    body: <InstructionsModal />,
    customStyles: {
      backgroundColor: 'var(--white)',
      border: '4px solid #e9c300'
    }
  })

  return <S.Container>
    <S.ContentBg>
      <S.Products>
        <img src={products} alt="" />
      </S.Products>

      <S.Content>
        <h1>Pontos <br/>no Susto</h1>

        <figure>
          <img src={logo} alt="Logo Oreo" />
        </figure>

        <S.ButtonsContainer>
          <S.PlayButton onClick={OnStart}>Jogar</S.PlayButton>

          <S.InstructionsButton onClick={handleOpenInstructionsModal}>Ver instruções</S.InstructionsButton>
        </S.ButtonsContainer>
      </S.Content>
    </S.ContentBg>

    <S.WebBottom>
      <img src={webBottom} alt="" />
    </S.WebBottom>

    <S.WebTop>
      <img src={webTop} alt="" />
    </S.WebTop>

    <S.AmebaBottom>
      <img src={amebaBottom} alt="" />
    </S.AmebaBottom>

    <S.AmebaTop>
      <img src={amebaTop} alt="" />
    </S.AmebaTop>
  </S.Container>
}
