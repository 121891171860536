import styled from 'styled-components'

export const TableHeaderTitle = styled.p`
  color: var(--white);
`

export const Image = styled.img`
  width: auto;
  max-height: 100%;
`
