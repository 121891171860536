import { ReactElement, useMemo } from 'react'
import boy from '../../../../assets/img/hub/boy-waving.png'
import * as S from './styled'

interface StatsProps {
  stats: {
    current: number
    total: number
  }
}

export default function Stats ({ stats }: StatsProps): ReactElement {
  const progress = useMemo(() => {
    return Math.floor(stats.current / stats.total * 100)
  }, [stats])
  return <S.Container>
    <S.Card>
      <S.Content>
        <div>
          <S.CardTitle>{stats.current}/{stats.total}</S.CardTitle>
          <S.CardText>Adesão</S.CardText>
        </div>

        <S.Divider />

        <S.Graph progress={progress} />
      </S.Content>
    </S.Card>

    <S.Image>
      <img src={boy} alt="Garoto acenando" />
    </S.Image>
  </S.Container>
}
