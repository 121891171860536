import styled from 'styled-components'
import Card from '../../../components/Card'

export const StatsCard = styled(Card)`
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  height: 172px;
  justify-content: space-around;
  padding: 20px;
  position: relative;
  max-width: 100%;
  width: 303px;

  hr {
    border-left: 1px solid #cfcfd2;
    height: 132px;
    margin: 0;
    width: 1px;
  }

  > figure {
    top: -36px;
    left: -80px;
    position: absolute;
  }

  @media (max-width: 500px) {
    > figure {
      left: -68px;
      scale: .8;
      top: -16px;
    }
  }

  @media (max-width: 400px) {
    > figure {
      display: none;
    }
  }
`

export const StatsContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  h6 {
    color: #1d3f85;
    font-family: MdlzBiteType;
    font-size: 24px;
    margin: 0;
    text-align: center;
  }

  p {
    color: #aeaeae;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    margin: 0;
    text-align: center;
    text-transform: uppercase;

    strong {
      color: #3e405c;
      text-transform: none;
    }
  }

  figure {
    margin: 0;
  }
`
