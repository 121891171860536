import styled from 'styled-components'
import InputTextComponent from '../../../../components/Forms/InputText'

export const Container = styled.form`
  display: flex;
`

export const InputText = styled(InputTextComponent)`
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0px none !important;
`

export const Button = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  flex: 50px;
`
