import { AxiosError, AxiosInstance } from 'axios'
import { CreatePicPayOrder } from '../../domain/use-case/CreatePicPayOrder'

export class ApiCreatePicPayOrder implements CreatePicPayOrder {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (params: CreatePicPayOrder.Params): Promise<CreatePicPayOrder.Result> {
    try {
      const response = await this.api.post('/picpay', { claimValue: params.claimValue })
      return response
    } catch (err) {
      const axiosError = err as AxiosError
      if (axiosError.response) {
        return axiosError.response
      }

      if (axiosError.request) {
        return {
          data: axiosError.request,
          status: 0
        }
      }

      return {
        data: axiosError.message,
        status: 0
      }
    }
  }
}
