import { ReactElement, useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { io } from 'socket.io-client'
import Dropzone from '../../components/Dropzone'
import * as DropzoneStyle from '../../components/Dropzone/style'
import { Progress } from '../../components/Progress'
import { useAuth } from '../../contexts/AuthContext'
import { makeFetchHeader } from '../../main/factories/makeFetchHeader'
import api from '../../services/api'
import Template from '../../template'
import AssociateComponent from './AssociateComponent'
import SendingComponent from './SendingComponent'
import SuccessComponent from './SuccessComponent'

const fetchHeader = makeFetchHeader()

enum State {
  READY,
  TO_FOR,
  LOADING,
  ERROR,
  SENDING,
  SUCCESS
}

type HeadersProps = string[]

function UpdateCampaignResults (): ReactElement {
  const [acceptedFile, setAcceptedFile] = useState<any>()
  const [progressValue, setProgressValue] = useState<number>(0)
  const [state, setState] = useState<State>(State.READY)
  const [errors, setErrors] = useState<any[]>([])
  const [headers, setHeaders] = useState<HeadersProps>([])
  const [link, setLink] = useState<string>('')

  const onDrop = useCallback(async (newAcceptedFiles: any) => {
    const [file] = newAcceptedFiles
    setAcceptedFile(file)
    handleProgress()

    if (file) {
      setHeaders([])
      setState(State.LOADING)
      try {
        const result = await fetchHeader.handle({ file })
        setHeaders(result.headers)
        setState(State.TO_FOR)
      } catch (error) {
        console.error('Error reading Excel file:', error)
        toast.error('Ocorreu um erro ao importar a planilha. Por favor, tente novamente.', { theme: 'colored' })
        setErrors(['Erro'])
        setState(State.ERROR)
      }
    }
  }, [])

  const handleProgress = useCallback(async () => {
    setProgressValue(0)
    const totalSteps = 10
    const delay = 100

    for (let i = 1; i <= totalSteps; i++) {
      await new Promise((resolve) => setTimeout(resolve, delay))
      const newProgress = Math.round((i / totalSteps) * 100)
      setProgressValue(newProgress)
    }
  }, [])

  const handleOnAssociationComplete = useCallback(async (data: any) => {
    setState(State.SENDING)
    const formData = new FormData()
    formData.append('file', acceptedFile)
    Object.keys(data).forEach(key => {
      formData.append(key, data[key])
    })
    await api.post('/hub-campanha/minhas-campanhas/1/import', formData)
  }, [acceptedFile])

  const { token } = useAuth()
  useEffect((): any => {
    const ioServer = io(String(process.env.REACT_APP_BASE_URL), {
      query: { token },
      transports: ['websocket']
    })

    ioServer.on('report-file', link => {
      setLink(link)
      setState(State.SUCCESS)
    })

    return () => ioServer.close()
  }, [token])

  if (state === State.TO_FOR) return <AssociateComponent headers={headers} onComplete={handleOnAssociationComplete} />
  if (state === State.SENDING) return <SendingComponent />
  if (state === State.SUCCESS) return <SuccessComponent link={link} />

  return <Template>
    <div className="grid medium-space">
      <div className="account-hub-content">
        <div className="section-header">
          <div className="section-header-info">
            <p className="section-pretitle">Upload Resultados</p>

            <h2 className="section-title">
              Envie uma planilha para atualizar
            </h2>
          </div>
        </div>

        <div className="grid-column">
          <div className="widget-box">
            <p className="widget-box-title">Upload Resultados</p>
            <div className="widget-box-content">
              <Dropzone
                acceptedFile={acceptedFile}
                mimeTypes={{
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    ['.xlsx']
                }}
                handleOnDrop={onDrop}
                imageDrop={
                  <svg style={{ width: '40px', height: '40px' }}>
                    <use href="#svg-upload-xlsx" />
                  </svg>
                }
                message=" É permitido apenas o uso de planilhas no formato Excel (extensão .xlsx)"
              > {progressValue === 0 ? '' : <Progress color="#615dfa" progress={progressValue} width={1} />}
                <>
                  <div>
                    {state === State.LOADING && <p>Loading...</p>}
                    {state === State.READY && headers.length > 0 && (
                      <DropzoneStyle.Table>
                        <thead>
                          <tr>
                            {headers.map((header, index) => (
                              <th key={index}>{header}</th>
                            ))}
                          </tr>
                        </thead>
                      </DropzoneStyle.Table>
                    )}
                  </div>
                  {state === State.ERROR && (
                    <DropzoneStyle.Table>
                      <thead>
                        <tr>
                          <th>Linha</th>
                          <th>Erros</th>
                        </tr>
                      </thead>
                      {errors.map((e, index) => (
                        <thead key={index}>
                          <tr>
                            <td>{e.number}</td>
                            <td>
                              {e.errors.map((err: any, index: number) => (
                                <p key={index}>{err}</p>
                              ))}
                            </td>
                          </tr>
                        </thead>
                      ))}
                    </DropzoneStyle.Table>
                  )}
                </>
              </Dropzone>
            </div>
          </div>
        </div>
        <div className="grid-column errors">

        </div>
      </div>
    </div>
  </Template>
}

export default UpdateCampaignResults
