import { ComponentType } from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'

export const Header = styled.h4`
  margin-top: 15px;
  max-width: 470px;
`

export const Body = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr;
  gap: 4px;
  overflow: auto;
  max-height: 480px;
`

export const ButtonClose = styled.button`
  border: 1px solid #fff;
  display: flex;
  position: absolute;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  top: -8px;
  right: -8px;
  width: 40px;
  height: 40px;
  background-color: #4f2170;
`

export const CustomModal = styled(Modal as ComponentType<ReactModal['props']>) <{ size?: string }>`
  background-color:  #0e3e8a;
  border-radius: 36px;
  box-shadow:  0 2px 4px rgba(0, 0, 0, 0.2);
  left: 50%;
  overflow: visible;
  padding: 20px;
  position: fixed;
  top: 57%;
  transform:  translate(-50%, -50%);
  width: ${({ size = 'small' }) => size};

  @media screen and (max-width: 1366px) {
    width: 50%;
  }

  @media screen and (max-width: 764px) and (orientation: portrait) {
    width: calc(100% - 64px);
  }
`
