import styled from 'styled-components'
import background from '../assets/default-bg.png'
import contentBg from '../assets/start-container-bg.png'

export const Container = styled.div`
  align-items: center;
  display: flex;
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  justify-content: center;
  padding: 100px 32px;
  position: relative;
  width: 100%;

  @media (max-width: 500px) {
    padding: 100px 32px 55% 32px;
  }
`

export const ContentBg = styled.div`
  align-items: center;
  aspect-ratio: 528 / 461;
  background-image: url(${contentBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  position: relative;
  max-width: 528px;
  width: 100%;

  @media (max-width: 1024px) {
    max-width: 480px;
  }

  @media (max-width: 632px) {
    max-width: 90%;
  }
`

export const Products = styled.figure`
  max-width: fit-content;
  position: absolute;
  left: -72px;
  top: 0px;

  img {
    aspect-ratio: 712 / 155;
    width: fit-content;
    max-width: 712px;
  }

  @media (max-width: 1024px) {
    left: -36px;

    img {
      max-width: 600px;
    }
  }

  @media (max-width: 632px) {
    left: -24px;

    img {
      max-width: 108%;
    }
  }
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 72px;
  width: 100%;
  z-index: 0;

  h1 {
    color: #e9c300;
    font-size: 80px;
    letter-spacing: -3px;
    line-height: 56px;
    text-align: center;
    white-space: nowrap;
  }

  figure {
    max-width: fit-content;
    margin: -4px 0 0;

    img {
      width: 92%;
    }
  }

  @media (max-width: 1024px) {
    margin-top: 52px;

    h1 {
      font-size: 72px;
      line-height: 52px;
    }

    figure {
      max-width: 280px;
    }
  }

  @media (max-width: 550px) {
    h1 {
      font-size: 64px;
      line-height: 48px;
    }

    figure {
      max-width: 240px;
    }
  }

  @media (max-width: 450px) {
    margin-top: 64px;

    h1 {
      font-size: 56px;
      line-height: 40px;
    }

    figure {
      max-width: 60%;
    }
  }

  @media (max-width: 380px) {
    margin-top: 52px;

    h1 {
      font-size: 44px;
      line-height: 32px;
    }

    figure {
      margin-top: 0;
      max-width: 50%;
    }
  }
`

export const ButtonsContainer = styled.div`
  margin-top: -16px;

  button:focus {
    outline: none;
  }

  @media (max-width: 450px) {
    margin-top: -8px;
  }
`

export const PlayButton = styled.button`
  border-radius: 12px;
  background-color: #e9c300;
  color: #2d0f47;
  font-family: MdlzBiteType;
  font-size: 36px;
  font-weight: 500;
  height: 36px;
  line-height: 24px;
  text-align: center;

  @media (max-width: 550px) {
    font-size: 28px;
  }

  @media (max-width: 450px) {
    font-size: 20px;
    height: 24px;
  }
`

export const InstructionsButton = styled.button`
  background-color: transparent;
  color: #ffffff;
  font-family: MdlzBiteType;
  font-size: 18px;
  line-height: 24px;
  margin-top: 4px !important;
  text-align: center;

  @media (max-width: 550px) {
    font-size: 16px;
  }

  @media (max-width: 450px) {
    font-size: 12px;
    height: 20px;
    margin-top: initial !important;
  }
`

const AbsoluteImage = styled.figure`
  max-width: fit-content;
  position: absolute;

  img {
    width: 100%;
  }

  @media (max-width: 1280px) {
    max-width: 50%;
  }
`

export const AmebaBottom = styled(AbsoluteImage)`
  bottom: -28px;
  right: 0;

  @media (max-width: 520px) {
    bottom: 80px;
  }
`

export const AmebaTop = styled(AbsoluteImage)`
  left: 0;
  top: 56px;

  @media (max-width: 520px) {
    top: 0;
  }
`

export const WebBottom = styled(AbsoluteImage)`
  bottom: -28px;
  left: -28px;

  @media (max-width: 520px) {
    bottom: 80px;
  }
`

export const WebTop = styled(AbsoluteImage)`
  right: -28px;
  top: 40px;

  @media (max-width: 520px) {
    top: 0;
  }
`
