import styled from 'styled-components'

export const PresencaTextContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 46px;
    div p {
        color: #FFF;
        font-size: 16px;
        max-width: 245px;
        margin: 0px auto;
        text-align: center;

        &.pegadas {
            align-items: flex-end;
            display: flex;
        }
    }
    div p img {
        width: 25px;
        margin-left: 4px;
    }
`

export const PresencaTextContainerBottom = styled.div`
    margin-top: 40px;
    margin-bottom: 50px;
    p {
        text-align: left !important;
        & strong, & span {
            font-weight: 900;
        }
        & span {
            color: #FFC01A;
        }
    }
`
