import styled from 'styled-components'

export const ListGigaPassosTextContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 43px;
    font-weight: 300;
    div {
        display: flex;
        align-items: center;
        p {
            color: #FFF;
            font-size: 16px;
            line-height: 22px;
            margin: 0;
            margin-top: 2px;
                
            @media screen and (max-width: 390px) {
                font-size: 15px;
            }
        }
        img {
            width: 16px;
            margin-left: 5px;
        }
    }
`
