import styled from 'styled-components'
import TemplateBase from '../../../template'
import modalBg from './assets/game_1_bg.png'

export const Template = styled(TemplateBase)`
  &.content-grid {
    width: initial;
    min-height: initial;
    max-width: initial;
    padding: initial;
  }
`

export const Container = styled.div`
  align-items: center;
  background-color: #192e8d;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;

  h1, h2, p, button {
    font-family: MdlzBiteType;
    margin: 0;
  }
`

export const ModalBg = styled.div`
  align-items: center;
  display: flex;
  background-image: url(${modalBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  justify-content: center;
  padding: 100px 32px;
  position: relative;
  width: 100%;

  @media (max-width: 500px) {
    padding: 100px 32px 55% 32px;
  }
`
