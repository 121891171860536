import { ReactElement, ReactNode } from 'react'
import mondelezLogo from '../assets/mondelez-logo.png'
import * as S from './style'

interface SectionKPIFirstContentProps {
  content: ReactNode
  ellipsisName: string
  ellipsisImage: boolean
  children: ReactNode
}

export default function SectionKPIFirstContent ({ content, ellipsisImage, ellipsisName, children }: SectionKPIFirstContentProps): ReactElement {
  return (
    <S.SectionKPIFirstContentContainer>
      <S.SectionKPIsFirstContentEllipsis>
        <div>
          <p>KPI 1</p>
          <span>{ellipsisName}</span>
        </div>
        {ellipsisImage ? <img src={mondelezLogo} alt="Logo da empresa" /> : ''}
      </S.SectionKPIsFirstContentEllipsis>
      <div>
        {content}
      </div>
      <S.SectionKPIFirstContentBottomText>
        {children}
      </S.SectionKPIFirstContentBottomText>
    </S.SectionKPIFirstContentContainer>
  )
}
