/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useCallback, useEffect, useState } from 'react'
import hoverA from '../../../../assets/img/game-gira-giro/OVO-FAVORITOS.png'
import hoverC from '../../../../assets/img/game-gira-giro/OVO-OURO-BRANCO.png'
import hoverB from '../../../../assets/img/game-gira-giro/OVO-SONHO-DE-VALSA.png'
import ameba from '../../../../assets/img/game-gira-giro/amebas.png'
import coin from '../../../../assets/img/game-gira-giro/coin.png'
import character from '../../../../assets/img/game-gira-giro/posicao3.png'
import lateral from '../../../../assets/img/game-gira-giro/question-lateral.png'
import { useModalMdlz } from '../../../../contexts/ModalMdlzContext'
import CountdownComponent from '../../../../games/Memoria/CountdownComponent'
import api from '../../../../services/api'
import ModalDefeat from './ModalDefeat'
import GiroQuizProps from './props'
import * as S from './styled'

enum State {
  READY,
  SELECTED,
  DEFEAT
}

const hovers = [hoverA, hoverC, hoverB]
interface GiroQuizState {
  currentState: State
  selectedAlternative: number
  timer: number
}

export default function GiroQuiz ({ coins, quiz, Finish, TryAgain }: GiroQuizProps): ReactElement {
  const [state, setState] = useState<GiroQuizState>({
    currentState: State.READY,
    selectedAlternative: -1,
    timer: 40
  })

  const { openModal, closeModal } = useModalMdlz()

  const handleSelectCard = useCallback(async (index: number) => {
    if (state.currentState !== State.READY) return
    const alternative = quiz.Alternativas[index]

    setState({
      ...state,
      currentState: State.SELECTED,
      selectedAlternative: index
    })

    setTimeout(() => {
      openModal({
        body: <ModalDefeat TryAgain={handleTryAgain} />,
        hasCloseButton: false
      })
    }, 2000)

    try {
      await api.post('/games/giragiro', {
        questionId: quiz.giragiroId,
        alternativeId: alternative.id
      })
    } catch (err) { }
  }, [state])

  const handleTryAgain = useCallback(() => {
    closeModal()
    TryAgain()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (state.currentState !== State.READY) return
      setState((state) => {
        return {
          ...state,
          timer: state.timer - 1
        }
      })
    }, 1000)
    return () => clearInterval(interval)
  }, [state.currentState])

  useEffect(() => {
    if (state.timer === 0) {
      setState({
        ...state,
        currentState: State.DEFEAT
      })

      Finish()
    }
  }, [state.timer])

  return <S.Container>
    <S.LateralImage>
      <img src={lateral} alt="" />
    </S.LateralImage>

    <S.QuestionContainer>
      <h1></h1>

      <S.QuestionBubble>
        <S.QuestionContent>
          <p dangerouslySetInnerHTML={{ __html: quiz.title }}></p>
        </S.QuestionContent>
      </S.QuestionBubble>

      <S.BottomContainer>
        <S.Character>
          <img src={character} alt="" />
        </S.Character>

        <div>
          <S.AlternativesContainer>
            {quiz.Alternativas.map((item, index: number) => <S.AlternativeContainer $correct={item.isCorrect} $selected={state.currentState === State.SELECTED} onClick={async () => await handleSelectCard(index)}>
              {/* <S.HoverImage>
                <img src={hovers[index]} alt="" />
              </S.HoverImage> */}
              <S.AlternativeIndexContainer key={index} $correct={item.isCorrect} $selected={state.currentState === State.SELECTED}>
                <p>{String.fromCharCode(65 + index)}&#41;</p>
              </S.AlternativeIndexContainer>

              <S.AlternativeContent $correct={item.isCorrect} $selected={state.currentState === State.SELECTED}>
                <p>{item.title}</p>
              </S.AlternativeContent>

              <S.CorrectAnswerImage $correct={state.currentState === State.SELECTED}>
                <img src={ameba} alt="" />
              </S.CorrectAnswerImage>
            </S.AlternativeContainer>)}
          </S.AlternativesContainer>

          <S.CountdownContainer>
            <CountdownComponent time={state.timer} />

            <S.DigimoedasContainer>
              <p>{coins} Digimoedas</p>

              <figure>
                <img src={coin} alt="Digimoeda" />
              </figure>
            </S.DigimoedasContainer>
          </S.CountdownContainer>
        </div>
      </S.BottomContainer>
    </S.QuestionContainer>
  </S.Container >
}
