import React, { useCallback, useEffect, useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { io } from 'socket.io-client'
import { useAuth } from '../../contexts/AuthContext'
import * as S from './style'

export interface DropzoneProps {
  image?: string
  message?: string
  typeName?: string
  mimeTypes: Accept
  handleOnDrop?: (files: File[]) => void | Promise<void>
  init?: any
  acceptedFile?: File | null
  children?: React.ReactNode
  imageDrop?: any
  progress?: number
  maxFiles?: number
}

export default function Dropzone ({
  image,
  message,
  typeName,
  mimeTypes,
  handleOnDrop,
  init,
  acceptedFile,
  children,
  imageDrop,
  maxFiles = 1
}: DropzoneProps): JSX.Element {
  const [errors, setErrors] = useState<any[]>([])
  const [files, setFiles] = useState<Array<File & { preview: string }>>([])
  const [, setSocket] = useState<any>()
  const onDragLeave = useCallback(() => { }, [])
  const onDragEnter = useCallback(
    (isAccepted: boolean, isReject: boolean) => { },
    []
  )
  const onDropRejected = useCallback((files: any) => { }, [])

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setFiles(() => [...acceptedFiles.map((file) => (
        Object.assign(file, { preview: URL.createObjectURL(file) })
      ))])

      handleOnDrop != null && await handleOnDrop(acceptedFiles)
    }
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    onDropRejected,
    onDragLeave,
    onDragEnter: () => onDragEnter(isDragAccept, isDragReject),
    maxFiles,
    accept: mimeTypes
  })

  const { token } = useAuth()

  const handlePlaceholder = useCallback(() => {
    return Object.keys(mimeTypes).some((key) => key.includes('image'))
      ? 'Arraste uma imagem para cá ou clique para selecionar'
      : 'Arraste um arquivo para cá ou clique para selecionar'
  }, [mimeTypes])

  useEffect((): (() => void) => {
    const ioServer = io(String(process.env.REACT_APP_BASE_URL), {
      query: { token },
      transports: ['websocket']
    })

    ioServer.on('import-progress', (chunk) => {
      if (chunk.errors.length > 0) {
        setErrors((errors) => {
          return [chunk, ...errors]
        })
      }
    })

    ioServer.on('import-completed', (chunk) => {
      toast.success('Planilha importada com sucesso!', { theme: 'colored' })
    })

    setSocket(ioServer)
    return () => ioServer.close()
  }, [token])

  return (
    <>
      <S.DropZone {...getRootProps()}>
        <input role='button' {...getInputProps()} />
        <h3>{typeName}</h3>

        {isDragActive
          ? <p>Solte aqui...</p>
          : <p>{handlePlaceholder()}</p>}

        {files.length > 0 && <S.PreviewsList>
          {files.map(({ name, preview, type }) => (
            <S.Preview key={name}>
              <button onClick={() => setFiles((prev) => prev.filter((item) => item.name !== name))}>
                <i className="fa fa-times-circle"></i>
              </button>
              <figure>
                {
                  type.includes('image')
                    ? <img
                    alt={name}
                    onLoad={() => URL.revokeObjectURL(preview)}
                    src={preview}
                  />
                    : <i className="fa-regular fa-file-excel fa-xl"></i>
                }
              </figure>
              <p>{name}</p>
            </S.Preview>
          ))}
        </S.PreviewsList>}

        {imageDrop}
        {message != null && <S.DropZoneMessage {...getRootProps()}>{message}</S.DropZoneMessage>}

        {acceptedFile != null && (
          <p style={{ color: 'black', fontWeight: '700' }}>
            {acceptedFile.name}
          </p>
        )}
      </S.DropZone>

      {children}

      {errors.length > 0 && <S.Table>
        <thead>
          <tr>
            <th>Linha</th>
            <th>Planilha</th>
            <th>Erros</th>
          </tr>
        </thead>
        {errors.slice(0, 10).map((e, index) => <thead key={index}>
          <tr>
            <td>{e.number}</td>
            <td>{e.worksheet}</td>
            <td>{e.errors.map((err: string) => <p>{err}</p>)}</td>
          </tr>
        </thead>)}
      </S.Table>}
    </>
  )
}
