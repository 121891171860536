import rank from '../../../assets/img/incentivo-2024/home/amebas.png';
import * as S from './style';

function RankingImage ({ position }) {
  return <S.Container className="user-stat">
    <S.Rank>
      <img src={rank} alt="posição no rank" />
      <div><small>POSIÇÃO</small>{position}</div>
    </S.Rank>
  </S.Container>;
}

export default RankingImage;
