import styled from 'styled-components'
import background from '../assets/game_1_bg.png'

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  gap: 20px;
  min-height: 100vh;
  justify-content: center;
  padding: 100px 32px;
  position: relative;
  width: 100%;

  @media (max-width: 1000px) {
    align-items: center;
    flex-direction: column;

    > :first-child,
    > :last-child {
      display: none;
    }
  }

  @media (max-width: 500px) {
    padding: 50px 32px 55% 32px;
  }

`

export const MobileTimeScoreContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-around;
  width: 100%;

  @media (min-width: 1001px) {
    display: none;
  }

  @media (max-width: 432px) {
    flex-direction: column;
  }
`
