import { GetGiraGiroGame } from '../../domain/use-case/GetGiraGiroGame'
import api from '../../services/api'

export class RemoteGetGiraGiroGame implements GetGiraGiroGame {
  async handle (): Promise<GetGiraGiroGame.Result> {
    const response = await api.get('/games/giragiro')

    return response.data
  }
}
