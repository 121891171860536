import { ReactElement } from 'react'
import PicPayProps from '../props'
import character from './assets/character.png'
import * as S from './style'

export default function Intro ({ onClick }: PicPayProps): ReactElement {
  return <>
    <S.Character>
      <img src={character} alt="" />
    </S.Character>

    <S.Content>
      <h1>Confira <br/>o seu cadastro <br />para resgatar o valor</h1>

      <p>
        Antes de começar, verifique se você possui uma conta ativa no PicPay. Caso ainda não tenha uma conta, é rápido e fácil criar uma. Clique aqui para baixar o aplicativo PicPay e fazer o cadastro. Após concluir o cadastro e ativar sua conta PicPay, você poderá acessar a funcionalidade transferência de pontos.
      </p>

      <button onClick={onClick}>Completar cadastro</button>
    </S.Content>
  </>
}
