import { ReactElement } from 'react'
import arrowBack from '../../../assets/img/hub/arrow-back.svg'
import arrowForward from '../../../assets/img/hub/arrow-forward.svg'
import coin from '../../../assets/img/hub/auction/coin.png'
import girl from '../../../assets/img/hub/auction/girl-megaphone.png'
import SectionHeaderTitleProps from './props'
import * as S from './styled'

export default function SectionHeaderTitle ({ arrowBackAction, arrowForwardAction, title, className }: SectionHeaderTitleProps): ReactElement {
  return <S.Container className={className}>
    <h1 className="section-title">{title}</h1>

    {
      title === 'Leilão' && <S.AuctionMiddleContainer>
        <figure>
          <img src={girl} alt="" />
        </figure>

        <S.CoinCountContainer>
          <div>
            <p>
              Você tem:
            </p>
          </div>

          <div>
            <p>100</p>

            <div>
              <figure>
                <img src={coin} alt="" />
              </figure>

              <p>Digimoedas</p>
            </div>
          </div>
        </S.CoinCountContainer>
      </S.AuctionMiddleContainer>
    }

    {
      (arrowBackAction && arrowForwardAction) && <div>
        <button onClick={arrowBackAction}>
          <figure>
            <img src={arrowBack} alt="Voltar" />
          </figure>
        </button>

        <button onClick={arrowForwardAction}>
          <figure>
            <img src={arrowForward} alt="Próximo" />
          </figure>
        </button>
      </div>
    }
  </S.Container>
}
