import { ReactElement, useEffect, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import api from '../../services/api'
import Auction from './Auction'
import Badges from './Badges'
import Excercises from './Exercises'
import Games from './Games'
import MecanicaExtra from './MecanicaExtra'
import Missions from './Missions'
import Stats from './Stats'
import TopBanner from './TopBanner'
import * as S from './styled'

export default function Hub (): ReactElement {
  const { userCan } = useAuth()
  const [state, setState] = useState<any>()

  useEffect(() => {
    (async () => {
      const response = await api.get('/hub')
      setState(response.data)
    })()
  }, [])

  if (!state) return <>...</>

  return <S.Template>
    <S.Container>
      <TopBanner />

      <S.Content>
        <S.MissionsSection>
          <Missions missions={state.quest} />

          {userCan('read:badges') && <Badges />}

          <Stats stats={state} />
        </S.MissionsSection>

        <S.GamesSection>
          <Games />
        </S.GamesSection>

        {userCan('read:auction') && <S.AuctionSection>
          <Auction />
        </S.AuctionSection>}

        <S.MecanicaExercisesSection>
          {userCan('read:extra-pascoa') && <MecanicaExtra />}

          {userCan('read:excercise') && <Excercises />}
        </S.MecanicaExercisesSection>
      </S.Content>
    </S.Container>
  </S.Template>
}
