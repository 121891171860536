import { ReactElement, useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import PageLoaderComponent from '../../../components/PageLoader'
import { makeLoadMyHubCampanha } from '../../../main/factories/makeLoadMyHubCampanha'
import CampaignHubDataProps from '../../CampaignHub/props'
import * as S from './styled'

const loadMyHubCampaign = makeLoadMyHubCampanha()

enum State {
  LOADING,
  READY
}

function CampaignHubMecanica (): ReactElement {
  const { slug } = useParams()
  const [campaign, setCampaign] = useState<CampaignHubDataProps>({
    id: 0,
    description: '',
    banner: '',
    createdId: '',
    deletedAt: new Date(),
    deletedId: '',
    logo: '',
    name: '',
    ratio: 0,
    registred: 0,
    mecanicas: '',
    regulation: {
      title: '',
      content: '',
      createdAt: new Date()
    },
    users: [],
    total: 0,
    updatedAt: '',
    updatedId: '',
    hasResult: false
  })
  const [state, setState] = useState<State>(State.LOADING)

  const loadHubCampaign = useCallback(async () => {
    try {
      const response = await loadMyHubCampaign.handle({ id: String(slug) })
      setCampaign(response.data)
      console.log(response.data)
      setState(State.READY)
    } catch (error) {
      console.error(error)
      toast.error('Não foi possível carregar os detalhes da campanha. Por favor, tente novamente.', { theme: 'colored' })
    }
  }, [slug])

  useEffect(() => {
    loadHubCampaign()
    document.body.scrollTop = 500
    document.documentElement.scrollTop = 500
  }, [loadHubCampaign])

  if (state !== State.READY) return <PageLoaderComponent />

  return <S.Template>
    <S.Container>
      <S.TopBanner banner={campaign.banner} />
      <S.RegulationOpenContainer className="content-grid full">
        <S.ButtonsContainer>
          <Link to="/hub-de-campanhas">
            <button>Voltar para o Hub</button>
          </Link>

          {campaign.hasResult && <Link to={`/hub-de-campanhas/${campaign.id}/ranking`}>
            <button>Ver resultados</button>
          </Link>}
        </S.ButtonsContainer>

        <article className="regulation-open">
          <S.RegulationCard className="regulation-open-body">
            <div className="regulation-open-heading">
              <h2 className="regulation-open-title">{campaign.name}</h2>
            </div>
            <div className="regulation-open-content">
              <div className="regulation-open-content-body">
                <p className="regulation-open-paragraph" dangerouslySetInnerHTML={{ __html: campaign.mecanicas }}></p>
              </div>
            </div>
          </S.RegulationCard>
        </article>
      </S.RegulationOpenContainer>
    </S.Container>
  </S.Template>
}

export default CampaignHubMecanica
