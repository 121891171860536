import { ReactElement } from 'react'
import { CardProps } from './props'
import * as S from './style'

function Card ({ onClick, children, ...rest }: CardProps): ReactElement {
  return <S.Container onClick={onClick}>
    <S.CardFace {...rest} />

    <S.CardFace {...rest}>
      {children}
    </S.CardFace>
  </S.Container>
}

export default Card
