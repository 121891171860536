import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Card from '..'

export const Container = styled(Card)`
  box-shadow: 0 0 40px 0 rgba(0,0,0,.06);
  display: flex;
  max-width: initial;
  height: 100%;
`

export const Banner = styled.figure`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin: 0;
  overflow: hidden;
  width: 100%;

  img {
    object-fit: cover;
    height: auto;
  }
`

export const Content = styled.div`
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  font-weight: 700;
  height: 100%;
  justify-content: space-between;
  padding: 20px 20px 28px;
`

export const AvatarHexagon = styled.figure`
  background-color: var(--white);
  display: flex;
  height: 100%;
  margin: 0 auto;
  max-height: 130px;
  max-width: 120px;
  width: 100%;

  img {
    clip-path: inherit;
    height: 110px;
    object-fit: cover;
    margin: auto;
    width: 100px;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;

  h4, p {
    line-height: 150%;
    margin: 0;
    font-family: Roboto;
    text-align: center;
  }

  h4 {
    color: #3e3f5e;
    font-size: 1.25rem;
  }

  p {
    color: #adafca;
    font-size: 0.75rem;
    margin: 0;
    text-transform: uppercase;
  }
`

export const StatsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-around;
  margin-top: 30px;
  text-align: center;
  width: 100%;

  > div {
    padding: 0;
  }
`

export const UsersContainer = styled.div`
  display: flex;

  figure + figure {
    margin-left: -10px;
  }
`

export const UserContainer = styled.figure`
  background-color: var(--primary);
  clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  display: flex;
  height: 32px;
  margin: 34px 0 0;
  position: relative;
  width: 30px;

  &:has(a) {
    height: 36px;
    width: 34px;

    a {
      color: var(--white);
      font-size: 12px;
      margin: auto;
      text-decoration: none;
      height: 100%;
      text-align: center;
      vertical-align: middle;
      line-height: 36px;
    }
  }

  img {
    clip-path: inherit;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

export const EditLink = styled(Link)`
  width: 100%;
`

export const JoinButton = styled.button`
  align-items: center;
  background-color: var(--primary);
  border-radius: 10px;
  box-shadow: 4px 7px 12px 0 rgba(35, 210, 226, 0.2);
  color: var(--white);
  display: flex;
  font-family: Roboto !important;
  font-size: 14px;
  font-weight: bold;
  gap: 16px;
  height: 48px;
  justify-content: center;
  margin-top: 40px !important;
  padding-inline: 20px;
  transition: background-color .2s ease-in-out,color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out;
  white-space: nowrap;

  &:hover {
    background-color: var(--primary-opacity);
  }
`

export const RegulationModal = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 12px 8px;

  h1 {
    color: #F5BE4A;
    font-family: MdlzBiteType;
    font-size: 32px;
  }

  p {
    color: #F5BE4A;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }
`

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-around;
  margin-top: 40px;
  width: 100%;

  button {
    align-items: center;
    border-radius: 10px;
    color: var(--white);
    display: flex;
    font-family: Rajdhani;
    font-size: 14px;
    gap: 16px;
    height: 48px;
    justify-content: center;
    padding-inline: 20px;
    transition: background-color .2s ease-in-out,color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out;
    white-space: nowrap;
  }
`

export const AcceptButton = styled.button`
  background-color: var(--success);

  &:hover {
    background-color: #28a745CC;
  }
`

export const RejectButton = styled.button`
  background-color: var(--danger);

  &:hover {
    background-color: #DC3545CC;
  }
`
