import { Link } from 'react-router-dom'
import styled from 'styled-components'

import footer from '../../assets/img/incentivo-2024/home/HOME_FOOTER.png'
import header from '../../assets/img/incentivo-2024/home/HOME_HEADER.png'
import background from '../../assets/img/incentivo-2024/home/bg-home.jpg'

export const HomeContainer = styled.div.attrs(() => {
  return {
    className: 'js-home-container'
  }
})`
  background-color: #427ae5;
  width: 100%;
`

export const Header = styled.header<{ menuOpen: boolean }>`
  background-image: url(${header});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 50%;
  max-height: 316px;
  padding-top: 164px;
  position: absolute;
  /* overflow: hidden; */
  top: -125px;
  transform: translateX(-50%);
  width: 100%;
  z-index: ${({ menuOpen }) => menuOpen ? 1 : 'auto'};

  a, p, li {
    color: #fff;
    font-weight: 600;
    white-space: nowrap;
  }
`

export const ToggleContainer = styled.div.attrs(() => {
  return {
    className: 'js-toogle-container'
  }
})`
  display: flex;
  flex: 1;

  @media (min-width: 950px) {
    display: none;
  }
`

export const MenuToggle = styled.button`
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 8px;
  outline: none;
  width: fit-content;

  > img {
    max-width: 32px;
  }

  @media screen and (min-width: 1366px) and (orientation: portrait) {
    display: none;
  }
`

export const CloseIcon = styled.button`
  align-self: flex-start;
  background-color: transparent;
  border: none;
  display: flex;
  outline: none;
  width: fit-content;

  img {
    max-width: 32px;
  }
`

export const Menu = styled.div`
  align-items: center;
  background-color: #4f7bdd;
  border-bottom-left-radius: 68px;
  border-bottom-right-radius: 68px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  /* margin: 0 20px; */
  padding: 32px;
  position: absolute;
  top: 124px;
  width: 92%;
  left: 50%;
  margin: auto;
  transform: translateX(-50%);
  z-index: 99;

  > div {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`

export const MenuItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  gap: 8px;

  img {
    height: 32px;
    width: 32px;
  }

  p {
    font-family: MdlzBiteType;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
  }
`

export const MobileButton = styled.button`
  align-items: center;
  border-radius: 60px;
  background-color: #ffdc39;
  justify-content: center;
  height: 48px;
  padding: 0 20px;
  display: flex;
  position: absolute;
  left: 50%;
  margin: auto;
  transform: translateX(-50%);
  bottom: 16%;
  color: #153aaa;
  font-family: MdlzBiteType;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  margin-top: 200px;
  max-width: fit-content;

  @media screen and (orientation: landscape) {
    display: none;
  }
`

export const HeaderImg = styled.img`
  height: 100%;
  left: -150px;
  max-width: 304px;
  position: absolute;
  top: -5%;

  @media screen and (max-width: 1365px) and (orientation: portrait) {
    display: none;
  }
`

export const HeaderContent = styled.div.attrs(() => {
  return {
    className: 'js-header-content'
  }
})`
  align-items: center;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1365px) and (orientation: portrait) {
    gap: 16px;
    justify-content: space-between;
    padding-inline: 16px;
  }

  @media (max-width: 950px) {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;

    &>figure {
      margin: 0 auto;
    }
  }
  `

export const MdlzLogoContainer = styled.figure`
  margin: 0 1.5rem 0 0;
  max-width: 120px;
  display: flex;
  flex: 1;
  z-index: 5;

  @media screen and (max-width: 1365px) and (orientation: portrait) {
    max-width: 80px;
    margin: 0;
  }
`

export const NavList = styled.ul`
  display: flex;
  gap: 1rem;
  margin: 0;
  list-style: none;

  @media screen and (max-width: 1365px) and (orientation: portrait) {
    display: none;
  }
`

export const NavListItem = styled.li`
  > a {
    font-family: MDLZBiteType, sans-serif;
    font-size: 20px;
    text-transform: uppercase;
  }
`

export const DropdownContainer = styled.div`
  align-items: baseline;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;

  p {
    margin: 0;
  }

  @media screen and (max-width: 1365px) and (orientation: portrait) {
    align-items: center;
    flex-direction: row-reverse;
  }
`

export const Avatar = styled.figure`
  aspect-ratio: 1;
  border: 1px solid #02ed3b;
  border-radius: 50%;
  margin: 0 0 0 1rem;
  overflow: hidden;
  width: 40px;

  @media screen and (max-width: 1365px) and (orientation: portrait) {
    margin: 0;
    width: 28px;
  }
`

export const DropdownToggleContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  > svg {
    fill: #02ed3b;
    width: 8px;
    height: 10px;
    transform: rotate(90deg);
  }


  @media screen and (max-width: 1365px) and (orientation: portrait) {
    align-items: center;

    p {
      white-space: normal;
      max-width: 64px;
    }

    @media (max-width: 950px) {
      font-size: 1rem;
    }
  }
`

export const DropdownList = styled.ul<{ visible: boolean }>`
  position: absolute;
  list-style: none;
  margin: 0;
  top: 28px;
  visibility: ${({ visible }) => !visible && 'hidden'};

  > li, & > a {
    font-family: Rajdhani, sans-serif;
    text-decoration: underline;
  }
`

export const Footer = styled.footer`
  background-image: url(${footer});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  max-height: 316px;
  padding-inline: 20px;
  position: absolute;
  bottom: -225px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  width: 100%;
`

export const Tabs = styled.div`
  align-items: center;
  background-color: #ffdc39;
  border-radius: 96px;
  height: 64px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  margin-top: 64px;
  padding: 12px 24px;
  width: 100%;

  @media screen and (orientation: landscape) {
    display: none;
  }
`

export const TabItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;

  img {
    width: 20px;
  }

  p {
    color: #153aaa;
    font-family: MdlzBiteType;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
    text-align: center;
  }
`

export const FooterImg = styled.img`
  bottom: -10%;
  position: absolute;
  right: -150px;
  height: 100%;
  max-width: 304px;

  @media screen and (max-width: 1246px) {
    height: 98%;
  }

  @media screen and (max-width: 1365px) and (orientation: portrait) {
    display: none;
  }
`

export const GigantesLogo = styled.figure`
  left: 50%;
  margin: auto;
  max-width: 300px;
  position: absolute;
  top: 100px;
  transform: translateX(-50%);
  z-index: 9001;
`

export const Hidden = styled.div`
  height: 100%;
  overflow: hidden;
  width: 100%;

  @media (max-width: 911px) {
    max-height: 100vh;
  }

  @media (max-width: 520px) {
    display: none;
  }
`

export const HiddenMobile = styled.div`
  margin-top: 122px;
  width: 100%;

  @media (min-width: 521px) {
    display: none;
  }

  img {
    margin-top: 64px;
    max-width: 100%;
    display: block;
  }
`

const translateX = (val: number): number => -val * 100 / 2560
const translateY = (val: number): number => -val * 100 / 1556

export interface LinksContainerProps {
  left: number
  top: number
}

export const LinksContainer = styled.div<LinksContainerProps>`
  aspect-ratio: 2560 / 1556;
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  inset: 0;
  height: 100%;
  position: relative;
  width: 100%;

  @media (max-width: 911px) {
    width: 1920px;
    transform: translate(calc(${(props) => translateX(props.left)}% + 50vw), calc(${(props) => translateY(props.top)}% + 50vh));
  }
`

export interface ButtonProps {
  height: number
  hover: string
  left: number
  normal: string
  top: number
  width: number
}

export const Button = styled(Link) <ButtonProps>`
  background-image: url(${({ normal }) => normal});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  left: ${({ left }) => `${left * 100 / 2560}%`};
  height: ${({ height }) => `${height * 100 / 1556}%`};
  position: absolute;
  top: ${({ top }) => `${top * 100 / 1556}%`};
  width: ${({ width }) => `${width * 100 / 2560}%`};

  &:hover {
    background-image: url(${({ hover }) => hover});
  }
`

export const Product = styled(Link).attrs(() => {
  return {
    className: 'mdlz-2024-button',
    to: '/em-breve'
  }
})`
  grid-area: 1 / 1 / 4 / 3;

  &>img {
    display: none;
  }

  &:hover>img {
    display: block;
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
  }

  @media screen and (min-height: 874px) {
    grid-area: 1 / 1 / 5 / 3;
  }
`

export const Quiz = styled(Link).attrs(() => {
  return {
    className: 'mdlz-2024-button',
    to: '/em-breve'
  }
})`
  grid-area: 4 / 2 / 6 / 3;
  height: 100%;
  justify-self: flex-end;
  max-height: 80px;
  margin-top: 20px;
  width: 100%;

  @media (max-width: 1368px) and (min-width: 1364px) {
    margin-top: -30px;
    left: -15px;
    position: relative;
    
    &:hover>img {
      top: -2% !important;
      left: 15% !important;
    }
  }

  &>img {
    display: none;
  }

  &:hover>img {
    display: block;
    position: relative;
    width: 100%;
    top: -20%;
    left: -15%;
  }

  @media screen and (min-height: 874px) {
    align-self: flex-end;
    grid-area: 5 / 2 / 7 / 3;
    justify-self: flex-start;
    max-height: 100px;
    max-width: 100px;

    &:hover>img {
      scale: 2;
      top: -20px;
      left: 0;
    }
  }
`

export const ClubeMdlz = styled(Link).attrs(() => {
  return {
    className: 'mdlz-2024-button js-club-mdlz',
    to: '/em-breve'
  }
})`
  align-self: flex-end;
  height: 100%;
  max-width: 250px;
  width: 100%;
  grid-area: 7 / 2 / 9 / 4;

  &>img {
    display: none;
  }

  &:hover>img {
    display: block;
    position: relative;
    max-width: 100%;
    top: -71px;
    left: -90px;
  }

  @media screen and (max-height: 874px) {
    justify-self: center;
    grid-area: 5 / 2 / 7 / 4;
    max-height: 115px;

    &:hover>img {
      left: -30%;
      top: -48%;
    }
  }
`

export const PrizeShopping = styled(Link).attrs(() => {
  return {
    className: 'mdlz-2024-button',
    to: '/em-breve'
  }
})`
  grid-area: 2 / 3 / 5 / 5;
  width: 265px;

  &>img {
    display: none;
  }

  &:hover>img {
    display: block;
    position: relative;
    width: 100%;
    right: 0;
    top: -37%;
  }

  @media screen and (min-height: 874px) {
    grid-area: 3 / 3 / 6 / 5;

    &:hover>img {
      top: -8%;
      right: -15%;
    }
  }
`

export const Highlights = styled(Link).attrs(() => {
  return {
    className: 'mdlz-2024-button',
    to: '/em-breve'
  }
})`
  grid-area: 1 / 5 / 4 / 6;

  @media (max-width: 1368px) and (min-width: 1364px) {
    position: relative;
    top: -70px;
    width: 160px;
    left: 13%;

    &:hover>img {
      &:first-child {
        top: -25% !important;
      }
      &:last-child {
        top: -26%;
      }
    }
  }

  &>img {
    display: none;
  }

  &:hover>img {
    display: block;
    position: relative;
    width: 100%;
    /* scale: 2; */

    &:first-child {
      right: -15%;
      scale: 1.5;
      top: -10%;
    }

    &:last-child {
      bottom: 0%;
      right: -5%;
      scale: 2;
    }
  }

  @media screen and (min-height: 874px) {
    grid-area: 1 / 5 / 5 / 6;
  }
`

export const Newsfeed = styled(Link).attrs(() => {
  return {
    className: 'mdlz-2024-button',
    to: '/perfil/newsfeed'
  }
})`
  grid-area: 5 / 4 / 7 / 5;
  max-height: 200px;
  overflow: hidden;

  @media (max-width: 1368px) and (min-width: 1364px) {
    border-radius: 0 !important;
    position: relative;
    left: 10px;
    &:hover>img {
      transform: scale(.8) !important;
    }
  }

  &>img {
    display: none;
  }

  &:hover>img {
    display: block;
    position: relative;
    width: 100%;
    scale: 1.5;
    bottom: -15%;
  }

  @media screen and (min-height: 874px) {
    align-self: flex-end;
    grid-area: 6 / 4 / 9 / 5;

    &:hover>img {
      bottom: -20%;
      right: 0;
    }
  }
`

export const Dropbox = styled.div<{ active: boolean }>`
background-color: #ffffff80;
backdrop-filter: blur(8px);
position: absolute;
width: min(90vw, 212px);
top: 64px;
right: 6px;
opacity: ${props => props.active ? 1 : 0};
visibility: ${props => props.active ? 'visible' : 'hidden'} ;
transform: ${props => props.active ? 'translate(0px)' : 'translate(0px, -40px)'};
transition: transform 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s;
z-index: 99;
&>a {
  color: var(--primary);
}
.dropdown-navigation-link:hover {
  color: var(--primary);
}
@media(max-width: 640px) {
  max-width: 15rem;
  &.notice-box {
    right: -60px;
    max-width: unset;
  }
}
`

export const LeaveButton = styled.button`
i {
  margin-right: 16px;
}
`
