import { useEffect } from 'react';
import BannerTitleComponent from '../../components/BannerTitleHome';
import CardWidget from '../../components/CardWidget';
import UserStatus from '../../components/UserStatus';
import { useNotify } from '../../contexts/NotifyContext';
import Template from '../../template';

export default function Newsfeed () {
  const { notices, markAllAsReady } = useNotify();
  useEffect(() => {
    const unread = notices.find((notice) => !notice.isRead);
    if (unread) markAllAsReady();
  });
  return (
    <Template>
      <BannerTitleComponent
        avatar={'avatar'}
        username={'user.name'}
        position={'0'}
        stars={'0'}
      />
      <div className="grid mobile-prefer-content">
        <div className="grid-column">
          {notices.length > 0 && notices.map(notice => <>
            <CardWidget content={notice} key={notice.id} status={<UserStatus user={notice.sender} createdAt={notice.created_at} />} message={<div dangerouslySetInnerHTML={{ __html: notice.message }}></div>}></CardWidget>
          </>
          )}
          <CardWidget content={''} status={<UserStatus user={{ avatar: { path: '' }, name: 'Gigantes da Execução' }} createdAt={'2024-02-01 03:00:00'} />} message={<div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe src="https://player.vimeo.com/video/908437527?h=49db4a8823&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} title="GIGANTES DA EXECUCAO_2024">
            </iframe>
          </div>}></CardWidget>
        </div>
      </div>
    </Template>
  );
}
