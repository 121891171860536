import styled from 'styled-components'
import CardComponent from '../../../../components/Card'
import banner from './assets/banner.png'

export const Container = styled.div`
  flex: 25%;
  width: 252px;
  margin-top: 51px;

  a {
    text-decoration: none;
  }

  @media (max-width: 1280px) {
    margin-top: 43px;
  }

  @media (max-width: 315px) {
    width: 100%;
  }
`

export const Card = styled(CardComponent)`
  background-color: #2e3192;
  border-radius: 8px;
  filter: drop-shadow(0px 3px 5px rgba(27,27,29,0.16));
  height: 100%;
  max-height: 220px;
  padding: 0;
  position: relative;
  max-width: 100%;

  @media (max-width: 1280px) {
    border-radius: 4px;
  }
`

export const BannerContainer = styled.figure`
  background-image: url(${banner});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  max-height: 100px;
  min-height: 100px;
  position: relative;

  img {
    height: 114%;
    position: absolute;
    right: 26px;
    top: -6px;
    width: fit-content;
  }
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    color: #f5be4a;
    font-size: 2.25rem;
    line-height: 1.75rem;
    text-align: center;
  }

  figure {
    width: fit-content;

    img {
      max-width: 80px;
      width: 100%;
    }
  }
`
