import { AxiosError, AxiosInstance } from 'axios'
import { LoadAccount } from '../../domain/use-case/LoadAccount'

export class ApiLoadAllSales implements LoadAccount {
  private readonly api: AxiosInstance
  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (): Promise<LoadAccount.Result> {
    try {
      const response = await this.api.get('/admin/store/sales')
      return response
    } catch (err) {
      const axiosError = err as AxiosError
      if (axiosError.response) {
        return axiosError.response
      }

      if (axiosError.request) {
        return {
          data: axiosError.request,
          status: 0
        }
      }

      return {
        data: { message: axiosError.message },
        status: 0
      }
    }
  }
}
