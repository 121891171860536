import styled from 'styled-components'

import carouselBgMobile from '../../../assets/img/incentivo-2024/leilao/carousel-bg-mobile.png'
import carouselBg from '../../../assets/img/incentivo-2024/leilao/carousel-bg.png'

export const ProductCarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-top: 64px;
  max-width: 816px;
  z-index: 0;

  h1, h2, p {
    font-family: MdlzBiteType;
    margin: 0;
  }

  > figure {
    margin: 0;
    max-width: 200px;
    position: absolute;
    z-index: -1;

    &:first-of-type {
      left: -10%;
      top: -14%;

      img {
        rotate: 160deg;
      }
    }

    &:last-of-type {
      bottom: 2%;
      right: -8%;

      img {
        rotate: -35deg;
      }
    }
  }

  @media screen and (orientation: landscape) {
    margin: 64px auto;
  }

  @media screen and (max-width: 1280px) and (orientation: portrait) {
    > figure {
      display: none;
    }
  }

  button {
    :active, :focus {
      outline: none;
    }
  }
`

export const ProductCarouselBg = styled.div`
  aspect-ratio: 1.583;
  background-color: transparent;
  background-image: url(${carouselBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 32px;
  width: 100%;

  @media screen and (max-width: 1280px) and (orientation: portrait) {
    aspect-ratio: 1.536;
    background-image: url(${carouselBgMobile});
    padding: 0;
  }
`

export const ProductCarouselContent = styled.div`
  align-items: center;
  display: flex;
  gap: 24px;
  height: 100%;
  justify-content: flex-start;
  padding: 40px 56px;
  position: relative;

  @media screen and (max-width: 1280px) and (orientation: portrait) {
    gap: 8px;
    justify-content: center;
    padding: 12px 32px 12px 64px;
  }

  @media screen and (max-width: 520px) and (orientation: portrait) {
    gap: 16px;
    justify-content: center;
    padding: 12px 32px 12px 32px;
  }
`

export const ArrowContainer = styled.button`
  background-color: transparent;
  max-width: 48px;
  position: absolute;

  &:first-of-type {
    left: -8%;
    rotate: 180deg;
  }

  &:nth-last-of-type(1) {
    right: -8%;
  }

  figure {
    margin: 0;
  }

  @media screen and (max-width: 1280px) and (orientation: portrait) {
    max-width: 32px;

    &:first-of-type {
      left: 9%;
    }

    &:nth-last-of-type(1) {
      right: 9%;
    }
  }
`

export const AuctionContainer = styled.div`
  h1 {
    color: #f7cb25;
    font-size: 96px;
    letter-spacing: 4px;
    line-height: 96px;
    position: relative;
    rotate: 180deg;
    text-orientation: mixed;
    writing-mode: vertical-rl;

    :after {
      color: transparent;
      content: attr(data-text);
      left: 4px;
      position: absolute;
      top: 4px;
      z-index: -1;
      -webkit-text-stroke: 2px #fff;
    }
  }


  @media screen and (max-width: 1280px) and (orientation: portrait) {
    display: none;

    &.auction-mobile-title {
      display: initial;
      margin: 0 auto;

      h1 {
        font-size: 64px;
        left: 0;
        right: 0;
        line-height: 64px;
        rotate: 0deg;
        text-orientation: initial;
        writing-mode: initial;
      }
    }
  }

  @media screen and (orientation: landscape) {
    &.auction-mobile-title {
      display: none;
    }
  }
`

export const ProductImageContainer = styled.figure`
  height: auto;
  margin: 0;
  width: 50%;

  @media screen and (max-width: 1280px) and (orientation: portrait) {
    width: 32%;
  }
`

export const ProductDetailsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 232px;

  h2 {
    color: #fff;
    font-size: 44px;
    text-align: center;
  }

  @media screen and (min-width: 724px) and (max-width: 1024px) {
    h2 {
      font-size: 32px;
      max-width: 54%;
    }
  }

  @media screen and (min-width: 540px) and (max-width: 723px) and (orientation: portrait) {
    h2 {
      font-size: 24px;
      max-width: 54%;
      text-align: left;
    }
  }

  @media screen and (max-width: 539px) {
    width: 36%;

    h2 {
      font-size: 20px;
      text-align: left;
    }
  }
`

export const DigimoedasContainer = styled.div`
  align-items: flex-end;
  display: flex;
  gap: 8px;

  p {
    color: #f7cb25;
    font-size: 20px;
    letter-spacing: 1px;
    text-align: center;

    :first-child {
      font-size: 72px;
      letter-spacing: -4px;
    }
  }

  figure {
    margin: 0;
    max-width: 48px;
    height: fit-content;
  }

  @media screen and (min-width: 540px) and (max-width: 1024px) {
    p {
      font-size: 16px;
      text-align: left;
      max-width: 64%;

      :first-child {
        font-size: 56px;
        letter-spacing: -4px;
      }
    }
  }

  @media screen and (max-width: 540px) and (orientation: portrait) {
    p {
      font-size: 12px;
      text-align: left;
      max-width: 64%;

      :first-child {
        font-size: 48px;
        letter-spacing: -4px;
      }
    }

    figure {
      margin: 0;
      max-width: 20px;
      height: fit-content;
    }
  }
`

export const BidButton = styled.button`
  background-color: #f7cb25;
  border-radius: 24px;
  color: #1d3f85;
  font-family: MdlzBiteType;
  font-size: 32px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 24px;
  padding: 16px 32px;
  white-space: nowrap;
  width: fit-content;

  @media screen and (max-width: 1280px) and (orientation: portrait) {
    bottom: 6%;
    font-size: 1.25rem;
    left: 0;
    line-height: 1.25rem;
    margin: 0 auto;
    padding: 8px 16px;
    position: absolute;
    right: 0;
  }

  @media screen and (min-width: 540px) and (orientation: portrait) {
    bottom: 10%;
  }
`
