import { AxiosInstance } from 'axios'
import { LoadRankingByCampaing } from '../../domain/use-case/LoadRankingByCampaing'
import { RankingModel } from '../entities/RankingModel'

const mapToRankingModel = (ranking: any): RankingModel => ({ ...ranking })

export class ApiLoadRankingByCampaing implements LoadRankingByCampaing {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (params: LoadRankingByCampaing.Params): Promise<RankingModel[]> {
    const response = await this.api.get(`/hub-campanha/minhas-campanhas/${String(params.campaingId)}/ranking`, {
      params: {
        channelId: params.channelId,
        cargoId: params.cargoId
      }
    })
    return response.data.ranking.map(mapToRankingModel)
  }
}
