import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const TableContainer = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
`

export const SalesTable = styled.table`
  border-collapse: collapse;
  color: var(--white);
  font-family: Rajdhani, sans-serif;
  width: 100%;

  thead {
    font-size: 0.75rem;

    th {
      padding: 0.875rem 1.5rem;
    }
  }

  tbody {
    backdrop-filter: blur(8px);
    border-radius: 12px;
    box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1em;

    tr {
      &:first-child td:first-child {
        border-top-left-radius: 0.75rem;
      }

      &:first-child td:last-child {
        border-top-right-radius: 0.75rem;
      }

      &:last-child td:first-child {
        border-bottom-left-radius: 0.75rem;
      }

      &:last-child td:last-child {
        border-bottom-right-radius: 0.75rem;
      }

      td {
        background-color: rgba(0, 0, 0, .3);
        padding: 28px 8px;

        &:first-child {
          padding-left: 28px;
        }

        &:last-child {
          padding-right: 28px;
        }

        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(6) {
          color: #adafca;
          font-weight: 500;
          line-height: 1.1428571429em;
        }
      }
    }
  }


  th, td {
    &:nth-child(n+3) {
      text-align: center;
    }
  }
`

export const ItemLink = styled(Link)`
  color: var(--success);
  text-decoration: none;
`

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  width: 1rem;
  height: 1rem;
  border: 2px solid transparent;
  border-radius: 50%;

  &:has(svg.icon-minus-small) {
    border-color: #ff5384;

    svg {
      fill: #ff5384;
    }
  }

  &:has(svg.icon-plus-small) {
    border-color: #23d2e2;

    svg {
      fill: #23d2e2;
    }
  }
`
