import styled from 'styled-components'
import CardComponent from '../../../../components/Card'

export const Card = styled(CardComponent)`
  border-radius: 4px;
  padding: 0;
  max-width: 202px;

  p, a {
    font-family: Rajdhani !important;
  }

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    border-radius: 8px;
  }
`

export const Container = styled.div`
  background-color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  filter: drop-shadow(0px 3px 5px rgba(27,27,29,0.16));
  flex: 1;
  position: relative;

  figure {
    margin: 0;
  }

  p, a {
    color: #3E3F5E;
    font-size: 8px;
    font-weight: 700;
    margin: 0;
    text-decoration: none;
  }

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    p, a {
      font-size: 12px;
    }
  }
`

export const ExpContainer = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 0 40px 0 rgba(94,92,154,.06);
  display: flex;
  padding: 4px;
  position: absolute;
  right: -4%;
  top: 2%;

  p {
    span {
      color: #00c7d9;
    }
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px 12px 12px;
  position: relative;

  > figure {
    max-width: 20px;
    position: absolute;
    top: -16%;
  }

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    padding: 24px 20px 20px;

    > figure {
      max-width: 36px;
      top: -20%;
    }
  }
`

export const Description = styled.p`
  font-size: 4px !important;
  font-weight: 500 !important;

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    font-size: 10px !important;
  }
`

export const ProgressText = styled.p`
  text-transform: uppercase;
`
