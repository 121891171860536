import { useCallback, useEffect, useState } from 'react';
import api from '../../../services/api';
import ChannelCardComponent from './ChannelCardComponent';
import * as S from './style';

const disallowed = [
  'Mondelez',
  'Motivare',
  'Casa de Bragança',
  'Staff'
];

function MecanicasSelectorComponent () {
  const [channels, setChannels] = useState();

  const loadChannels = useCallback(async () => {
    const response = await api.get('/channels');
    setChannels(response.data.filter(c => !disallowed.includes(c.name)));
  }, []);

  useEffect(() => {
    loadChannels();
  }, [loadChannels]);
  return <S.Container>
    {channels && channels.map(c => <ChannelCardComponent name={c.name} />)}
  </S.Container>;
}

export default MecanicasSelectorComponent;
