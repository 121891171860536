import styled from 'styled-components'
import background from '../assets/default-bg.png'
import contentBg from '../assets/finished-container-bg.png'

export const Container = styled.div`
  align-items: center;
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding: 100px 32px;
  position: relative;
  width: 100%;

  @media (max-width: 500px) {
    padding: 100px 32px 55% 32px;
  }
`

export const ContentBg = styled.div`
  aspect-ratio: 593 / 416;
  background-image: url(${contentBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  max-width: 593px;
  width: 100%;

  h1, p, span {
    text-align: center;
    white-space: nowrap;
  }

  h1 {
    color: #e9c300;
    font-size: 80px;
    letter-spacing: 1px;
    margin-bottom: 8px;
  }

  p {
    color: var(--white);
    filter: drop-shadow(0px 1px 3.5px rgba(21,21,22,0.15));
    font-size: 36px;
    margin-left: 10%;

    span {
      color: #422069;
      background-color: #e9c300;
      border-radius: 16px;
      padding: 0 8px 4px;
    }

    img {
      margin-bottom: 4px;
    }
  }

  > figure {
    max-width: fit-content;

    img {
      width: 100%;
    }
  }

  @media (max-width: 672px) {
    h1 {
      font-size: 64px;
    }

    p {
      font-size: 24px;
      margin: 0;
    }
  }

  @media (max-width: 500px) {
    gap: 4px;

    h1 {
      font-size: 48px;
    }

    p {
      font-size: 20px;
    }
  }

  @media (max-width: 450px) {
    h1 {
      font-size: 40px;
    }

    p {
      font-size: 16px;

      img {
        max-width: 32px;
      }
    }
  }

  @media (max-width: 400px) {
    h1 {
      font-size: 32px;
      margin: 0;
    }

    p {
      font-size: 16px;
    }
  }
`

const AbsoluteImage = styled.figure`
  max-width: fit-content;
  position: absolute;

  img {
    width: 100%;
  }

  @media (max-width: 1280px) {
    max-width: 50%;
  }
`

export const AmebaBottom = styled(AbsoluteImage)`
  bottom: -28px;
  right: 0;

  @media (max-width: 520px) {
    bottom: 80px;
  }
`

export const AmebaTop = styled(AbsoluteImage)`
  left: 0;
  top: 56px;

  @media (max-width: 520px) {
    top: 0;
  }
`

export const WebBottom = styled(AbsoluteImage)`
  bottom: -28px;
  left: -28px;

  @media (max-width: 520px) {
    bottom: 80px;
  }
`

export const WebTop = styled(AbsoluteImage)`
  right: -28px;
  top: 40px;

  @media (max-width: 520px) {
    top: 0;
  }
`
