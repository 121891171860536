import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import api from '../../services/api'
import CatalogPoints from './CatalogPoints'
import KPICard from './KPICard'
import QuestResultsCard from './QuestResultsCard'
import RankingPositionCard from './RankingPositionCard'
import * as S from './style'

interface Params {
  [k: string]: string | number
}

export default function MyResults (): ReactElement {
  const { userId } = useParams()
  const [data, setData] = useState<any[]>([])
  const { user, userCan } = useAuth()
  const urlParams = new URLSearchParams(window.location.search)
  const [params] = useState<Params>({ stepId: Number.parseInt(urlParams.get('stepId') ?? '1') })
  const navigate = useNavigate()

  const loadDashboardData = useCallback(async (params: Params) => {
    if (userId !== undefined) {
      const response = await api.get(`/result/${userId}`, { params })
      setData(response.data.content)
      return
    }
    const response = await api.get(`/result/${String(user.id)}`, { params })
    setData(response.data.content)
  }, [userId, user])

  useEffect(() => {
    loadDashboardData(params)
  }, [loadDashboardData, params])

  return <S.Template>
    <S.Container>
      {userCan('read:ranking-anual') && <S.ButtonsContainer>
        <button onClick={() => navigate('/ranking-anual')}>Resultados do ano</button>
      </S.ButtonsContainer>}

      <S.CardsContainer>
        {data.map((item, i) => {
          switch (item.component) {
            case 'coins':
              return <S.Digimoedas key={i} {...item} />
            case 'position':
              return <RankingPositionCard key={i} {...item} />
            case 'quests':
              return <QuestResultsCard key={i} {...item} />
            case 'kpi':
              return <KPICard key={i} {...item} />
            case 'catalog':
              return <CatalogPoints key={i} {...item} />
          }
          return <></>
        })}
      </S.CardsContainer>
    </S.Container>
  </S.Template>
}
