import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import Modal from 'react-modal'
import { Link } from 'react-router-dom'
import { io } from 'socket.io-client'
import { useAuth } from '../AuthContext'
import * as S from './style'

interface QuestModalContextProps {
  children: React.ReactNode
}

interface QuestModalProviderProps {
  name: string
  image: string
  reward: string
}

type EffectReturnCallback = () => void

const nullWebSocket: QuestModalProviderProps = { name: '', image: '', reward: '' }

export const QuestModalContext = createContext<undefined>(undefined)

export const QuestModalProvider: React.FC<QuestModalContextProps> = ({ children }) => {
  const { token } = useAuth()
  const [questInfos, setQuestInfos] = useState<QuestModalProviderProps>(nullWebSocket)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const customStyles: Modal.Styles = {
    content: {
      backgroundColor: '#FFFFFF',
      border: '1px solid rgba(225, 225, 225, 1)',
      borderRadius: '24px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      height: 'min-content',
      left: '50%',
      maxHeight: '90%',
      maxWidth: '90%',
      overflow: 'visible',
      padding: '0px',
      position: 'fixed',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '320px'
    },
    overlay: { zIndex: '1000' }
  }
  const closeModal = (): void => {
    setModalIsOpen(false)
    setQuestInfos(nullWebSocket)
  }
  const handleQuestCompleted = useCallback((chunk: QuestModalProviderProps): void => {
    setQuestInfos(chunk)
    console.log(chunk)
    setModalIsOpen(true)
  }, [])

  useEffect((): EffectReturnCallback => {
    if (!token) return () => { }
    const ioServer = io(String(process.env.REACT_APP_BASE_URL), {
      query: { token },
      transports: ['websocket']
    })

    ioServer.on('quest-completed', (chunk) => handleQuestCompleted(chunk))
    ioServer.on('connect', () => console.log('ws-log: Connecting', token))
    ioServer.on('disconnect', () => console.log('ws-log: Close connection'))

    return () => ioServer.close()
  }, [handleQuestCompleted, token])

  return (
    <QuestModalContext.Provider value={undefined}>
      {children}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <S.ModalHeader>
          <S.ButtonClose onClick={closeModal}>X</S.ButtonClose>
          <img src={questInfos.image} alt="Badge de ranking" />
        </S.ModalHeader>
        <S.ModalBody>
          <h2>Parabéns!</h2>
          <p>Você completou a missão {questInfos.name}.</p>
          <span>Você conquistou</span>
          <h3>{questInfos.reward}</h3>
          <Link to="/quests" onClick={closeModal}>Ver todas as conquistas</Link>
        </S.ModalBody>
      </Modal>
    </QuestModalContext.Provider>
  )
}

export const useQuestModal = (): QuestModalProviderProps => {
  const context = useContext(QuestModalContext)
  if (context == null) {
    throw new Error('Use useQuestModal whitin a QuestModalProvider')
  }
  return context
}
