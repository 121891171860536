import styled from 'styled-components'
import TemplateBase from '../../../template'

export const Template = styled(TemplateBase)`
  &.content-grid {
    width: initial;
    min-height: initial;
    max-width: initial;
    padding: initial;
  }
`

export const Container = styled.div`
  align-items: center;
  background-color: #192e8d;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;

  h1, h2, p, button {
    font-family: MdlzBiteType;
    margin: 0;
  }
`
