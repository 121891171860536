import { ReactElement, useCallback, useMemo, useRef } from 'react'
import SunEditorReact from 'suneditor-react'
import { UploadBeforeHandler, UploadBeforeReturn } from 'suneditor-react/dist/types/upload'
import 'suneditor/dist/css/suneditor.min.css'
import SunEditorCore from 'suneditor/src/lib/core'
import plugins from 'suneditor/src/plugins'
import api from '../../services/api'
import SunEditorProps from './props'
import * as S from './styled'

export default function SunEditor ({ onChange, value }: SunEditorProps): ReactElement {
  const editorRef = useRef<SunEditorCore>()

  const getSunEditorInstance = useCallback((sunEditor: SunEditorCore): void => {
    editorRef.current = sunEditor
  }, [])

  const handleImageUploadBefore = useCallback((files: File[], info: Record<string, any>, uploadHandler: UploadBeforeHandler): UploadBeforeReturn => {
    (async () => {
      if (files.length === 0) return

      const formData = new FormData()
      formData.append('image', files[0])

      const response = await api.post('/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      const res = {
        result: [{
          name: response.data.image?.name,
          size: info.size,
          url: response.data.image?.path
        }]
      }

      uploadHandler(res)
      return true
    })()

    uploadHandler()
    return false
  }, [])

  const buttonList = useMemo(() => [
    ['paragraphStyle'],
    ['bold', 'italic', 'underline', 'strike'],
    ['list', 'outdent', 'indent', 'font', 'fontSize'],
    ['fontColor', 'hiliteColor', 'textStyle', 'align'],
    ['link', 'image', 'table'],
    ['undo', 'redo']
  ], [])

  return <S.Container>
    <SunEditorReact
      defaultValue={value}
      getSunEditorInstance={getSunEditorInstance}
      height="320px"
      lang="pt_br"
      setDefaultStyle={`
        font-family: Rajdhani;
        font-size: 24px;
      `}
      setOptions={{
        buttonList,
        font: ['Rajdhani', 'MdlzBiteType'],
        plugins
      }}
      onChange={onChange}
      onImageUploadBefore={handleImageUploadBefore}
      setAllPlugins={false}
    />
  </S.Container>
}
