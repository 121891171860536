import styled, { keyframes } from 'styled-components'

const bounce = keyframes`
  70% { transform:translateY(0%); }
  80% { transform:translateY(-15%); }
  90% { transform:translateY(0%); }
  95% { transform:translateY(-7%); }
  97% { transform:translateY(0%); }
  99% { transform:translateY(-3%); }
  100% { transform:translateY(0); }
`
export const Container = styled.div`
    animation: ${bounce} 2s ease infinite;
    bottom: 56px;
    cursor: pointer;
    position: fixed;
    right: 56px;
    z-index: 999;

    @media (max-width: 786px) {
      display: none;
    }
`

export const Icon = styled.img`
    transform: scale(2.5);
    fill: #431a61;
`
