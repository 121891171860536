import { ReactElement } from 'react'
import ProgressCircle from '../../../components/ProgressCircle'
import { NumberAdapter } from '../../../infra/adapters/NumberAdapter'
import KPICardProps from './props'
import * as S from './style'

const shortnerAdapter = new NumberAdapter()

export default function KPICard ({ progress, target, realized, title, description }: KPICardProps): ReactElement {
  return <S.KPICard>
    <S.StatsContainer>
      <h6>
        {title}
      </h6>

      {target && <S.IndicatorsTable>
        <thead>
          <tr>
            <th>Target <br />Acumulado</th>
            <th>Realizado <br />Acumulado</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>{shortnerAdapter.shorten(target, 2)}</td>
            <td>{shortnerAdapter.shorten(realized, 2)}</td>
          </tr>
        </tbody>

        <caption>{description}</caption>
      </S.IndicatorsTable>}
    </S.StatsContainer>

    <ProgressCircle progress={progress} />
  </S.KPICard>
}
