import { HTMLAttributes, ReactElement } from 'react'
import * as S from './style'

export default function SectionKPITableMerchan (props: HTMLAttributes<HTMLDivElement>): ReactElement {
  return <S.TableContainer {...props}>
    <table>
      <thead>
        <tr>
          <th colSpan={4}>
            Premiados Merchan (por onda)
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <center><b>Cargos</b></center>
          </td>
          <td>
            <center>Promotores e Aprendizes</center>
          </td>
          <td>
            <center>Coordenadores</center>
          </td>
          <td>
            <center>Gerentes</center>
          </td>
        </tr>
        <tr>
          <td>
            <center><b>Qnt. por onda</b></center>
          </td>
          <td>
            <center>400</center>
          </td>
          <td>
            <center>35</center>
          </td>
          <td>
            <center>2</center>
          </td>
        </tr>
        <tr>
          <td>
            <center><strong>Premiação</strong></center>
          </td>
          <td>
            <center>R$ 400,00</center>
          </td>
          <td>
            <center>R$ 500,00</center>
          </td>
          <td>
            <center>R$ 1000,00</center>
          </td>
        </tr>
      </tbody>
    </table>
  </S.TableContainer>
}
