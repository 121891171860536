import { ReactElement, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loading from '../../../components/Loading'
import SectionHeaderComponent from '../../../components/SectionHeaderComponent'
import { makeLoadAllSales } from '../../../main/factories/makeLoadAllSales'
import Template from '../../../template'
import * as S from './style'

const loadSales = makeLoadAllSales()

enum PageStatus {
  LOADING,
  READY
}

export default function AdminStoreSalesStatement (): ReactElement {
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING)
  const [data, setData] = useState<any>()

  const getIcon = useCallback((status: string) => {
    switch (status) {
      case 'APROVADO':
        return <svg className="percentage-diff-icon icon-plus-small">
          <use xlinkHref="#svg-plus-small"></use>
        </svg>
      case 'REPROVADO':
        return <svg className="percentage-diff-icon icon-minus-small">
          <use xlinkHref="#svg-minus-small"></use>
        </svg>
      case 'AGUARDANDO':
        return <svg className="percentage-diff-icon icon-clock">
          <use xlinkHref="#svg-clock"></use>
        </svg>
    }
    return <svg className="percentage-diff-icon icon-cross">
      <use xlinkHref="#svg-cross"></use>
    </svg>
  }, [])

  useEffect(() => {
    (async () => {
      setStatus(PageStatus.LOADING)
      const response = await loadSales.handle()
      setData(response.data)
      console.log(response.data)
      setStatus(PageStatus.READY)
    })()
  }, [])

  if (status === PageStatus.LOADING) return <Loading />

  return <Template>
    <SectionHeaderComponent
      pretitle="MINHA LOJA"
      title="Histórico de Resgates"
    />

    <S.ContentContainer>

      <S.TableContainer>
        <S.SalesTable>
          <thead>
            <tr>
              <th>DATA</th>
              <th>ITEM</th>
              <th>TIPO</th>
              <th>CÓDIGO</th>
              <th>PREÇO</th>
              <th>QUANTIDADE</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {data.produtos.map((d: any) => <tr>
              <td>{d.date}</td>
              <td>
                <Link to={`/product/${String(d.id)}`}>{d.name}</Link>
              </td>
              <td>{d.type}</td>
              <td>{d.code}</td>
              <td>$ {d.price}</td>
              <td>{d.amount}</td>
              <td>
                <S.IconContainer>{getIcon(d.status)}</S.IconContainer>
              </td>
            </tr>)}
          </tbody>
        </S.SalesTable>
      </S.TableContainer>
    </S.ContentContainer>
  </Template>
}
