import { ReactElement, useCallback, useEffect, useState } from 'react'
import character from '../../../assets/img/incentivo-2024/mechanics/pascoa/character.png'
import PageLoaderComponent from '../../../components/PageLoader'
import { useModalMdlz } from '../../../contexts/ModalMdlzContext'
import api from '../../../services/api'
import * as S from './style'

enum State {
  LOADING,
  READY
}

interface PascoaAttributes {
  currentState: State
  data?: any
}

export default function Pascoa (): ReactElement {
  const { openModal, closeModal } = useModalMdlz()
  const [state, setState] = useState<PascoaAttributes>({
    currentState: State.LOADING
  })

  useEffect(() => {
    (async () => {
      const response = await api.get('/me')
      setState({
        currentState: State.READY,
        data: response.data
      })
    })()
  }, [])

  const handleModdalToggle = useCallback(async () => {
    openModal({
      body: <S.ModalContainer>
        <S.ModalTitle>Regras Gerais:</S.ModalTitle>

        <S.ModalList>
          <li>
            Somente colaboradores que trabalharam no período de páscoa poderão usufruir da premiação.
          </li>
          <li>
            Esse BOOST, assim como toda campanha, é exclusivo para os colaboradores efetivos da Mondelēz. Dessa maneira, contratações temporárias para projetos esporádicos não poderão participar da campanha
          </li>
          <li>
            Em caso de desligamento (sem justa causa, por justa causa e a pedido do colaborador), seja durante o período da campanha / BOOST ou até no período de entrega da premiação, o participante não terá mais direito à premiação.
          </li>
        </S.ModalList>
      </S.ModalContainer>
    })
  }, [closeModal, openModal])

  if (state.currentState === State.LOADING) return <PageLoaderComponent />

  return <S.Template>
    <S.Container>
      <S.MechanicContainer>
        <S.MechanicContent>
          <S.MechanicTitle>
            Mecânica páscoa
            <br />
            vendas
          </S.MechanicTitle>

          <S.DescriptionContainer>
            <S.DescriptionTitle>
              Bateu levou!
            </S.DescriptionTitle>

            <S.TextContainer>
              <div>
                <S.Subtitle>
                  1,4%
                </S.Subtitle>
                <S.Text>
                  Devolução (R$)
                  <br />
                  Total Brasil
                </S.Text>
              </div>

              <S.Divider />

              <div>
                {state.data.channel.name !== 'MERCHAN' && <S.Subtitle>
                  500
                  <span> pontos</span>
                </S.Subtitle>}

                {state.data.channel.name === 'MERCHAN' && <S.SubtitleMerchan>
                  <p><span>Promotor </span> 300 gigapassos</p>
                  <p><span>Coordenador </span> 500 gigapassos</p>
                  <p><span>Gerente </span> 500 gigapassos</p>
                </S.SubtitleMerchan>}
                <S.Text>
                  aceleradores para a viagem
                  <br />
                  mais aguardada do ano!
                </S.Text>
              </div>
            </S.TextContainer>
          </S.DescriptionContainer>

          <S.ModalButton onClick={handleModdalToggle}>
            Confira
            <br />
            as regras
          </S.ModalButton>
        </S.MechanicContent>
      </S.MechanicContainer>

      <S.ImageContainer>
        <figure>
          <img src={character} alt="" />
        </figure>
      </S.ImageContainer>
    </S.Container>
  </S.Template>
}
