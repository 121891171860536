import styled from 'styled-components'

export const StatsItem = styled.div`
  display: grid;
  gap: 10px;
  padding-inline: 24px;
  text-transform: uppercase;

  p {
    margin: 0;
  }
`

export const Count = styled.p`
  color: #3e3f5e;
  font-size: 14px;
`

export const Label = styled.p`
  color: #adafca;
  font-size: 12px;
`
