import styled from 'styled-components'

export const ProfileInfoTitle = styled.p`
  font-weight: 700;
  margin-bottom: 2.25rem;
`

export const AcceptInfo = styled.p`
  display: block;
  border-radius: 0.75rem;
  border: 1px solid var(--secondary);
  margin-top: 1rem;
  color: black;
  font-weight: 700;
  overflow: hidden;

  .label {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 280px;
    background-color: var(--secondary);
    color: var(--white);

    @media (max-width: 520px) {
      width: 100% !important;
      border-top-right-radius: 12px;
    }
  }
  span:not(.label) {
    color: var(--secondary);
    width: 20rem;
    background-color: transparent;
    font-weight: 700;
    text-transform: capitalize;
    margin-left: 1rem;
  }
`
