import { FormatAdapter } from '../../data/protocols/FormatAdapter'
import { ShortenerAdapter } from '../../data/protocols/ShortenerAdapter'

export class NumberAdapter implements ShortenerAdapter, FormatAdapter {
  private readonly simbols: string[] = ['', 'K', 'M', 'B']

  format (value: number, fractionDigits: number): string {
    const digits = Math.pow(10, fractionDigits)
    const newValue = Math.floor(value * digits) / digits
    return newValue.toLocaleString('pt-br', {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits
    })
  }

  shorten (value: number, fractionDigits: number): string {
    let newValue = value
    let size = 0
    while (newValue >= 1000) {
      newValue = Math.floor(newValue) / 1000
      size++
    }
    if (size === 0) {
      const formatted = newValue.toLocaleString('pt-br')
      return formatted
    }
    const formatted = this.format(newValue, fractionDigits)
    return `${formatted}${this.simbols[size]}`
  }
}
