
import '@fortawesome/fontawesome-free/css/all.min.css'
import { ReactElement } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Cookie from './components/Cookie'
import AuthProvider from './contexts/AuthContext'
import CampaignProvider from './contexts/CampaignContext'
import CartProvider from './contexts/CartContext'
import DesktopMenuProvider from './contexts/DesktopMenuContext'
import LockedModalProvider from './contexts/LockedModalContext'
import MenuProvider from './contexts/MenuContext'
import ModalProvider from './contexts/ModalContext'
import ModalMdlzProvider from './contexts/ModalMdlzContext'
import NotifyProvider from './contexts/NotifyContext'
import { QuestModalProvider } from './contexts/QuestModalContext'
import Router from './route'
import GlobalStyle from './styles'

export default function App (): ReactElement {
  return <>
    <BrowserRouter>
      <AuthProvider>
        <DesktopMenuProvider>
          <CartProvider>
            <CampaignProvider>
              <NotifyProvider>
                <QuestModalProvider>
                  <ModalProvider>
                    <LockedModalProvider>
                      <ModalMdlzProvider>
                        <MenuProvider>
                          <Router />
                          <ToastContainer autoClose={3000} />
                          <GlobalStyle />
                        </MenuProvider>
                      </ModalMdlzProvider>
                    </LockedModalProvider>
                  </ModalProvider>
                </QuestModalProvider>
              </NotifyProvider>
            </CampaignProvider>
          </CartProvider>
        </DesktopMenuProvider>
      </AuthProvider>
    </BrowserRouter>
    <Cookie />
  </>
}
