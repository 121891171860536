import { ReactElement, useCallback, useEffect, useState } from 'react'
import Card from '../../../../../components/Card'
import InputSelect from '../../../../../components/Forms/InputSelect'
import SectionHeaderComponent from '../../../../../components/SectionHeaderComponent'
import { useAuth } from '../../../../../contexts/AuthContext'
import { makeClaimGiftCard } from '../../../../../main/factories/makeClaimGiftCard'
import { makeFindMyGiftCardOrder } from '../../../../../main/factories/makeFindMyGiftCardOrder'
import * as S from './style'

const findMyGiftCardOrders = makeFindMyGiftCardOrder()
const claimGiftCard = makeClaimGiftCard()

export default function StoreDownloads (): ReactElement {
  const [data, setData] = useState<any[]>([])
  const { userCan } = useAuth()

  const handleClaimGiftCard = useCallback(async (id: string) => {
    const response = await claimGiftCard.handle(id)
    if (response.status === 200) {
      setData((data: any[]): any[] => {
        const newData = [...data]
        const dataIndex = newData.findIndex(d => d.id === Number.parseInt(id))
        if (dataIndex === -1) return newData
        newData[dataIndex].status = 'DONE'
        return newData
      })
    }
  }, [])

  useEffect(() => {
    (async () => {
      const response = await findMyGiftCardOrders.find()
      setData(response.data)
    })()
  }, [])

  return <>
    <SectionHeaderComponent
      pretitle="MINHA LOJA"
      title='Resgatar Vouchers'
    />

    <S.ContentContainer>
      {userCan('filter:giftcards') && <Card className="single-card">
        <S.FilterContainer>
          <InputSelect label="Filtrar">
            <option selected>Todas as Categorias</option>
          </InputSelect>

          <InputSelect label="Ordenar">
            <option selected>Data de Compra (Mais recentes primeiro)</option>
            <option>Data de Compra (Mais antigos primeiro)</option>
          </InputSelect>

          <button onClick={() => { }}>Filtrar Downloads!</button>
        </S.FilterContainer>
      </Card>}

      <S.TableContainer>
        <S.SalesTable>
          <thead>
            <tr>
              <th>ITEM</th>
              <th>DATA</th>
              <th>PREÇO</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {data.map((el, i) => (
              <>
                <tr>
                  <td>
                    <S.ItemLink to="">
                      <figure>
                        <img src={el.image} alt="" />
                      </figure>
                    </S.ItemLink>

                    <S.ItemLinkContent>
                      <S.ItemLink to="">{el.name}</S.ItemLink>
                    </S.ItemLinkContent>
                  </td>
                  <td>{new Date(el.createdAt).toLocaleDateString('pt-br', { timeZone: 'America/Sao_Paulo' })}</td>
                  <td>
                    <span>R$</span>
                    {' '}
                    {el.price}
                  </td>
                  <td>
                    <S.ButtonsContainer>
                      {el.status === 'WAITING_CLAIM' && <button onClick={async () => await handleClaimGiftCard(el.id.toString())} className='content'>Resgatar</button>}

                      {el.status === 'DONE' && <button>{el.pinCode}</button>}
                      {el.status === 'WAITING_CODE' && <button>Processando</button>}
                    </S.ButtonsContainer>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </S.SalesTable>
      </S.TableContainer>
    </S.ContentContainer>
  </>
}
