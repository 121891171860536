import { ReactElement } from 'react'
import Template from '../../../template'

interface SuccessComponentProps {
  link: string
}

function SuccessComponent ({ link }: SuccessComponentProps): ReactElement {
  return <Template>
    <div className="grid medium-space">
      <div className="account-hub-content">
        <div className="section-header">
          <div className="section-header-info">
            <p className="section-pretitle">Planilha</p>

            <h2 className="section-title">
              Baixar o relatório
            </h2>
          </div>
        </div>

        <div className="grid-column">
          <div className="widget-box">
            <p className="widget-box-title">Para baixar o relatório clique no link abaixo</p>
            <div className="widget-box-content">
              <a href={link}>Baixar o relatório</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Template>
}

export default SuccessComponent
