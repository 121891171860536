import styled from 'styled-components'

export const AuctionProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 679px) and (orientation: portrait) {
    padding-inline: 24px;
  }
`

export const Title = styled.h1`
  color: var(--white);
  font-family: MdlzBiteType;
  font-size: 40px;

  @media screen and (orientation: portrait) {
    font-size: 24px;
    text-align: center;
    white-space: nowrap;
  }
`

export const AuctionProductsGridContainer = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, 248px);
  justify-content: center;
`

export const ArrowsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 104px;
  justify-content: center;
`

export const ArrowButton = styled.button`
  background-color: transparent;
  color: var(--white);
  font-family: MdlzBiteType;
  font-size: 48px;
  width: fit-content;
`
