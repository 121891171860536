import styled from 'styled-components'

interface GetColorProps {
  isDragAccept: boolean
  isDragReject: boolean
  isFocused: boolean
}

const getColor = (props: GetColorProps): string => {
  if (props.isDragAccept) {
    return 'var(--success)'
  }
  if (props.isDragReject) {
    return 'var(--danger)'
  }
  if (props.isFocused) {
    return 'var(--info)'
  }
  return 'var(var(--dark))'
}

export const DropZone = styled.div<GetColorProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  margin: 30px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: var(--light);
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`

export const DropZoneMessage = styled.div<GetColorProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: ${(props) => getColor(props)};
  outline: none;
  transition: color 0.15s ease-in-out;
  text-align: center;
`

export const ProgressContainer = styled.div`
  border: 1px solid var(var(--dark));
  width: 100%;
  height: 30px;
  border-radius: 8px;
  margin-top: 16px;
  p {
    font-weight: 700;
    position: absolute;
    bottom: 4px;
    font-size: 20px;
  }
  p.error {
    color: var(--red);
  }

  p.completed {
    color: var(--success);
  }
`

export const ProgressBar = styled.div<{ progressValue: number }>`
  width: calc(${(props) => (props.progressValue) || 0}% + 2px);
  position: relative;
  bottom: 1px;
  right: 1px;
  height: inherit;
  border-radius: inherit;
  background-color: var(--primary);
  transition: width 0.4s ease;
  &.completed {
    background-color: var(--success);
  }
  &.error {
    border: 1px solid var(--danger);
    background-color: var(--danger);
  }
`

export const Table = styled.table`
  margin-top: 32px;
  width: 100%;
  & tr > th,
  & tr > td {
    text-align: center;
    padding: 8px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    &:first-child {
      width: 20%;
      text-align: left;
      border-left: 1px solid #eee;
    }
    &:last-child {
      width: 20%;
      text-align: right;
      border-right: 1px solid #eee;
    }
  }
`

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  input {
    padding: 1rem 0.5rem;
    min-width: 10rem;
    width: 100%;
    border: 1px solid #dedeea;
    border-radius: 12px;
  }
  select {
    padding: 1rem 0.5rem;
  }
  &&& button {
    width: 100%;
  }
  @media (max-width: 480px) {
     flex-wrap: wrap;
    }
`

export const PreviewsList = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 16px;
  margin: 30px;
  width: 100%;
`

export const Preview = styled.div`
  aspect-ratio: 284 / 72;
  border-radius: 20px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 300px;
  position: relative;
  width: 100%;

  > figure {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 100%;
    margin: 0;
    overflow: hidden;
    width: 100%;

    img {
      object-fit: cover;
      width: 100%;
    }

    i {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    img, i {
      height: 100%;
      width: 100%;
    }
  }

  button {
    color: var(--danger);
    line-height: 0;
    position: absolute;
    right: -2px;
    top: -2px;
    width: fit-content;
  }

  p {
    padding: 8px 12px;
    font-size: 12px;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
