import styled from 'styled-components'

export const CountdownContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  p {
    font-family: MdlzBiteType;
    font-weight: 700;
    margin: 0;
    text-align: center;
    vertical-align: middle;
  }

  figure {
    margin: 0;
    max-width: 164px;

    img {
      width: 100%;
    }
  }

  > figure {
    margin: 0 32px -104px;
    z-index: 2;
  }

  @media screen and (max-width: 1280px) and (orientation: portrait) {
    > figure {
      margin: 0;
      width: 64%;
    }


    > div.countdown {
      display: none;
    }
  }

  @media screen and (max-width: 679px) and (orientation: portrait) {
    padding-inline: 24px;
  }
`

export const DigimoedasCountContainer = styled.div`
  background-color: rgba(71,119,217,0.9019607843137255);
  border-radius: 20px;
  display: flex;
  filter: drop-shadow(0px 6px 4px rgba(0,0,0,0.15));
  justify-content: space-around;
  gap: 8px;
  margin-left: 48px;
  max-height: 168px;
  max-width: 292px;
  padding: 16px 8px 16px 16px;
  width: 100%;

  p {
    font-family: MdlzBiteType;
    font-weight: 700;
    margin: 0;
    text-align: center;
    vertical-align: middle;
  }

  figure {
    position: relative;
    max-width: 40%;
    width: 100%;

    img {
      bottom: -36px;
      height: auto;
      position: absolute;
      width: 100%;
    }
  }

  @media screen and (max-width: 1280px) and (orientation: portrait) {
    margin-left: 16px;

    figure img {
      bottom: -28px;
    }
  }
`

export const DigimoedasCountContent = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 8px;

  > p {
    color: #fff;
    white-space: nowrap;
  }

  > div {
    background-color: #f7cb25;
    border-radius: 30px;
    display: flex;
    filter: drop-shadow(0px 6px 4px rgba(0,0,0,0.15));
    flex-direction: column;
    padding: 0 16px 8px;

    > p {
      color: #1d3f85;
      font-size: 52px;
      font-weight: 600;

      &:last-child {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }

    @media screen and (max-width: 1280px) and (orientation: portrait) {
      border-radius: 12px;
      padding-inline: 16px;

      > p {
        font-size: 44px;

        &:last-child {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 1px;
        }
      }
    }

    @media screen and (max-width: 450px) and (orientation: portrait) {
      padding-inline: 8px;
    }
  }

  @media screen and (max-width: 450px) and (orientation: portrait) {
    > p {
      font-size: 16px;
    }
  }
`
