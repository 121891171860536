import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import CategoryCardProps from './CategoryCard.props'

export const CategoryCardContainer = styled(Link) <Pick<CategoryCardProps, 'bgImgSrc'>>`
  ${({ bgImgSrc }) => css`
    background: url(${bgImgSrc}) no-repeat right top, linear-gradient(45deg, #e7912d 0%, #f1cd3d 100%);
    background-size: cover;
    border-radius: 16px;
    box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
    filter: drop-shadow(0 0 20px rgba(94,93,151,0.06));
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-between;
    padding: 24px 28px;
    width: 272px;

    p {
      color: var(--white);
      font-weight: 700;
      margin: 0;
    }

    &:nth-child(1) {
      > :last-child {
        color: #605df6;
      }
    }

    &:nth-child(2) {
      > :last-child {
        color: #658cfb;
      }
    }

    &:nth-child(3) {
      > :last-child {
        color: #7accfd;
      }
    }

    &:nth-child(4) {
      > :last-child {
        color: #79d1e1;
      }
    }
  `}
`

export const CategoryCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  max-width: 50%;

  p {
    &:first-child {
      font-size: 1.125rem;
    }

    &:last-child {
      font-size: 0.875rem;
      font-weight: 500;
    }
  }
`

export const ButtonTitle = styled.p`
  background-color: white;
  border-radius: 12.5rem;
  padding-inline: 0.5rem;
  text-decoration: none;
  text-transform: uppercase;
  width: fit-content;

  font-size: 12px;
  letter-spacing: 0px;
  line-height: 28px;
  color: #658cfb;
  font-weight: 700;
  font-family: Rajdhani, sans-serif;
`
