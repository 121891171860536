import styled from 'styled-components'
import CardComponent from '../../../components/Card'

export const Card = styled(CardComponent)`
  background-color: var(--white);
  border-radius: 4px;
  filter: drop-shadow(0px 3px 5px rgba(27,27,29,0.16));
  padding: 0;
  width: 116px;
  max-width: 100%;

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    border-radius: 8px;
    margin-top: 48px;
    width: 238px;
  }
`

export const TitleContainer = styled.div`
  align-items: center;
  background-image: linear-gradient(90deg, #185cc6 0%, #59affe 100%);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  justify-content: center;

  h1 {
    color: #f8f8fb;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 36px !important;

    h1 {
      font-size: 24px;
      line-height: 24px;
    }
  }
`

export const BadgesContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-around;
  gap: 4px 8px;
  padding: 4px 12px 8px;


  figure {
    margin: 0;
    width: 16px;
  }

  @media screen and (min-width: 1280px) and (orientation: landscape) {
    padding: 20px;

    figure {
      width: 40px;
    }
  }
`
