import styled from 'styled-components'
import SectionKPITable from '../SectionKPIs/SectionKPITable'
import SectionKPITableMerchan from '../SectionKPIs/SectionKPITable/SectionKPITableMerchan'
import TopMask from '../assets/top-mask.svg'

export const Container = styled.div`
  background-color: #FFFFFF80;
  backdrop-filter: blur(8px);
  box-shadow: 0 0 60px 0 rgba(94,92,154,.12);
  padding: 5rem;
  position: relative;
  width: 100%;
`

export const RegulamentoContainer = styled.div`
  margin-top: 20px;
  background-color: #4F1E70;
  backdrop-filter: blur(8px);
  border-radius: 12px 12px 0px 0px;
  padding: 0px 125px 32px 125px;

  @media screen and (max-width: 1365px) {
    padding: 0px 28px 32px 28px;
  }
  p, h3, table {
    color: #FFF;
    font-size: 15px;
  }
  h3 {
    font-size: 20px;
  }
  hr {
    height: 2px;
    background-color: #E18719;
  }

  figure {
    margin: 0;
  }

  img {
    max-width: 100%;
  }

`
export const RegulamentoContainerTopMask = styled.div`
  text-align: center;
  font-weight: 900;
  background-image: url(${TopMask});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  white-space: pre-line;
  font-size: 26px;
  color: #FFF;
  width: 100%;
  height: 155px;
  position: relative;

  @media screen and (max-width: 1365px) {
    font-size: 20px;
  }
  @media screen and (max-width: 520px) {
    font-size: 9px;
    padding: 6px;
    background-position: top left;
    height: 95px;
  }
  span {
    color: #4F1E70;
    font-size: 32px;

    @media screen and (max-width: 1365px) {
      font-size: 24px;
    }
    @media screen and (max-width: 520px) {
        font-size: 16px;
    }
  }
  div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 520px) {
        justify-content: flex-start;
    }
  }
`
export const RegulamentoContainerTopMaskImage = styled.div`
  width: 124px;
  height: 270px;
  position: absolute;
  right: -30px;
  top: 20px;

  @media screen and (max-width: 1365px) {
      display: none !important;
  }
`

export const RegulamentoTitle = styled.div`
  color: #000;
  align-items: center;
  background: linear-gradient(180deg, rgba(104, 82, 237, 0.05) 0%,rgb(186, 67, 252, 0.05) 100%);
  display: flex;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  height: 12rem;
  justify-content: center;
  margin-inline: -1.75rem;
  padding-inline: 1.75rem;

  h1 {
    /* color: var(--white); */
    font-family: MDLZBiteType, sans-serif;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: clamp(3rem, 2.8274rem + 0.7673vw, 3.75rem);
    letter-spacing: -0.2188rem;
    line-height: 3.4063rem;
    text-align: center;
    text-transform: uppercase;
  }
`

export const Table = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  top: -1px;
  margin: 0 auto 18px;
  max-width: 532px;

  table {
      color: #FFF;
      font-size: 15px;
      max-width: 564px;
      width: 100%;

      thead {
          tr {
              th {
                  border-collapse: collapse;
                  border-inline: 1px solid #E18719;
                  border-top: 2px solid #E18719;
                  padding: 12px 0 3px;
                  text-align: center;

                  &:first-child {
                      border-left: none;
                      text-align: left;
                  }

                  &:last-child {
                      border-right: none;
                    }

                  &:last-child,
                  &:nth-last-child(2) {
                    width: 24%;
                  }
              }
          }
      }
      tbody tr {
          &:last-child {
              td {
                  border-bottom: 0 none;
              }
          }
          td {
              margin: 0 auto;
              border: 1px solid #E18719;
              border-collapse: collapse;
              padding: 3px 0;

              @media screen and (max-width: 520px) {
                  font-size: 9px;
                  padding: 3px 5px;
              }
              &:first-child {
                  border-left: 0 none;
              }
              &:last-child {
                  border-right: 0 none;
              }
          }

          @media screen and (max-width: 1365px) {
              grid-column: initial;
          }
      }

      tbody {
          tr:last-child td {
              color: #FFC01A;
          }
      }

      @media screen and (max-width: 1365px) {
          margin: 36px auto 60px;
      }
    }
`

export const SectionTable = styled(SectionKPITable)`
  margin: 0 auto 20px;
  max-width: 532px;

  table {
    margin: 0;
  }
`

export const MerchanTable = styled(SectionKPITableMerchan)`
  margin-inline: auto;
  max-width: 532px;

  table {
    margin: 0;
  }
`
