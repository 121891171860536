import styled from 'styled-components'

export const Statistics = styled.section`
  align-items: center;
  display: flex;
  gap: 1.125rem;

  &.main-stat {
    justify-content: space-between;

    @media screen and (max-width: 960px) {
      justify-content: center;
    }
  }
`

export const Icon = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 3rem;
  justify-content: center;
  min-width: 3rem;

  :has(svg.icon-wallet) {
    background-color: var(--secondary);
  }

  :has(svg.icon-item) {
    background-color: var(--primary);
  }

  :has(svg.icon-earnings) {
    background-color: #4f8dff;
  }

  :has(svg.icon-revenue) {
    background-color: #3ad2fe;
  }

  svg {
    fill: var(--white);
  }
`

export const BalanceContainer = styled.div`
  display: flex;
  gap: 1rem;
`

export const StatContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  justify-content: center;
  text-align: start;
`

export const StatTitle = styled.p`
  font-size: 1.75rem;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;

  span {
    font-size: 1.125rem;
    text-transform: uppercase;
  }
`

export const StatDescription = styled.p`
  color: #333333;
  font-size: 0.75rem;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
`

export const UserStatsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1.875rem;
  justify-content: center;
  padding-right: 1.875rem;

  @media screen and (max-width: 960px) {
    display: none;
  }
`

export const UserStat = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  gap: 0.625rem;
  text-align: center;
`

export const Divider = styled.div`
  content: "";
  background-color: #eaeaf5;
  height: 20px;
  width: 1px;
`

export const GridContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, 284px);
  justify-content: center;
`
