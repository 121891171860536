import { ReactElement } from 'react'
import ameba from '../../assets/img/incentivo-2024/home/amebas.png'
import ChatIcon from '../../assets/img/mobile/home/tab-icons/chat.svg'
import GearIcon from '../../assets/img/mobile/home/tab-icons/gear.svg'
import NewsIcon from '../../assets/img/mobile/home/tab-icons/news.svg'
import RankingIcon from '../../assets/img/mobile/home/tab-icons/ranking.svg'
import * as S from './style'

function FooterComponent (): ReactElement {
  return <S.FooterContainer>
    <S.Footer>
      <S.Tabs>
        <S.TabItem to="/mecanicas">
          <img src={GearIcon} />
          <p>Mecânica</p>
        </S.TabItem>

        <S.TabItem to="/ranking">
          <img src={RankingIcon} />
          <p>Ranking</p>
        </S.TabItem>

        <S.TabItem to="/fale-conosco">
          <img src={ChatIcon} />
          <p>Fale Conosco</p>
        </S.TabItem>

        <S.TabItem to="/newsfeed">
          <img src={NewsIcon} />
          <p>Notícias</p>
        </S.TabItem>
      </S.Tabs>
      <S.FooterImg src={ameba} />
    </S.Footer>
  </S.FooterContainer>
}

export default FooterComponent
