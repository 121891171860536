import { AxiosInstance } from 'axios'
import { ReproveOrder } from '../../domain/use-case/ReproveOrder'

export class ApiReproveOrder implements ReproveOrder {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (orderId: number): Promise<void> {
    await this.api.patch(`/order/${orderId}/reprove`)
  }
}
