import { ReactElement } from 'react'
import DigimoedasCountContainerProps from './props'
import * as S from './style'

export default function RankingPositionCard ({ position, title, value }: DigimoedasCountContainerProps): ReactElement {
  return <S.DigimoedasCountContainer>
    <S.DigimoedasCountContent>
      <p>{title}</p>

      <div>
        <p>{String(position).padStart(3, '00')}º</p>

        <S.GigapassosCount>
          <p>{value}</p>
          <p>gigapassos</p>
        </S.GigapassosCount>
      </div>
    </S.DigimoedasCountContent>
  </S.DigimoedasCountContainer>
}
