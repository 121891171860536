import { ReactElement } from 'react'
import digimoeda from '../../../../assets/img/game-gira-giro/digimoeda.png'
import GameFinishedProps from './props'
import * as S from './styled'

export default function GameFinished ({ points }: GameFinishedProps): ReactElement {
  return <S.Container>
    <p>Você atingiu o número máximo de jogadas.</p>
    {points > 0 && <h1>Parabéns!</h1>}

    <p>
      Você ganhou <span>{String(points).padStart(2, '0')} digimoedas</span>
      {' '}
      <img src={digimoeda} alt="" />
    </p>

    <p>Obrigado por participar!</p>
  </S.Container>
}
