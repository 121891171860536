import styled from 'styled-components'
import HederTopMask from './assets/top-section-mask.svg'

export const SectionKPIsHeader = styled.div`
    width: 100%;
    background-color: #4F1E70;
    padding: 50px 0;

    @media (max-width: 1365px) {
        padding-bottom: 20px;
    }
`

export const SectionKPIHeaderTop = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 40px;

    @media screen and (max-width: 1365px) {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
`

export const SectionKPIHeaderTopPerson = styled.img`
    position: relative;
    left: -64px;
    bottom: -50px;

    @media screen and (max-width: 1365px) {
        display: none;
    }
`

export const SectionKPIHeaderTopSpace = styled.div`
    position: relative;
    width: 100%;
    max-width: 175px;
    height: 210px;

    @media screen and (max-width: 1365px) {
            display: none !important;
        }
`

export const SectionKPIHeaderTopMask = styled.div`
    text-align: center;
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
    background-image: url(${HederTopMask});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    white-space: pre-line;
    font-size: 40px;
    color: #FFF;
    width: 400px;
    height: 133px;
    line-height: 1;

    @media screen and (max-width: 1365px) {
            font-size: 27px;
            height: 95px;
        }
    span {
        color: #4F1E70;
        font-size: 48px;

        @media screen and (max-width: 1365px) {
            font-size: 35px;
        }
    }
    div {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`

export const SectionKPIHeaderTopText = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: 900;
    line-height: 20px;
    font-size: 20px;
    color: #FFC01A;
    margin-top: 30px;

    @media screen and (max-width: 1365px) {
        margin: 0;
        text-align: center;

        span {
            font-size: 15px;

            br {
                display: none;
            }
        }
    }

    span {
        color: #FFF;
        font-weight: 400;
        padding-bottom: 20px;
        margin-top: 4px;
    }
`
