import { ReactElement, useCallback, useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import api from '../../services/api'
import BannerTitleHomeProps from './BannerTitleHome.props'

import * as S from './style'

interface Banner {
  id: string
  link: string
  path: string
}

export default function BannerTitleHome ({ avatar, username, position, stars }: BannerTitleHomeProps): ReactElement {
  const [banners, setBanners] = useState<Banner[]>([])

  const loadBanners = useCallback(async () => {
    const response = await api.get('/banner/')
    setBanners(response.data.banners)
  }, [])

  useEffect(() => {
    loadBanners()
  }, [loadBanners])

  return (
    <S.BannerContainer className="section-banner profile-banner">
      <S.SliderHome>
        <Carousel showThumbs={false} showArrows={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={5000}>
          {banners.map((banner) => <div key={banner.id}>
            <S.Anchor href={banner.link}>
              <img src={banner.path} alt="BannerHome" />
            </S.Anchor>
          </div>)}
        </Carousel>
      </S.SliderHome>

      <div style={{ clear: 'both' }}></div>
    </S.BannerContainer>
  )
}
