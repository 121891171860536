import styled from 'styled-components'
import TemplateBase from '../../template'

export const Template = styled(TemplateBase)`
  &.content-grid {
    width: initial;
    min-height: initial;
    max-width: initial;
    padding: initial;
  }
`

export const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100vw;

  h1, p, span, a, button {
    font-family: MdlzBiteType;
    margin: 0;

    br {
      content: "";
      display: block;
    }
  }

  a {
    text-decoration: none;
  }

  @media (min-width: 1280px) {
    gap: 32px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 28px 100px;
`

export const CampaignCardsContainer = styled.div`
  align-items: stretch;
  display: grid;
  gap: 16px;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fill, 324px);
  justify-content: center;
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
`
