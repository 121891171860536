import { ReactElement } from 'react'
import digimoeda from '../../../../assets/img/game-gira-giro/digimoeda.png'
import amebaBottom from '../assets/ameba-bottom.png'
import amebaTop from '../assets/ameba-top.png'
import products from '../assets/finished-products.png'
import webBottom from '../assets/web-bottom.png'
import webTop from '../assets/web-top.png'
import GameFinishedProps from './props'
import * as S from './styled'

export default function GameFinished ({ points }: GameFinishedProps): ReactElement {
  return <S.Container>
    <S.ContentBg>
      {points > 0 && <h1>Parabéns!</h1>}

      <p>
        Você ganhou <span>{String(points).padStart(2, '0')} digimoedas</span>
        {' '}
        <img src={digimoeda} alt="" />
      </p>

      <p>Obrigado por participar!</p>

      <figure>
        <img src={products} alt="" />
      </figure>
    </S.ContentBg>

    <S.WebBottom>
      <img src={webBottom} alt="" />
    </S.WebBottom>

    <S.WebTop>
      <img src={webTop} alt="" />
    </S.WebTop>

    <S.AmebaBottom>
      <img src={amebaBottom} alt="" />
    </S.AmebaBottom>

    <S.AmebaTop>
      <img src={amebaTop} alt="" />
    </S.AmebaTop>
  </S.Container>
}
