import { ReactElement, useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Loading from '../../../components/Loading'
import { useAuth } from '../../../contexts/AuthContext'
import { ApiCreateBid } from '../../../data/use-case/ApiCreateBid'
import { ApiLoadBid } from '../../../data/use-case/ApiLoadBid'
import api from '../../../services/api'
import BidContainer from '../AuctionProducts/BidContainer'
import BidButton from '../BidButton'
import CountdownComponent from '../CountdownComponent'
import CurrentCoins from '../CurrentCoins'
import Slider from '../Slider'
import { ModalBidProps } from './props'
import * as S from './style'

const loadBid = new ApiLoadBid(api)
const createBid = new ApiCreateBid(api)

enum State {
  LOADING,
  READY,
  SENDING,
  SUCCESS,
  FAILURE
}

interface BidData {
  id: number
  image: string
  value: number
  title: string
  earnedCoins: number
}

function ModalBid ({ id, endTime }: ModalBidProps): ReactElement {
  const [state, setState] = useState<State>(State.LOADING)
  const [data, setData] = useState<BidData>({} as unknown as BidData)
  const [value, setValue] = useState<number>(0)
  const { user } = useAuth()

  const handleSendBid = useCallback(async () => {
    setState(State.SENDING)
    const response = await createBid.handle({ itemId: id, value })
    switch (response.status) {
      case 400:
        switch (response.data.message) {
          case 'Invalid param: value':
            toast.error('O valor informado está abaixo do lance atual', { theme: 'colored' })
            setState(State.READY)
            return
          case 'Invalid param: bid':
            toast.error('O lance atual já é seu', { theme: 'colored' })
            setState(State.READY)
            return
          case 'Missing param: value':
            toast.error('Você não informou o valor', { theme: 'colored' })
            setState(State.READY)
            return
          case 'Insuficient: coins':
            toast.error('Você não tem moedas suficientes', { theme: 'colored' })
            setState(State.READY)
            return
        }
        toast.error(response.data.message, { theme: 'colored' })
        setState(State.READY)
        return
      case 403:
        toast.error('Você não está autorizado a acessar esta página', { theme: 'colored' })
        setState(State.READY)
        return
      case 500:
        toast.error('Ocorreu um erro inesperado', { theme: 'colored' })
        setState(State.READY)
        return
      case 200:
        setData(response.data)
        setValue(Number.parseInt(response.data.value) + 10)
        setState(State.SUCCESS)
    }
  }, [value, user])

  useEffect(() => {
    (async () => {
      const response = await loadBid.handle({ id })
      switch (response.status) {
        case 403:
          toast.error('Você não está autorizado a acessar esta página', { theme: 'colored' })
          setState(State.FAILURE)
          return
        case 500:
          toast.error(response.data.message, { theme: 'colored' })
          setState(State.FAILURE)
          return
        case 200:
          setData(response.data)
          setState(State.READY)
          setValue(Number.parseInt(response.data.value) + 10)
      }
    })()
  }, [])

  return <S.Container>
    {state === State.LOADING && <Loading />}
    {[State.READY, State.SENDING].includes(state) && <>
      <CountdownComponent endTime={endTime} />
      <S.Content>
        <S.ProductContainer>
          <S.ImageContainer>
            <S.ProductImage src={data.image} alt={data.title} />
          </S.ImageContainer>
          <S.InfoContainer>
            <S.InfoTitle>{data.title}</S.InfoTitle>
            <BidContainer>{data.value}</BidContainer>
          </S.InfoContainer>
        </S.ProductContainer>
        <S.BidContainer>
          <CurrentCoins coins={data.earnedCoins} />
          <S.SliderContainer>
            {/* <S.BulletButton onClick={handleDecreaseValue}>-</S.BulletButton> */}
            <Slider value={data.value} currentValue={value} onChange={(value) => setValue(Number.parseInt(value))} />
            {/* <S.BulletButton onClick={handleIncreaseValue}>+</S.BulletButton> */}
          </S.SliderContainer>
        </S.BidContainer>
      </S.Content>
      <BidButton onClick={handleSendBid} disabled={state === State.SENDING}>Dar Lance</BidButton>
    </>}
    {state === State.SUCCESS && <>Seu lance foi aceito</>}
    {state === State.FAILURE && <>Não foi possivel carregas as informações</>}
  </S.Container>
}

export default ModalBid
