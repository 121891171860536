import { ReactElement, useCallback } from 'react'
import { Link } from 'react-router-dom'
import Banner from '../../../../components/Card/Banner'
import AuctionCardProps from './props'

import BidButton from '../../BidButton'
import * as S from './styled'

export default function AuctionProductCard ({ onClick, ...item }: AuctionCardProps): ReactElement {
  const shortenDescription = useCallback((description: string) => {
    if (description === undefined) return ''
    return description.length < 70
      ? `${description.replace(/&lt;/g, '<').replace(/&gt;/g, '<')}`
      : `${description.replace(/&lt;/g, '<').replace(/&gt;/g, '<').substring(0, 80)}...`
  }, [])

  return <S.Card>
    <S.CardContainer>
      <Link to={''}>
        <Banner>
          <img src={item.image} alt={item.title} />
        </Banner>
      </Link>

      <S.CardContentContainer>
        <S.PriceContainer>
          <p>{item.currentBid}</p>
          <p>Digimoedas</p>
        </S.PriceContainer>

        <S.TitleContainer>
          <Link to={''}>{item.title}</Link>

          <S.CategoryLinkContainer>
            <Link to="">{item.category}</Link>
          </S.CategoryLinkContainer>
        </S.TitleContainer>

        <S.DescriptionContainer dangerouslySetInnerHTML={{ __html: shortenDescription(item.description) }} />
      </S.CardContentContainer>
    </S.CardContainer>

    <S.CardFooter>
      <div>
        <BidButton onClick={onClick}>
          Dar lance
        </BidButton>
      </div>
    </S.CardFooter>
  </S.Card>
}
