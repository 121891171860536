import { ReactElement } from 'react'
import * as S from './styled'

export default function InstructionsModal (): ReactElement {
  return <S.InstructionsModalContainer>
    <h1>Instruções <br/>do jogo</h1>

    <ul>
      <li>É SÓ MOVER A BARRA OREO E ELIMINAR O CORVO, A LÁPIDE, A TEIA, A CAVEIRA E O MORCEGO.</li>
      <li>VOCÊ TEM DIREITO A DUAS RODADAS, VALENDO A DE MAIOR PONTUAÇÃO.</li>
      <li>CADA JOGADA DURA ATÉ 1min E 30S.</li>
      <li>QUANTO MAIS ALTO O ELEMENTO, MAIOR A PONTUAÇÃO.</li>
      <li>PONTUAÇÃO MÁXIMA POSSÍVEL POR JOGADA: 400 DIGIMOEDAS.</li>
    </ul>
  </S.InstructionsModalContainer>
}
