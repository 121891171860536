import styled from 'styled-components'
import avatarBg from '../assets/amebas.png'

interface Direction {
  $reverse?: boolean
}

export const Container = styled.div<Direction>`
  align-items: center;
  aspect-ratio: 296 / 156;
  display: flex;
  flex-direction: ${({ $reverse }) => $reverse && 'row-reverse'};
  gap: 8px;
`

export const AvatarBg = styled.figure`
  align-items: center;
  aspect-ratio: 252 / 279;
  background-image: url(${avatarBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  margin: 0;
  position: relative;
  width: 124px;
`

export const Avatar = styled.img`
  margin-left: 4px;
  height: fit-content;
  max-width: 94px;
  width: 76%;
`

export const PrizeImg = styled.img<Direction>`
  bottom: 0;
  height: fit-content;
  left: ${({ $reverse }) => $reverse ? '-16%' : 'initial'};
  max-width: 72px;
  position: absolute;
  right: ${({ $reverse }) => !$reverse ? '-28%' : 'initial'};

  &.reserva {
    left: ${({ $reverse }) => $reverse ? '-24%' : 'initial'};
    max-width: 64px;
  }
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  gap: 8px;
  max-width: 148px;

  p {
    color: var(--white);
    font-family: MdlzBiteType;
    margin: 0;
    text-align: center;
  }
  `

export const WinnerName = styled.p`
  font-family: Roboto !important;
  font-size: 16px;
  font-weight: 700;
`

export const PrizeContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  p {
    font-size: 20px;
    max-width: 132px;
  }
`

export const Digimoedas = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;

  p {
    color: #f7cb45;
    font-size: 32px;
    letter-spacing: -2px;

    br {
      content: '';
      display: block;
      margin: -16px;
    }

    span {
      font-size: 12px;
      letter-spacing: 0;
    }
  }

  figure {
    max-width: fit-content;

    img {
      height: fit-content;
      max-width: 24px;
      width: 100%;
    }
  }
`
