import { ReactElement } from 'react'
import winner1 from './assets/01Erica_Matos.png'
import winner2 from './assets/02Natalya_Carvalho.png'
import winner3 from './assets/03Valdir_Rosa_Junior.png'
import winner4 from './assets/04Fabio_Junior.png'
import winner5 from './assets/05Jader_Gabriel.png'
import winner6 from './assets/06Lucas_Peixoto.png'
import winner7 from './assets/07Leandro_Lomeu.png'
import winner8 from './assets/08YAN_SAVIO.png'
import winner9 from './assets/09Raphael_Muniz.png'
import ameba2 from './assets/amebas_bottom.png'
import ameba1 from './assets/amebas_top.png'
import * as S from './styled'

export default function WaveTwoWinners (): ReactElement {
  return <S.Container>
    <h1>Ganhadores Leilão <span>Onda 2</span></h1>

    <S.BannerBg>
      <S.WinnersContainer>
        <S.Winner>
          <figure>
            <img src={winner1} alt="" />
          </figure>

          <p>ERICA CRISTINA MATOS ROSA</p>
        </S.Winner>

        <S.Winner>
          <figure>
            <img src={winner2} alt="" />
          </figure>

          <p>NATALYA CARVALHO COSTA</p>
        </S.Winner>

        <S.Winner>
          <figure>
            <img src={winner3} alt="" />
          </figure>

          <p>VALDIR ROSA JUNIOR</p>
        </S.Winner>

        <S.Winner>
          <figure>
            <img src={winner4} alt="" />
          </figure>

          <p>FABIO JUNIOR FERNANDES</p>
        </S.Winner>

        <S.Winner>
          <figure>
            <img src={winner5} alt="" />
          </figure>

          <p>JADER GABRIEL DE OLIVEIRA VALBUENO</p>
        </S.Winner>

        <S.Winner>
          <figure>
            <img src={winner6} alt="" />
          </figure>

          <p>LUCAS PEIXOTO ORTIZ</p>
        </S.Winner>

        <S.Winner>
          <figure>
            <img src={winner7} alt="" />
          </figure>

          <p>LEANDRO LOMEU DE AVELAR</p>
        </S.Winner>

        <S.Winner>
          <figure>
            <img src={winner8} alt="" />
          </figure>

          <p>YAN SAVIO ALVES DA COSTA</p>
        </S.Winner>

        <S.Winner>
          <figure>
            <img src={winner9} alt="" />
          </figure>

          <p>RAPHAEL MUNIZ</p>
        </S.Winner>
      </S.WinnersContainer>

      <figure>
        <img src={ameba1} alt="" />
      </figure>

      <figure>
        <img src={ameba2} alt="" />
      </figure>
    </S.BannerBg>
  </S.Container>
}
