import styled from 'styled-components'

export const SectionKPISecondContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFF;
`

export const SectionKPIsSecondContentEllipsis = styled.div`
    background-color: #FFC01A;
    border-radius: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 20px;
    max-height: 80px;
    max-width: 360px;

    @media screen and (max-width: 1365px) {
        max-height: 100px;
    }
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #4F1E70;
        font-weight: 900;
    }
    div p {
        color: #4F1E70;
        font-weight: 900;
        font-size: 30px;
        margin: 0px;
    }
    div span {
        font-size: 14px;
        text-align: center;
        line-height: 18px;
        margin-top: 4px;

        @media screen and (max-width: 390px) {
            font-size: 16px;
        }
    }
    img {
        height: 90px;
        margin-left: 10px;

        @media screen and (max-width: 520px) {
            height: 72px;
            margin-left: 2px;
        }
    }

`

export const SectionKPIsSecondContentBottomText = styled.div`
    width: 100%;
    max-width: 390px;
    margin: 30px auto 0px auto;
    color: #FFF;
    font-size: 10px;
    font-weight: 400;

    @media screen and (max-width: 1365px) {
        margin: 30px auto 30px auto;
    }
`

export const SectionKPISecondContentWarning = styled.div`
    width: 100%;
    max-width: 390px;
    margin: 14px auto 0px auto;
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    padding: 0 64px;
`
