import styled from 'styled-components'
import CoinsContainer from '../../../components/CoinsContainer'
import InputSelectComponent from '../../../components/Forms/InputSelect'
import DashboardChartComponent from './DashboardChart'
import DashboardComponent from './DashboardTable'

interface GraphProps {
  value: string
}

export const Graph = styled.div<GraphProps>`
  --primary: #615dfa;
  --secondary: #23d2e2;
  aspect-ratio: 1;
  background: conic-gradient(var(--primary) 0%, var(--secondary) ${props => `${props.value}%`}, #e8e8ef ${props => `${props.value}.1%`}, #e8e8ef 100%);
  border-radius: 50%;
  margin: 0 auto;
  max-width: 140px;
  position: relative;
  width: 100%;

  &:after {
    align-items: center;
    aspect-ratio: 1;
    background-color: #ffffff;
    border-radius: 50%;
    content: "${props => `${props.value}%`}";
    display: flex;
    font-family: Rajdhani, sans-serif;
    font-size: 2rem;
    font-weight: bold;
    justify-content: center;
    margin: 8px;
    position: absolute;
    width: calc(100% - 16px);
  }
`

export const FilterContainer = styled.div`
  display: flex;
  flex: 1 100%;
  gap: 16px;
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(94,92,154,.06);
  padding: 32px 28px 100px;
  position: relative;

  & > button {
    flex: 0 0;
    padding: 0 32px;
  }
`
export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: 1030px;
`

export const InputSelect = styled(InputSelectComponent)`
  max-width: 200px;
  select {
    background-color: #FFFFFF;
  }
`

export const Grid = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  max-width: 840px;
  width: 100%;

  @media (max-width: 1142px) {
    grid-template-columns: 1fr;
    max-width: 500px;

    > * {
      max-width: 500px;
      width: 100%;
    }
  }
`

export const Coins = styled(CoinsContainer)`
  margin: 0;

  @media screen {
    div div p {
      font-size: 32px;
    }

    figure {
      max-width: 80px;
    }
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const GridCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`

export const Card = styled.div`
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  box-shadow: rgba(94, 92, 154, 0.06) 0px 0px 40px 0px;
  padding: 32px 28px;
  position: relative;

  @media (max-width: 500px) {
    padding: 20px;
  }
`

export const CardTitle = styled.h1`
  font-size: 24px;
  font-weight: bold;
  text-align: center;

  @media (max-width: 500px) {
    font-size: 20px;
  }
`
export const CardValue = styled.p`
  font-size: 36px;
  font-weight: bold;
  margin: 0;
  text-align: center;

  @media (max-width: 500px) {
    font-size: 28px;
  }
`

export const DashboardTable = styled(DashboardComponent)`
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  box-shadow: rgba(94, 92, 154, 0.06) 0px 0px 40px 0px;
  padding: 32px 28px;
  position: relative;

  @media (max-width: 584px) {
    overflow: auto;
  }
  `

export const DashboardChart = styled(DashboardChartComponent)`
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  box-shadow: rgba(94, 92, 154, 0.06) 0px 0px 40px 0px;
  padding: 32px 28px 100px;
  position: relative;

  @media (max-width: 1142px) {
    padding: 32px 28px;
  }

  @media (max-width: 584px) {
    padding: 20px;
    overflow: auto;
  }
`
