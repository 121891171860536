import { ReactElement, useEffect, useState } from 'react'
import safe from '../../../assets/img/incentivo-2024/leilao/COFRE.png'
import { makeLoadCoins } from '../../../main/factories/makeLoadCoins'
import * as S from './styled'

const loadCoins = makeLoadCoins()

export interface PointsState {
  coins: number
  name: string
}

export enum ComponentStatus {
  LOADING,
  READY
}
export interface DigimoedasCountContainerProps {
  className?: string
  step?: number
}

export default function DigimoedasCountContainer ({ className, step }: DigimoedasCountContainerProps): ReactElement {
  const [status, setStatus] = useState<ComponentStatus>(ComponentStatus.LOADING)
  const [data, setData] = useState<PointsState>({} as unknown as PointsState)

  useEffect(() => {
    (async () => {
      const currentStep = step ?? 3
      const response = await loadCoins.handle({ step: currentStep })
      switch (response.status) {
        case 200:
          setData(response.data)
          setStatus(ComponentStatus.READY)
          return
        default:
          setStatus(ComponentStatus.READY)
      }
    })()
  }, [step])

  if (status === ComponentStatus.LOADING) return <>Carregando...</>

  return <S.DigimoedasCountContainer className={className}>
    <S.DigimoedasCountContent>
      <p>Você tem:</p>

      <div>
        <p>{(data.coins).toLocaleString('pt-br', { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</p>
        <p>digimoedas onda {data.name}</p>
      </div>
    </S.DigimoedasCountContent>

    <figure>
      <img src={safe} />
    </figure>
  </S.DigimoedasCountContainer>
}
