import { Link } from 'react-router-dom'
import styled from 'styled-components'
import CardComponent from '../../../components/Card'

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  gap: 16px;

  @media (max-width: 1280px) {
    display: flex;
    flex: 4;
    flex-wrap: wrap;
  }

  @media (max-width: 550px) {
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
`

export const LinkContainer = styled(Link)`
  :hover {
    text-decoration: none;
  }
`

export const MemoryContainer = styled.div`
  flex: 25%;
  max-width: 252px;
`

export const MemoryCard = styled(CardComponent)`
  background-color: var(--white);
  border-radius: 8px;
  filter: drop-shadow(0px 3px 5px rgba(27,27,29,0.16));
  max-height: 220px;
  padding: 0;
  position: relative;

  > figure {
    bottom: 20%;
    position: absolute;
    left: -12%;
    max-width: 100%;
  }

  @media (max-width: 1280px) {
    border-radius: 4px;

    > figure {
      bottom: 12%;
      left: -20%;
    }
  }
`

export const MemoryContentContainer = styled.div`
  background-color: var(--white);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  filter: drop-shadow(0px 3px 5px rgba(27,27,29,0.16));
  overflow: hidden;
  position: relative;
  max-height: 100px;
  min-height: 100px;

  > figure {
    margin: 0;
  }

  p, a {
    color: #3E3F5E;
    font-size: 8px;
    font-weight: 700;
    margin: 0;
    text-decoration: none;
  }

  @media (max-width: 1280px) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    max-height: 60px;
    min-height: 60px;
  }
`

export const MemoryContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 4px;

  h1, p {
    font-family: MdlzBiteType;
    margin: 0;
    text-align: center;
  }

  h1 {
    color: #1d3f85;
    font-size: 38px;

    span {
      color: #f5be4a;
      font-size: 48px;
    }

    br {
      content: "";
      display: block;
      margin: -8px;
    }
  }

  p {
    color: #f5be4a;
    font-size: 14px;
  }

  @media (max-width: 1280px) {
    h1 {
      font-size: 28px;
    }

    p {
      font-size: 8px;
    }
  }
`

export const GiroContainer = styled.div`
  flex: 25%;
  max-width: 252px;
  width: 100%;
  margin-top: 51px;

  @media (max-width: 1280px) {
    margin-top: 43px;
  }
`

export const GiroCard = styled(CardComponent)`
  background-color: var(--white);
  border-radius: 8px;
  filter: drop-shadow(0px 3px 5px rgba(27,27,29,0.16));
  height: 100%;
  max-height: 220px;
  padding: 0;
  position: relative;
  max-width: 100%;

  @media (max-width: 1280px) {
    border-radius: 4px;
  }
`

export const GiroContentContainer = styled.div`
  background-color: var(--white);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  filter: drop-shadow(0px 3px 5px rgba(27,27,29,0.16));
  max-height: 100px;
  min-height: 100px;
  position: relative;

  > figure {
    margin: 0;

    &:last-of-type {
      &, img {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        max-height: 100px;
        min-height: 100px;
      }
    }
  }

  p, a {
    color: #3E3F5E;
    font-size: 8px;
    font-weight: 700;
    margin: 0;
    text-decoration: none;
  }

  @media (max-width: 1280px) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    max-height: 60px;
    min-height: 60px;

    > figure {
      &:last-of-type {
        &, img {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          max-height: 60px;
          min-height: 60px;
        }
      }
    }
  }
`

export const GiroBoy = styled.figure`
  left: 4%;
  position: absolute;
  top: -8%;

  @media (max-width: 1280px) {
    max-width: 60%;
    left: 0;
    top: -76%;
  }
`

export const GiroCoins = styled.figure`
  position: absolute;
  right: 8%;
  top: -6%;

  @media (max-width: 1280px) {
    right: 10%;
    max-width: 18%;
  }
`

export const GiroRoulette = styled.figure`
  bottom: 0;
  position: absolute;
  right: 0;

  @media (max-width: 1280px) {
    max-width: 60%;
  }
`

export const Logo = styled.figure`
  bottom: 14%;
  position: absolute;
  right: 17%;

  @media (max-width: 1280px) {
    bottom: 8%;
    max-width: 12%;
  }
`

export const GiroContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 4px;

  h1, p {
    font-family: MdlzBiteType;
    margin: 0;
    text-align: center;
  }

  p {
    color: #f5be4a;
    font-size: 14px;
  }

  @media (max-width: 1280px) {
    p {
      font-size: 8px;
    }
  }
`

export const GiroTitleContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  justify-content: center;

  figure {
    margin: 0;
  }

  h1 {
    color: #1d3f85;
    font-size: 48px;

    span {
      color: #f5be4a;
    }

    br {
      content: "";
      display: block;
      margin: -16px;
    }
  }

  @media (max-width: 1280px) {
    figure {
      max-width: 10%;
    }

    h1 {
      font-size: 28px;

      br {
        margin: -8px;
      }
    }
  }
`

export const TripCard = styled(CardComponent)`
  background-color: var(--white);
  border-radius: 8px;
  filter: drop-shadow(0px 3px 5px rgba(27,27,29,0.16));
  max-height: 220px;
  height: 100%;
  padding: 0;
  margin-top: 51px;
  max-width: 100%;

  @media (max-width: 1280px) {
    border-radius: 4px;
    flex: 50%;
    margin-top: 8px !important;
  }

  @media (max-width: 550px) {
    max-width: 252px;
  }
`

export const TripTitleContainer = styled.div`
  align-items: center;
  background-image: linear-gradient(90deg, #185cc6 0%, #59affe 100%);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  max-height: 100px;
  min-height: 100px;
  padding-right: 120px;
  justify-content: left;
  padding-left: 16px;

  h1 {
    color: #f8f8fb;
    font-size: 44px;
    letter-spacing: -1px;
    margin: 0;
    text-align: center;
    white-space: nowrap;
  }

  @media (max-width: 1280px) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    max-height: 60px;
    min-height: 60px;

    h1 {
      font-size: 28px;
    }
  }
`

export const TripContent = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  padding: 8px 12px;
  position: relative;

  > figure {
    bottom: 0;
    margin: 0;
    position: absolute;
    right: -8%;
  }

  > a div {
    align-items: center;
    background-image: linear-gradient(90deg, #f1973d 0%, #f7ce4f 100%);
    border-radius: 12px;
    filter: drop-shadow(0 0 20px rgba(94,94,150,0.06));
    display: flex;
    height: 90px;
    padding: 8px 32px 8px 8px;
    position: relative;
    width: fit-content;

    p {
      color: var(--white);
      font-size: 36px;
      letter-spacing: 0;
      line-height: 36px;
    }

    > figure {
      position: absolute;

      :first-of-type {
        max-width: 72%;
        right: -40%;
        top: -20%;
      }

      :last-of-type {
        max-width: 40%;
        right: -20%;
        bottom: -50%;
      }
    }
  }

  @media (max-width: 1280px) {
    > figure {
      bottom: -20%;
      right: -12%;
      max-width: 50%;
    }

    > a div {
      height: initial;

      p {
        font-size: 24px;
        letter-spacing: initial;
        line-height: initial;
      }
    }
  }

  @media (max-width: 550px) {
    > figure {
      right: -28%;
    }
  }
`

export const WednesdayContainer = styled.div`
  flex: 25%;
  max-width: 240px;
  width: 100%;
  margin-top: 51px;

  @media (max-width: 1280px) {
    margin-top: 43px;
  }
`

export const WednesdayCard = styled(CardComponent)`
  background-color: var(--white);
  border-radius: 8px;
  filter: drop-shadow(0px 3px 5px rgba(27,27,29,0.16));
  height: 100%;
  max-height: 220px;
  padding: 0;
  position: relative;
  max-width: 100%;

  @media (max-width: 1280px) {
    border-radius: 4px;
  }
`

export const WednesdayContentContainer = styled.div`
  background-color: var(--white);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  filter: drop-shadow(0px 3px 5px rgba(27,27,29,0.16));
  max-height: 100px;
  min-height: 100px;
  position: relative;

  > figure {
    margin: 0;

    &:last-of-type {
      &, img {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        max-height: 100px;
        min-height: 100px;
      }
    }
  }

  p, a {
    color: #3E3F5E;
    font-size: 8px;
    font-weight: 700;
    margin: 0;
    text-decoration: none;
  }

  @media (max-width: 1280px) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    max-height: 60px;
    min-height: 60px;

    > figure {
      &:last-of-type {
        &, img {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          max-height: 60px;
          min-height: 60px;
        }
      }
    }
  }
`

export const WednesdayProducts = styled.figure`
  bottom: -8px;
  position: absolute;
  right: -12px;

  @media (max-width: 1280px) {
    max-width: 80%;
    right: 0;
    left: 0;
    margin-inline: auto !important;
  }
`

export const WednesdayContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 4px;

  h1, p {
    font-family: MdlzBiteType;
    margin: 0;
    text-align: center;
  }

  h1 {
    color: #e9c300;
    font-size: 48px;
    line-height: 40px;
  }

  p {
    color: #310645  ;
    font-size: 14px;
  }

  @media (max-width: 1280px) {
    h1 {
      font-size: 28px;
    }

    p {
      font-size: 8px;
    }
  }
`
