import { ReactElement, useCallback, useState } from 'react'
import arrow from '../../../assets/img/incentivo-2024/leilao/arrow.png'
import coin from '../../../assets/img/incentivo-2024/leilao/coin.png'
import ameba from '../../../assets/img/incentivo-2024/login/AMEBAS_HEADER.png'
import * as S from './styled'

interface HighlightCarouselProps {
  onClick: (id: number) => void
  items: any[]
}

export default function HighlightCarousel ({ items, onClick }: HighlightCarouselProps): ReactElement {
  const [index, setIndex] = useState<number>(0)

  const handleChangeIndex = useCallback((next: number) => () => {
    const nextItem = index + next
    const newIndex = Math.max(Math.min(items.length, nextItem), 0)
    setIndex(newIndex)
  }, [index])

  return <S.ProductCarouselContainer>
    <S.AuctionContainer className="auction-mobile-title">
      <h1 data-text="Leilão">Leilão</h1>
    </S.AuctionContainer>

    <S.ProductCarouselBg>
      <S.ProductCarouselContent>
        <S.ArrowContainer disabled={index === 0} onClick={handleChangeIndex(-1)}>
          <figure>
            <img src={arrow} alt="Voltar" />
          </figure>
        </S.ArrowContainer>

        <S.ArrowContainer disabled={index === items.length - 1} onClick={handleChangeIndex(1)}>
          <figure>
            <img src={arrow} alt="Próximo" />
          </figure>
        </S.ArrowContainer>

        <S.AuctionContainer>
          <h1 data-text="Leilão">Leilão</h1>
        </S.AuctionContainer>

        <S.ProductImageContainer>
          <img src={items[index].image} alt={items[index].title} />
        </S.ProductImageContainer>

        <S.ProductDetailsContainer>
          <h2>{items[index].title}</h2>

          <S.DigimoedasContainer>
            <div>
              <p>{items[index].currentBid}</p>
              <p>digimoedas</p>
            </div>

            <figure>
              <img src={coin} alt="Mondelez Coin" />
            </figure>
          </S.DigimoedasContainer>

          <S.BidButton onClick={() => onClick(items[index].id)}>
            Dar lance
          </S.BidButton>
        </S.ProductDetailsContainer>
      </S.ProductCarouselContent>
    </S.ProductCarouselBg>

    <figure>
      <img src={ameba} />
    </figure>

    <figure>
      <img src={ameba} />
    </figure>
  </S.ProductCarouselContainer>
}
