import { ReactElement, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InputText from '../../components/Forms/InputText'
import { UserUpdatePasswordForm } from '../../components/UserUpdatePasswordForm'
import { useAuth } from '../../contexts/AuthContext'
import { useCampaign } from '../../contexts/CampaignContext'
import { useLockedModal } from '../../contexts/LockedModalContext'
import { useModal } from '../../contexts/ModalContext'
import { MaskDocument } from '../../utils/mask'
import RecoveryPassForm from './RecoveryPassForm'
import * as S from './style'

import amebaFooter from '../../assets/img/incentivo-2024/login/AMEBAS_FOOTER.png'
import amebaHeader from '../../assets/img/incentivo-2024/login/AMEBAS_HEADER.png'
import clubeMdlz from '../../assets/img/incentivo-2024/login/Clube_MDLZ.png'
import gigantesLogo from '../../assets/img/incentivo-2024/login/LOGO_GIGANTES.png'
import logoMdlz from '../../assets/img/incentivo-2024/logo_MDLZ.png'
import whatsAppIcon from '../../assets/img/incentivo-2024/WhatsApp-icon.png'
import InputPassword from '../../components/Forms/InputPassword'

export default function Login (): ReactElement {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const { doLogin, user: loggedUser } = useAuth()
  const { closeModal: closeLocked } = useLockedModal()
  const { closeModal, openModal } = useModal()
  const { campaignData } = useCampaign()

  async function handleLogin (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> {
    event.preventDefault()
    await doLogin(email, password)

    if (loggedUser.hasPassword === false) {
      openUpdatePasswordModal()
    }
  }

  const handleConfirmTerm = useCallback(async () => {
    closeLocked()
    navigate('/home')
  }, [closeLocked, navigate])

  const openUpdatePasswordModal = useCallback(() => {
    closeLocked(() => {
      openModal({
        header: 'Atualize sua senha',
        body: <UserUpdatePasswordForm onSave={handleConfirmTerm} />
      })
    })
  }, [closeModal, openModal, handleConfirmTerm])

  const handleMask = useCallback((login: any) => {
    const charCode = login.charCodeAt()
    if (charCode > 47 && charCode < 58) {
      return MaskDocument(login)
    }
    return login
  }, [])

  const handleAskRecovery = useCallback(() => {
    openModal({
      header: 'Redefinir Senha',
      body: <RecoveryPassForm />
    })
  }, [openModal])

  return (
    <>
      <S.LoginBg>
        <S.Header>
          <S.HeaderImg src={amebaHeader} />

          <S.HeaderContent>
            <S.MdlzLogoContainer>
              <img alt="Mondelēz International" src={logoMdlz} />
            </S.MdlzLogoContainer>

          </S.HeaderContent>
        </S.Header>

        <S.Footer>
          <S.FooterImg src={amebaFooter} />
        </S.Footer>

        <S.LoginContainer>
          <S.GigantesLogo>
            <img src={gigantesLogo} alt="Gigantes da Execução 2024" />
          </S.GigantesLogo>

          <S.LoginFormContainer>
            <S.LoginFormTitle>Gigantes da Execução</S.LoginFormTitle>

            <S.AbsoluteGigantesLogo>
              <img src={gigantesLogo} alt="Gigantes da Execução 2024" />
            </S.AbsoluteGigantesLogo>

            <S.LoginForm onSubmit={handleLogin}>
              <InputText
                label="Matrícula ou Email"
                mask={handleMask}
                name="email"
                onChange={setEmail}
              />

              <InputPassword
                label="Senha"
                name="password"
                onChange={setPassword}
                type="password"
              />

              <S.LinkText onClick={handleAskRecovery}>Recuperar minha senha</S.LinkText>

              <S.LoginButton>Entrar</S.LoginButton>
            </S.LoginForm>

            <S.EmailContainer>
              <S.Text>Se você tiver problemas entre em contato com o e-mail</S.Text>

              <S.EmailLink href={`mailto: ${campaignData.email}`}>{campaignData.email}</S.EmailLink>
            </S.EmailContainer>

            <S.EmailContainer>

              <S.Text>Clique abaixo para acessar</S.Text>

              <S.Text>Acesso exclusivo para o canal TT</S.Text>

              <S.ClubeMdlzButton href='https://clubemdlz.com.br/'>
                <img src={clubeMdlz} />
              </S.ClubeMdlzButton>
            </S.EmailContainer>

          </S.LoginFormContainer>
        </S.LoginContainer>
      </S.LoginBg>

      <S.WhatsAppLink href='https://wa.me/5511988637184' target='__blank'>
        <figure>
          <img src={whatsAppIcon} alt="WhatsApp" />
        </figure>
      </S.WhatsAppLink>
    </>
  )
}
