import styled from 'styled-components'
import TemplateBase from '../../template'

export const Template = styled(TemplateBase)`
  &.content-grid {
    width: initial;
    min-height: initial;
    max-width: initial;
    padding: initial;
  }
`

export const BgContainer = styled.div`
  background: linear-gradient(0deg, #0f479d 0%, #0a3d83 100%);
  min-height: 100vh;
  width: 100vw;

  > div {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;

    @media screen and (max-width: 1280px) and (orientation: portrait) {
      background-size: cover;
    }
  }

`

export const ContentContainer = styled.div.attrs((props) => {
  return {
    ...props,
    className: 'content-grid'
  }
})`
  align-items: center;
  padding-top: 200px;

  @media screen and (min-width: 680px) and (max-width: 900px) {
    padding-inline: 112px !important;
  }

  > div.countdown {
    margin-bottom: 64px;

    @media screen and (orientation: landscape) {
      display: none;
    }
  }
`

export const NoContentText = styled.div`
  align-items: center;
  background-color: #ffffff80;
  backdrop-filter: blur(8px);
  border-radius: 20px;
  display: flex;
  font-size: 32px;
  justify-content: center;
  padding: 112px;
`
