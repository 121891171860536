import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { State } from '../..'
import { useModalMdlz } from '../../../../../contexts/ModalMdlzContext'
import { makeCreateNoSusto } from '../../../../../main/factories/makeCreateNoSusto'
import TryAgainModal from '../Modals/TryAgainModal'
import Paddle from './Paddle'
import BRICKS_INITIAL_STATE from './bricksPositions'
import { adjustHorizontal, adjustVertical, getRandomDirection } from './helpers'
import BreakoutProps from './props'
import * as S from './styled'

const createNoSusto = makeCreateNoSusto()

const BALL_INITIAL_STATE = { x: adjustHorizontal(200), y: adjustVertical(420) }
const PADDLE_INITIAL_STATE = { x: adjustHorizontal(130), y: adjustVertical(480) }

export default function PontosNoSusto ({ clearScore, increaseScore, setState, state }: BreakoutProps): ReactElement {
  const { closeModal, openModal } = useModalMdlz()
  const containerRef = useRef<HTMLDivElement>(null)
  const [ballPosition, setBallPosition] = useState(BALL_INITIAL_STATE)
  const [ballDirection, setBallDirection] = useState({ x: getRandomDirection(), y: getRandomDirection() })
  const [ballSpeed, setBallSpeed] = useState(1)
  const [paddlePosition, setPaddlePosition] = useState(PADDLE_INITIAL_STATE)
  const [bricks, setBricks] = useState([...BRICKS_INITIAL_STATE])
  const [isGameOver, setIsGameOver] = useState(false)

  const handlePaddleDrag = (newX: number): void => {
    const limitedX = Math.min(Math.max(newX, 0), adjustVertical(255))
    setPaddlePosition({ x: limitedX, y: adjustVertical(480) })
  }

  const moveBall = (): void => {
    const { x, y } = ballPosition
    let { x: dx, y: dy } = ballDirection

    // Check collision with walls
    if (x + dx > adjustHorizontal(400) || x + dx < 0) dx = -dx
    if (y + dy < 0) dy = -dy

    // Check collision with paddle
    if (y + dy > adjustVertical(450) && x > paddlePosition.x && x < paddlePosition.x + adjustHorizontal(167)) {
      dy = -dy
      setBallSpeed(ballSpeed + 0.1)
    }

    // Check collision with bricks
    const brickWidth = adjustHorizontal(48)
    const brickHeight = adjustVertical(48)

    const hitBrickIndex = bricks.findIndex((brick) => {
      if (!brick.exists) return false

      const ballRadius = adjustHorizontal(26) / 2

      const brickRight = brick.x + brickWidth
      const brickBottom = brick.y + brickHeight

      const ballNextX = x + dx
      const ballNextY = y + dy

      const ballRight = ballNextX + ballRadius
      const ballBottom = ballNextY + ballRadius

      return (
        ballRight > brick.x &&
        ballNextX < brickRight &&
        ballBottom > brick.y &&
        ballNextY < brickBottom
      )
    })

    if (hitBrickIndex >= 0) {
      const updatedBricks = bricks.map((brick, index) => index === hitBrickIndex ? { ...brick, exists: false } : brick)

      setBricks(updatedBricks)
      increaseScore(updatedBricks[hitBrickIndex].points)

      const brickCenterX = bricks[hitBrickIndex].x + brickWidth / 2
      const brickCenterY = bricks[hitBrickIndex].y + brickHeight / 2

      if (x < brickCenterX) {
        dx = -Math.abs(dx)
      } else {
        dx = Math.abs(dx)
      }

      if (y < brickCenterY) {
        dy = -Math.abs(dy)
      } else {
        dy = Math.abs(dy)
      }
    }

    if (y + dy > adjustVertical(500)) {
      setIsGameOver(true)
    } else {
      setBallPosition({ x: x + dx * ballSpeed, y: y + dy * ballSpeed })
      setBallDirection({ x: dx, y: dy })
    }
  }

  useEffect(() => {
    if (isGameOver) {
      setState((prev) => ({ ...prev, currentState: State.LOSE })
      )
      handleTryAgainModal()
    }

    const interval = setInterval(() => {
      moveBall()
    }, 16) // 60 FPS

    return () => clearInterval(interval)
  }, [ballPosition, ballDirection, ballSpeed, isGameOver, bricks, state])

  const handleViewPoints = useCallback(() => {
    setState((prev) => ({ ...prev, currentState: State.FINISHED }))

    closeModal()
  }, [state.currentState, state.points])

  const savePlay = useCallback(async (): Promise<void> => {
    await createNoSusto.handle({
      points: state.points
    })
    setState((prev) => ({ ...prev, plays: prev.plays++ }))
  }, [state])

  const handleTryAgainModal = useCallback(async (): Promise<void> => {
    savePlay()
    if (state.plays === 1) {
      handleViewPoints()
      return
    }
    openModal({
      body: <TryAgainModal
        handleTryAgain={handleRestart}
        handleViewPoints={handleViewPoints}
        time={state.time}
      />,
      customStyles: {
        backgroundColor: '#e9c300',
        border: '4px solid var(--white)',
        maxWidth: 468,
        padding: 0,
        width: '80%'
      },
      hasCloseButton: false,
      size: 'fit'
    })
  }, [state])

  const handleRestart = (): void => {
    setIsGameOver(false)
    setBricks([...BRICKS_INITIAL_STATE])
    setBallPosition(BALL_INITIAL_STATE)
    setBallDirection({ x: getRandomDirection(), y: getRandomDirection() })
    setBallSpeed(1)
    setPaddlePosition(PADDLE_INITIAL_STATE)
    setState((prev) => ({ ...prev, currentState: State.PLAYING }))
    clearScore()
    closeModal()
  }

  useEffect(() => {
    if (!state) return

    const interval = setInterval(() => {
      if (state.currentState !== State.PLAYING) return

      setState((prev) => {
        const newState = { ...prev }
        newState.time -= 1

        if (newState.time === 0 && newState.points === 0) {
          newState.currentState = State.LOSE
          handleTryAgainModal()
        }

        const bricksLeft = bricks.some(({ exists }) => exists)

        if ((newState.time === 0 && newState.points > 0) || !bricksLeft) {
          savePlay()
          newState.currentState = State.FINISHED
        }

        return newState
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [state, bricks])

  return <S.Container ref={containerRef}>
    {bricks.map(({ img, x, y, exists }, index) => exists && <S.Brick key={index} $brickImg={img} $x={x} $y={y} />)}

    <S.Ball $x={ballPosition.x} $y={ballPosition.y} />
    <Paddle containerRef={containerRef} onDrag={handlePaddleDrag} x={paddlePosition.x} />
  </S.Container>
}
