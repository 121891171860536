import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PageLoaderComponent from '../../components/PageLoader';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';
import SectionKPIs from '../Regulamento/SectionKPIs';
import MecanicasSelectorComponent from './MecanicasSelectorComponent';
import * as S from './style';

function Mecanicas () {
  const [channelName, setChannelName] = useState()
  const { search } = useLocation();
  const { userCan } = useAuth();

  const channel = useMemo(() => {
    const channel = new URLSearchParams(search).get('channel');
    if (userCan('mechanic:read-all')) return channel;
    return channelName;
  }, [search, channelName, userCan]);

  useEffect(() => {
    (async () => {
      await api.get('/mecanicas')
      const response = await api.get('/me')
      setChannelName(response.data.channel.name)
    })()
  }, [setChannelName])


  if (!channelName) return <PageLoaderComponent />

  return (
    <>
      <S.Template>
        <S.Container>
          {userCan('mechanic:read-all') && !channel && <MecanicasSelectorComponent />}
          {channel && <SectionKPIs mecanica={channel} />}
        </S.Container>
      </S.Template >
    </>
  );
}

export default Mecanicas;
