import styled from 'styled-components'
import mobilePurpleBg from '../../assets/img/hub/bottom-bg-mobile.png'
import purpleBg from '../../assets/img/hub/bottom-bg.png'
import TemplateBase from '../../template'

export const Template = styled(TemplateBase)`
  &.content-grid {
    width: initial;
    min-height: initial;
    max-width: initial;
    padding: initial;
  }
`

export const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100vw;

  h1, p, span, a, button {
    font-family: MdlzBiteType;
    margin: 0;

    br {
      content: "";
      display: block;
    }
  }

  a {
    text-decoration: none;
  }

  @media (min-width: 1280px) {
    gap: 32px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    justify-content: center;
    padding: 0 32px 32px;
  }
`

export const MissionsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 32px;

  @media (max-width: 1280px) {
    gap: 32px 16px;
  }

  @media (max-width: 520px) {
    align-items: center;
    flex-direction: column-reverse;
    width: 100%;
  }
`

export const GamesSection = styled.div`
  align-items: center;
  background-image: url(${purpleBg}), linear-gradient(to top, #551a74 10%, var(--white) 10%);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;

  @media (max-width: 1280px) {
    background-image: url(${mobilePurpleBg}), linear-gradient(to top, #532375 40%, var(--white) 40%);
  }
`

export const AuctionSection = styled.div`
  align-items: center;
  background-color: #551a74;
  display: flex;

  h1.section-title {
    color: #f5be4a;
  }

  @media (max-width: 1280px) {
    background-color: #532375;
  }
`

export const MecanicaExercisesSection = styled.div`
  align-items: center;
  background-color: #551a74;
  display: flex;
  flex-wrap: wrap;
  gap: 32px 16px;
  padding-bottom: 132px !important;

  h1.section-title {
    color: #f5be4a;
  }

  @media (max-width: 1280px) {
    background-color: #532375;
  }
`
