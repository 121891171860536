import { ReactElement, useCallback, useMemo, useState } from 'react'
import { SliderProps } from './props'
import * as S from './style'

function Slider (props: SliderProps): ReactElement {
  const [value, setValue] = useState<number>(10)
  const [getInitialValue] = useState<number>(props.value)

  const progress = useMemo(() => {
    if (!value) return 0
    return (value - 10) / 0.9
  }, [value])

  const handleChange = useCallback((evt: any) => {
    const number = Number.parseInt(evt.target.value)
    setValue(number)
    props.onChange((number + getInitialValue).toString())
  }, [props])

  return <S.Container>
    <S.Slider type="range" step={10} value={value} min={10} max={100} onChange={handleChange} />
    <S.Progress progress={progress}></S.Progress>
    <S.ToolTip progress={progress}>
      <div>{props.value + value}</div>
    </S.ToolTip>
  </S.Container>
}

export default Slider
