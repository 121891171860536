import { Link } from 'react-router-dom'
import styled from 'styled-components'
import correct from '../../../assets/img/hub/excercises/correct-icon.png'
import CardComponent from '../../../components/Card'
import SectionHeaderTitleComp from '../SectionHeaderTitle'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  h1.section-title {
    color: var(--white);
  }
`

export const SectionHeaderTitle = styled(SectionHeaderTitleComp)`
  h1 {
    color: var(--white);
  }
`

export const Card = styled(CardComponent)`
  background-color: #fff;
  border-radius: 16px;
  filter: drop-shadow(0px 3px 5px rgba(27,27,29,0.16));
  padding: 0;
  min-width: 500px;

  figure {
    margin: 0;
  }

  @media (max-width: 1280px) {
    border-radius: 10px;
    min-width: 308px;
  }
`

export const RulesButton = styled(Link)`
  background-color: #ffdc39;
  border-radius: 16px;
  color: #4f2170;
  margin-top: 20px !important;
  padding: 8px 32px;
  width: fit-content;
  z-index: 0;
`

export const Banner = styled.figure`
  display: flex;
  height: 120px;
  position: relative;

  h1 {
    bottom: 0;
    color: #f6c349;
    font-size: 46px;
    height: fit-content;
    left: 6%;
    margin: auto 0 !important;
    position: absolute;
    top: 0;

    br {
      content: "";
      display: block;
      margin: -8px;
    }
  }

  img {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  @media (max-width: 1280px) {
    height: 74px;

    h1 {
      font-size: 28px;

      br {
        margin: -8px;
      }
    }

    img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
`

export const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 284px;

  @media (max-width: 1280px) {
    height: initial;
  }
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 24px;
  width: 44%;

  h1, p {
    margin: 0;
    text-align: center;
  }
`

export const UploadContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  h1 {
    color: #1d3f85;
    font-family: MdlzBiteType;
    font-size: 14px;
  }

  > p {
    color: #4f2170;
    font-size: 18px;
    font-family: Rajdhani !important;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: 16px !important;
    white-space: nowrap;
  }

  @media (max-width: 1280px) {
    > p {
      font-size: 10px;
      line-height: initial;
    }
  }
`

export const UploadButton = styled.button`
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;

  :focus {
    outline: none;
  }

  div {
    align-items: center;
    display: flex;
    justify-content: center;

    &:first-of-type {
      background-color: #1d3f85;
      border-radius: 50%;
      height: 72px;
      width: 72px;

      p {
        color: var(--white);
        display: inline-block;
        font-size: 54px;
        line-height: 0px;
        margin: -6px 0 0;
      }
    }

    @media (max-width: 1280px) {
      &:first-of-type {
        height: 44px;
        width: 44px;

        p {
          font-size: 32px;
        }
      }

      &:last-of-type {
        p {
          font-size: 8px;
        }
      }
    }
  }
`

export const UploadText = styled.div`
  background-color: #f6c349;
  border-radius: 4px;
  top: 75%;
  padding: 4px 8px;
  position: absolute;
  margin: 0 auto;
  width: fit-content;

  p {
    color: var(--primary);
    font-family: Rajdhani;
    font-size: 14px;
    text-transform: uppercase;
  }
`

export const SendButton = styled.button`
  background: #93ef93;
  border: 1px solid #65bd65;
  border-radius: 14px;
  color: #2e832e;
  font-size: 16px;
  font-weight: 500;
  height: 54px;
  margin-top: 48px !important;

  &:disabled {
    background: var(--white);
    border: 1px solid #dedee9;
    color: #9192a4;
  }

  @media (max-width: 1280px) {
    border-radius: 8px;
    font-size: 10px;
    height: 32px;
    margin-top: 24px !important;
  }
`

export const VideoContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-right: 24px;
`

export const Character = styled.figure`
  position: absolute;
  right: -8%;
  bottom: -23%;

  @media (max-width: 1280px) {
    bottom: -22%;
    right: -48%;

    img {
      max-width: 66%;
    }
  }
`

export const ArrowsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 20px;
  z-index: 0;

  button {
    background-color: transparent;
    cursor: pointer;
    padding-inline: 8px;

    figure {
      width: 8px;
      margin: 0;

      @media (max-width: 1280px) {
        width: 4px;
      }
    }

    :focus {
      outline: none;
    }
  }

`

export const ModalContainer = styled.div`
  height: 100%;
  padding: 32px;
  width: 100%;
`

export const ModalBody = styled.div`
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 0px 16px #00000040;
  color: #4f1e70;
  display: flex;
  flex-direction: column;
  font-family: MdlzBiteType;
  font-size: 24px;
  gap: 24px;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 200px;

  h1 {
    color: inherit;
    display: flex;
    font-size: 32px;

    &:after, &:before {
      content: url(${correct});
      display: block;
      margin: 0 8px;
    }
  }

  p {
    color: inherit;
    text-align: center;
  }
`
