import styled from 'styled-components'
import TemplateComponent from '../../../template'
import TopBannerComponent from '../TopBannerHubCampanha'

export const Template = styled(TemplateComponent)`
  &.content-grid {
    width: initial;
    min-height: initial;
    max-width: initial;
    padding: initial;
  }
`

export const TopBanner = styled(TopBannerComponent)`
    .counter {
        display: none;
    }
`

export const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100vw;

  .content-grid {
    padding: 0 80px;
    margin-top: 20px;

     @media (max-width: 764px) {
        padding-inline: 28px;
     }
  }

  h1, p, span, a, button {
    font-family: MdlzBiteType;
    margin: 0;

    br {
      content: "";
      display: block;
    }
  }

  a {
    text-decoration: none;
  }

  @media (min-width: 1280px) {
    gap: 32px;
  }
`
export const ImgPreview = styled.img`
    width: 32px;
    height: 35px;
`

export const Form = styled.form`
    display: flex;
    gap: 16px;

    button.button {
        height: 68px;
    }

    @media (max-width: 520px) {
        padding-bottom: 50px;
    }
`

export const RegulationOpenContainer = styled.div`
    padding-bottom: 100px !important;
    .post-open-cover {
        background-color: #3e3f5e;
    }
    .post-open-image {
        background-color: #615dfa;
    }
`

export const RegulationRelatedPreview = styled.div`
    max-width: 100% !important;
    padding: 0 100px !important;

    .post-preview-image {
        background-color: #615dfa;
    }
    .section-header-info {
        margin-left: 1rem;
    }
    .post-preview {
        padding-inline: 1rem;
    }

    @media screen and (max-width: 960px) {
        padding: 0 40px !important;
    }
`

export const BannerContainer = styled.div`
    overflow: hidden;
`

export const Banner = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
`

export const RegulationCard = styled.div`
    background-color: #fff;
    padding: 64px;
    width:85% !important;
    max-width: 1153px !important;
    margin: 0 auto;
    position: relative;

    .regulation-open-title, .regulation-open-paragraph {
        color: #3e3f5e;
    }

    .regulation-open-paragraph {
        font-family: 'Roboto';
        line-height: 140%;
        margin-bottom: 16px;
        overflow-x: auto;

        img {
            max-width: 100%;
            height: auto;
        }

        td {
            border: 1px solid #3e3f5e;
            padding: 16px;

            p {
                margin-bottom: 0;
            }
        }

        p, h3 {
            font-family: 'Roboto';
            line-height: 140%;
            margin-bottom: 16px;
        }
    }

    @media (max-width: 764px) {
        padding-inline: 0;
        width: 100% !important;
    }
`

export const Carousel = styled.div`
    overflow-x: scroll;
    padding-bottom: 0;

    &>div {
        display: flex;

        &>div {
            flex: 1 0 380px;
        }
    }
`
