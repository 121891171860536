import styled from 'styled-components'
import ballImg from '../../assets/oreo.png'
import { adjustHorizontal, adjustVertical } from './helpers'

interface BallProps {
  $x: number
  $y: number
}

interface BrickProps extends BallProps {
  $brickImg: string
}

export const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 13px;
  height: 532px;
  filter: drop-shadow(0px 8px 3.5px rgba(0,0,0,0.65));
  position: relative;
  max-width: 421px;
  min-width: 421px;

  @media (max-width: 480px) {
    height: 360px;
    max-width: 300px;
    min-width: 300px;
  }
`

export const Brick = styled.div.attrs<BrickProps>((props) => {
  return {
    ...props,
    style: {
      left: props.$x,
      top: props.$y
    }
  }
})<BrickProps>`
  background: url(${({ $brickImg }) => $brickImg}) no-repeat center center;
  background-size: contain;
  height: 48px;
  position: absolute;
  width: 48px;

  @media (max-width: 480px) {
    height: 28px;
    width: 28px;
  }
`

export const Ball = styled.div.attrs<BallProps>((props) => {
  return {
    ...props,
    style: {
      left: props.$x,
      top: props.$y
    }
  }
})<BallProps>`
  position: absolute;
  width: ${adjustHorizontal(26)}px;
  height: ${adjustVertical(25)}px;
  background: url(${ballImg}) no-repeat center center;
  background-size: contain;
`
