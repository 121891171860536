import { AxiosInstance } from 'axios'
import { LoadRoles } from '../../domain/use-case/LoadRoles'

export class ApiLoadRoles implements LoadRoles {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (): Promise<LoadRoles.Result> {
    const response = await this.api.get('/roles')
    return response.data
  }
}
