import { ReactElement } from 'react'
import CountdownProps from './props'
import * as S from './styled'

export default function CountdownComponent ({ time }: CountdownProps): ReactElement {
  const minutes = String(Math.floor(time / 60)).padStart(2, '0')
  const seconds = String(Math.floor(time % 60)).padStart(2, '0')

  return <S.CountdownContent>
    <p className="title">Tempo</p>

    <S.CountdownNumbersContainer>
      <div>
        <div>
          <S.CountdownNumberContainer>
            <p>{minutes[0]}</p>
          </S.CountdownNumberContainer>
          <S.CountdownNumberContainer>
            <p>{minutes[1]}</p>
          </S.CountdownNumberContainer>
        </div>
      </div>

      <S.SeparatorContainer>
        <p>:</p>
      </S.SeparatorContainer>

      <div>
        <div>
          <S.CountdownNumberContainer>
            <p>{seconds[0]}</p>
          </S.CountdownNumberContainer>
          <S.CountdownNumberContainer>
            <p>{seconds[1]}</p>
          </S.CountdownNumberContainer>
        </div>
      </div>
    </S.CountdownNumbersContainer>
  </S.CountdownContent>
}
