import InputSelect from '../Forms/InputSelect';

const cargos = [
  {
    name: 'Vendas',
    content: [
      { name: 'Executivo de Vendas' },
      { name: 'Gerente de Área' },
      { name: 'Gerente Regional' }
    ]
  },
  {
    name: 'Merchandising',
    content: [
      { name: 'Aprendiz' },
      { name: 'Promotor' },
      { name: 'Coordenador' },
      { name: 'Gerente' }
    ]
  },
  {
    name: 'Staff',
    content: [
      { name: 'Diretoria MDLZ' },
      { name: 'MDLZ Staff' }
    ]
  }
]

function CargoComponent ({ ...rest }) {
  return <InputSelect {...rest} name="cargo" label="Cargo">
    <option value="">Selecione o cargo</option>
    {cargos.map(grupo => <optgroup label={grupo.name}>
      {grupo.content.map(cargo => <option selected={rest.value === cargo.name} value={cargo.name}>{cargo.name}</option>)}
    </optgroup>
    )}
  </InputSelect>;
}

export default CargoComponent;
