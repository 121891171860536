import { ReactElement, useCallback } from 'react'
import logo from '../../../../assets/img/hub/games/LOGO-GIGANTES.png'
import coins from '../../../../assets/img/hub/games/coins.png'
import giraBoy from '../../../../assets/img/hub/games/gira-boy.png'
import giroBanner from '../../../../assets/img/hub/games/gira-giro-banner.png'
import giroRoulette from '../../../../assets/img/hub/games/giro-roulette.png'
import LogoGigaGiro from '../../../../components/LogoGigaGiro'
import { useModalMdlz } from '../../../../contexts/ModalMdlzContext'
import StartGameProps from './props'
import * as S from './styled'

export default function StartGame ({ OnStart }: StartGameProps): ReactElement {
  const { closeModal, openModal } = useModalMdlz()

  const handleModalToggle = useCallback(async () => {
    openModal({
      size: 'medium',
      body: <S.ModalContainer>
        <h1>Regras Gerais:</h1>

        <ul>
          <li>Gire a roleta e espere até parar em um dos 7 desafios.</li>
          <li>Assim que a roleta parar, aparecerá 1 pergunta e 3 opções de respostas.</li>
          <li>Você terá 40 segundos para responder.</li>
          <li>Se estourar o limite de tempo, perderá a pontuação e seguirá para a segunda pergunta.</li>
          <li>Serão 2 chances de jogar, errando ou acertando.</li>
          <li>Cada resposta certa = 200 pontos.</li>
          <li>Total de pontos = total de digimoedas que você ganhar.</li>
        </ul>

        <S.ModalButton onClick={startGame}>
          Jogar
        </S.ModalButton>
      </S.ModalContainer>
    })
  }, [closeModal, openModal])

  const startGame = (): void => {
    closeModal()
    OnStart()
  }

  return <S.Container>
    <S.GiroContentContainer>
      <S.GiroRoulette>
        <img src={giroRoulette} alt="" />
      </S.GiroRoulette>

      <S.Logo>
        <img src={logo} alt="" />
      </S.Logo>

      <S.GiroBoy>
        <img src={giraBoy} alt="" />
      </S.GiroBoy>

      <S.GiroCoins>
        <img src={coins} alt="" />
      </S.GiroCoins>

      <figure>
        <img src={giroBanner} alt="" />
      </figure>
    </S.GiroContentContainer>

    <S.GiroContent>
      <LogoGigaGiro />

      <button onClick={handleModalToggle} >Jogar</button>
    </S.GiroContent>
  </S.Container>
}
