import { useAuth } from '../../contexts/AuthContext';
import Template from '../../template';
import * as S from './style';

export default function Reports (props) {
  const { userCan } = useAuth();
  return (
    <Template>
      <div className="grid medium-space">
        <S.Grid>
          <S.GridFastAcccess>
            {userCan('create:newsfeed') && <S.FastLink to="/members" className="product-category-box category-all results" >
              <p className="product-category-box-title">Membros</p>
              <p className="product-category-box-text">Envie mensagem para os nossos membros</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('system:manage') && <S.FastLink to="/admin/results" className="product-category-box category-all results" >
              <p className="product-category-box-title">Importar Resultados</p>
              <p className="product-category-box-text">Importar resultados da campanha</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('system:manage') && <S.FastLink to="/admin/quiz" className="product-category-box category-all" >
              <p className="product-category-box-title">Criar Quiz</p>
              <p className="product-category-box-text">Crie ou edite um quiz!</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('system:manage') && <S.FastLink to="/admin/room" className="product-category-box category-all" >
              <p className="product-category-box-title">Lives</p>
              <p className="product-category-box-text">Crie novas lives!</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('post:create') && <S.FastLink to="/admin/blog" className="product-category-box category-all" >
              <p className="product-category-box-title">Admin Blog</p>
              <p className="product-category-box-text">Poste uma nova notícia</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('system:manage') && <S.FastLink to="/admin/banner" className="product-category-box category-all" >
              <p className="product-category-box-title">Admin Banner</p>
              <p className="product-category-box-text">Gestão de banners</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('users:read') && <S.FastLink to="/admin/usuarios" className="product-category-box category-all" >
              <p className="product-category-box-title">Usuários</p>
              <p className="product-category-box-text">Consultar e editar cadastro de usuários</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('users-approve:read') && <S.FastLink to="/admin/not-approved" className="product-category-box category-all" >
              <p className="product-category-box-title">Aprovar Usuários</p>
              <p className="product-category-box-text">Aprovar usuários</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('user-status:export') && <S.FastLink to="/admin/reports" className="product-category-box category-all" >
              <p className="product-category-box-title">Extração de relatórios</p>
              <p className="product-category-box-text">Exporte os dados da campanha!</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            <S.FastLink to="/admin/dashboard" className="product-category-box category-all" >
              <p className="product-category-box-title">Dashboard Geral</p>
              <p className="product-category-box-text">Informações gerais da campanha</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>

            {userCan('system:manage') && <S.FastLink to="/admin/notice" className="product-category-box category-all" >
              <p className="product-category-box-title">Postar notícia</p>
              <p className="product-category-box-text">Enviar uma nova notícia</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('homolog-ranking:read') && <S.FastLink to="/ranking/homolog" className="product-category-box category-all" >
              <p className="product-category-box-title">Ver o ranking</p>
              <p className="product-category-box-text">Ver qualquer ranking do site</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('manage:giftcard-orders') && <S.FastLink to="/admin/giftcards" className="product-category-box category-all" >
              <p className="product-category-box-title">Ver Giftcards</p>
              <p className="product-category-box-text">Ver seus giftcards</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('read:pedidos') && <S.FastLink to="/admin/resgate-de-premios" className="product-category-box category-all" >
              <p className="product-category-box-title">Resgatar Prêmios</p>
              <p className="product-category-box-text">Resgate seus prêmios</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('manage:marketplace-banner') && <S.FastLink to="/admin/marketplace/banner" className="product-category-box category-all" >
              <p className="product-category-box-title">Banners do marketplace</p>
              <p className="product-category-box-text">Gerenciar banners do marketplace</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('post:create') && <S.FastLink to="/admin/campanha" className="product-category-box category-all" >
              <p className="product-category-box-title">Admin Campanhas</p>
              <p className="product-category-box-text">Crie uma nova campanha</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}
          </S.GridFastAcccess>
        </S.Grid>
      </div>
    </Template >
  );
}
