import { ReactElement } from 'react'
import footPrintIcon from '../assets/footprint-icon.svg'
import * as S from './style'

export default function ListGigaPassosTextCCMT (): ReactElement {
  return <S.ListGigaPassosTextContainer>
    <div>
      <p>&lt;&lt;&lt;&lt; 49% = 0 Gigapassos </p>
      <img src={footPrintIcon} alt='Ícone de pegada' />
    </div>
    <div>
      <p>50% a 59% = 50 Gigapassos </p>
      <img src={footPrintIcon} alt='Ícone de pegada' />
    </div>
    <div>
      <p>60% a 69% = 60 Gigapassos </p>
      <img src={footPrintIcon} alt='Ícone de pegada' />
    </div>
    <div>
      <p>70% a 79% = 70 Gigapassos </p>
      <img src={footPrintIcon} alt='Ícone de pegada' />
    </div>
    <div>
      <p>80% a 89% = 80 Gigapassos </p>
      <img src={footPrintIcon} alt='Ícone de pegada' />
    </div>
    <div>
      <p>90% &gt;&gt;&gt;&gt; % de atingimento</p>
    </div>
    <div>
      <p>é o mesmo que o de Gigapassos</p>
      <img src={footPrintIcon} alt='Ícone de pegada' />
    </div>
  </S.ListGigaPassosTextContainer>
}
