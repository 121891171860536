import styled from 'styled-components'

export const DigimoedasCountContainer = styled.div`
  background-color: rgba(71,119,217,0.9019607843137255);
  border-radius: 20px;
  display: flex;
  filter: drop-shadow(0px 6px 4px rgba(0,0,0,0.15));
  justify-content: space-around;
  gap: 8px;
  padding: 16px 8px 16px 16px;
  width: 100%;

  p {
    font-family: MdlzBiteType;
    font-weight: 700;
    margin: 0;
    text-align: center;
    vertical-align: middle;
  }

  @media screen and (max-width: 1280px) and (orientation: portrait) {
    margin-left: 16px;
  }
`

export const DigimoedasCountContent = styled.div`
  align-self: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;

  > p {
    color: #fff;
    white-space: nowrap;
  }

  > div {
    background-color: #f7cb25;
    border-radius: 30px;
    display: flex;
    filter: drop-shadow(0px 6px 4px rgba(0,0,0,0.15));
    flex-direction: column;
    padding: 0 16px 8px;

    > p {
      color: #1d3f85;
      font-size: 52px;
      font-weight: 600;

      &:last-child {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }

    @media screen and (max-width: 1280px) and (orientation: portrait) {
      border-radius: 12px;
      padding-inline: 16px;

      > p {
        font-size: 44px;

        &:last-child {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 1px;
        }
      }
    }

    @media screen and (max-width: 450px) and (orientation: portrait) {
      padding-inline: 8px;
    }
  }

  @media screen and (max-width: 450px) and (orientation: portrait) {
    > p {
      font-size: 16px;
    }
  }
`

export const SafeContainer = styled.figure`
  position: relative;
  width: 107px;

  img {
    bottom: -36px;
    height: auto;
    position: absolute;
    width: 100%;

    @media screen and (max-width: 1280px) and (orientation: portrait) {
      bottom: -28px;
    }
  }
`
